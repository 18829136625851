class UserHelpers {
  static isAdmin(user) {
    if (!user) return false;
    const { user_id, roles } = user;
    return roles.includes('admin');
  }

  static isOrganizer(user, community) {
    if (!user || !community) return false;
    const { user_id, roles } = user;

    if (community.members && community.members[user_id]) {
      const { role } = community.members[user_id];
      if (role && role.length) {
        return role.includes('organizer');
      }
    }
    return false;
  }
  static isTeamMember(user, community) {
    if (!user || !community) return false;
    const { user_id, roles } = user;

    if (community.members && community.members[user_id]) {
      const { role } = community.members[user_id];
      if (role && role.length) {
        return role.includes('team_member');
      }
    }
    return false;
  }

  static isCoMember(user, community) {
    if (!user || !community) return false;
    const { user_id, roles } = user;

    if (community.members && community.members[user_id]) {
      const { role } = community.members[user_id];
      if (role && role.length) {
        return role.includes('co_member');
      }
    }
    return false;
  }

  static getUserPreferencesStr(user) {
    try {
      if (!user) return '';

      const { profile } = user;

      const industries = profile.user_preferences?.industries?.join(' ') || '';
      const technologies =
        profile.user_preferences?.technologies?.join(' ') || '';

      return `${industries} ${technologies}`;
    } catch (err) {
      console.error(err);
      return '';
    }
  }
}

export default UserHelpers;
