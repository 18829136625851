const emitLocalEvent = (event, data) => {
  try {
    return new Promise((resolve, reject) => {
      const ev = new CustomEvent(event, { detail: data });
      document.dispatchEvent(ev);
      setTimeout(resolve, 30);
    });
  } catch (err) {
    return Promise.resolve();
  }
};

export { emitLocalEvent };
