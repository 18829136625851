import { isGuid } from '~lib/utils/strings';

class CommentsHelpers {
  static getAllCompanyComments({
    companyCommentMap = null,
    vendorStats = {},
    companyId = null,
    factorKey = null,
    listId = null,
  }) {
    try {
      if (!companyCommentMap || !companyId) return [];

      const evaluationComments = Object.keys(
        vendorStats.decisions || {}
      )?.reduce((acc, userId) => {
        const decision = vendorStats.decisions[userId];

        if (!listId || listId === decision.list_id) {
          decision.timeline.forEach((timelineDec, idx) => {
            const curDec = timelineDec.decision;

            const decComment = {
              // comment_id: `${companyId}_${timelineDec.owner_id}_${idx}`,
              comment_id: null,
              type: isGuid(userId) ? '' : 'external',
              data: {
                text: decodeURIComponent(curDec?.comment || ''),
              },
              evaluation_id: timelineDec.evaluation_id,
              user_id: isGuid(userId) ? userId : null,
              email: isGuid(userId) ? null : userId,
              created_at: timelineDec.created_at,
            };

            if (curDec?.comment) {
              acc.push(decComment);
            }

            Object.keys(curDec?.form_data || {}).forEach((decFactorKey) => {
              const fDec = curDec.form_data[decFactorKey];
              const factorComment = fDec?.comment;

              // if (factorKey && factorComment) {
              if (factorComment) {
                acc.push({
                  ...decComment,
                  factor_type: decFactorKey,
                  data: {
                    text: decodeURIComponent(factorComment || ''),
                  },
                });
              }
            });
          });
        }

        return acc;
      }, []);

      const allComments = [
        ...evaluationComments,
        ...(companyCommentMap?.comments || []),
      ];

      allComments?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      return allComments;
    } catch (err) {
      console.log(err);
      return [];
    }
  }
  static getCompanyCommentsCount({
    companyCommentMap = null,
    vendorStats = {},
    companyId = null,
    factorKey = true,
  }) {
    try {
      if (!companyId) return 0;

      const evaluationCommentsCount = Object.keys(
        vendorStats.decisions || {}
      )?.reduce((acc, userId) => {
        const decision = vendorStats.decisions[userId];

        decision.timeline.forEach((timelineDec, idx) => {
          const curDec = timelineDec.decision;

          if (curDec?.comment) {
            acc += 1;
          }

          Object.keys(curDec?.form_data || {}).forEach((decFactorKey) => {
            const fDec = curDec.form_data[decFactorKey];
            const factorComment = fDec?.comment;

            if (factorKey && factorComment) {
              acc += 1;
            }
          });
        });

        return acc;
      }, 0);

      return (
        (evaluationCommentsCount || 0) +
        (companyCommentMap?.comments?.length || 0)
      );
    } catch (err) {
      console.log(err);
      return 0;
    }
  }
}

export default CommentsHelpers;
