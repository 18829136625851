import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiPortfolio {
  static async getMemberPortfolio(communityId, userId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/portfolio/member_get?communityId=${communityId}&memberId=${userId}`,
        {
          signal: KLAbortController.genController('getMemberPortfolio').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getMemberPortfolio');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async getPortfolio(userId, communityId, organizationId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/portfolio/get?communityId=${communityId}&organizationId=${organizationId}&userId=${userId}`,
        {
          signal: KLAbortController.genController('getPortfolio').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getPortfolio');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async getCommunityPortfolios(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/portfolio/get_community_portfolio?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getCommunityPortfolios')
            .signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getCommunityPortfolios');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async savePortfolio(
    userId,
    communityId,
    organizationId,
    portfolioData,
    token
  ) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/portfolio/save`, {
        signal: KLAbortController.genController('savePortfolio').signal,
        method: 'POST',
        body: JSON.stringify({
          userId,
          communityId,
          organizationId,
          portfolioData,
        }),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('savePortfolio');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return {};
    }
  }

  static async addNewFolder(communityId, organizationId, folderData, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/portfolio/new_folder`,
        {
          signal: KLAbortController.genController('addNewFolder').signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            organizationId,
            folderData,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('addNewFolder');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async removeFolder(communityId, organizationId, folderId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/portfolio/remove_folder`,
        {
          signal: KLAbortController.genController('removeFolder').signal,
          method: 'DELETE',
          body: JSON.stringify({
            communityId,
            organizationId,
            folderId,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('removeFolder');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async removeFromPortfolio(communityId, companyId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/portfolio/removeFromPortfolio`,
        {
          signal: KLAbortController.genController('removeFromPortfolio').signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            companyId,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('removeFromPortfolio');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
  static async archive(communityId, companyId, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/portfolio/archive`, {
        signal: KLAbortController.genController('archive').signal,
        method: 'POST',
        body: JSON.stringify({
          communityId,
          companyId,
        }),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('archive');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
  static async unarchive(communityId, companyId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/portfolio/unarchive`,
        {
          signal: KLAbortController.genController('unarchive').signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            companyId,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('unarchive');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
}

export default ApiPortfolio;
