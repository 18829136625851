const genUniqueId = () => {
  return Math.round(Math.random() * 36 ** 12).toString(36);
};

const isValidContactEmail = (email) => {
  const reg = /^(info|contact|general)/;
  return !reg.test(email.toLowerCase());
};

const isValidEmail = (email) => {
  const reg = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;
  return reg.test(String(email).toLowerCase());
};

const isValidPhone = (phoneNumber) => {
  const phoneNumberPattern = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
  return phoneNumberPattern.test(phoneNumber);
};

const isGuid = (str) => {
  if (!str) return false;

  const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
  return regexGuid.test(str);
};

const formatMoneyValue = (value, currency = '') => {
  if (value === undefined || value === null) return 'NA';
  if (currency === null) currency = '';
  return value;
  const _value = value.toString().replace(/[*/><!@#$%^&*()`'"]/gi, '');
  const pieces = parseFloat(_value).toFixed(2).split('');
  let ii = pieces.length - 3;
  while ((ii -= 3) > 0) {
    pieces.splice(ii, 0, ',');
  }
  return pieces.join('').split('.')[0];
};

const formatHyperLink = (link) => {
  if (!link) return '';
  let formattedLink = link;

  const regexSubdomain = /^(www\.)/gim;
  const regexGuid = /^(https?:\/\/)/gim;

  if (!regexSubdomain.test(formattedLink) && !regexGuid.test(formattedLink)) {
    formattedLink = `www.${link}`;
  }

  if (!regexGuid.test(formattedLink)) {
    formattedLink = `https://${formattedLink}`;
  }

  return formattedLink;
};

const isValidLinkedinURL = (url) => {
  const pattern = new RegExp(
    /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/gm
  );

  return !!pattern.test(url);
};

const isValidYoutubeURL = (url) => {
  if (!url) return false;

  const pattern = new RegExp(
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gi
  );

  return !!pattern.test(url);
};

const isValidUrl = (url) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(url);
  // const isValidUrl = /((?:https?\:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i;

  // var isValidUrl = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
  // return isValidUrl.test(url);
};

const isValidUrl2 = (url) => {
  const res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

const sanitizeLink = (link) => {
  if (!link) return null;
  let _link = link;
  if (_link.indexOf('http') !== 0) {
    _link = `http://${link}`;
  }
  return _link;
};

const extractVideoUrl = (url) => {
  let match =
    url.match(
      /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
    ) ||
    url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
  if (match) {
    return `${match[1] || 'https'}://www.youtube.com/embed/${
      match[2]
    }?showinfo=0`;
  }
  // eslint-disable-next-line no-cond-assign
  if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
    return `${match[1] || 'https'}://player.vimeo.com/video/${match[2]}/`;
  }
  return url;
};

const getYouTubeVideoIdFromLink = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

const buildYouTubeEmbedUrl = (id, options) => {
  if (!id) return null;
  return `//www.youtube.com/embed/${id}?${options}`;
};

const buildYouTubeThumbUrl = (id) => {
  if (!id) return null;
  return `https://img.youtube.com/vi/${id}/0.jpg`;

  const yt_video_id = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
  if (yt_video_id.length === 11) {
    return `https://img.youtube.com/vi/${yt_video_id}/maxresdefault.jpg`;
  }

  return null;
};

const getVimeoVideoIdFromLink = (url) => {
  const match = /vimeo.*\/(\d+)/i.exec(url);

  if (match) {
    return match[1];
  }
};

const buildVimoeVideoUrl = (link, options) => {
  if (!link) return null;

  if (link.indexOf('player.') !== -1) return link;

  const pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
  if (pattern1) {
    const replacement = link.replace(
      pattern1,
      `//player.vimeo.com/video/$1${options && '?autoplay=1&muted=1'}`
    );
    return replacement;
  }

  return link;
};

const buildVimeoThumbUrl = async (id) => {
  return null;

  // $.getJSON(
  //   'https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + id,
  //   { format: 'json' },
  //   (data) => {
  //     console.log(data);
  //   }
  // );

  // return new Promise((resolve) => {
  //   fetch(`http://vimeo.com/api/v2/video/${id}.json?callback=showThumb`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       resolve({ ...data });
  //     });
  // });
};

const tryUnescape = (str) => {
  try {
    if (!str) return '';

    return unescape(unescape(str));
  } catch (err) {
    console.error(err);
    return str;
  }
};

const unescapeString = (string) => {
  if (!string) return '';
  const tags = {
    '&s;': "'",
    '&amp;': '&',
    '&a;': '&',
    '&quot;': '"',
    '&#x2f;': '/',
    '&#x27;': "'",
  };

  let newString = string;

  Object.keys(tags).forEach((tag) => {
    const newRegExp = new RegExp(`${tag}`, 'gi');
    newString = newString.replace(newRegExp, tags[tag]);
  });

  return newString;
};

const hasNumber = (str) => {
  return /\d/.test(str);
};
const hasUpperCase = (str) => {
  return /[A-Z]/.test(str);
};
const hasLowerCase = (str) => {
  return /[a-z]/.test(str);
};

const hasSymbol = (str) => {
  return /[!#@\,\-\$%\&\*\?]/.test(str);
};

const isValidJSON = (str) => {
  if (
    /^[\],:{}\s]*$/.test(
      str
        .replace(/\\["\\\/bfnrtu]/g, '@')
        .replace(
          /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
          ']'
        )
        .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    )
  ) {
    return true;
  } else {
    return false;
  }
};

const genString = ({ length, seed, casing }) => {
  const NUM_SEEDS = '0123456789';
  const HEX_SEEDS = `abcdef${NUM_SEEDS}`;
  const ALPHABET_SEEDS = 'abcdefghijklmnopqrstuvwxyz';

  let givenSeedType;
  switch (seed) {
    case 10:
    case 1:
    case 'n':
    case 'number':
      givenSeedType = 'number';
      break;
    case 8:
    case 'o':
    case 'oct':
    case 'octal':
      givenSeedType = 'octal';
      break;
    case 2:
    case 'b':
    case 'binary':
      givenSeedType = 'binary';
      break;
    case 16:
    case 'h':
    case 'hex':
    case 'hexadecimal':
      givenSeedType = 'hexadecimal';
      break;
    case 'a':
    case 'alphabet':
    case 'l':
    case 'letter':
      givenSeedType = 'alphabet';
      break;
    default: {
      if (typeof seed === 'string') {
        givenSeedType = 'customized';
      } else {
        // no seed is given
        givenSeedType = 'random';
      }
    }
  }

  const generateMixedCasedAlphabetSeeds = (casing) => {
    let seeds;
    if (casing === null || casing === 'm' || casing === 'mixed') {
      seeds = `${ALPHABET_SEEDS}${ALPHABET_SEEDS.toUpperCase()}`;
    } else {
      seeds = ALPHABET_SEEDS;
    }
    return seeds;
  };

  const generateRandomSeedPickFunc = (seeds) => {
    const sampleIndex = Math.floor(Math.random() * Math.floor(seeds.length));
    return seeds[sampleIndex];
  };

  let randomFunc;
  switch (givenSeedType) {
    case 'number':
      randomFunc = () => Math.floor(Math.random() * Math.floor(10));
      break;
    case 'octal':
      randomFunc = () => Math.floor(Math.random() * Math.floor(8));
      break;
    case 'binary':
      randomFunc = () => Math.floor(Math.random() * Math.floor(2));
      break;
    case 'hexadecimal':
      randomFunc = () => generateRandomSeedPickFunc(HEX_SEEDS);
      break;
    case 'alphabet': {
      const seeds = generateMixedCasedAlphabetSeeds(casing);
      randomFunc = () => generateRandomSeedPickFunc(seeds);
      break;
    }
    default: {
      let seeds;
      if (givenSeedType === 'customized') {
        seeds = seed;
      } else {
        // no seed is given
        seeds = `${generateMixedCasedAlphabetSeeds(casing)}${NUM_SEEDS}`;
      }
      randomFunc = () => generateRandomSeedPickFunc(seeds);
    }
  }
  let generatedToken = Array.from({ length }, randomFunc).join('');
  if (casing === 'u' || casing === 'upper') {
    generatedToken = generatedToken.toUpperCase();
  } else if (casing === 'l' || casing === 'lower') {
    generatedToken = generatedToken.toLowerCase();
  }
  return generatedToken;
};

const similarity = (first, second) => {
  first = first.replace(/\s+/g, '');
  second = second.replace(/\s+/g, '');

  if (first === second) return 1; // identical or empty
  if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

  let firstBigrams = new Map();

  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

    firstBigrams.set(bigram, count);
  }

  let intersectionSize = 0;

  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize++;
    }
  }

  return (2.0 * intersectionSize) / (first.length + second.length - 2);
};

const capitalize = (str) => {
  const arr = str.split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(' ');
};

const truncate = (text, length = 5) => {
  if (!text) return '';
  if (text.length > length) {
    return text.substring(0, length) + '...';
  }
  return text;
};

const stripHtmlFromText = (text) => {
  return text.replace(/(<([^>]+)>)/gi, '');
};

export {
  truncate,
  isGuid,
  genString,
  genUniqueId,
  formatMoneyValue,
  isValidUrl,
  isValidUrl2,
  isValidPhone,
  isValidYoutubeURL,
  getYouTubeVideoIdFromLink,
  getVimeoVideoIdFromLink,
  buildYouTubeEmbedUrl,
  buildVimoeVideoUrl,
  sanitizeLink,
  isValidEmail,
  isValidContactEmail,
  isValidLinkedinURL,
  tryUnescape,
  unescapeString,
  hasNumber,
  hasUpperCase,
  hasLowerCase,
  hasSymbol,
  isValidJSON,
  similarity,
  capitalize,
  stripHtmlFromText,
  buildYouTubeThumbUrl,
  buildVimeoThumbUrl,
  extractVideoUrl,
  formatHyperLink,
};
