import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiProjects {
  static async getProjects(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/projects/get?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getProjects').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getProjects');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return {};
    }
  }

  static async createProject(communityId, projectData, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/projects/create`, {
        signal: KLAbortController.genController('createProject').signal,
        method: 'POST',
        body: JSON.stringify({
          communityId,
          projectData,
        }),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('createProject');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async updateProject(projectId, communityId, projectData, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/projects/update`, {
        signal: KLAbortController.genController('updateProject').signal,
        method: 'POST',
        body: JSON.stringify({
          projectId,
          communityId,
          projectData,
        }),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('updateProject');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async addProjectStartupItem(projectId, columnId, startupItem, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/projects/startup/add`,
        {
          signal: KLAbortController.genController('addProjectStartupItem')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            projectId,
            columnId,
            startupItem,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('addProjectStartupItem');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async removeProjectStartupItem(projectId, startupId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/projects/startup/remove`,
        {
          signal: KLAbortController.genController('removeProjectStartupItem')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            projectId,
            startupId,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('removeProjectStartupItem');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
}

export default ApiProjects;
