import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiHeartbeat {
  static async keepAlive(token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/heartbeat/ping`, {
        signal: KLAbortController.genController('keepAlive').signal,
        method: 'POST',
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('keepAlive');
      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
}

export default ApiHeartbeat;
