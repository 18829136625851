import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiNotifications {
  static async getNotifications(communityId, organizationId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/notifications/get?community_id=${communityId}&organization_id=${organizationId}`,
        {
          signal: KLAbortController.genController('getNotifications').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getNotifications');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async syncNotifications(communityId, notificationList, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/notifications/sync`,
        {
          signal: KLAbortController.genController('syncNotifications').signal,
          method: 'POST',
          body: JSON.stringify({ communityId, notificationList }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('syncNotifications');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiNotifications;
