import { attr, Model } from 'redux-orm';

/**
 * Feedbacks model
 * @author Villi K.
 */
class Feedbacks extends Model {
  static get modelName() {
    return 'Feedbacks';
  }

  static get fields() {
    return {
      feedback_list: attr(),
      didLoad: attr(),
    };
  }

  static reducer(action, Feedbacks, session) {
    if (!Feedbacks) return;
    switch (action.type) {
      case 'CREATE_FEEDBACKS_DB':
        // Comments.delete();

        Feedbacks.create({
          feedback_list: [],
          didLoad: false,
        });
        break;
      case 'SET_FEEDBACKS': {
        let feedbacksDB = Feedbacks.last();
        if (!feedbacksDB) {
          Feedbacks.create({
            feedback_list: [],
            didLoad: false,
          });

          feedbacksDB = Feedbacks.last();
        }
        const { feedbacks = [] } = action.payload;

        feedbacksDB.didLoad = true;
        feedbacksDB.feedback_list = feedbacks || [];

        feedbacksDB.update();
        break;
      }
      case 'INSERT_FEEDBACKS': {
        const feedbacksDB = Feedbacks.last();
        const { feedbacks } = action.payload;
        if (!feedbacks) return;

        if (!feedbacksDB.feedback_list) return;

        feedbacksDB.update({
          feedback_list: [...feedbacks, ...feedbacksDB.feedback_list],
        });
        break;
      }
      case 'DELETE_FEEDBACKS_DB':
        try {
          Feedbacks.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Feedbacks };
export default Feedbacks;
