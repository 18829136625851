import { KleverApi } from '~shared/adapters/NetworkAdapter';
import { createSliceWithThunks } from '../createSlice';

const initialState = {
  loading: false,
  company: {},
  experts: [],
  stats: {},
  evaluations: [],
  customFields: [],
  files: [],
};

export const companySlice = createSliceWithThunks({
  name: 'company',
  initialState,
  reducers: (create) => ({
    fetchCompany: create.asyncThunk(
      async ({ companyId, token, communityId }) => {
        const res = KleverApi.Companies.getCompany(
          companyId,
          true,
          token,
          communityId
        );
        return await res;
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, { payload: { company } }) => {
          state.loading = false;
          state.company = { ...company };
        },
      }
    ),
    updateCompany: create.asyncThunk(
      async ({ companyId, token, communityId }) => {
        const res = KleverApi.Companies.getCompany(
          companyId,
          true,
          token,
          communityId
        );
        return await res;
      },
      {
        fulfilled: (state, { payload: { company } }) => {
          state.company = { ...company };
        },
      }
    ),
    fetchCompanyFiles: create.asyncThunk(
      async ({ companyId, token, communityId }) => {
        const res = await KleverApi.Files.getFiles(
          communityId,
          companyId,
          token
        );
        return await res;
      },
      {
        fulfilled: (state, { payload: { files } }) => {
          state.files = files;
        },
      }
    ),
    setFiles: create.reducer((state, { payload }) => {
      state.files = [...payload];
    }),
    addFile: create.reducer((state, { payload }) => {
      state.files = [...state.files, payload];
    }),
    deleteFile: create.reducer((state, { payload }) => {
      state.files = [
        ...state?.files?.filter((file) => file.file_id !== payload),
      ];
    }),
    fetchCustomFields: create.asyncThunk(
      async ({ communityId, companyId, token }) => {
        const res = KleverApi.CustomFields.getCustomFieldsValues({
          token,
          companyId,
          communityId,
        });
        return await res;
      },
      {
        fulfilled: (state, { payload: { cfields_values } }) => {
          state.customFields = [...cfields_values];
        },
      }
    ),
    saveCustomField: create.asyncThunk(
      async ({ cfieldValueData, companyId, communityId, token }) => {
        const res = KleverApi.CustomFields.saveCustomField({
          cfieldValueData,
          companyId,
          communityId,
          token,
        });
        return await res;
      },
      {
        fulfilled: (state, { payload: { success, company } }) => {
          if (success) {
            state.company = { ...company };
          }
        },
      }
    ),
    setCompany: create.reducer((state, { payload }) => {
      state.company = { ...payload };
    }),
    setUpdatedTime: create.reducer((state, { payload }) => {
      const dataObject = { updated_at: new Date().toUTCString() };

      state.customFields = [
        ...state.customFields?.map((field) => {
          if (field.cfield_id === payload) {
            return {
              ...field,
              custom_field_data: [dataObject],
            };
          } else return field;
        }),
      ];
    }),
    fetchExperts: create.asyncThunk(
      async ({ data, token }) => {
        const res = KleverApi.Community.getExperts(data, token);
        return await res;
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, { payload: { user_experts } }) => {
          state.loading = false;
          state.experts = [...user_experts];
        },
      }
    ),
    fetchStats: create.asyncThunk(
      async ({ companyId, communityId, token }) => {
        const res = KleverApi.Companies.getCompanyStats(
          companyId,
          communityId,
          token
        );
        return await res;
      },
      {
        fulfilled: (state, { payload: { statistic } }) => {
          state.loading = false;
          state.stats = { ...statistic };
        },
      }
    ),
    updateStats: create.reducer((state) => {
      const { scores, ...statsRest} = state.stats;
      state.stats = { ...statsRest };
    }),
    fetchEvaluations: create.asyncThunk(
      async ({ communityId, token, companyId }) => {
        const res = KleverApi.Evaluations.getEvaluationsByCompany(
          communityId,
          token,
          companyId
        );
        return await res;
      },
      {
        fulfilled: (state, { payload: { evaluations } }) => {
          state.loading = false;
          state.evaluations = [...evaluations];
        },
      }
    ),
    clearCompany: create.reducer((state) => {
      state.company = {};
    }),
    clearExperts: create.reducer((state) => {
      state.experts = [];
    }),
    clearStats: create.reducer((state) => {
      state.stats = {};
    }),
    clearEvaluations: create.reducer((state) => {
      state.evaluations = [];
    }),
  }),
  selectors: {
    selectCompany: (sliceState) => sliceState.company,
    selectExperts: (sliceState) => sliceState.experts,
    selectLoading: (sliceState) => sliceState.loading,
    selectStats: (sliceState) => sliceState.stats,
    // selectFullStats: (sliceState) => sliceState.fullStats,
    selectEvaluations: (sliceState) => sliceState.evaluations,
    selectCustomFields: (sliceState) => sliceState.customFields,
    selectFiles: (sliceState) => sliceState.files,
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchCompany,
  fetchExperts,
  fetchStats,
  fetchEvaluations,
  clearCompany,
  clearExperts,
  clearStats,
  clearEvaluations,
  updateCompany,
  updateStats,
  fetchCustomFields,
  setCompany,
  saveCustomField,
  setUpdatedTime,
  fetchCompanyFiles,
  addFile,
  deleteFile,
  setFiles,
} = companySlice.actions;

// Selector creators for each uniqe value inside state
export const {
  selectCompany,
  selectLoading,
  selectExperts,
  selectStats,
  selectEvaluations,
  selectCustomFields,
  selectFiles,
} = companySlice.selectors;

export default companySlice.reducer;
