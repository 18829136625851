import { attr, Model } from 'redux-orm';
import KanbanHelpers from '../app/helpers/KanbanHelpers';

/**
 * KanbanBoards user model
 * @author Villi K.
 */
class KanbanBoards extends Model {
  static get modelName() {
    return 'KanbanBoards';
  }

  static get fields() {
    return {
      kanban_boards: attr(),
      active_board_id: attr(),
      didLoad: attr(),
    };
  }

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param User
   * @param session
   * @returns {undefined}
   */
  static reducer(action, KanbanBoards, session) {
    if (!KanbanBoards) return;
    switch (action.type) {
      case 'CREATE_KANBAN_BOARDS_DB':
        // KanbanBoards.delete();
        KanbanBoards.create({
          kanban_boards: {},
          active_board_id: KanbanHelpers.defaultBoardName,
          didLoad: false,
        });
        break;
      case 'SET_KANBAN_DATA': {
        let mainKanban = KanbanBoards.last();
        if (!mainKanban) {
          KanbanBoards.create({
            kanban_boards: {},
            active_board_id: KanbanHelpers.defaultBoardName,
            didLoad: false,
          });
          mainKanban = KanbanBoards.last();
        }
        const { kanbanData } = action.payload;
        mainKanban.kanban_boards = kanbanData || {
          [KanbanHelpers.defaultBoardName]: KanbanHelpers.defaultBoard,
        };
        if (Object.keys(kanbanData).length)
          mainKanban.active_board_id = Object.keys(kanbanData)[0];

        mainKanban.didLoad = true;
        mainKanban.update();
        KanbanBoards.withId(mainKanban.id).update({
          kanban_boards: mainKanban.kanban_boards,
        });

        break;
      }
      case 'SET_KANBAN_BOARD': {
        const mainKanban = KanbanBoards.last();

        if (!mainKanban) return;

        const { kanbanData } = action.payload;
        if (!kanbanData) return;

        mainKanban.kanban_boards[kanbanData.board_id] = kanbanData;
        mainKanban.active_board_id = kanbanData.board_id;
        mainKanban.didLoad = true;

        mainKanban.update({
          active_board_id: kanbanData.board_id,
          didLoad: true,
          kanban_boards: {
            ...(mainKanban.kanban_boards || {}),
            [kanbanData.board_id]: kanbanData,
          },
        });

        break;
      }
      case 'SET_ACTIVE_BOARD_ID': {
        const mainKanban = KanbanBoards.last();
        if (!mainKanban) return;

        const { boardId } = action.payload;

        mainKanban.active_board_id = boardId || KanbanHelpers.defaultBoardName;
        mainKanban.update();

        break;
      }
      case 'SET_BOARD_COLUMNS': {
        const mainKanban = KanbanBoards.last();
        if (!mainKanban) return;
        const { data, boardId } = action.payload;
        if (data && boardId) {
          mainKanban.update({
            ...mainKanban,
            kanban_boards: {
              ...(mainKanban.kanban_boards || {}),
              [boardId]: {
                ...(mainKanban?.kanban_boards?.[boardId] || {}),
                columns: data,
              },
            },
          });
        }
        break;
      }
      case 'ADD_COLUMN': {
        const mainEntity = KanbanBoards.last();
        if (!mainEntity) return;

        const data = action.payload.organizations;

        Object.keys(data).forEach((k) => {
          if (
            mainEntity.organizations[k] === null ||
            mainEntity.organizations[k] === undefined
          ) {
            mainEntity.organizations[k] = data[k];
          }
        });

        mainEntity.update();
        break;
      }
      case 'UPDATE_COLUMN': {
        const mainEntity = KanbanBoards.last();
        if (!mainEntity) return;

        const { companyId, data } = action.payload;

        if (!mainEntity.startups?.[companyId]) return;

        const { startups } = mainEntity;

        startups[companyId] = {
          ...(startups?.[companyId] || {}),
          ...data,
        };

        mainEntity.startups = startups;

        mainEntity.update();
        break;
      }
      case 'ADD_ITEM_BUNCH': {
        const mainEntity = KanbanBoards.last();
        if (!mainEntity) return;

        const { companyId } = action.payload;
        if (companyId) {
          mainEntity.followUpList[companyId] = { following: true };
          mainEntity.update();
        }
        break;
      }
      case 'INSERT_ADDED_COLUMN': {
        const mainKanban = KanbanBoards.last();
        if (!mainKanban) return;

        const { compList, boardId } = action.payload;
        if (compList && boardId) {
          const addedColumn = mainKanban.kanban_boards[boardId].columns.find(
            (i) => i.is_main_column
          );
          const addedColumnIndex = mainKanban.kanban_boards[
            boardId
          ].columns.findIndex((i) => i.is_main_column);
          if (addedColumn) {
            mainKanban.kanban_boards[boardId] = {
              ...mainKanban.kanban_boards[boardId],
            };
            (mainKanban.kanban_boards[boardId].columns[addedColumnIndex] = {
              ...addedColumn,
              companies: compList,
            }),
              mainKanban.update();
          }
        }
        break;
      }
      case 'INSERT_COLUMN_NEW_ITEMS': {
        try {
          const mainKanban = KanbanBoards.last();
          if (!mainKanban) return;

          const { compList, columnId, boardId } = action.payload;

          if (compList && boardId) {
            const boardCols = mainKanban.kanban_boards[boardId].columns;

            const filteredCompList = KanbanHelpers.boardHasItems(
              boardCols,
              compList
            );

            const mainColumn = columnId
              ? boardCols.find((i) => i.column_id === columnId)
              : boardCols.find((i) => i.is_main_column);

            const mainColumnIdx = columnId
              ? boardCols.findIndex((i) => i.column_id === columnId)
              : boardCols.findIndex((i) => i.is_main_column);

            if (mainColumn) {
              boardCols[mainColumnIdx] = {
                ...mainColumn,
                companies: [
                  ...filteredCompList.map((i) => ({
                    company_id: i,
                    item_moved: false,
                    item_pos: -1,
                    is_new: true,
                    date: Date.now(),
                  })),
                  ...mainColumn.companies,
                ].map((item, idx) => ({ ...item, item_pos: idx })),
              };
              mainKanban.update({
                ...mainKanban,
                kanban_boards: mainKanban.kanban_boards,
              });
            }
          }
        } catch (err) {
          console.error(err);
        }
        break;
      }
      case 'REMOVE_COLUMN_ITEM': {
        try {
          const mainKanban = KanbanBoards.last();

          if (!mainKanban) return;

          const { compId, columnId, boardId } = action.payload;

          if (compId && boardId) {
            const boardCols = mainKanban.kanban_boards[boardId].columns;

            const mainColumn = columnId
              ? boardCols.find((i) => i.column_id === columnId)
              : boardCols.find((i) => i.is_main_column);

            const mainColumnIdx = columnId
              ? boardCols.findIndex((i) => i.column_id === columnId)
              : boardCols.findIndex((i) => i.is_main_column);

            if (mainColumn) {
              boardCols[mainColumnIdx] = {
                ...mainColumn,
                companies: mainColumn.companies.filter(
                  (i) => i.company_id !== compId
                ),
              };
              mainKanban.update({
                ...mainKanban,
                kanban_boards: mainKanban.kanban_boards,
              });
            }
          }
        } catch (err) {
          console.error(err);
        }
        break;
      }
      case 'REMOVE_COLUMN': {
        const mainKanban = KanbanBoards.last();
        if (!mainKanban) return;
        const { columnId, boardId } = action.payload;
        if (columnId && boardId && mainKanban.kanban_boards[boardId]) {
          mainKanban.kanban_boards[boardId] = mainKanban.kanban_boards[
            boardId
          ].filter((i) => i.column_id.toString() !== columnId.toString());
          mainKanban.update();
        }
        break;
      }
      case 'REMOVE_KANBAN_DB':
        try {
          KanbanBoards.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export default KanbanBoards;
export { KanbanBoards };
