import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiBoards {
  static async getBoards(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/boards/get?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getBoards').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getBoards');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async getBoardByListId({ communityId, listId, token }) {
    try {
      if (!communityId || !listId || !token) return null;

      const json = await KleverApi.workerFetch(
        `${API_URL}/boards/get?communityId=${communityId}&listId=${listId}`,
        {
          signal: KLAbortController.genController('getBoardByListId').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getBoardByListId');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json.boards?.[0] || null;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async createBoard(communityId, boardData, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/boards/create`, {
        signal: KLAbortController.genController('createBoard').signal,
        method: 'POST',
        body: JSON.stringify({ communityId, boardData }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('createBoard');

      // KleverApi.handleErrorCodes(json.code);
      // if (!json.success) {
      //   KleverApi.handleErrorMessage(json);
      // }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async updateBoard(boardId, communityId, boardData, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/boards/edit`, {
        signal: KLAbortController.genController('updateBoard').signal,
        method: 'POST',
        body: JSON.stringify({ boardId, communityId, boardData }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('updateBoard');

      // KleverApi.handleErrorCodes(json.code);
      // if (!json.success) {
      //   KleverApi.handleErrorMessage(json);
      // }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async updateElementPosition({ boardId, communityId, elData, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/boards/move_company`,
        {
          signal: KLAbortController.genController('updateBoard').signal,
          method: 'POST',
          body: JSON.stringify({
            boardId,
            communityId,
            ...elData,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('updateBoard');

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async editBoardColumns({
    boardId,
    communityId,
    columnIndexes,
    token,
  }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/boards/column_edit`,
        {
          signal: KLAbortController.genController('editBoardColumns').signal,
          method: 'POST',
          body: JSON.stringify({
            boardId,
            communityId,
            columnIndexes,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('editBoardColumns');

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async updateBoardPosition({ boardId, communityId, boardData, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/boards/upd_positions`,
        {
          signal: KLAbortController.genController('updateBoardPosition').signal,
          method: 'POST',
          body: JSON.stringify({
            boardId,
            communityId,
            boardData,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('updateBoardPosition');

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiBoards;
