import CommunityHelpers from '~app/helpers/CommunityHelpers';
import CommentsHelpers from '~app/helpers/CommentsHelpers';
import { isNil } from '~lib/utils/lodash';

class TechVendorHelpers {
  static getTopTags(techVendors) {
    const results = Object.values(techVendors || {}).reduce((acc, val) => {
      val.tags.forEach((i) => {
        const key = i.toLowerCase().replace(/ /gi, '_');
        if (!acc[key])
          acc[key] = {
            count: 1,
            tag: key,
          };
        else acc[key].count += 1;
      });
      return acc;
    }, {});

    const sorted = Object.values(results);
    sorted.sort((a, b) => b.count - a.count);

    return sorted;
  }

  static getVendorTags({ vendor, withLowerCase = true }) {
    try {
      if (!vendor) {
        console.error('Please provide Tech Solution info');
        return [];
      }

      return (
        vendor.custom_tags?.reduce((acc, val) => {
          if (!!val?.tag_text) {
            const tag = withLowerCase
              ? val.tag_text.toLowerCase()
              : val.tag_text;
            acc.push(tag);
          }
          return acc;
        }, []) || []
      );
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  static getPrimaryEmail(company) {
    try {
      let primaryEmail = (company.contact_info || []).find((i) => i.is_primary);

      if (!primaryEmail && company.contact_info?.length) {
        primaryEmail = company.contact_info[0];
      }

      return primaryEmail?.contact_email || '';
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  static findNestedPath = ({ obj = {}, curPath = '', propKey = '' }) => {
    let fullPath = '';
    Object.keys(obj).some((key) => {
      if (key === propKey) {
        fullPath = curPath + propKey;
        return true;
      }

      if (
        obj[key] &&
        typeof obj[key] === 'object' &&
        !Array.isArray(obj[key])
      ) {
        const res = TechVendorHelpers.findNestedPath({
          obj: obj[key],
          curPath: curPath + `${key}.`,
          propKey: propKey,
        });

        if (res) {
          fullPath = res;
        }
      }
    });

    return fullPath;
  };

  static setCompanyValues = (company, fields) => {
    if (!company || !Object.keys(fields || {})?.length) return company || {};

    const localCompany = company;
    Object.keys(fields).forEach((fKey) => {
      if (!isNil(fields[fKey])) {
        localCompany[fKey] = fields[fKey];
      }
    });

    return localCompany;
  };
  static getAntiBiasMode = ({
    isMobile = false,
    companyId = null,
    userId = '',
    comments = {},
    vendorStats = {},
    community = {},
    evaluations = {},
  }) => {
    if (!companyId || !userId || isMobile) return false;

    const commentsMap = comments?.[companyId] || {};

    const allComments = CommentsHelpers.getAllCompanyComments({
      companyCommentMap: commentsMap,
      vendorStats,
      companyId,
    });

    const view = CommunityHelpers.canViewAntiBiasContent({
      allComments,
      decisions: vendorStats?.decisions,
      userId,
      evaluations,
      companyId,
    });

    const antiBiasDisabled =
      CommunityHelpers.isRole(community, userId, ['organizer', 'admin']) ||
      (!allComments?.length && !vendorStats?.dataPoints) ||
      view;

    return !antiBiasDisabled;
  };

  static searchFilter = (comp, curSearchTxt = '') => {
    try {
      if (!comp) {
        console.error('Tech Solutions Helpers: Pleas provide valid company');
        return comp;
      }
      return (
        comp.company_name?.toLowerCase().indexOf(curSearchTxt.toLowerCase()) ===
          0 ||
        comp.location?.country
          ?.toLowerCase()
          ?.indexOf(curSearchTxt.toLowerCase()) === 0 ||
        comp.location?.headquarters
          ?.toLowerCase()
          ?.indexOf(curSearchTxt.toLowerCase()) === 0 ||
        comp.description?.toLowerCase()?.indexOf(curSearchTxt.toLowerCase()) !==
          -1
      );
    } catch (e) {
      console.error('Tech Solutions Helpers: Pleas provide valid company');
      return comp;
    }
  };
}

export default TechVendorHelpers;
