import { createSliceWithThunks } from '../createSlice';
import KleverApi from '~lib/api/api';

const initialState = {
  userStats: {},
  statsIsLoading: false,
  topContribs: [],
  contribsIsLoading: false,
};

export const dashboardUserProfileSlice = createSliceWithThunks({
  name: 'dashboardUserProfile',
  initialState,
  reducers: (create) => ({
    fetchStats: create.asyncThunk(
      async ({ communityId, memberId, token }, thunkApi) => {
        const response = await KleverApi.Portfolio.getMemberPortfolio(
          communityId,
          memberId,
          token
        );
        return response;
      },
      {
        pending: (state) => {
          state.statsIsLoading = true;
        },
        rejected: (state) => {
          state.statsIsLoading = false;
        },
        fulfilled: (state, { payload }) => {
          state.statsIsLoading = false;

          let obj = {};

          for (const [key, value] of Object.entries(payload)) {
            if (key === 'views' || key === 'success') continue;

            obj = {
              ...obj,
              [key]: {
                digit: value,
                name: key,
                status: {},
              },
            };
          }
          state.userStats = obj;
        },
      }
    ),
    updateStats: create.asyncThunk(
      async ({ communityId, memberId, token }, thunkApi) => {
        const response = await KleverApi.Portfolio.getMemberPortfolio(
          communityId,
          memberId,
          token
        );
        return response;
      },
      {
        fulfilled: (state, { payload }) => {
          let obj = {};

          for (const [key, value] of Object.entries(payload)) {
            if (key === 'views' || key === 'success') continue;

            obj = {
              ...obj,
              [key]: {
                digit: value,
                name: key,
                status: {},
              },
            };
          }
          state.userStats = obj;
        },
      }
    ),
    fetchContribs: create.asyncThunk(
      async ({ communityId, token }, thunkApi) => {
        const response = await KleverApi.Profile.getConsultsFeed({
          communityId,
          token,
        });
        return response;
      },
      {
        pending: (state) => {
          state.contribsIsLoading = true;
        },
        rejected: (state) => {
          state.contribsIsLoading = false;
        },
        fulfilled: (state, { payload: { consults } }) => {
          state.contribsIsLoading = false;
          state.topContribs = [...consults.slice(0, 20)].sort(
            (a, b) => b.likes - a.likes
          );
        },
      }
    ),
  }),
  selectors: {
    getUserStats: (sliceState) => sliceState.userStats,
    getStatsIsLoading: (sliceState) => sliceState.statsIsLoading,
    getTopContribs: (sliceState) => sliceState.topContribs,
    getContribsIsLoading: (sliceState) => sliceState.contribsIsLoading,
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchContribs,
  fetchStats,
  updateStats,
} = dashboardUserProfileSlice.actions;

// Selector creators for each uniqe value inside state
export const {
  getUserStats,
  getStatsIsLoading,
  getTopContribs,
  getContribsIsLoading,
} = dashboardUserProfileSlice.selectors;

export default dashboardUserProfileSlice.reducer;
