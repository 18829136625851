import { attr, Model } from 'redux-orm';

import { genUniqueId } from '~lib/utils/strings';

/**
 * Invites model
 * @author Villi K.
 */
class Invites extends Model {
  static get modelName() {
    return 'Invites';
  }

  static get fields() {
    return {
      list: attr(),
      didLoad: attr(),
    };
  }

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param User
   * @returns {undefined}
   */
  static reducer(action, Invites, session) {
    if (!Invites) return;
    switch (action.type) {
      case 'CREATE_INVITES_DB':
        // Invites.delete();

        Invites.create({
          list: [],
          didLoad: true,
        });
        break;
      case 'SET_INVITES': {
        const invitesDB = Invites.last();
        if (!invitesDB) return;

        const { invites = [] } = action.payload;

        if (!invitesDB) {
          Invites.create({
            list: invites,
            didLoad: true,
          });
          return;
        } else {
          invitesDB.didLoad = true;
          invitesDB.list = invites;
        }

        invitesDB.update();
        break;
      }
      case 'INSERT_INVITE': {
        const invitesDB = Invites.last();
        if (!invitesDB) return;

        const { inviteData } = action.payload;

        if (!inviteData || !invitesDB) return;

        invitesDB.didLoad = true;

        invitesDB.update({ list: [...(invitesDB.list || []), inviteData] });
        break;
      }
      case 'UPDATE_INVITES': {
        break;
      }
      case 'REMOVE_INVITE': {
        break;
      }
      case 'DELETE_INVITES_DB':
        try {
          Invites.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Invites };
export default Invites;
