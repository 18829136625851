class ImageUtils {
  static fileToBase64(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        resolve(null);
        return;
      }

      const isValidMime = ['.jpg', '.jpeg', '.png'].some((item) =>
        file.name.toLowerCase().endsWith(item)
      );
      if (!isValidMime) {
        resolve(null);
        return;
      }
      const fr = new FileReader();

      fr.addEventListener('load', (e) => {
        resolve(e.target.result);
      });

      fr.readAsDataURL(file);
    });
  }

  static dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  static isAllowedExt(str) {
    const imageReg = /[\/.](jpg|jpeg|png)$/i;
    return imageReg.test(String(str).toLowerCase());
  }

  static createImage(url) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
  }

  static makeRoundImageBase64(base64) {
    return new Promise((resolve) => {
      var tmpCanvas = document.createElement('canvas'),
        tmpCtx = tmpCanvas.getContext('2d'),
        image = new Image();

      const $dim = 160;

      image.src = base64;
      image.onload = () => {
        tmpCanvas.width = tmpCanvas.height = $dim * 4 || 50;

        tmpCtx.save();
        tmpCtx.beginPath();
        tmpCtx.arc($dim * 2, $dim * 2, 2 * $dim, 0, Math.PI * 2, true);
        tmpCtx.closePath();
        tmpCtx.clip();

        tmpCtx.drawImage(image, 0, 0, 4 * $dim, 4 * $dim);

        tmpCtx.beginPath();
        tmpCtx.arc(0, 0, 2 * $dim, 0, Math.PI * 2, true);
        tmpCtx.clip();
        tmpCtx.closePath();
        tmpCtx.restore();

        const dataURL = tmpCanvas.toDataURL();
        // console.log(dataURL);
        resolve(dataURL);
      };
    });
  }

  static getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  static async resizeByMaxSize(image, max_width, max_height, mime) {
    const img = await ImageUtils.createImage(image);
    const canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    var canvasCopy = document.createElement('canvas');
    var copyContext = canvasCopy.getContext('2d');
    var ratio = 1;

    if (img.width > max_width) ratio = max_width / img.width;
    else if (img.height > max_height) ratio = max_height / img.height;

    canvasCopy.width = img.width;
    canvasCopy.height = img.height;
    copyContext.drawImage(img, 0, 0);

    canvas.width = img.width * ratio;
    canvas.height = img.height * ratio;
    ctx.drawImage(
      canvasCopy,
      0,
      0,
      canvasCopy.width,
      canvasCopy.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
    return canvas.toDataURL(mime);
  }

  static async resizeImage(image, width, height, mime) {
    const img = await ImageUtils.createImage(image);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const safeArea = Math.max(image.width, image.height) * 2;
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    ImageUtils.resample_single(canvas, width, height, true);
    return canvas.toDataURL(mime);
  }

  static async getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    mime = 'image/jpg',
    maxWidth = 160
  ) {
    const image = await ImageUtils.createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(ImageUtils.getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    if (canvas.width > maxWidth) {
      ImageUtils.resample_single(canvas, maxWidth, maxWidth, true);
    }

    // As Base64 string
    return canvas.toDataURL(mime);

    // As a blob
    // return new Promise(resolve => {
    //   canvas.toBlob(file => {
    //     resolve(URL.createObjectURL(file));
    //   }, `${mime}`);
    // });
  }

  static blobToBase64(blob) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  }

  static randomAvatarUrl() {
    const AVATAR_LIST_SQUARE = [
      '/imgs_new/avatars/avatar_r.png',
      '/imgs_new/avatars/avatar_b.png',
      '/imgs_new/avatars/avatar_o.png',
      '/imgs_new/avatars/avatar_p.png',
      '/imgs_new/avatars/avatar_g.png',
    ];

    try {
      return AVATAR_LIST_SQUARE[
        Math.floor(Math.random() * AVATAR_LIST_SQUARE.length)
      ];
    } catch (err) {
      return AVATAR_LIST_SQUARE[0];
    }
  }

  static parseImagePath(url, defaultImage) {
    return url || defaultImage || ImageUtils.randomAvatarUrl();
    const allowedExt = ['png', 'jpeg', 'jpg', 'gif', 'bmp'];
    const forbidden = ['undefined'];
    const ext = /(?:\.([^.]+))?$/.exec(url)[1];
    if (
      !ext ||
      forbidden.includes(ext) ||
      ext.endsWith('undefined') ||
      !allowedExt.includes(ext)
    ) {
      url = defaultImage || ImageUtils.randomAvatarUrl();
    }
    return url;
  }

  /**
   * Hermite resize - fast image resize/resample using Hermite filter. 1 cpu version!
   *
   * @param {HtmlElement} canvas
   * @param {int} width
   * @param {int} height
   * @param {boolean} resize_canvas if true, canvas will be resized. Optional.
   */
  static resample_single(canvas, width, height, resize_canvas) {
    var width_source = canvas.width;
    var height_source = canvas.height;
    width = Math.round(width);
    height = Math.round(height);

    var ratio_w = width_source / width;
    var ratio_h = height_source / height;
    var ratio_w_half = Math.ceil(ratio_w / 2);
    var ratio_h_half = Math.ceil(ratio_h / 2);

    var ctx = canvas.getContext('2d');
    var img = ctx.getImageData(0, 0, width_source, height_source);
    var img2 = ctx.createImageData(width, height);
    var data = img.data;
    var data2 = img2.data;

    for (var j = 0; j < height; j++) {
      for (var i = 0; i < width; i++) {
        var x2 = (i + j * width) * 4;
        var weight = 0;
        var weights = 0;
        var weights_alpha = 0;
        var gx_r = 0;
        var gx_g = 0;
        var gx_b = 0;
        var gx_a = 0;
        var center_y = (j + 0.5) * ratio_h;
        var yy_start = Math.floor(j * ratio_h);
        var yy_stop = Math.ceil((j + 1) * ratio_h);
        for (var yy = yy_start; yy < yy_stop; yy++) {
          var dy = Math.abs(center_y - (yy + 0.5)) / ratio_h_half;
          var center_x = (i + 0.5) * ratio_w;
          var w0 = dy * dy; //pre-calc part of w
          var xx_start = Math.floor(i * ratio_w);
          var xx_stop = Math.ceil((i + 1) * ratio_w);
          for (var xx = xx_start; xx < xx_stop; xx++) {
            var dx = Math.abs(center_x - (xx + 0.5)) / ratio_w_half;
            var w = Math.sqrt(w0 + dx * dx);
            if (w >= 1) {
              //pixel too far
              continue;
            }
            //hermite filter
            weight = 2 * w * w * w - 3 * w * w + 1;
            var pos_x = 4 * (xx + yy * width_source);
            //alpha
            gx_a += weight * data[pos_x + 3];
            weights_alpha += weight;
            //colors
            if (data[pos_x + 3] < 255)
              weight = (weight * data[pos_x + 3]) / 250;
            gx_r += weight * data[pos_x];
            gx_g += weight * data[pos_x + 1];
            gx_b += weight * data[pos_x + 2];
            weights += weight;
          }
        }
        data2[x2] = gx_r / weights;
        data2[x2 + 1] = gx_g / weights;
        data2[x2 + 2] = gx_b / weights;
        data2[x2 + 3] = gx_a / weights_alpha;
      }
    }
    //clear and resize canvas
    if (resize_canvas === true) {
      canvas.width = width;
      canvas.height = height;
    } else {
      ctx.clearRect(0, 0, width_source, height_source);
    }

    //draw
    ctx.putImageData(img2, 0, 0);
  }
}

export default ImageUtils;
