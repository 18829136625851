import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiComments {
  static async getComments(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/comments/get?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getComments').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getComments');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async addNewComment(
    communityId,
    companyId,
    listId,
    commentData,
    token
  ) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/comments/new`, {
        signal: KLAbortController.genController('addNewComment').signal,
        method: 'POST',
        body: JSON.stringify({ communityId, companyId, listId, commentData }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('addNewComment');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async editComment(
    communityId,
    companyId,
    commentId,
    commentData,
    token
  ) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/comments/edit`, {
        signal: KLAbortController.genController('editComment').signal,
        method: 'POST',
        body: JSON.stringify({
          communityId,
          companyId,
          commentId,
          commentData,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('editComment');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async likeComment(communityId, companyId, commentId, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/comments/like`, {
        signal: KLAbortController.genController('likeComment').signal,
        method: 'POST',
        body: JSON.stringify({ communityId, companyId, commentId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('likeComment');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async removeComment(communityId, companyId, commentId, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/comments/remove`, {
        signal: KLAbortController.genController('removeComment').signal,
        method: 'POST',
        body: JSON.stringify({ communityId, companyId, commentId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('removeComment');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async lastCommunityComments(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/comments/last?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('lastCommunityComments')
            .signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('lastCommunityComments');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async AFGetComments(string, token, history = false) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/comments/get?communityId=${string}${
          history ? '&history=true' : ''
        }`,
        {
          signal: KLAbortController.genController('AFGetComments').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('AFGetComments');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async AFCreateComment(data, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/comments/new`, {
        signal: KLAbortController.genController('AFCreateComment').signal,
        method: 'POST',
        body: JSON.stringify({ ...data }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('AFCreateComment');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async AFEditComment(data, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/comments/edit`, {
        signal: KLAbortController.genController('AFEditComment').signal,
        method: 'POST',
        body: JSON.stringify({ ...data }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('AFEditComment');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async AFDeleteComment(data, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/comments/remove`, {
        signal: KLAbortController.genController('AFDeleteComment').signal,
        method: 'POST',
        body: JSON.stringify({ ...data }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('AFDeleteComment');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async GetCompanyCounters(data, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/comments/get_company_counters`,
        {
          signal: KLAbortController.genController('GetCompanyCounters').signal,
          method: 'POST',
          body: JSON.stringify({ ...data }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('GetCompanyCounters');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async sendTeamsRequest(token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/comments/from_teams`,
        {
          signal: KLAbortController.genController('teamsComments').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('teamsComments');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiComments;
