import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: '',
  profile: {},
  role: [],
  id: '',
  unlockedCompanies: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUnlockedCompanies: (state, { payload }) => {
      state.unlockedCompanies = [
        ...new Set([...state.unlockedCompanies, ...payload]),
      ];
    },
  },
  selectors: {
    selectUnlockedCompanies: (sliceState) => sliceState.unlockedCompanies,
  },
});

// Action creators are generated for each case reducer function
export const { setUnlockedCompanies } = userSlice.actions;

// Selector creators for each uniqe value inside state
export const { selectUnlockedCompanies } = userSlice.selectors;

export default userSlice.reducer;
