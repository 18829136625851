import { KleverApi } from '~shared/adapters/NetworkAdapter';
import { createSliceWithThunks } from '../createSlice';

const initialState = {
  loading: false,
  editMode: false,
  originalKanban: {},
  originalMappedKanban: {},
  originalKanbanCols: [],
  originalKanbanColsBeforeEdit: [],
  originalKanbanComps: [],
  editedKanbanCols: [],
  editedKanbanComps: [],
  kanbanState: {},
  companiesData: [],
  decisionMakers: [],
  searchQuery: '',
};

export const kanbanSlice = createSliceWithThunks({
  name: 'kanban',
  initialState,
  reducers: (create) => ({
    fetchKanban: create.asyncThunk(
      async ({ communityId, listId, token }) => {
        const res = KleverApi.Boards.getBoardByListId({
          communityId,
          listId,
          token,
        });
        return await res;
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, { payload }) => {
          state.loading = false;
          state.originalKanban = { ...(payload || {}) };
          // state.originalKanbanCols = state.originalKanban.columns;
          // const compList = state.originalKanbanCols
          //   ?.map((column) => [
          //     ...column.companies.map((comp) => ({
          //       ...comp,
          //       column_id: `${column.column_id}`,
          //     })),
          //   ])
          //   ?.flat();
          // state.originalKanbanComps = compList;
          // if (state.companiesData?.length > 0 && compList?.length > 0) {
          //   state.originalKanbanComps = compList
          //     .map((company) => ({
          //       ...company,
          //       ...state.companiesData.find(
          //         (data) => data.company_id === company.company_id
          //       ),
          //     }))
          //     .filter((comp) => comp.company_name);
          // }
        },
      }
    ),
    // fetchComps: create.asyncThunk(
    //   async ({ communityId, listId, token }) => {
    //     const res = KleverApi.Lists.getListCompanies({
    //       communityId,
    //       listId,
    //       token,
    //     });
    //     return await res;
    //   },
    //   {
    //     pending: (state) => {
    //       state.loading = true;
    //     },
    //     rejected: (state) => {
    //       state.loading = false;
    //     },
    //     fulfilled: (state, { payload }) => {
    //       state.loading = false;
    //       state.decisionMakers = { ...payload?.decision_makers };
    //       state.companiesData = { ...payload?.companyList };
    //       if (
    //         state.originalKanbanComps?.length > 0 &&
    //         payload?.companyList?.length > 0
    //       ) {
    //         state.originalKanbanComps = state.originalKanbanComps
    //           .map((company) => ({
    //             ...company,
    //             ...payload?.companyList.find(
    //               (data) => data.company_id === company.company_id
    //             ),
    //           }))
    //           .map((company) => ({
    //             ...company,
    //             ...(company.score == 0 &&
    //               company.totalDataPoints != 0 && { score: 0.01 }),
    //           }))
    //           .filter((comp) => comp.company_name);
    //       }
    //     },
    //   }
    // ),
    setKanbanCompanies: create.reducer((state, { payload }) => {
      state.originalMappedKanban = {
        ...state.originalKanban,
        columns:
          state.originalKanban.columns?.map((column) => ({
            ...column,
            companies: column.companies?.length
              ? [
                  ...column.companies.map((company) => ({
                    ...company,
                    ...payload.find(
                      (item) => item.company_id === company.company_id
                    ),
                    column_id: column.column_id,
                    column_name: column.column_title,
                  })),
                ]
              : [],
          })) || [],
      };
    }),
    setSearchQuery: create.reducer((state, { payload }) => {
      state.searchQuery = payload;
    }),
    setEditMode: create.reducer((state) => {
      state.editMode = !state.editMode;
    }),
    setOriginalKanban: create.reducer((state, { payload }) => {
      state.originalKanban = { ...payload };
    }),
    setOriginalKanbanCols: create.reducer((state, { payload }) => {
      state.originalKanbanCols = [...payload];
    }),
    setOriginalKanbanColsBeforeEdit: create.reducer((state, { payload }) => {
      state.originalKanbanColsBeforeEdit = [...payload];
    }),
    setOriginalKanbanComps: create.reducer((state, { payload }) => {
      state.originalKanbanComps = [...payload].filter(
        (comp) => comp.company_name
      );
    }),
    setEditedKanbanCols: create.reducer((state, { payload }) => {
      state.editedKanbanCols = [...payload];
    }),
    setEditedKanbanComps: create.reducer((state, { payload }) => {
      state.editedKanbanComps = [...payload];
    }),
    saveEditedKanban: create.reducer((state) => {
      state.originalKanbanCols = [...state.editedKanbanCols];
      state.originalKanbanComps = [...state.editedKanbanComps];
    }),
    clearEditedKanban: create.reducer((state) => {
      state.editedKanbanCols = [];
      state.editedKanbanComps = [];
    }),
    clearOriginalMappedKanban: create.reducer((state) => {
      state.originalMappedKanban = [];
    }),
    // setUsers: create.reducer((state) => {
    //   const users = state.fullComments
    //     .map((comment) => ({
    //       ...(comment.type !== 'challenge' ? comment.user_obj : {}),
    //     }))
    //     .filter((user) => user.user_id);
    //   state.usersList = [
    //     ...new Set(users.map((user) => user.user_id)),
    //   ].map((user) => users.find((item) => item.user_id === user));
    // }),
    // setShowHistory: create.reducer((state, { payload: { showHistory } }) => {
    //   state.showHistory = showHistory;
    // }),
    // setFilterBy: create.reducer((state, { payload: { key, values } }) => {
    //   state.filterBy = { key, values, active: !!values?.length };
    // }),
    // setFilteredComments: create.reducer((state, { payload: { comments } }) => {
    //   state.filteredComments = [...comments];
    // }),
    // setChallenge: create.reducer((state, { payload: { challenge } }) => {
    //   state.challenge = challenge;
    // }),
    // clearUnits: create.reducer((state) => {
    //   state.unitsList = [];
    // }),
    // clearUsers: create.reducer((state) => {
    //   state.usersList = [];
    // }),
    // clearFilter: create.reducer((state) => {
    //   state.filterBy = [
    //     {
    //       key: '',
    //       active: false,
    //       values: [],
    //     },
    //   ];
    //   state.list = [...state.fullComments];
    // }),
  }),
  selectors: {
    selectKanbanState: (sliceState) => sliceState.kanbanState,
    selectEditMode: (sliceState) => sliceState.editMode,
    selectIsLoading: (sliceState) => sliceState.loading,
    selectOriginalKanbanCols: (sliceState) => sliceState.originalKanbanCols,
    selectOriginalKanbanColsBeforeEdit: (sliceState) =>
      sliceState.originalKanbanColsBeforeEdit,
    selectOriginalKanbanComps: (sliceState) => sliceState.originalKanbanComps,
    selectEditedKanbanCols: (sliceState) => sliceState.editedKanbanCols,
    selectEditedKanbanComps: (sliceState) => sliceState.editedKanbanComps,
    selectOriginalKanban: (sliceState) => sliceState.originalKanban,
    selectOriginalMappedKanban: (sliceState) => sliceState.originalMappedKanban,
    selectCompaniesData: (sliceState) => sliceState.companiesData,
    selectDecisionMakers: (sliceState) => sliceState.decisionMakers,
    selectSearchQuery: (sliceState) => sliceState.searchQuery,
  },
});

// Action creators are generated for each case reducer function
export const {
  setEditMode,
  saveEditedKanban,
  setOriginalKanbanCols,
  setOriginalKanbanColsBeforeEdit,
  setOriginalKanbanComps,
  setEditedKanbanCols,
  setEditedKanbanComps,
  setOriginalKanban,
  fetchKanban,
  fetchComps,
  clearEditedKanban,
  setSearchQuery,
  setKanbanCompanies,
  clearOriginalMappedKanban,
} = kanbanSlice.actions;

// Selector creators for each uniqe value inside state
export const {
  selectKanbanState,
  selectEditMode,
  selectIsLoading,
  selectOriginalKanbanCols,
  selectOriginalKanbanColsBeforeEdit,
  selectOriginalKanbanComps,
  selectEditedKanbanCols,
  selectEditedKanbanComps,
  selectOriginalKanban,
  selectCompaniesData,
  selectDecisionMakers,
  selectSearchQuery,
  selectOriginalMappedKanban,
} = kanbanSlice.selectors;

export default kanbanSlice.reducer;
