import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiAuth {
  static async login(email, pass, mfa_token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/login`, {
        signal: KLAbortController.genController('login').signal,
        method: 'POST',
        body: JSON.stringify({ email, password: pass, mfa_token }), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('login');

      return json;
    } catch (err) {
      return null;
    }
  }

  static async register(email, pass, fullName) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/signup`, {
        signal: KLAbortController.genController('register').signal,
        method: 'PUT',
        body: JSON.stringify({ email, password: pass, fullName }), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('register');

      return json;
    } catch (err) {
      return null;
    }
  }

  static async registerOrganization(fullName, email, companyName, role) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/register/corp`, {
        signal: KLAbortController.genController('registerOrganization').signal,
        method: 'POST',
        body: JSON.stringify({ email, fullName, companyName, role }), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('registerOrganization');

      return json;
    } catch (err) {
      return null;
    }
  }
  static async registerStartup(fullName, email, companyName, role) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/onboarding/startup`,
        {
          signal: KLAbortController.genController('registerStartup').signal,
          method: 'POST',
          body: JSON.stringify({ email, fullName, companyName, role }), // data can be `string` or {object}!
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('registerStartup');

      return json;
    } catch (err) {
      return null;
    }
  }
  static async resetPassword(token, email, newPass) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/reset_pass`, {
        signal: KLAbortController.genController('resetPassword').signal,
        method: 'POST',
        body: JSON.stringify({ token, email, newPass }), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('resetPassword');

      return json;
    } catch (err) {
      return null;
    }
  }

  static async forgotPassword(email) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/request_reset_pass`,
        {
          signal: KLAbortController.genController('forgotPassword').signal,
          method: 'POST',
          body: JSON.stringify({ email }), // data can be `string` or {object}!
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('forgotPassword');

      return json;
    } catch (err) {
      return null;
    }
  }

  static async changePass(prevPass, newPass, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/user/change_pass`, {
        signal: KLAbortController.genController('changePass').signal,
        method: 'POST',
        body: JSON.stringify({ prev_pass: prevPass, new_pass: newPass }), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('changePass');

      return json;
    } catch (err) {
      return null;
    }
  }

  static async logout(userId, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/user/logout`, {
        // signal: KLAbortController.genController('logout').signal,
        method: 'POST',
        body: JSON.stringify({ userId }), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      // KLAbortController.clearController('logout');

      return json;
    } catch (err) {
      return null;
    }
  }
  static async getMFALink(token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/get_mfa_link/`, {
        signal: KLAbortController.genController('getMFALink').signal,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });

      KLAbortController.clearController('getMFALink');

      return json;
    } catch (err) {
      return null;
    }
  }
  static async connectMFA(mfa_token1, mfa_token2, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/connect_mfa/?mfa_token1=${mfa_token1}&mfa_token2=${mfa_token2}`,
        {
          signal: KLAbortController.genController('connectMFA').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );

      KLAbortController.clearController('connectMFA');

      return json;
    } catch (err) {
      return null;
    }
  }
  static async disconnectMFA(token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/remove_mfa`, {
        signal: KLAbortController.genController('disconnectMFA').signal,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });

      KLAbortController.clearController('disconnectMFA');

      return json;
    } catch (err) {
      return null;
    }
  }
}

// window.registerUser = ApiAuth.register;

export default ApiAuth;
