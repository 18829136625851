import { attr, Model } from 'redux-orm';

import { genUniqueId } from '~lib/utils/strings';

/**
 * Communities model
 * @author Villi K.
 */
class Notes extends Model {
  static get modelName() {
    return 'Notes';
  }

  static get fields() {
    return {
      notes: attr(),
      didLoad: attr(),
    };
  }

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param User
   * @returns {undefined}
   */
  static reducer(action, Notes, session) {
    if (!Notes) return;
    switch (action.type) {
      case 'CREATE_NOTES_DB':
        // Notes.delete();

        Notes.create({
          notes: [],
          didLoad: false,
        });
        break;
      case 'SET_NOTES': {
        const notesDB = Notes.last();
        if (!notesDB) return;

        const { userNotes = [] } = action.payload;

        notesDB.didLoad = true;
        notesDB.notes = userNotes || [];

        notesDB.update();
        break;
      }
      case 'INSERT_NOTE': {
        const notesDB = Notes.last();
        if (!notesDB) return;

        const { noteData } = action.payload;
        if (!noteData) return;

        if (!notesDB.notes) notesDB.notes = [];
        notesDB.notes.push(noteData);

        notesDB.update();
        break;
      }
      case 'UPDATE_NOTE': {
        const notesDB = Notes.last();
        if (!notesDB) return;

        const { noteId, noteData } = action.payload;
        if (!noteId) return;

        const idx = notesDB.notes.findIndex((i) => i.note_id === noteId);
        if (idx === -1) return;

        notesDB.notes[idx] = {
          ...notesDB.notes[idx],
          data: {
            ...notesDB.notes[idx].data,
            ...noteData,
          },
        };

        notesDB.update();
        break;
      }
      case 'REMOVE_NOTE': {
        const notesDB = Notes.last();
        if (!notesDB) return;

        const { noteId } = action.payload;
        if (!noteId) return;

        notesDB.notes = notesDB.notes.filter((item) => item.note_id !== noteId);

        notesDB.update();
        break;
      }
      case 'DELETE_NOTES_DB':
        try {
          Notes.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Notes };
export default Notes;
