import { createSliceWithThunks } from '../createSlice';
import KleverApi from '~lib/api/api';
import sort from '~store/rtk/helpers/memberListSortHelper';

const initialState = {
  curTab: null,
  members: [],
  guests: [],
  techPartners: [],
  matchesCompaniesMembers: [],
  matchesCompaniesGuests: [],
  membersLength: 0,
  guestsLentgh: 0,
  techPartnersLength: 0,
  communityLength: 0,
  communityCurCount: 0,
  filteredMembers: [],
  sort: {},
  techPartnersOptions: {
    sort: {
      sortKey: null,
      upToDown: null,
    },
    curPage: 1,
    limit: 20,
    search: '',
    filter: {},
  },
  search: '',
  statsIsLoading: false,
  membersIsLoading: false,
  techPartnersIsLoading: false,
  isFilteredList: false,
  membersLoaded: false,
  guestsLoaded: false,
};

export const networkSlice = createSliceWithThunks({
  name: 'network',
  initialState,
  reducers: (create) => ({
    setCurTab: create.reducer((state, action) => {
      state.curTab = action.payload;
    }),
    setSort: create.reducer((state, { payload }) => {
      state.isFilteredList = true;
      state.filteredMembers = [
        ...sort(payload, state.curTab === 0 ? state.members : state.guests),
      ];
      state.sort = { ...payload };
    }),
    clearIsFilteredList: create.reducer((state) => {
      state.isFilteredList = false;
      state.filteredMembers = [];
      state.search = '';
    }),
    setSearch: create.reducer((state, { payload }) => {
      state.search = payload;
      state.isFilteredList = true;
      state.filteredMembers =
        state.curTab === 0
          ? [
              ...state.members.filter((member) =>
                `${member.first_name} ${member.last_name}`
                  .toLowerCase()
                  .includes(payload.toLowerCase())
              ),
            ]
          : [
              ...state.guests.filter((member) =>
                `${member.first_name} ${member.last_name}`
                  .toLowerCase()
                  .includes(payload.toLowerCase())
              ),
            ];
    }),
    setTechPartnersOptions: create.reducer(
      (state, { payload: { key, value } }) => {
        state.techPartnersOptions = {
          ...state.techPartnersOptions,
          [key]: value,
        };
      }
    ),
    clearTechPartnersOptions: create.reducer((state) => {
      state.techPartnersOptions = {
        sort: {
          sortKey: null,
          upToDown: null,
        },
        curPage: 1,
        limit: 20,
        search: '',
        filter: {},
      };
    }),
    fetchMembers: create.asyncThunk(
      async ({ communityId, token }, thunkApi) => {
        const response = await KleverApi.Community.fetchMembers(
          communityId,
          'member',
          token
        );
        return response;
      },
      {
        pending: (state) => {
          state.membersIsLoading = true;
        },
        rejected: (state) => {
          state.membersIsLoading = false;
        },
        fulfilled: (state, { payload: { team_members, matchesCompanies } }) => {
          state.membersIsLoading = false;
          state.members = [
            ...team_members.map((member) => ({
              ...member,
              unit_abbr: member.unit_abbr[0] || '',
              unit_tooltip: member.unit_tooltip[0] || '',
            })),
          ];
          state.membersLoaded = true;
          state.matchesCompaniesMembers = [...matchesCompanies];
        },
      }
    ),
    fetchGuests: create.asyncThunk(
      async ({ communityId, token }, thunkApi) => {
        const response = await KleverApi.Community.fetchMembers(
          communityId,
          'guest',
          token
        );
        return response;
      },
      {
        pending: (state) => {
          state.membersIsLoading = true;
        },
        rejected: (state) => {
          state.membersIsLoading = false;
        },
        fulfilled: (state, { payload: { team_members, matchesCompanies } }) => {
          state.membersIsLoading = false;
          state.guests = [
            ...team_members.map((member) => ({
              ...member,
              unit_abbr: member.unit_abbr[0] || '',
              unit_tooltip: member.unit_tooltip[0] || '',
            })),
          ];
          state.guestsLoaded = true;
          state.matchesCompaniesGuests = [...matchesCompanies];
        },
      }
    ),
    fetchStats: create.asyncThunk(
      async ({ communityId, token }, thunkApi) => {
        const response = await KleverApi.Community.getNetworkStats(
          communityId,
          token
        );
        return response;
      },
      {
        pending: (state) => {
          state.statsIsLoading = true;
        },
        rejected: (state) => {
          state.statsIsLoading = false;
        },
        fulfilled: (state, { payload: { stats } }) => {
          const {
            members,
            guests,
            partners,
            full_community,
            full_seats,
          } = stats;
          state.statsIsLoading = false;
          state.membersLength = members;
          state.guestsLentgh = guests;
          state.techPartnersLength = partners;
          state.communityLength = full_seats;
          state.communityCurCount = full_community;
        },
      }
    ),
    fetchTechPartners: create.asyncThunk(
      async ({ communityId, token, options, additionals }, thunkApi) => {
        const response = await KleverApi.Community.fetchTechPartners({
          main: {
            communityId,
            token,
            ...options,
          },
        });
        return response;
      },
      {
        pending: (state) => {
          state.techPartnersIsLoading = true;
        },
        rejected: (state) => {
          state.techPartnersIsLoading = false;
        },
        fulfilled: (state, { payload: { partners } }) => {
          state.techPartners = [...partners];
          state.techPartnersIsLoading = false;
        },
      }
    ),
  }),
  selectors: {
    getCurTab: (sliceState) => sliceState.curTab,
    getGuestsLength: (sliceState) => sliceState.guestsLentgh,
    getMembersLength: (sliceState) => sliceState.membersLength,
    getTechPartnersLength: (sliceState) => sliceState.techPartnersLength,
    getCommunityLength: (sliceState) => sliceState.communityLength,
    getCommunityCurCount: (sliceState) => sliceState.communityCurCount,
    getMatchesCompaniesGuests: (sliceState) =>
      sliceState.matchesCompaniesGuests,
    getMatchesCompaniesMembers: (sliceState) =>
      sliceState.matchesCompaniesMembers,
    getMembers: (sliceState) => sliceState.members,
    getGuests: (sliceState) => sliceState.guests,
    getTechPartners: (sliceState) => sliceState.techPartners,
    getSort: (sliceState) => sliceState.sort,
    getIsFilteredList: (sliceState) => sliceState.isFilteredList,
    getFilteredList: (sliceState) => sliceState.filteredMembers,
    getSearch: (sliceState) => sliceState.search,
    getTechPartnersOptions: (sliceState) => sliceState.techPartnersOptions,
    getMembersIsLoading: (sliceState) => sliceState.membersIsLoading,
    getStatsIsLoading: (sliceState) => sliceState.statsIsLoading,
    getTechPartnersIsLoading: (sliceState) => sliceState.techPartnersIsLoading,
    getMembersLoaded: (sliceState) => sliceState.membersLoaded,
    getGuestsLoaded: (sliceState) => sliceState.guestsLoaded,
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurTab,
  setSort,
  setSearch,
  fetchMembers,
  fetchTechPartners,
  fetchGuests,
  fetchStats,
  clearIsFilteredList,
  clearTechPartnersOptions,
  setTechPartnersOptions,
} = networkSlice.actions;

// Selector creators for each uniqe value inside state
export const {
  getCurTab,
  getGuestsLength,
  getMembersLength,
  getTechPartnersLength,
  getCommunityLength,
  getCommunityCurCount,
  getMatchesCompaniesGuests,
  getMatchesCompaniesMembers,
  getMembers,
  getGuests,
  getTechPartners,
  getSort,
  getSearch,
  getIsFilteredList,
  getFilteredList,
  getTechPartnersOptions,
  getMembersIsLoading,
  getStatsIsLoading,
  getTechPartnersIsLoading,
  getMembersLoaded,
  getGuestsLoaded,
} = networkSlice.selectors;

export default networkSlice.reducer;
