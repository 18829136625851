/**
 * ORM Model registration routine
 * @author Villi K.
 */
import { ORM } from 'redux-orm';

import {
  App,
  AppState,
  KLRouter,
  User,
  Communities,
  EntityPool,
  KanbanBoards,
  Files,
  Lists,
  Chats,
  Catalogs,
  CompanyUpdates,
  Portfolios,
  Notifications,
  Evaluations,
  Notes,
  Comments,
  Forms,
  Onboardings,
  Invites,
  Projects,
  Startup,
  Feedbacks,
  ContactRequests,
  Leads,
  KnowledgeCenter,
  Pins,
} from '../models';

const orm = new ORM({ stateSelector: (state) => state.db });

// register models here
orm.register(
  App,
  AppState,
  KLRouter,
  User,
  EntityPool,
  KanbanBoards,
  Files,
  Chats,
  Lists,
  Catalogs,
  CompanyUpdates,
  Portfolios,
  Communities,
  Notifications,
  Evaluations,
  Onboardings,
  Notes,
  Comments,
  Forms,
  Invites,
  Projects,
  Startup,
  Feedbacks,
  ContactRequests,
  Leads,
  KnowledgeCenter,
  Pins
);

export default orm;
