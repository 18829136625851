import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiCustomFields {
  static async getSections({
    type,
    parentId,
    communityId,
    companyId,
    skip = 0,
    limit = 0,
    isGeneral = true,
    token,
  }) {
    try {
      const communityPath = communityId ? `communityId=${communityId}&` : '';
      const companyPath = companyId ? `companyId=${companyId}&` : '';
      const typePath = type ? `cfType=${type}&` : '';
      const pIdPath = parentId ? `parentId=${parentId}` : '';

      const path = `${API_URL}/custom_fields/get_all?${communityPath}${companyPath}&skip=${skip}&limit=${limit}&isGeneral=${isGeneral}&${typePath}${pIdPath}`;

      const json = await KleverApi.workerFetch(path, {
        signal: KLAbortController.genController('getSections').signal,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });

      KLAbortController.clearController('getSections');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async getSectionValue({
    parentId,
    communityId,
    companyId,
    skip = 0,
    limit = 0,
    token,
  }) {
    try {
      const communityPath = communityId ? `communityId=${communityId}&` : '';
      const companyPath = companyId ? `companyId=${companyId}&` : '';
      const pIdPath = parentId ? `parentId=${parentId}` : '';

      const path = `${API_URL}/cfields_values/get_all??${communityPath}${companyPath}&skip=${skip}&limit=${limit}&${pIdPath}`;

      const json = await KleverApi.workerFetch(path, {
        signal: KLAbortController.genController('getSections').signal,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });

      KLAbortController.clearController('getSections');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async getCustomFieldsValues({
    evaluationId,
    communityId,
    companyId,
    skip = 0,
    limit = 0,
    token,
  }) {
    try {
      const communityPath = communityId ? `communityId=${communityId}` : '';
      const companyPath = companyId ? `companyId=${companyId}` : '';
      const evaluationPath = evaluationId ? `evaluationId=${evaluationId}` : '';

      const path = `${API_URL}/cfields_values/get_all?${communityPath}${
        !!companyPath.length || !!evaluationPath.length
          ? !!communityPath.length
            ? '&'
            : ''
          : ''
      }${companyPath}${!!companyPath.length ? '&' : ''}${evaluationPath}`;

      const jsonHeaders = {
        'Content-Type': 'application/json',
      };

      if (token) {
        jsonHeaders['Authorization'] = `kl=${token}`;
      }

      const json = await KleverApi.workerFetch(path, {
        signal: KLAbortController.genController('getSections').signal,
        method: 'GET',
        headers: jsonHeaders,
      });

      KLAbortController.clearController('getSections');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getEvaluationsCustomFieldsValues({
    evaluationId,
    communityId,
    companyId,
    skip = 0,
    limit = 0,
  }) {
    try {
      const communityPath = communityId ? `communityId=${communityId}` : '';
      const companyPath = companyId ? `companyId=${companyId}` : '';
      const evaluationPath = evaluationId ? `evaluationId=${evaluationId}` : '';

      const path = `${API_URL}/cfields_values/get_for_evaluation?${communityPath}${
        !!companyPath.length || !!evaluationPath.length
          ? !!communityPath.length
            ? '&'
            : ''
          : ''
      }${companyPath}${!!companyPath.length ? '&' : ''}${evaluationPath}`;

      const json = await KleverApi.workerFetch(path, {
        signal: KLAbortController.genController('getSections').signal,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      KLAbortController.clearController('getSections');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async createCustomField({
    title = '',
    description = '',
    cfieldList = [],
    cfieldType = '',
    companyId = null,
    communityId = null,
    isPublic = false,
    token,
  }) {
    try {
      if (!title || !token || !companyId || !communityId || !cfieldType)
        return null;

      const cfieldData = {
        title,
        description,
        cfieldList,
        cfieldType,
        companyId,
        communityId,
        isPublic,
      };

      const json = await KleverApi.workerFetch(`${API_URL}/custom_fields/add`, {
        signal: KLAbortController.genController('getSections').signal,
        method: 'GET',
        body: JSON.stringify({ cfieldData }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });

      KLAbortController.clearController('getSections');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async saveCustomField({
    cfieldValueData = [],
    companyId = null,
    communityId = null,
    token,
  }) {
    try {
      if (!token || !companyId || !communityId) return null;

      const json = await KleverApi.workerFetch(
        `${API_URL}/cfields_values/save`,
        {
          signal: KLAbortController.genController('saveCustomField').signal,
          method: 'POST',
          body: JSON.stringify({ cfieldValueData, companyId, communityId }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );

      KLAbortController.clearController('saveCustomField');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiCustomFields;
