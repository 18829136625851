import { API_URL } from '~lib/constants';

import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiFiles {
  static async getFiles(communityId, companyId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/files/get${
          communityId ? `?communityId=${communityId}` : ''
        }${companyId && communityId ? `&companyId=${companyId}` : ''}`,
        {
          signal: KLAbortController.genController('getFiles').signal,
          // mode: 'no-cors',
          // credentials: 'omit',
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getFiles');
      window.last_request_time = Date.now();

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async uploadFile(file) {
    try {
      const formData = new FormData();
      formData.append('pitchdeck', file);

      const json = await KleverApi.workerFetch(
        `${API_URL}/files/pitchdeck`,
        {
          signal: KLAbortController.genController('uploadFile').signal,
          method: 'POST',
          body: formData,
        },
        true
      );
      KLAbortController.clearController('uploadFile');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async uploadUserFile(file, token) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const json = await KleverApi.workerFetch(`${API_URL}/upload/user/file`, {
        signal: KLAbortController.genController('uploadUserFile').signal,
        method: 'POST',
        headers: {
          Authorization: `kl=${token}`,
        },
        body: formData,
      });
      KLAbortController.clearController('uploadUserFile');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async uploadCommunityCompanyFile(
    file,
    communityId,
    companyId,
    listId,
    token
  ) {
    try {
      const formData = new FormData();

      formData.append('file', file);

      const json = await KleverApi.workerFetch(
        `${API_URL}/upload/community/company/file?communityId=${communityId}&companyId=${companyId}${
          listId ? '&listId=' + listId : ''
        }`,
        {
          signal: KLAbortController.genController('uploadCommunityCompanyFile')
            .signal,
          method: 'POST',
          headers: {
            Authorization: `kl=${token}`,
          },
          body: formData,
        },
        true
      );
      KLAbortController.clearController('uploadCommunityCompanyFile');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async downloadFileByName(communityId, companyId, fileName, token) {
    try {
      const json = await fetch(
        `${API_URL}/files/${communityId}/${companyId}/${fileName}`,
        {
          signal: KLAbortController.genController('downloadFileByName').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('downloadFileByName');

      // KleverApi.handleErrorCodes(json.code);
      // if (!json.success) {
      //   KleverApi.handleErrorMessage(json);
      // }
      return json.blob();
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async removeFile(communityId, fileId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/files/remove`,
        {
          signal: KLAbortController.genController('removeFile').signal,
          method: 'POST',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            communityId,
            fileId,
          }),
        }
      );
      KLAbortController.clearController('removeFile');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getCompaniesFiles(token) {
    try {
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiFiles;
