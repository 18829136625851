import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiAnalytics {
  static async click(page, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/analytics/click?page_name=${page}`,
        {
          signal: KLAbortController.genController('click').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );

      KLAbortController.clearController('click');

      // KleverApi.handleErrorCodes(json.code);
      // if (!json?.success) {
      //   KleverApi.handleErrorMessage(json || {});
      // }
      // return json;
    } catch (err) {
      console.error(err);
      // return null;
    }
  }
  static async openCategory({ categoryId, categoryTitle, communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/analytics/category?categoryId=${categoryId}&categoryTitle=${categoryTitle}&communityId=${communityId}`,
        {
          signal: KLAbortController.genController('openCategory').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );

      KLAbortController.clearController('openCategory');

      // KleverApi.handleErrorCodes(json.code);
      // if (!json?.success) {
      //   KleverApi.handleErrorMessage(json || {});
      // }
      // return json;
    } catch (err) {
      console.error(err);
      // return null;
    }
  }
  static async openConsult(evaluationId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/analytics/open_consult?evaluationId=${evaluationId}`,
        {
          signal: KLAbortController.genController('openConsult').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );

      KLAbortController.clearController('openConsult');
    } catch (err) {
      console.error(err);
    }
  }
}

export default ApiAnalytics;
