import React, { Suspense, useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import DefaultLoader from '~components/loaders/DefaultLoader.jsx';
import ThemeProvider from '~components/hoc/ThemeProvider';

import AsyncRoute from './AsyncRoute';
// import AuthenticationPage from '../app/authentication/AuthenticationPage';
// import ResetPassword from '../app/authentication/resetpass/ResetPassword';
// import ForgotPassword from '../app/authentication/forgotpass/ForgotPassword';
// import Workspace from '../app/workspace/workspace';
// import StaticPage from '../app/static/StaticPage';
// import StartupPage from '../app/startup/Startup';

// const Dashboard = React.lazy(() => import('../app/dashboard/dashboard'));

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
          return window.location.reload(); // refresh the page
        }
        reject(error);
      });
  });
};

const StaticPage = React.lazy(() =>
  lazyRetry(() => import('../app/static/StaticPage'))
);
const StartupPage = React.lazy(() =>
  lazyRetry(() => import('../app/startup/startup'))
);
const Workspace = React.lazy(() =>
  lazyRetry(() => import('../app/workspace/workspace'))
);
const AuthenticationPage = React.lazy(() =>
  lazyRetry(() => import('../app/authentication/AuthenticationPage'))
);
const ResetPassword = React.lazy(() =>
  lazyRetry(() => import('../app/authentication/resetpass/ResetPassword'))
);
const ForgotPassword = React.lazy(() =>
  lazyRetry(() => import('../app/authentication/forgotpass/ForgotPassword'))
);

const MfaForm = React.lazy(() =>
  lazyRetry(() => import('../app/authentication/mfa/MFA'))
);

// const retryLoadBundle = (lazy, retriesLeft = 5, interval = 1000) => {
// $FlowFixMe

// const history = createBrowserHistory();

const Routes = {
  '/': Workspace,
  '/login': AuthenticationPage,
  '/register': AuthenticationPage,
  '/landing': AuthenticationPage,
  '/resetpass': ResetPassword,
  '/forgotpass': ForgotPassword,
  '/mfa': MfaForm,
  '/workspace': Workspace,
  '/startup': StartupPage,
  '/static': StaticPage,
};

const cachedRoutes = {};

const renderLoader = () => {
  return (
    // <div className='full-width full-height flex flex-center justify-content-center'>
    //   {/* <DefaultLoader /> */}
    // </div>
    <div className='loader-container'>
      <div className='loader-img'></div>
      <div
        className='loader default margin-center absolute'
        style={{
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          width: '200px',
          height: '200px',
          borderRadius: '200px',
        }}
      >
        <svg
          style={{ height: '200px', width: '200px' }}
          width='36'
          height='36'
          viewBox='0 0 36 36'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='18' cy='18' r='16' stroke='#574F89' strokeWidth='1' />
          <path
            d='M34 18C34 9.16344 26.8366 2 18 2'
            stroke='#eb571d'
            strokeWidth='1'
            strokeLinecap='round'
          />
        </svg>
      </div>
    </div>
  );
};

const handleRenderComponent = (Component, props, routerProps) => {
  if (
    (!props.user || !props.user.isLoggedIn) &&
    !routerProps.match.path.includes('/static')
  ) {
    return (
      <Suspense fallback={renderLoader()}>
        <AuthenticationPage
          redirectUrl={routerProps.location.pathname}
          {...props}
          {...routerProps}
        />
      </Suspense>
    );
  }

  // check if user already logged in and trying to access auth page
  if (
    props.user &&
    (routerProps.match.path.includes('/login') ||
      routerProps.match.path.includes('/register') ||
      routerProps.match.path.includes('/landing'))
  ) {
    routerProps.history.replace('/');
    return null;
  }
  return (
    <Suspense fallback={renderLoader()}>
      <ThemeProvider>
        <Component {...props} {...routerProps} />
      </ThemeProvider>
    </Suspense>
  );
};

const _genRoutes = (props) => {
  const list = [];
  Object.keys(Routes).forEach((key) => {
    const Component = Routes[key];
    if (!Component) return null;

    const _props = {};
    if (!cachedRoutes[key]) cachedRoutes[key] = Component;

    if (key === '/') {
      _props.exact = true;
      _props.default = true;
    }
    list.push(
      <AsyncRoute
        key={key}
        {..._props}
        path={key}
        render={handleRenderComponent.bind(null, Component, props)}
      />
    );
  });

  // list.push(<AsyncRoute key={'default_route'} default component={Workspace} />);

  return list;
};

const MainRouter = (props) => {
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const { user } = props;
    if (user && user.user_type === 'startup') {
      Routes['/'] = StartupPage;
      Routes['/workspace'] = StartupPage;
    } else if (user && user.user_type === 'organization') {
      Routes['/'] = Workspace;
      Routes['/startup'] = Workspace;
    }
    setRoutes(_genRoutes(props));

    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );

    if (hasRefreshed) {
      window.versionNotifyTimeout = setTimeout(() => {
        const settings = {
          title: 'New Update',
          message: 'New version of KLEVER was installed!',
          type: 'info',
          insert: 'top',
          container: 'bottom-left',
          animationIn: ['animated', 'flipInX'],
          animationOut: ['animated', 'flipOutX'],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        };
        store.addNotification(settings);

        clearTimeout(window.versionNotifyTimeout);
        delete window.versionNotifyTimeout;
      }, 2000);
    }
  }, [props]);

  const router = (_props) => {
    return routes;
  };

  return router(props);
};

export default MainRouter;
