import { attr, Model } from 'redux-orm';

/**
 *  Notifications model
 * @author Villi K.
 */
class Notifications extends Model {
  static get modelName() {
    return 'Notifications';
  }

  static get fields() {
    return {
      notifications: attr(),
    };
  }

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param User
   * @param session
   * @returns {undefined}
   */
  static reducer(action, Notifications, session) {
    if (!Notifications) return;
    switch (action.type) {
      case 'CREATE_NOTIFICATION_DB':
        // Notifications.delete();
        Notifications.create({
          notifications: action.payload && [...action.payload],
        });
        break;
      case 'SYNC_NOTIFICATIONS': {
        const mainEntity = Notifications.last();
        if (!mainEntity) return null;

        const { notifications } = action.payload;
        if (Array.isArray(notifications)) {
          mainEntity.notifications = notifications;
        }
        mainEntity.update();
        break;
      }
      case 'ADD_NEW_NOTIFICATION': {
        const mainEntity = Notifications.last();
        if (!mainEntity) return null;

        const { notification } = action.payload;
        mainEntity.notifications = [...mainEntity.notifications, notification];

        mainEntity.update();
        mainEntity.update({
          notifications: mainEntity.notifications,
        });

        break;
      }
      case 'SYNC_NOTIFICATIONS_STATES': {
        const mainEntity = Notifications.last();
        if (!mainEntity) return null;

        const { notificationIdList } = action.payload;
        mainEntity.notifications = mainEntity.notifications.map((item) => {
          return {
            ...item,
            is_viewed: true,
          };
        });

        mainEntity.update();
        break;
      }
      case 'REMOVE_NOTIFICATION_DB':
        try {
          Notifications.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export default Notifications;
export { Notifications };
