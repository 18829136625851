import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiEvaluations {
  static async getEvaluations(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/evaluation/get?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getEvaluations').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getEvaluations');

      const parsedJson = json;

      KleverApi.handleErrorCodes(parsedJson.code);
      if (!parsedJson.success) {
        KleverApi.handleErrorMessage(parsedJson);
      }
      return parsedJson;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getEvaluationById(communityId, evaluationId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/evaluation/get?communityId=${communityId}&evaluationId=${evaluationId}`,
        {
          signal: KLAbortController.genController('getEvaluationById').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getEvaluationById');

      const parsedJson = json;

      KleverApi.handleErrorCodes(parsedJson.code);
      if (!parsedJson.success) {
        KleverApi.handleErrorMessage(parsedJson);
      }
      return parsedJson;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getEvaluationsByCompany(communityId, token, companyId) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/evaluation/get?communityId=${communityId}&companyId=${companyId}`,
        {
          signal: KLAbortController.genController('getEvaluationsByCompany')
            .signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getEvaluationsByCompany');

      const parsedJson = json;

      KleverApi.handleErrorCodes(parsedJson.code);
      if (!parsedJson.success) {
        KleverApi.handleErrorMessage(parsedJson);
      }
      return parsedJson;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async createSharedEvaluation({
    communityId,
    companyIdList = [],
    memberIdList = [],
    externalMemberList = [],
    controlType = 'digits',
    subject = null,
    customMessage = null,
    projectId = null,
    listId = null,
    formId = null,
    isDecisionRoomShare = false,
    token,
  }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/evaluation/share`, {
        signal: KLAbortController.genController('createSharedEvaluation')
          .signal,
        method: 'POST',
        body: JSON.stringify({
          communityId,
          companyIdList,
          memberIdList,
          externalMemberList,
          controlType,
          subject,
          projectId,
          listId,
          formId,
          isDecisionRoomShare,
          customMessage,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('createSharedEvaluation');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async generateEvaluationLinks({
    communityId,
    companyIdList = [],
    memberIdList = [],
    externalMemberList = [],
    controlType = 3,
    projectId = null,
    listId = null,
    formId = null,
    isDecisionRoomShare = false,
    token,
  }) {
    try {
      if (
        !communityId ||
        !companyIdList.length ||
        (!memberIdList.length && !externalMemberList.length)
      )
        return;

      const json = await KleverApi.workerFetch(
        `${API_URL}/evaluation/generate_links`,
        {
          signal: KLAbortController.genController('generateEvaluationLinks')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            companyIdList,
            memberIdList,
            externalMemberList,
            projectId,
            listId,
            formId,
            isDecisionRoomShare,
            controlType,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('generateEvaluationLinks');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async createCoEvaluation({
    communityId = null,
    companyIdList = [],
    memberIdList = [],
    externalMemberList = [],
    controlType = 3,
    formId = null,
    projectId = null,
    listId = null,
    token,
  }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/evaluation/co_evaluation`,
        {
          signal: KLAbortController.genController('createCoEvaluation').signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            companyIdList,
            memberIdList,
            externalMemberList,
            controlType,
            projectId,
            listId,
            formId,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('createCoEvaluation');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async evaluate(communityId, evaluationId, decisions = {}, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/evaluation/evaluate`,
        {
          signal: KLAbortController.genController('evaluate').signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            evaluationId,
            decisions,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('evaluate');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async verifyStaticEvaluationToken(requestId, key) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/evaluation/external?requestId=${requestId}&key=${key}`,
        {
          signal: KLAbortController.genController('verifyStaticEvaluationToken')
            .signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('verifyStaticEvaluationToken');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async submitStaticEvaluationToken(requestId, key, decisions) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/evaluation/external`,
        {
          signal: KLAbortController.genController('submitStaticEvaluationToken')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            requestId,
            key,
            decisions,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('submitStaticEvaluationToken');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiEvaluations;
