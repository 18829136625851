import { attr, Model } from 'redux-orm';

/**
 * Comments model
 * @author Villi K.
 */
class Comments extends Model {
  static get modelName() {
    return 'Comments';
  }

  static get fields() {
    return {
      comments_list: attr(),
      needUpdate: attr(),
      didLoad: attr(),
    };
  }

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param Comments
   * @returns {undefined}
   */
  static reducer(action, Comments, session) {
    if (!Comments) return;
    switch (action.type) {
      case 'CREATE_COMMENTS_DB':
        // Comments.delete();

        Comments.create({
          comments_list: {},
          needUpdate: false,
          didLoad: false,
        });
        break;
      case 'UPDATE_COMMENTS':
        const commentsDB = Comments.last();
        if (!commentsDB) return;

        const { needUpdates = false } = action.payload;

        commentsDB.update({
          needUpdate: needUpdates,
        });
        break;
      case 'SET_COMMENTS': {
        try {
          const commentsDB = Comments.last();
          if (!commentsDB) return;

          const { comments = [] } = action.payload;

          if (comments) {
            const newComments = comments.reduce((acc, val) => {
              if (val.company_id) {
                acc[val.company_id] = val;
              }
              return acc;
            }, {});

            commentsDB.update({
              comments_list: newComments,
              didLoad: true,
            });
          }

          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }
      case 'CREATE_COMPANY_COMMENTS_CONTAINER': {
        try {
          const commentsDB = Comments.last();
          if (!commentsDB) return;

          const { companyId, commentContainer = {} } = action.payload;

          if (!commentsDB.comments_list[companyId]) {
            if (commentContainer && companyId) {
              commentsDB.comments_list = {
                ...commentsDB.comments_list,
                [companyId]: commentContainer,
              };
            }
          } else {
            const prevComments = commentsDB.comments_list[companyId].comments;

            commentsDB.comments_list = {
              ...commentsDB.comments_list,
              [companyId]: {
                ...commentsDB.comments_list[companyId],
                comments: { ...prevComments, ...commentContainer },
              },
            };
          }

          commentsDB.update({
            comments_list: commentsDB.comments_list,
          });

          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'INSERT_COMMENT': {
        try {
          const commentsDB = Comments.last();
          if (!commentsDB) return;

          const { companyId, commentData } = action.payload;
          if (!companyId || !commentData || !commentsDB.comments_list) return;

          const companyComments = commentsDB.comments_list[companyId];

          if (
            !companyComments ||
            !companyComments.comments ||
            companyComments.comments.find(
              (i) => i.comment_id === commentData.comment_id
            )
          )
            return;

          companyComments.comments.push(commentData);

          commentsDB.update({
            comments_list: {
              ...commentsDB.comments_list,
              [companyId]: { ...companyComments },
            },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'UPDATE_COMMENT': {
        try {
          const commentsDB = Comments.last();
          if (!commentsDB) return;

          const { commentId, companyId, delta } = action.payload;
          if (!commentId || !companyId || !delta) return;

          if (!commentsDB.comments_list) return;

          const companyComments = commentsDB.comments_list[companyId];

          if (!companyComments || !companyComments.comments) return;

          const prevCommentData = (companyComments.comments || []).find(
            (i) => i.comment_id === commentId
          );
          const commentDelta = Object.keys(delta).reduce((acc, val) => {
            if (delta[val]) {
              acc[val] = delta[val];
            }
            return acc;
          }, {});

          if (!prevCommentData) return;

          const updatedComments = companyComments.comments
            .map((i) => {
              if (i.comment_id === commentId) {
                return {
                  ...prevCommentData,
                  data: { ...prevCommentData.data, ...commentDelta },
                };
              }
              return i;
            })
            .filter((i) => i);

          commentsDB.update({
            comments_list: {
              ...commentsDB.comments_list,
              [companyId]: { ...companyComments, comments: updatedComments },
            },
          });

          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'LIKE_COMMENT': {
        try {
          const commentsDB = Comments.last();
          if (!commentsDB) return;

          const { commentId, companyId, likeData } = action.payload;
          if (!companyId || !likeData) return;

          if (!commentsDB.comments_list) return;

          const companyComments = commentsDB.comments_list[companyId];

          if (!companyComments || !companyComments.comments) return;

          const commentIdx = (companyComments.comments || []).findIndex(
            (i) => i.comment_id === commentId
          );

          if (
            commentIdx === -1 ||
            !companyComments.comments[commentIdx] ||
            !companyComments.comments[commentIdx].data
          )
            return;

          const isLiked = (
            companyComments.comments[commentIdx].data.likes || []
          ).find((i) => i.user_id === likeData.user_id);

          const updatedLikes = isLiked
            ? (companyComments.comments[commentIdx].data.likes || []).filter(
                (i) => i.user_id !== likeData.user_id
              )
            : [
                ...(companyComments.comments[commentIdx].data.likes || []),
                likeData,
              ];

          const updatedComments = companyComments.comments
            .map((i) => {
              if (i.comment_id === commentId) {
                return { ...i, data: { ...i.data, likes: updatedLikes } };
              }
              return i;
            })
            .filter((i) => i);

          commentsDB.update({
            comments_list: {
              ...commentsDB.comments_list,
              [companyId]: { ...companyComments, comments: updatedComments },
            },
          });

          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'DELETE_COMMENT': {
        try {
          const commentsDB = Comments.last();
          if (!commentsDB) return;

          const { companyId, commentId } = action.payload;
          if (!companyId || !commentId) return;

          if (!commentsDB.comments_list) return;

          const companyComments = commentsDB.comments_list[companyId];

          if (!companyComments || !companyComments.comments) return;

          const updatedComments = companyComments.comments.filter(
            (i) => i.comment_id !== commentId
          );

          commentsDB.update({
            comments_list: {
              ...commentsDB.comments_list,
              [companyId]: { ...companyComments, comments: updatedComments },
            },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'DELETE_COMMENTS_DB':
        try {
          Comments.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Comments };
export default Comments;
