export default function sort(key, arr) {
  const { sortKey, upToDown } = key;
  console.log(sortKey, upToDown);
  console.log(arr);
  const array = [...arr];
  switch (sortKey) {
    case 'unit':
      const filteredUnit = array.filter((item) => item?.unit_tooltip[0]);
      const emptyUnit = array.filter((item) => !item?.unit_tooltip[0]);
      if (!upToDown) {
        return [
          ...emptyUnit,
          ...filteredUnit.sort((a, b) =>
            b?.unit_tooltip
              .toLowerCase()
              .localeCompare(a?.unit_tooltip.toLowerCase())
          ),
        ].reverse();
      }
      return [
        ...filteredUnit.sort((a, b) =>
          b?.unit_tooltip
            .toLowerCase()
            .localeCompare(a?.unit_tooltip.toLowerCase())
        ),
        ...emptyUnit,
      ];
    case 'created_at':
      if (!upToDown) {
        return array
          .sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
          .reverse();
      }
      return array.sort(
        (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
      );
    case 'rooms':
      const filteredRooms = array.filter((item) => item?.lists?.length);
      const emptyRooms = array.filter((item) => !item?.lists?.length);
      if (!upToDown) {
        return [
          ...emptyRooms,
          ...filteredRooms.sort((a, b) => b?.lists?.length - a?.lists?.length),
        ].reverse();
      }
      return [
        ...filteredRooms.sort((a, b) => b?.lists?.length - a?.lists?.length),
        ...emptyRooms,
      ];
    case 'insights':
      const filteredInsights = array.filter((item) => item?.insights);
      const emptyInsights = array.filter((item) => !item?.insights);
      if (!upToDown) {
        return [
          ...emptyInsights,
          ...filteredInsights.sort((a, b) => b?.insights - a?.insights),
        ].reverse();
      }
      return [
        ...filteredInsights.sort((a, b) => b?.insights - a?.insights),
        ...emptyInsights,
      ];
    case 'coalitions':
      const filteredCoalitions = array.filter((item) => item?.matches?.length);
      const emptyCoalitions = array.filter((item) => !item?.matches?.length);
      if (!upToDown) {
        return [
          ...emptyCoalitions,
          ...filteredCoalitions.sort(
            (a, b) => b?.matches?.length - a?.matches?.length
          ),
        ].reverse();
      }
      return [
        ...filteredCoalitions.sort(
          (a, b) => b?.matches?.length - a?.matches?.length
        ),
        ...emptyCoalitions,
      ];
    case 'name':
    default:
      if (!upToDown) {
        return array
          .sort((a, b) =>
            `${b?.first_name}${b?.last_name}`
              .toLowerCase()
              .localeCompare(`${a?.first_name}${a?.last_name}`.toLowerCase())
          )
          .reverse();
      }
      return array.sort((a, b) =>
        `${b?.first_name}${b?.last_name}`
          .toLowerCase()
          .localeCompare(`${a?.first_name}${a?.last_name}`.toLowerCase())
      );
  }
}
