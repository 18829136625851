import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiCompanies {
  static async checkCompanyNameAvailability(companyName) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/company/check`, {
        signal: KLAbortController.genController('checkCompanyNameAvailability')
          .signal,
        method: 'POST',
        body: JSON.stringify({ companyName }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('checkCompanyNameAvailability');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async requestClaimName({ email, companyName }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/company/request_claim_name`,
        {
          signal: KLAbortController.genController('requestClaimName').signal,
          method: 'POST',
          body: JSON.stringify({
            requestPath: window.location.href,
            email,
            companyName,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('requestClaimName');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getCompany(companyId, withFetch, token, communityId = null) {
    try {
      const json = await KleverApi.fetchRetry(
        `${API_URL}/company/get?${
          communityId ? `communityId=${communityId}&` : ''
        }companyId=${companyId}&withFetch=${withFetch}`,
        {
          signal: KLAbortController.genController('getCompany').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getCompany');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getCompanies(query) {
    try {
      const json = await KleverApi.fetchRetry(
        `${API_URL}/explore/autocomplete_all`,
        {
          signal: KLAbortController.genController('getCompanies').signal,
          method: 'POST',
          body: JSON.stringify({ query }),
        }
      );
      KLAbortController.clearController('getCompanies');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getCompanyByLegal(companyId, withFetch, token) {
    try {
      const encodedId = encodeURIComponent(companyId || '');

      if (!encodedId) return null;

      const json = await KleverApi.fetchRetry(
        `${API_URL}/company/get_legal?companyId=${encodedId}&withFetch=${withFetch}`,
        {
          signal: KLAbortController.genController('getCompany').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getCompany');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getCommunityCompany(companyId, communityId, token) {
    try {
      const json = await KleverApi.fetchRetry(
        `${API_URL}/community/company/get?communityId=${communityId}&companyId=${companyId}`,
        {
          signal: KLAbortController.genController('getCommunityCompany').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getCommunityCompany');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getUpdates(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/company/updates?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getUpdates').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getUpdates');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async toggleUpdateView(communityId, updateIds, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/company/update/view`,
        {
          signal: KLAbortController.genController('toggleUpdateView').signal,
          method: 'POST',
          body: JSON.stringify({ communityId, updateIds }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('toggleUpdateView');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getCompanyStats(companyId, communityId, token) {
    try {
      const json = await KleverApi.fetchRetry(
        `${API_URL}/company/stats?companyId=${companyId}&communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getCompanyStats').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getCompanyStats');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async ImportProfile({ website, linkedinUrl, communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/company/import_profile`,
        {
          signal: KLAbortController.genController('ImportProfile').signal,
          method: 'POST',
          body: JSON.stringify({ website, linkedinUrl, communityId }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('ImportProfile');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiCompanies;
