import { getDate } from '~lib/utils/date';
import { similarity } from '~lib/utils/strings';

class FormsHelper {
  static CategoryType = {
    PRODUCT: 'product',
    // COMPLIANCE: 'compliance',
    TEAM: 'team',
    MISSION: 'vision',
    MARKET: 'market',
    // TRACTION: 'traction',
    SCALABILITY: 'scalability',
  };

  static EVALUATION_REASONS_MAP = {
    1: 'market',
    2: 'scalability',
    5: 'vision',
    4: 'team',
    0: 'product',
  };

  static defaultQuestion = {
    category_type: null, // Required, string
    owner_id: null, // Required, string
    community_id: null, // Required, string
    question_id: null, // Required, string
    question_title: null, // Required, string
    question_description: null, // Optional, string
    is_klever: true, // Bool, by default false
    created_at: Date.now(), // By default now
    updated_at: Date.now(), // By default now
  };

  static defaultCategory = {
    category_id: null, // Required, string
    category_title: null, // Required, string
    category_type: null, // Required, string + enum to [product, team, mission, market, traction, compliance]
    description: null, // Optional, string
    weight: 50, // Number, by default 50
    icon: null, // String, can be null
  };

  static defaultForm = {
    form_id: null,
    community_id: null,
    owner_id: null,
    categories: {
      [FormsHelper.CategoryType.TEAM]: {
        category_type: null,
        category_title: null,
        weight: 50,
        icon: null,
        items: [FormsHelper.defaultQuestion],
      },
    },
    form_name: null,
    settings: {},
    model_version: null,
    created_at: Date.now(),
  };

  static defaultFormCategories = {
    market: {
      items: [
        {
          question_id: '1',
          question_title: 'Overall market',
          question_description: `How attractive is the market in terms of\nmarket size, competition, entry barriers?`,
        },
      ],
    },
    product: {
      items: [
        {
          question_id: '2',
          question_title: 'Overall product',
          question_description: `How would you assess the value proposition\n of the product and its technology?`,
        },
      ],
    },
    scalability: {
      items: [
        {
          question_id: '3',
          question_title: 'Overall scalability',
          question_description: `How well the company is able to scale\nin terms of clients, market reach, revenues?`,
        },
      ],
    },
    team: {
      items: [
        {
          question_id: '4',
          question_title: 'Overall team',
          question_description: `How would you assess the founding team\n and its executional skills?`,
        },
      ],
    },
    vision: {
      items: [
        {
          question_id: '5',
          question_title: 'Overall vision',
          question_description: `How would you assess the disruption potential\n or the impact of the company?`,
        },
      ],
    },
    // ...(curData || {}),
    // ...(curData?.comment ? items : {}),
  };

  static staticForms = [
    {
      title: 'SaaS Compliance',
      action: 'SaaS Compliance',
      is_klever: true,
      bg: 'primary',
      questions: {
        Product: {
          'Quality Management System (QMS)': true,
          'Development Lifecycle': true,
          'Incident Response Policy': true,
          'Password Management Procedure': true,
          'Privacy Principles': true,
          'Data Management Policy': true,
          GDPR: true,
        },
      },
    },
    {
      title: 'Tech Assessment',
      action: 'Tech Assessment',
      is_klever: true,
      bg: 'yellow',
      questions: {
        Product: {
          'Product Readiness': true,
          'Software Scalability': true,
          'Architecture & Infrastructure': true,
          'Machine Learning': true,
          IP: true,
          'Development Team': true,
          'R&D Budget': true,
        },
      },
    },
  ];

  static defaultFormBank = [
    {
      title: 'Product',
      type: 'product',
      items: [
        'Overall product',
        'Value proposition',
        'Product uniqueness',
        'IP/ Patents',
        'Product readiness',
        'Product scalability potential',
        'Quality Management System (QMS)',
        'Software Development Lifecycle',
        'Incident Response Policy',
        'Password Management Procedure',
        'Privacy Principles',
        'Data Management Policy',
        'GDPR',
        'Software Scalability',
        'Architecture & Infrastructure',
        'Machine Learning',
        'Development Team',
        'R&D Budget',
      ],
      icon: '/imgs_new/forms/product.svg',
    },
    {
      title: 'Team',
      type: 'team',
      items: [
        'Overall team',
        `Team's experience`,
        'Board and consultants / Advisors',
        'Personal impression from team',
        `Team's adaptability`,
        `Team's Executional skills`,
      ],
      icon: '/imgs_new/forms/team.svg',
    },
    {
      title: 'Market',
      type: 'market',
      items: [
        'Overall market',
        'Market feedback',
        'Market size – TAM',
        'Competition',
        'High entry barriers to market',
      ],
      icon: '/imgs_new/forms/market.svg',
    },
    {
      title: 'Traction',
      type: 'traction',
      items: [
        'Overall market',
        'Market feedback',
        'Market size – TAM',
        'Competition',
        'High entry barriers to market',
      ],
      icon: '/imgs_new/forms/market.svg',
    },
    {
      title: 'Mission',
      type: 'mission',
      items: [
        'Overall vision',
        'Industry disruption potential',
        'Problem worth solving',
        'Positive Impact (Sustainability)',
      ],
      icon: '/imgs_new/forms/mission.svg',
    },
    {
      title: 'Compliance & Legal',
      type: 'compliance',
      items: [
        'Overall scalability',
        'Commercial status',
        'Number of clients and sales status ',
        'Growth dynamics and customer acquisition plan',
        'Monetisation method',
        'Financial model, burn rate, Revenue projection – P&L',
      ],
      icon: '/imgs_new/forms/scale.svg',
    },
    // {
    //   title: 'Other',
    //   type: 'other',
    //   items: [
    //     'Financial model, burn rate, Revenue projection – P&L',
    //     'Overall scalability',
    //     'Commercial status',
    //     'Number of clients and sales status ',
    //     'Growth dynamics and customer acquisition plan',
    //     'Monetisation method',
    //   ],
    //   icon: '/imgs_new/forms/scale.svg',
    // },
  ];

  static getStaticCategoriesMap = (categories, questions) => {
    return Object.values(categories || {}).reduce((acc, val) => {
      const items = Object.values(questions || {}).filter(
        (q) => q.category_type === val.category_type
      );

      items.sort((a, b) => getDate(b.created_at) - getDate(a.created_at));

      acc[val.category_type] = { ...val, items };
      return acc;
    }, {});
  };

  static randomFormId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  static getQuestion = (text) => {
    return {
      question_id: FormsHelper.randomFormId(),
      question: text,
      question_pos: null,
      is_klever: true,
      created_at: Date.now(),
      updated_at: Date.now(),
    };
  };

  static getCategory = (category, catIdx) => {
    try {
      if (!category) return null;

      return {
        type: category.type || Object.values(FormsHelper.CategoryType)[catIdx],
        category_title: category.title,
        weight: null,
        icon: category.icon,
        items: category.items.map((i) => FormsHelper.getQuestion(i)),
      };
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  static getSelectedQ = (allCategories, formCategories, questions) => {
    try {
      return allCategories?.reduce((acc, val) => {
        const curCategoryItems = formCategories[val]?.items?.filter(
          (q) => questions[q.question_id]?.category_type === val
        );

        if (curCategoryItems) {
          acc[val] =
            curCategoryItems.reduce(
              (questions, q) => [...questions, q.question_id],
              []
            ) || [];
        }

        return acc;
      }, {});
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  static getNewForm = ({
    userId,
    newFormData,
    categories,
    questionExchange,
    is_temporary,
  }) => {
    if (
      !userId ||
      (!newFormData && !is_temporary) ||
      !categories ||
      !questionExchange
    )
      return {};

    if (newFormData.form_name || is_temporary) {
      const formCategories = Object.values(categories).reduce((acc, val) => {
        const catType = val.category_type;

        if (questionExchange[catType]) {
          const selectedQuestions = questionExchange[catType].map(
            (qId, idx) => ({
              question_id: qId,
              question_pos: idx,
            })
          );

          acc[val.category_id] = {
            category: val.category_id,
            items: selectedQuestions,
          };
        }

        return acc;
      }, {});

      newFormData.categories = formCategories;
      // newFormData.owner_id = userId;
    }

    return { ...newFormData, is_temporary };
  };

  static getAllQuestionsCount = (categories) => {
    try {
      return Object.keys(categories || {})?.reduce((acc, val) => {
        if (Array.isArray(categories[val])) {
          return acc + (categories[val]?.length || 0);
        }

        return acc + (categories[val]?.items?.length || 0);
      }, 0);
    } catch (err) {
      console.error(err);
      return 0;
    }
  };

  static setCategoryQuestions = ({ staticCategories, type }) => {
    const category = staticCategories.find((cat) => cat.type === type);
    return category?.items || [];
  };

  static getDefaultCategoryByType = (type) => {
    if (!type) return null;

    return FormsHelper.staticForms.find((cat) => cat.type === type);
  };

  static getKleverQuestions = () => {
    return FormsHelper.defaultFormBank.reduce((acc, val, catIdx) => {
      const category = FormsHelper.getCategory(val, catIdx, 3);
      acc[category.type] = category;
      return acc;
    }, {});
  };

  static kleverQuestionsBank = FormsHelper.getKleverQuestions();

  static getCategoryWithBank = (categoriesMap) => {
    try {
      const allQuestionsMap = Object.values(categoriesMap || {}).reduce(
        (acc, val) => {
          const curCategoryBank = FormsHelper.kleverQuestionsBank?.[val.type];

          if (curCategoryBank) {
            const catQuestions = [...val.items];

            const bankQuestions = curCategoryBank.items?.reduce((acc, q) => {
              const isAlreadyExist = catQuestions.find(
                (catQ) => catQ.question === q
              );

              if (!isAlreadyExist) {
                // const formattedQuestion = FormsHelper.getQuestion(q);
                acc.push(q);
              }

              return acc;
            }, []);

            acc[val.type] = {
              ...val,
              items: [...catQuestions, ...bankQuestions],
            };
          }

          return acc;
        },
        {}
      );

      return allQuestionsMap;
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  static getCategoriesBank = ({ questions = {} }) => {
    try {
      // const formsCategories = Object.values(forms || {}).reduce((acc, form) => {
      //   const curCategories = Object.values(form.categories || {});

      //   if (curCategories?.length) {
      //     Object.values(FormsHelper.CategoryType).some((type) => {
      //       const cat = curCategories.find((c) => c.type === type) || {};

      //       const defaultCat =
      //         FormsHelper.defaultFormBank.find((c) => c.type === type) || {};

      //       if (acc[type]) {
      //         const prevItems = acc[type]?.items || [];

      //         const newItems = (cat.items || []).filter(
      //           (el) =>
      //             !prevItems.find((q) => q.question_id === el.question_id) ||
      //             !prevItems.find((q) => q.question === el.question)
      //         );

      //         const allCatQuestion = [...prevItems, ...newItems];

      //         const questionsMap = allCatQuestion.reduce((acc, q) => {
      //           acc[q.question] = q;
      //           return acc;
      //         }, {});

      //         acc[type].items = Object.values(questionsMap);
      //       } else {
      //         acc[type] = {
      //           type,
      //           category_title: defaultCat.title,
      //           icon: defaultCat.title,
      //           items: defaultCat.items,
      //           ...(cat || {}),
      //         };
      //       }
      //     });
      //   }

      //   return acc;
      // }, {});

      const categoriesMap = Object.values(questions).reduce((acc, q) => {
        const prevQuestions = acc[q.category_type]?.items || [];
        const items = [...prevQuestions, q];
        const curCategory =
          acc[q.category_type] ||
          FormsHelper.defaultFormBank.find((c) => c.type === q.category_type) ||
          {};

        acc[q.category_type] = {
          type: q.category_type,
          category_title: curCategory.title,
          icon: curCategory.title,
          items,
        };

        return acc;
      }, {});

      return categoriesMap;
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  static getFormsByQuery = (query, forms) => {
    const curSearchTxt = (query || '').trim().toLowerCase();
    const results = Object.values(forms).reduce((acc, f) => {
      const isMatching =
        f.form_name?.toLowerCase().indexOf(curSearchTxt) !== -1;

      if (isMatching) acc.push(f);

      return acc;
    }, []);

    return results;
  };

  static getQuestionsByQuery = (query, qCategories) => {
    const curSearchTxt = (query || '').trim().toLowerCase();
    const results = Object.values(qCategories).reduce((acc, cat) => {
      cat.items?.some((q) => {
        const qAccuracy = similarity(
          q.question_title.toLowerCase(),
          curSearchTxt
        );

        // const isMatching =
        //   curSearchTxt.length <= 2 &&
        //   q.question?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1;

        // if (qAccuracy > 0.2 || isMatching) {
        //   acc = [...acc, { ...q, qAccuracy, catType: cat.category_type }];
        // }

        if (q.question_title?.toLowerCase()?.indexOf(curSearchTxt) !== -1) {
          acc.push({ ...q, qAccuracy, catType: cat.category_type });
        }
        return;
      });

      // item.question.toLowerCase().indexOf(query.toLowerCase()) !== -1;

      return acc;
    }, []);

    results.sort((a, b) => b.qAccuracy - a.qAccuracy);

    return results;
  };

  static getFormsSelectedQCount = ({ questionExchange, currentForm }) => {
    return Object.values(currentForm?.categories || {}).reduce((acc, cur) => {
      const selectedItems = questionExchange?.[cur.category_type];

      cur.items?.forEach((q) => {
        if (selectedItems?.includes(q.question_id)) acc = acc + 1;
      });

      return acc;
    }, 0);
  };

  static getQuestionnaireDelta = ({
    qCategories,
    prevCategories,
    curCategories,
  }) => {
    if (!prevCategories || !curCategories) return {};

    const categoriesDelta = {};

    Object.values(FormsHelper.CategoryType).forEach((fKey) => {
      const prevItems = prevCategories[fKey]?.items;
      const curItems = curCategories[fKey];
      const defCategory = qCategories[fKey];

      if ((!prevItems && !curItems) || !defCategory) return;

      // if (prevItems?.length !== curItems?.length) {
      categoriesDelta[fKey] = {
        category: defCategory.category_id,
        items: defCategory.items.reduce((a, q) => {
          if (curItems?.includes(q.question_id)) {
            a.push({
              question_id: q.question_id,
              question_pos: q.question_pos || 0,
            });
          }

          return a;
        }, []),
      };
      // }
    });

    return categoriesDelta;
  };

  static isQuestionnaireChanged = (prevCategories, curCategories) => {
    const formQuestionsCount = FormsHelper.getAllQuestionsCount(prevCategories);
    const curQuestionsCount = FormsHelper.getAllQuestionsCount(curCategories);

    return formQuestionsCount !== curQuestionsCount;
  };

  static getForms(communityId, userId) {
    try {
      const allForms = FormsHelper.staticForms.reduce((acc, i, idx) => {
        const form = {
          ...FormsHelper.defaultForm,
          form_id: FormsHelper.randomFormId(),
          community_id: communityId,
          user_id: userId,
          categories: FormsHelper.defaultFormBank.reduce((acc, val, catIdx) => {
            const category = FormsHelper.getCategory(val, catIdx, idx);
            acc[category.type] = category;
            return acc;
          }, {}),
          form_name: i.title,
          klever_form: true,
        };

        acc[form.form_id] = form;

        return acc;
      }, {});

      return allForms;
    } catch (err) {
      console.log(err);
      return [];
    }
  }
}

export default FormsHelper;
