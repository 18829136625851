import { attr, Model } from 'redux-orm';

/**
 * Pins model
 * @author Bohdan L.
 */
class Pins extends Model {
  static get modelName() {
    return 'Pins';
  }

  static get fields() {
    return {
      pins_list: attr(),
      didLoad: attr(),
    };
  }

  static reducer(action, Pins, session) {
    if (!Pins) return;
    switch (action.type) {
      case 'CREATE_PINS_DB':
        Pins.create({
          pins_list: {},
          didLoad: false,
        });
        break;
      case 'SET_PINS': {
        try {
          let PinsDB = Pins.last();

          if (!PinsDB) {
            Pins.create({
              pins_list: {},
              didLoad: false,
            });

            PinsDB = Files.last();
          }

          const { pins = {} } = action.payload;

          FilesDB.didLoad = true;
          FilesDB.files_list = filesData || {};

          FilesDB.update({ pins_list: pins });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'PIN': {
        try {
          const PinsDB = Pins.last();
          const pins = PinsDB.pins_list;

          const { pinId, pinData } = action.payload;

          if (!pinId || !pinData || !pins) return;

          PinsDB.update({
            pins_list: { [pinId]: pinData, ...pins },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'UNPIN': {
        try {
          const PinsDB = Pins.last();

          const { pinId } = action.payload;

          if (!PinsDB.pins_list || !pinId) return;

          const newPinsDB = { ...PinsDB.pins_list };

          delete newPinsDB[pinId];

          PinsDB.update({
            pins_list: newPinsDB,
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'DELETE_PINS_DB':
        try {
          Pins.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Pins };
export default Pins;
