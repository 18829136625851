const _data = {};

const AppStore = {
  set: (key, data) => {
    _data[key] = data;
  },
  get: (key) => _data[key],
};

Object.freeze(AppStore);
export default AppStore;
