/**
 * ORM and store registration
 * @author Villi K.
 */
import { applyMiddleware, combineReducers, createStore, compose } from 'redux';

import thunkMiddleware from 'redux-thunk';

import { createReducer } from 'redux-orm';

import DefaultStateConfigurator from './initialStateConfigurator';
import orm from './orm';
import { rtkReducers } from './rtk/rtkStore';

// const config = {
//   storage: localForage,
//   key: 'db',
//   version: 1,
//   stateReconciler: autoMergeLevel2,
//   // whitelist: ['orm'],
//   blacklist: ['User'],
// };

const ormReducer = createReducer(orm);
const reducers = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'REMOVE_USER') state = undefined;

  return ormReducer(state, action);
};

// const persistedReducer = persistCombineReducers(config, {
//   db: rootReducer,
// });

const rootReducer = combineReducers({
  db: reducers,
  rtk: rtkReducers,
});

const reduxDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

// this is an empty state example
// const emptyDBState = orm.getEmptyState();

// setup initial app state
const initialState = DefaultStateConfigurator(orm);

// create the middlewares
let createStoreWithMiddleWare = null;

if (__DEVELOPMENT__ && reduxDevTools) {
  createStoreWithMiddleWare = (red, state) =>
    createStore(
      red,
      state,
      compose(
        applyMiddleware(thunkMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    );
} else {
  createStoreWithMiddleWare = (red, state) =>
    createStore(red, state, applyMiddleware(thunkMiddleware));
}

const store = createStoreWithMiddleWare(rootReducer, {
  db: initialState,
});

export default () => {
  // const persistor = persistStore(
  //   store,
  //   __DEVELOPMENT__ &&
  //     window.__REDUX_DEVTOOLS_EXTENSION__ &&
  //     window.__REDUX_DEVTOOLS_EXTENSION__()
  // );
  return { store };
};
