import React, { createContext, useState } from 'react';

export const ThemeContext = createContext({
  theme: '',
  setTheme: (theme) => {},
});

const ThemeProvider = ({ children }) => {
  // check user's browser deafult theme
  const isBrowserDefaultDark = () =>
    window.matchMedia('(prefers-color-scheme: dark)').matches;

  // check if user already choose theme
  const getDefaultTheme = () => {
    const localStorageTheme = localStorage.getItem('default-theme');
    const browserDefault = isBrowserDefaultDark() ? 'dark' : 'light';
    // return localStorageTheme || browserDefault;
  };

  const [theme, setTheme] = useState(getDefaultTheme() || 'light');

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`full-width full-height theme-${theme}`}>{children}</div>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
