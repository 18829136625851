function* range(x, y) {
  while (true) {
    if (x <= y) yield x++;
    else return null;
  }
}
const mode = (array) => {
  if (array.length == 0) return null;
  var modeMap = {};
  var maxEl = array[0],
    maxCount = 1;
  for (var i = 0; i < array.length; i++) {
    var el = array[i];
    if (modeMap[el] == null) modeMap[el] = 1;
    else modeMap[el]++;
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }
  return maxEl;
};

const isSameArray = (a, b) => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length;
    const bCount = b.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

const findLastIndex = function (cond) {
  if (!this.length) return -1;
  if (!cond) return this.length - 1;
  for (var i = this.length - 1; i >= 0; --i) {
    if (cond(this[i])) return i;
  }
  return -1;
};

const swapArrFirstItemWithLast = (arr) => {
  try {
    [arr[0], arr[arr.length - 1]] = [arr[arr.length - 1], arr[0]];
  } catch (err) {}
};

export { range, isSameArray, mode, findLastIndex, swapArrFirstItemWithLast };
