import CommunityHelpers from '~app/helpers/CommunityHelpers';
import { isValidEmail } from '~lib/utils/strings';
import UserHelpers from '~shared/helpers/userHelpers';

class ListsHelpers {
  static defaultListName = 'default';
  static watchlistId = `${Date.now()}_${Math.floor(Math.random() * 1000 + 1)}`;

  static defaultList = {
    list_id: `${Date.now()}_${Math.floor(Math.random() * 1000 + 1)}`,
    user_id: null,
    community_id: null,
    list_title: '',
    list_img: { thumb: '/imgs_new/vertical_imgs/blockchain.jpg', origin: '' },
    list_description: '',
    list_items: [],
    shared_with: [],
    is_public: null,
    is_archived: null,
    tags: [],
    updated_at: Date.now(),
    created_at: Date.now(),
  };

  static defaultListCategory = {
    category_id: `${Date.now()}_${Math.floor(Math.random() * 1000 + 1)}`,
    owner_id: null,
    category_name: '',
    category_description: '',
    category_hex_color: null,
    category_items: [],
    category_position: 0,
    updated_at: Date.now(),
    created_at: Date.now(),
  };

  static createCategory = (user, data) => {
    try {
      const createdCategory = {
        ...ListsHelpers.defaultListCategory,
        owner_id: user.user_id,
        ...data,
      };

      return createdCategory;
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  static checkCompanyExist = (list, company) => {
    try {
      const isCompanyExist =
        company &&
        list.list_items?.findIndex(
          (comp) => comp.company_id === company.company_id
        ) !== -1;

      return isCompanyExist;
    } catch (err) {
      console.error(err);
      return true;
    }
  };

  static checkListPermission = (list, user, community) => {
    try {
      const isListOwner = list ? list.user_id === user.user_id : true;

      const isListShared =
        list && list.shared_with?.some((i) => i.user_id === user.user_id);

      const hasPermission =
        UserHelpers.isTeamMember(user, community) ||
        UserHelpers.isOrganizer(user, community);

      return isListOwner || (isListShared && hasPermission);
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  static canCreateDR = (community, user, lists) => {
    const isReached =
      CommunityHelpers.isRole(community, user.user_id, [
        'member',
        'co_member',
      ]) &&
      lists &&
      Object.values(lists).filter((i) => i.user_id === user.user_id).length >=
        2;

    return isReached;
  };

  static removeDRCompanies = ({
    listItems = [],
    listCategories = [],
    compIds = [],
  }) => {
    try {
      const newListItems = listItems.filter(
        (comp) => !compIds.includes(comp.company_id)
      );

      const newListCategories = listCategories
        .map((cat) => {
          if (cat.category_id) {
            cat.category_items = (cat.category_items || []).filter(
              (comp) => !compIds.includes(comp.company_id)
            );

            return cat;
          }
        })
        .filter((i) => i);

      return { newListItems, newListCategories };
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  static validateInvitedUser = ({ user = {}, members = {} }) => {
    try {
      return (
        user.firstName &&
        user.lastName &&
        user.email &&
        isValidEmail(user.email) &&
        user.role &&
        members[user.user_id]
      );
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  static buildLists = ({ lists = {}, portfolio_data = {} }) => {
    try {
      const createdLists = {
        ...lists,
        [ListsHelpers.watchlistId]: {
          ...ListsHelpers.defaultList,
          list_id: ListsHelpers.watchlistId,
          list_title: 'My Watchlist',
          list_items: portfolio_data.all
            ? Object.keys(portfolio_data.all).map((i) => ({
                company_id: i,
                added_at: Date.now(),
              }))
            : {},
        },
      };

      return createdLists;
    } catch (err) {
      console.error(err);
      return {};
    }
  };
}

export default ListsHelpers;
