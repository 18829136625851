import React, { useEffect } from 'react';
// $FlowFixMe
import { hot } from 'react-hot-loader';
// $FlowFixMe
import { useSelector } from 'react-redux';
import KleverApi from '~lib/api/api';
// $FlowFixMe
import { findLastIndex } from '~lib/utils/arrays';
// $FlowFixMe
import MainRouter from '~root/routers/MainRouter';
// $FlowFixMe
import { getApp } from '~selectors/appSelectors';
// $FlowFixMe
import ReactNotification from 'react-notifications-component';
import { getUser } from '~selectors/userSelectors';
import { getCommunity } from '~selectors/communitySelectors';
import { loadAnalytics } from '~shared/hooks/connectAnalytics';
import useLocalStorage from '~shared/hooks/useLocalStorage';
import AppContext from './helpers/AppContext';

const __DEVELOPMENT__ = process.env.NODE_ENV === 'development';

// $FlowFixMe
Array.prototype.findLastIndex = findLastIndex;

/**
 * Klever app entry point
 * @author Villi K.
 * @return any value
 */
const KleverClient = (props) => {
  const [appSettings, setAppSettings] = useLocalStorage('app_settings', {
    guide: {},
    settings: {},
    application: {},
  });
  const { app, user, community } = useSelector((state) => {
    return {
      app: getApp(state),
      user: getUser(state),
      community: getCommunity(state),
    };
  });

  const getAppSettings = async () => {
    // get app settings
    const app = await KleverApi.App.getAppSettings();
    if (app && app.success) {
      setAppSettings({
        ...appSettings,
        application: app.application,
      });
    }
  };

  const runHelpCenter = () => {
    try {
      // HelpCrunch init
      HelpCrunch('init', 'klevercompany', {
        applicationId: 1,
        user: {
          user_id: user.user_id,
          email: user.email,
          name: `${user?.profile?.first_name || ''} ${
            user?.profile?.last_name || ''
          }`,
          company: community.community_name,
        },
        applicationSecret:
          '+u0qJWZ3VkxiD2/cYI6w2/xeu4Uv4wWUMxb4nohShPcqgXlLXejTaJlhuDRUv0sNdToJxMYRW4ZeJBzetTxFNQ==',
      });
      runHelpCrunchMethods();
    } catch (err) {
      console.error('Failed to start help center');
    }
  };

  useEffect(() => {
    // const _initializedAnalytics = loadAnalytics();
    // _initializedAnalytics.setCredentials(
    //   'UA-146456888-2',
    //   user ? user.user_id : 'Guest'
    // );

    getAppSettings();
    AppContext.set('app_settings', appSettings);

    // notification service subscribe
    // subscribeUser(user?.token);
    if (window.HelpCrunch) {
      HelpCrunch('hideChatWidget');
    }

    if (!user || !user.token) {
      // props.persistor.purge();
      if (window.HelpCrunch) {
        HelpCrunch('hideChatWidget');
      }
      return;
    }

    runHelpCenter();
  }, []);

  return (
    <React.Fragment>
      <ReactNotification />
      <MainRouter app={app} user={user} />
    </React.Fragment>
  );
};

const HotModule = hot(module)(KleverClient);

const Module = KleverClient;

export default (props) => {
  try {
    // $FlowFixMe
    if (__DEVELOPMENT__) {
      return <HotModule {...props} />;
    } else {
      return <Module {...props} />;
    }
  } catch (err) {
    console.log(err);
  }
};
