import { attr, Model } from 'redux-orm';

/**
 * Klever Contact Requests model
 * @author Villi K.
 */
class ContactRequests extends Model {
  static get modelName() {
    return 'ContactRequests';
  }

  static get fields() {
    return {
      contact_request_list: attr(),
      didLoad: attr(),
    };
  }

  static reducer(action, ContactRequests, session) {
    if (!ContactRequests) return;
    switch (action.type) {
      case 'CREATE_CONTACT_REQUESTS_DB': {
        ContactRequests.create({
          contact_request_list: [],
          didLoad: false,
        });
        break;
      }
      case 'SET_CONTACT_REQUESTS': {
        const contactRequests = ContactRequests.last();
        if (!contactRequests) return;

        const { list } = action.payload;
        if (!list) return;

        contactRequests.update({
          contact_request_list: list,
          didLoad: true,
        });
        break;
      }
      case 'ADD_CONTACT_REQUEST_ITEM': {
        const contactRequests = ContactRequests.last();
        if (!contactRequests) return;

        const { item } = action.payload;
        if (!item) return;

        if (!contactRequests.contact_request_list.length) {
          contactRequests.contact_request_list = [];
        }
        contactRequests.contact_request_list.push(item);
        contactRequests.didLoad = true;
        contactRequests.update();
        break;
      }
      case 'DELETE_CONTACT_REQUESTS_DB': {
        try {
          ContactRequests.delete();
        } catch (err) {
          console.log(err);
        }
        break;
      }
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `ContactRequests`;
  }
}

export default ContactRequests;
export { ContactRequests };
