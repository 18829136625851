import { attr, Model } from 'redux-orm';

/**
 * Klever router model
 * @author Villi K.
 */
class KLRouter extends Model {
  static get modelName() {
    return 'KLRouter';
  }

  static get fields() {
    return {
      curRoute: attr(),
      backStack: attr(),
    };
  }

  static reducer(action, KLRouter, session) {
    if (!KLRouter) return;
    switch (action.type) {
      case 'CREATE_ROUTER': {
        KLRouter.create({
          curRoute: '',
          backStack: [],
        });
        break;
      }
      case 'DELETE_ROUTER': {
        try {
          KLRouter.delete();
        } catch (err) {
          console.log(err);
        }
        break;
      }
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `KLRouter model`;
  }
}

export default KLRouter;
export { KLRouter };
