import { attr, Model } from 'redux-orm';

/**
 * KnowledgeCenter state model
 * @author Villi K.
 */
class KnowledgeCenter extends Model {
  static get modelName() {
    return 'KnowledgeCenter';
  }

  static get fields() {
    return {
      investmentData: attr(),
      suggestions: attr(),
      didLoad: attr(),
    };
  }

  static reducer(action, KnowledgeCenter, session) {
    if (!KnowledgeCenter) return;
    switch (action.type) {
      case 'CREATE_KNOWLEDGE_CENTER': {
        KnowledgeCenter.create({
          investmentData: null,
          suggestions: null,
          didLoad: false,
        });
        break;
      }
      case 'SET_ORGANIZATION_INVESTMENT_DATA': {
        const knowledgeCenter = KnowledgeCenter.last();
        if (!knowledgeCenter) return;

        const { investmentData } = action.payload;

        knowledgeCenter.investmentData = investmentData || {};
        knowledgeCenter.update();
        break;
      }
      case 'SET_SUGGESTIONS_DATA': {
        const knowledgeCenter = KnowledgeCenter.last();
        if (!knowledgeCenter) return;

        const { suggestions } = action.payload;

        knowledgeCenter.suggestions = suggestions || {};
        knowledgeCenter.update();
        break;
      }
      case 'SET_DID_LOAD_KNOWLEDGE_CENTER_DATA': {
        const knowledgeCenter = KnowledgeCenter.last();
        if (!knowledgeCenter) return;

        const { didLoad } = action.payload;

        knowledgeCenter.didLoad = didLoad;
        knowledgeCenter.update();
        break;
      }
      case 'DELETE_KNOWLEDGE_CENTER_DB': {
        try {
          KnowledgeCenter.delete();
        } catch (err) {
          console.log(err);
        }
        break;
      }
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `KnowledgeCenter model`;
  }
}

export default KnowledgeCenter;
export { KnowledgeCenter };
