import { attr, Model } from 'redux-orm';

/**
 * Leads model
 * @author Villi K.
 */
class Leads extends Model {
  static get modelName() {
    return 'Leads';
  }

  static get fields() {
    return {
      leads: attr(),
      didLoad: attr(),
    };
  }

  static reducer(action, Leads, session) {
    if (!Leads) return;
    switch (action.type) {
      case 'CREATE_LEADS_DB':
        Leads.create({
          leads: [],
          didLoad: false,
        });
        break;
      case 'SET_LEADS': {
        const leadsDB = Leads.last();
        const { leads = [] } = action.payload;

        if (!leadsDB) {
          Leads.create({
            list: leads || [],
            didLoad: true,
          });
          return;
        } else {
          leadsDB.didLoad = true;
          leadsDB.leads = leads || [];
        }

        leadsDB.update();
        break;
      }
      case 'INSERT_LEAD': {
        const leadsDB = Leads.last();
        const { leadData } = action.payload;
        if (!leadData) return;

        if (!leadsDB.leads) leadsDB.leads = [];
        leadsDB.leads.push(leadData);

        leadsDB.update();
        break;
      }
      case 'DELETE_LEADS_DB':
        try {
          Leads.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Leads };
export default Leads;
