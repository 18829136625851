import { createSliceWithThunks } from '../createSlice';
import KleverApi from '~lib/api/api';
import { isNil } from '~lib/utils/lodash';

const initialState = {
  companies: {
    digit: 0,
    status: {},
  },
  peers: {
    digit: 0,
    status: {},
  },
  insights: {
    digit: 0,
    status: {},
  },
  topics: [],
  chartData: [],
  statsLoading: false,
};

const getColor = (string) => {
  const floatNumber = +string.replace(/[^0-9.-]+/g, '');

  switch (true) {
    case floatNumber > 0:
      return 'green';
    case floatNumber < 0:
      return 'red';
    default:
      return '';
  }
};

export const dashboardStatsSlice = createSliceWithThunks({
  name: 'dashboardStats',
  initialState,
  reducers: (create) => ({
    fetchNetworkStats: create.asyncThunk(
      async ({ communityId, token }) => {
        const response = await KleverApi.Community.getNetworkStats(
          communityId,
          token
        );
        return response;
      },
      {
        pending: (state) => {
          state.statsLoading = true;
        },
        rejected: (state) => {
          state.statsLoading = false;
        },
        fulfilled: (state, { payload: { stats } }) => {
          state.statsLoading = false;
          state.companies.digit = stats.partners;
          state.peers.digit = stats.members + stats.guests;
        },
      }
    ),
    updateNetworkStats: create.asyncThunk(
      async ({ communityId, token }) => {
        const response = await KleverApi.Community.getNetworkStats(
          communityId,
          token
        );
        return response;
      },
      {
        fulfilled: (state, { payload: { stats } }) => {
          state.companies.digit = stats.partners;
          state.peers.digit = stats.members + stats.guests;
        },
      }
    ),
    fetchInsights: create.asyncThunk(
      async ({ communityId, token }) => {
        const res = await KleverApi.Community.fetchInsgigtsStats(
          communityId,
          token
        );

        return await res;
      },
      {
        pending: (state) => {
          state.statsLoading = true;
        },
        rejected: (state) => {
          state.statsLoading = false;
        },
        fulfilled: (state, { payload: { chart_data, total, trend } }) => {
          state.statsLoading = false;
          state.chartData = [
            ...chart_data.map((datapoint) => {
              if (isNil(datapoint.data)) {
                datapoint.data = 0;
              }
              return datapoint;
            }),
          ];
          state.insights.digit = total;
          state.insights.status.text = trend;
          state.insights.status.color = getColor(trend);
        },
      }
    ),
    fetchTopics: create.asyncThunk(
      async ({ token }) => {
        const response = await KleverApi.Profile.getDashboardRecommended({
          token,
        });

        return await response;
      },
      {
        pending: (state) => {
          state.statsLoading = true;
        },
        rejected: (state) => {
          state.statsLoading = false;
        },
        fulfilled: (state, { payload: { categories } }) => {
          state.statsLoading = false;
          state.topics = [...categories];
        },
      }
    ),
    clearDashboardStats: create.reducer((state) => {
      state.companies.digit = 0;
      state.companies.status = {};
      state.insights.digit = 0;
      state.insights.status = {};
      state.topics = [];
      state.chartData = [];
    }),
  }),
  selectors: {
    selectCompanies: (sliceState) => sliceState.companies,
    selectPeers: (sliceState) => sliceState.peers,
    selectInsights: (sliceState) => sliceState.insights,
    selectTopics: (sliceState) => sliceState.topics,
    selectChartData: (sliceState) => sliceState.chartData,
    selectStatsLoading: (sliceState) => sliceState.statsLoading,
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchNetworkStats,
  updateNetworkStats,
  fetchInsights,
  fetchTopics,
  clearDashboardStats,
} = dashboardStatsSlice.actions;

// Selector creators for each uniqe value inside state
export const {
  selectCompanies,
  selectPeers,
  selectInsights,
  selectTopics,
  selectChartData,
  selectStatsLoading,
} = dashboardStatsSlice.selectors;

export default dashboardStatsSlice.reducer;
