/**
 * App model selectors
 * @author Villi K.
 */
import { createSelector } from 'redux-orm';

import orm from '../store/orm';

const getApp = createSelector(orm, (session) => {
  return session.App.last();
});

export { getApp };
