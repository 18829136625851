export const API_URL = __API_URL__;

export const SOCKET_URL = 'https://ws.klevercompany.com';

export const PLATFORM_URL = __PLATFORM_URL__;

export const PROFILE_CATEGORIES = {
  DESCRIPTION: 'Description',
  LOCATION: 'Location',
  PRODUCT: 'Product',
  FUNDING: 'Funding',
  CLIENTS: 'Clients',
  TEAM: 'Team',
  FILES: 'Files',
};

export const EXPLORE_STOP_WORDS = [
  'a',
  'about',
  'above',
  'after',
  'again',
  'against',
  'all',
  'am',
  'an',
  'and',
  'any',
  'are',
  "aren't",
  'as',
  'at',
  'be',
  'because',
  'been',
  'before',
  'being',
  'below',
  'between',
  'both',
  'but',
  'by',
  "can't",
  'cannot',
  'could',
  "couldn't",
  'did',
  "didn't",
  'do',
  'does',
  "doesn't",
  'doing',
  "don't",
  'down',
  'during',
  'each',
  'few',
  'for',
  'from',
  'further',
  'had',
  "hadn't",
  'has',
  "hasn't",
  'have',
  "haven't",
  'having',
  'he',
  "he'd",
  "he'll",
  "he's",
  'her',
  'here',
  "here's",
  'hers',
  'herself',
  'him',
  'himself',
  'his',
  'how',
  "how's",
  'i',
  "i'd",
  "i'll",
  "i'm",
  "i've",
  'if',
  'in',
  'into',
  'is',
  "isn't",
  'it',
  "it's",
  'its',
  'itself',
  "let's",
  'me',
  'more',
  'most',
  "mustn't",
  'my',
  'myself',
  'no',
  'nor',
  'not',
  'of',
  'off',
  'on',
  'once',
  'only',
  'or',
  'other',
  'ought',
  'our',
  'ours',
  'ourselves',
  'out',
  'over',
  'own',
  'same',
  "shan't",
  'she',
  "she'd",
  "she'll",
  "she's",
  'should',
  "shouldn't",
  'so',
  'some',
  'such',
  'than',
  'that',
  "that's",
  'the',
  'their',
  'theirs',
  'them',
  'themselves',
  'then',
  'there',
  "there's",
  'these',
  'they',
  "they'd",
  "they'll",
  "they're",
  "they've",
  'this',
  'those',
  'through',
  'to',
  'too',
  'under',
  'until',
  'up',
  'very',
  'was',
  "wasn't",
  'we',
  "we'd",
  "we'll",
  "we're",
  "we've",
  'were',
  "weren't",
  'what',
  "what's",
  'when',
  "when's",
  'where',
  "where's",
  'which',
  'while',
  'who',
  "who's",
  'whom',
  'why',
  "why's",
  'with',
  "won't",
  'would',
  "wouldn't",
  'you',
  "you'd",
  "you'll",
  "you're",
  "you've",
  'your',
  'yours',
  'yourself',
  'yourselves',
];

export const ORGANIZATIONS = [
  {
    user_id: '28199e11-330b-444a-849f-f02c1bf7cb37',
    organization: 'Akzo Nobel',
  },
  {
    user_id: '773bd4d8-c835-40f7-9236-3951ebd9fa80',
    organization: 'KLEVER',
  },
  {
    user_id: '26407f93-4a2d-418c-a4b9-91e6ba6af5f8',
    organization: 'Alliander',
  },
  { user_id: '718bbe34-4f2c-4767-a94f-f668f526f8a1', organization: 'Damen' },
  { user_id: '36ccd5cc-b330-4aaf-b006-e90a59147713', organization: 'Sabic' },
  { user_id: 'c2c755e5-33aa-4c00-abff-b26d7de21d5b', organization: 'ING' },
  { user_id: 'dbe9598c-05bd-44d8-8846-a8b73897f09b', organization: 'ING' },
  { user_id: 'e49d7625-9000-4a81-80b4-c76f46dbb366', organization: 'Rabobank' },
  {
    user_id: '4215fbc5-d75a-409c-8d36-b1312dafd1fe',
    organization: 'Vanderlande',
  },
];

export const RECOMMENDED_CATEGORIES = [
  {
    name: 'ai',
    filter_key: 'Artificial Intelligence',
    title: 'AI',
  },
  {
    name: 'big_data',
    title: 'Big Data',
    filter_key: 'Big Data',
  },
  {
    name: 'biometrics',
    title: 'Biometrics',
  },
  {
    name: 'agritech',
    title: 'AgriTech',
    filter_key: 'Agriculture',
  },
  {
    name: 'blockchain',
    title: 'Blockchain',
  },
  {
    name: 'windenergy',
    filter_key: 'cleantech',
    title: 'Clean Tech',
  },
  {
    name: 'cloud',
    title: 'Cloud',
  },
  {
    name: 'cyber',
    title: 'Cyber Security',
  },
  {
    name: 'quantum',
    title: 'Quantum technology',
    filter_key: 'quantum computing',
  },
  {
    name: 'robotics',
    title: 'Robotics',
  },
  {
    name: 'iot',
    title: 'IoT',
    filter_key: 'Internet of things',
  },
  {
    name: 'science',
    title: 'Science & Engineering',
  },
  {
    name: 'vr',
    title: 'Immersive Tech',
    alternative_key: 'VR/AR/MR',
    filter_key: 'Virtual Reality',
  },
  {
    name: 'israel',
    title: 'Israel',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'spain',
    title: 'Spain',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'netherlands',
    title: 'Netherlands',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'germany',
    title: 'Germany',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'united_kingdom',
    filter_key: 'United Kingdom',
    title: 'United Kingdom',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'france',
    title: 'France',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'finland',
    title: 'Finland',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'switzerland',
    title: 'Switzerland',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'norway',
    title: 'Norway',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'sweden',
    title: 'Sweden',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'denmark',
    title: 'Denmark',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'portugal',
    title: 'Portugal',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'ireland',
    title: 'Ireland',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'italy',
    title: 'Italy',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'estonia',
    title: 'Estonia',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'lithuania',
    title: 'Lithuania',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'latvia',
    title: 'Latvia',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'poland',
    title: 'Poland',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'slovakia',
    title: 'Slovakia',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'hungary',
    title: 'Hungary',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'bucharest',
    filter_key: 'Romania',
    title: 'Romania',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'greece',
    title: 'Greece',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'bulgaria',
    title: 'Bulgaria',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'austria',
    title: 'Austria',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'belgium',
    title: 'Belgium',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'czechia',
    title: 'Czechia',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'croatia',
    title: 'Croatia',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'slovenia',
    title: 'Slovenia',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'luxembourg',
    title: 'Luxembourg',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'united_states',
    filter_key: 'United States',
    title: 'United States',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
  {
    name: 'canada',
    title: 'Canada',
    path: `/imgs_new/vertical_imgs/countries/`,
  },
];

export const RECOMMENDED_INDUSTRIES = [
  {
    title: 'AdTech',
    name: 'broadcasting',
  },
  {
    title: 'AgriTech & FoodTech',
    name: 'food',
  },
  {
    title: 'EdTech',
    name: 'edtech',
  },
  {
    title: 'Energy',
    name: 'windenergy',
    path: `/imgs_new/vertical_imgs/`,
    extension: `.jpg`,
  },
  {
    title: 'Fintech & InsurTech',
    name: 'fintech',
  },
  {
    title: 'Government & Military',
    name: 'government',
  },
  {
    title: 'HealthTech & BioTech',
    name: 'healthcare',
  },
  {
    title: 'Logistics & Manufacturing',
    name: 'logistics',
  },
  {
    title: 'Maritime',

    name: 'maritime',
  },
  {
    title: 'Media & Entertainment',
    name: 'media',
  },
  {
    title: 'Mobility',

    name: 'mobility',
  },
  {
    title: 'Privacy & Security',
    name: 'cyber',
  },
  {
    title: 'Construction & Real Estate',
    name: 'construction',
  },
  {
    title: 'Retail & Consumer Goods',
    name: 'retail',
  },
  {
    title: 'Services',
    name: 'services',
  },
  {
    title: 'Sustainability',
    name: 'sustainability',
  },
  {
    title: 'Telecommunications',
    name: 'telco',
  },
  {
    title: 'TravelTech',
    name: 'travel',
  },
  // {
  //   title: 'Other',
  //   name: 'other',
  //   path: `/imgs_new/vertical_imgs/`,
  //   extension: `.jpg`,
  // },
];

export const RECOMMENDED_STG_CATEGORIES = [
  {
    name: 'solar',
    filter_key: 'Solar',
    title: 'Solar Energy',
    path: `/imgs_new/vertical_imgs/stg/`,
  },
  {
    name: 'wind',
    filter_key: 'Wind',
    title: 'Wind Energy',
    path: `/imgs_new/vertical_imgs/stg/`,
  },
  {
    name: 'energy',
    filter_key: 'Energy grid, smart grid',
    title: 'Energy Grid',
    path: `/imgs_new/vertical_imgs/stg/`,
  },
  {
    name: 'prediction',
    filter_key: 'energy infrastructure, predictive maintenance',
    title: 'Predictive Maintenance',
    path: `/imgs_new/vertical_imgs/stg/`,
  },
  {
    name: 'batteries',
    filter_key: 'energy storage batteries',
    title: 'Energy Storage',
    path: `/imgs_new/vertical_imgs/stg/`,
  },

  {
    name: 'p2p',
    filter_key: 'P2P Energy',
    title: 'P2P Energy Trading',
    path: `/imgs_new/vertical_imgs/stg/`,
  },
  {
    name: 'carbon',
    filter_key: 'beccs carbon',
    title: 'BECCS',
    path: `/imgs_new/vertical_imgs/stg/`,
  },

  {
    name: 'biofuel',
    filter_key: 'biofuels',
    title: 'Biofuels',
    path: `/imgs_new/vertical_imgs/stg/`,
  },
];

export const MEMBERS_TABLE_TYPES = {
  TEAM_MEMBERS: 'team_members',
  GUESTS: 'guests',
};

export const MEMBER_PROFILE_CATEGORIES = {
  GENERAL: 'General',
  RESET_PASS: 'Reset Password',
};

export const COMPANY_ENTITY_TYPE = {
  PRIVATE: 'company-private',
  PUBLIC: 'company-public',
};

export const EVALUATION_FORM_TYPE = {
  YES_NO: 'yes_no',
  DIGITS: 'digits',
  FORM: 'form',
  KLEVER_CONTROL: 'klever_control',
  CUSTOM_FORM: 'custom',
};

export const GROWTH_LIST = [
  'Clients',
  'Prototype',
  'Paying Users',
  'Idea',
  'Users',
];

export const FUNDING_STATES = [
  'Pre-Seed',
  'Seed',
  'Series A',
  'Series B',
  'Series C+',
  'Self Funded',
  'Safe',
  'Other',
];

export const COMPANY_SIZES = [
  '1',
  '2-10',
  '11-50',
  '51-100',
  '101-200',
  '201-500',
  '501-1000',
  '1001-5000',
  '5001-10000',
  '10001+',
];

export const INTERFACE_LIST = [
  {
    title: 'Web',
  },
  {
    title: 'Desktop',
  },
  {
    title: 'Mobile Application',
  },
  {
    title: 'Hardware',
  },
  {
    title: 'Other',
  },
];

export const CURRENCY_SYMBOLS = {
  euro: '€',
  dollar: '$',
  ils: '₪',
  pound: '£',
  yen: '¥',
};

export const DEMO_STARTUP = {
  company_name: 'Your Company Name',
  description:
    'Here you can describe your main value proposition to the market and how you differentiate yourself',
  short_description: 'Your short company description',
  company_logo: {
    thumb:
      'https://kleverbuckets.s3.amazonaws.com/cummunities/0692b140-7046-11ea-9452-9536641938ad/0692b140-7046-11ea-9452-9536641938ad_1585581149365.png',
    original: '',
  },
  location: { headquarters: 'Netherlands', country: 'Netherlands' },
  product: { product_images: [] },
  foundation_date: '01/01/2020',
  website: 'www.klevercompany.com',
  video_url:
    'https://www.youtube.com/watch?v=UH76-7CsOa4&list=PLqmEWnA8-0blfI7woK7aBVy8bTmCRtIKn',
  company_size: '2-10',
  tags: ['Machine Learning', 'Venture Capital', 'Corporate Innovation'],
  founders: [
    {
      fullname: 'Your Name',
      img_path: '/imgs/default_avatar.png',
      role: 'Your Role',
      linkedin: '',
    },
  ],
  strategy: {
    strategy_geozone: '',
  },
  finance: {
    funding_phase: 'Seed',
    amount_raised: '250000',
    fund_currency: '$',
    investor_list: [],
    funding_rounds: [],
    last_investment_at: '',
    to_be_raised: '',
  },
  clients: {
    client_model: '',
    clients_amount: '',
    client_traction: true,
    clients_locations: [],
    client_list: [
      {
        fullname: 'Your Client',
        img_path: '/imgs/client_placeholder.jpg',
        client_type: 'PoC',
        website: '',
      },
    ],
  },
  market: {
    market_size: '',
    competitive_advantage: '',
    market_readiness_level: [],
    competitors: [],
  },
  mission: {
    general_mission: '...',
    disruption_desc: '...',
    disruption_industries: [],
    sustainability_goals: ['none'],
  },
  board_members: [],
  industries: ['other'],
  social: {
    facebook: '',
    linkedin: '',
    twitter: '',
    youtube: '',
    instagram: '',
    crunchbase: '',
  },
  employees: [],
  achievements: {
    free_text: '',
    links: [],
  },
  files: [],
  contact_info: [],
  is_public_data: null,
};

export const EVALUATION_FORM_TYPES = {
  YES_NO: 'yes_no',
  DIGITS: 'digits',
  FORM: 'form',
  CUSTOM: 'custom',
};

export const EVALUATION_CONTROL_TYPE = {
  YES_NO: 0,
  CONTACT: 1,
  SLIDER: 2,
  DIGITS: 3,
  FORM: 4,
  PORTFOLIO: 5,
  KLEVER_CONTROL: 9,
  CUSTOM: 10,
};

export const EVALUATION_CONTROL_TYPE_NAMES = {
  0: 0,
  CONTACT: 1,
  SLIDER: 2,
  DIGITS: 3,
  FORM: 4,
  PORTFOLIO: 5,
  CUSTOM_FORM: 6,
};

export const EVALUATION_API_CONTROL_TYPE = {
  yes_no: 0,
  digits: 3,
  form: 4,
  custom: 10,
};

export const EVALUATION_STATUS = {
  EXPIRED: 'expired',
  PROGRESS: 'in-progress',
  COMPLETED: 'completed',
};

export const FEATURE_TYPES = {
  CREATE_DECISION_ROOM: 'create_decision_room',
  INVITE_MEMBERS: 'invite_members',
  SEARCH_RECOMMENDATIONS: 'search_recommendations',
  CREATE_ECOSYSTEM: 'create_ecosystem',
  MULTI_INFO_REQUEST: 'multi_info_request',
};

export const COMPANY_DATA_KEYS = {
  GENERAL_INFORMATION: 'General Information',
  ACHIEVEMENTS: 'Achievements',
  PRODUCT: 'Product',
  TECHNOLOGY: 'Technology',
  TEAM: 'Team',
  MARKET: 'Market',
  MISSION: 'Mission',
  STRATEGY: 'Strategy',
  FUNDING: 'Funding',
  LOCATION: 'Location',
  CLIENTS: 'Clients',
  PITCH_DECK: 'Pitch Deck',
  SDG: 'SDG',
  VALUE_CHAIN: 'Value Chain',
};

export const MAX_REQ_INFO_AMOUNT = 50;

export const EVALUATION_REASONS = [
  {
    icon: '/imgs/icons/evaluations/product.png',
    name: 'Product',
    value: 0,
  },
  {
    icon: '/imgs/icons/evaluations/market.png',
    name: 'Market',
    value: 1,
  },
  {
    icon: '/imgs/icons/evaluations/scalability.png',
    name: 'Scalability',
    value: 2,
  },
  // {
  //   icon: '/imgs/icons/evaluations/competition.png',
  //   name: 'Competitive Advantage',
  //   value: 3,
  // },
  {
    icon: '/imgs/icons/evaluations/team.png',
    name: 'Team',
    value: 4,
  },
  {
    icon: '/imgs/icons/evaluations/mission.png',
    name: 'Mission',
    value: 5,
  },
];

export const ROLES = {
  organizer: 'Admin',
  team_member: 'Team Member',
  co_member: 'Guest',
  member: 'Guest',
};

export const ROLES_INFO_TEXT = {
  organizer: '',
  team_member:
    'Can view all scores, activities and comments. Can also invite startups',
  member:
    'Can view only startup you publish or share with. Cannot invite startups',
};
export const ROLE_COLOR = {
  organizer: {
    backgroundColor: '#ffddd0',
  },
  team_member: {
    backgroundColor: '#86b6ff',
    color: 'white',
  },
  member: {
    backgroundColor: '#b9a0e7',
    color: 'white',
  },
};

export const AVATAR_LIST_SQUARE = [
  '/imgs_new/avatars/avatar_r.png',
  '/imgs_new/avatars/avatar_b.png',
  '/imgs_new/avatars/avatar_o.png',
  '/imgs_new/avatars/avatar_g.png',
];

export const AVATAR_LIST_CIRCLE = [
  '/imgs_new/avatars/avatar_r_circle.png',
  '/imgs_new/avatars/avatar_b_circle.png',
  '/imgs_new/avatars/avatar_o_circle.png',
  '/imgs_new/avatars/avatar_g_circle.png',
];

export const USER_TYPES = {
  ORGANIZATION: 'organization',
  STARTUP: 'startup',
};

export const FILE_TYPES = {
  ['image/png']: 'image/png',
  ['image/jpeg']: 'image/jpeg',
};

export const FILE_UPLOAD_TYPES = [
  'image/*',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export const PRIMARY_FILE_TYPES = ['image/*', 'application/pdf'];

export const UPDATE_TYPES = {
  NEW_VENDOR: 'new_vendor',
  NEW_DATA_POINT: 'new_data_point',
};

export const CLAIMED_VENDORS = [
  'd5dfd70f-39e3-4d65-8618-b447d8111662',
  '833fadc1-50cd-4345-9d85-d109b867191b',
  '19d6e67b-6c70-40b9-b3a3-c26d6898325a',
  '57c629a3-9c32-4b18-8eea-fb94d5cf99c0',
  'b129d82b-eca1-4f3c-8676-dd38c1ffc0f8',
  '655ee0c8-85c9-428f-b654-9cd68a886575',
  'b6ff48a1-4ea3-4236-a4f2-a298320814c9',
];

export const GenerateRandomAvatar = (type = 0) => {
  switch (type) {
    case 0: {
      return AVATAR_LIST_SQUARE[
        Math.round(Math.random() * (AVATAR_LIST_SQUARE.length - 1))
      ];
    }
    default:
    case 1: {
      return AVATAR_LIST_CIRCLE[
        Math.round(Math.random() * (AVATAR_LIST_CIRCLE.length - 1))
      ];
    }
  }
};
