import TechVendorHelpers from '~app/helpers/TechVendorHelpers';

class CommunityHelpers {
  static canInviteMember(plan) {
    if (!plan) return false;
    const { membership_max_amount } = plan;
    return !membership_max_amount ? false : true;
  }

  static canViewAntiBiasContent({
    allComments = [],
    companyId = null,
    decisions = {},
    userId = '',
    evaluations = {},
  }) {
    const wasInvitedToConsult = Object.values(evaluations).some(
      (e) =>
        e.company_id_list.some((ev) => ev === companyId) &&
        e.participants.some((ev) => ev.user_id === userId)
    );
    return (
      allComments.some((c) => c.user_id === userId) ||
      allComments.some(
        (c) =>
          c?.data?.mentions &&
          c?.data?.mentions.some((m) => m?.user_id === userId)
      ) ||
      !!decisions[userId] ||
      wasInvitedToConsult
    );
  }

  static canInviteMore(community) {
    if (!community?.plan || !community?.members) return false;
    const { plan, members } = community;
    return plan.membership_max_amount > Object.keys(members || {}).length
      ? true
      : false;
  }

  static isRole(community, user_id, requiredPermissions) {
    try {
      if (!community || !community.members || !community.members[user_id])
        return false;
      const { role } = community.members[user_id];
      if (role && role.length) {
        // check user role
        return requiredPermissions.some((item) => role.some((r) => r === item));
      }
      return false;
    } catch (err) {
      return false;
    }
  }

  static isMember(community, user_id) {
    try {
      if (!community || !community.members || !community.members[user_id])
        return false;
      const { role } = community.members[user_id];
      return role && role.length === 1 && role[0] === 'member';
    } catch (err) {
      return false;
    }
  }

  static filterStartups(entityPool, filters, customList) {
    try {
      if (!filters) return [];
      let entitiesId = customList
        ? customList
        : Object.values(entityPool.startups || {});

      if (!Array.isArray(entitiesId)) return [];

      if (filters.text) {
        const { query, tag } = filters.text;
        if (query) {
          let _results = [];

          _results = _results.concat(
            entitiesId.filter(
              (item) =>
                item.company_name
                  ?.toLowerCase()
                  ?.indexOf(query.toLowerCase()) !== -1
            )
          );

          const tagResults = Object.values(entitiesId).filter((item) =>
            TechVendorHelpers.getVendorTags({ vendor: item })?.some(
              (i) => i.indexOf(query.toLowerCase()) !== -1
            )
          );

          const descriptionPattern = Object.values(entitiesId).filter(
            (item) =>
              item.description &&
              item.description?.toLowerCase()?.indexOf(query.toLowerCase()) !==
                -1
          );

          const _tagResults = tagResults;
          const _descriptionResults = descriptionPattern.filter((item) =>
            _tagResults.every((i) => item.company_id !== i.company_id)
          );

          const countries = Object.values(entitiesId).filter((i) => {
            const country = i?.location?.country || i?.location?.headquarters;
            if (!country) return false;
            return country?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1;
          });

          _results = _results.concat(_tagResults);
          _results = _results.concat(_descriptionResults);
          _results = _results.concat(countries);

          entitiesId = _results;

          // handle dupes!
        }

        if (tag) {
          // entitiesId = entitiesId.filter(
          //   item =>
          //     item.company_name?.toLowerCase().indexOf(tag.toLowerCase()) !== -1
          // );

          if (tag.length >= 3) {
            const tagResults = Object.values(
              entityPool.startups
            ).filter((item) =>
              TechVendorHelpers.getVendorTags({ vendor: item })?.some(
                (i) => i.indexOf(tag.toLowerCase()) !== -1
              )
            );

            entitiesId = tagResults;
          }
          // handle dupes!
        }
      }

      if (filters.technologies) {
        const keys = Object.values(filters.technologies).reduce((n, v) => {
          if (v.tags) {
            n = [...n, ...v.tags];
          }
          return n;
        }, []);
        entitiesId = entitiesId.filter((item) => {
          const technologies = TechVendorHelpers.getVendorTags({
            vendor: item,
          });
          return keys.some((t) => technologies.includes(t.toLowerCase()));
        });
      }

      if (filters.funding) {
        const states = Object.keys(filters.funding);
        entitiesId = entitiesId.filter((item) => {
          const s = item.finance?.funding_phase?.toLowerCase();
          return states?.some((t) => s?.indexOf(t.replace(/_/gi, ' ')) !== -1);
        });
      }

      if (filters.industries) {
        const keys = Object.values(filters?.industries || {}).reduce((n, v) => {
          if (v.tags) {
            n = [...n, ...v.tags];
          }
          return n;
        }, []);
        entitiesId = entitiesId.filter((item) => {
          const industries = TechVendorHelpers.getVendorTags({ vendor: item });
          return keys?.some((t) => industries?.includes(t.toLowerCase()));
        });
      }

      // if (filters.industries) {
      //   let keys = filters.industries.tags;
      //   entitiesId = entitiesId.filter(item => {
      //     const industries = item.tags.map(t => t.toLowerCase());
      //     return keys.some(t => industries.includes(t.toLowerCase()));
      //   });
      // }

      if (filters.growth) {
        const keys = Object.keys(filters?.growth || {}).map((i) =>
          i.toLowerCase()
        );
        keys?.forEach((item) => {
          switch (item) {
            case 'clients': {
              entitiesId = entitiesId.filter((item) => {
                return item?.clients?.client_list?.length > 0;
              });
            }
          }
        });
      }

      if (filters.location) {
        const keys = Object.keys(filters?.location || {})?.map((i) =>
          i?.toLowerCase()?.replace(/ /gi, '_')
        );
        entitiesId = entitiesId?.filter((item) => {
          let country = item?.location?.country || item?.location?.headquarters;
          if (!country) return false;
          if (country.toLowerCase() === 'the netherlands')
            country = 'netherlands';
          return keys.includes(country.toLowerCase().replace(/ /gi, '_'));
        });
      }

      if (filters.company_size) {
        const keys = Object.keys(filters?.company_size || {})?.map((i) =>
          i?.toLowerCase()?.replace(/ /gi, '_')
        );
        entitiesId = entitiesId?.filter((item) => {
          const size = item.company_size;
          if (!size) return false;
          return keys.includes(size);
        });
      }

      const ids = [];
      const dupes = [];
      entitiesId.forEach((item) => {
        if (ids.findIndex((i) => i.company_id === item.company_id) !== -1) {
          dupes.push(item);
        } else {
          ids.push(item);
        }
      });
      return ids.filter((i) => i);
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  static getSortedList(entityPool, list, sortBy, customSort) {
    let arr = list;

    if (!Array.isArray(arr)) return [];

    switch (sortBy) {
      case 'name': {
        arr.sort((a, b) => {
          const compName1 = a.company_name?.toLowerCase();
          const compName2 = b.company_name?.toLowerCase();

          if (compName1 > compName2) {
            return 1;
          }
          if (compName2 > compName1) {
            return -1;
          }
        });
        break;
      }
      case 'activity': {
        arr.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        break;
      }
      case 'published': {
        arr.sort((a) => {
          return a.is_published ? -1 : 1;
        });
        break;
      }
      case 'relevance': {
        arr = customSort(arr)
          .map((i) => {
            return entityPool.startups?.[i];
          })
          .filter((i) => i);

        break;
      }
      default: {
        if (customSort) {
          try {
            arr = customSort(arr).filter((i) => i);
          } catch (err) {
            console.error(err);
          }
        }
        break;
      }
    }
    return arr;
  }

  static filterArchivedStartups(startupMap = {}, archiveMap = {}) {
    return Object.values(startupMap || {}).reduce((acc, val) => {
      if (!archiveMap[val.company_id]) {
        acc[val.company_id] = val;
      }

      return acc;
    }, {});
  }

  static filterUnarchivedStartups(startupMap = {}, archiveMap = {}) {
    return Object.values(startupMap || {}).reduce((acc, val) => {
      if (archiveMap[val.company_id]) {
        acc[val.company_id] = val;
      }

      return acc;
    }, {});
  }
}

export default CommunityHelpers;
