import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiNotes {
  static async getNotes(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/notes/get?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getNotes').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getNotes');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async addNewNote(communityId, companyId, noteData, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/notes/new`, {
        signal: KLAbortController.genController('addNewNote').signal,
        method: 'POST',
        body: JSON.stringify({ communityId, companyId, noteData }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('addNewNote');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async updateNote(communityId, companyId, noteId, noteData, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/notes/edit`, {
        signal: KLAbortController.genController('updateNote').signal,
        method: 'POST',
        body: JSON.stringify({ communityId, companyId, noteId, noteData }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('updateNote');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async removeNote(communityId, companyId, noteId, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/notes/remove`, {
        signal: KLAbortController.genController('removeNote').signal,
        method: 'POST',
        body: JSON.stringify({ communityId, noteId, companyId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('removeNote');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiNotes;
