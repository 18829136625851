import { attr, Model } from 'redux-orm';

import ListsHelpers from '~app/helpers/ListsHelpers';

/**
 * Catalogs user model
 * @author Bohdan L.
 */
class Catalogs extends Model {
  static get modelName() {
    return 'Catalogs';
  }

  static get fields() {
    return {
      catalogs: attr(),
      didLoad: attr(),
    };
  }

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param User
   * @param session
   * @returns {undefined}
   */

  static reducer(action, Catalogs, session) {
    if (!Catalogs) return;
    switch (action.type) {
      case 'CREATE_CATALOGS_DB':
        Catalogs.create({
          catalogs: {},
          didLoad: false,
        });
        break;

      case 'SET_CATALOGS_DATA': {
        let mainCatalogs = Catalogs.last();

        if (!mainCatalogs) {
          Catalogs.create({
            catalogs: {},
            didLoad: false,
          });
          mainCatalogs = Catalogs.last();
        }

        const { catalogsData } = action.payload;

        if (catalogsData) {
          mainCatalogs.catalogs = catalogsData;

          mainCatalogs.didLoad = true;

          mainCatalogs.update();
        }

        break;
      }
      case 'ADD_CATALOG': {
        const mainCatalogs = Catalogs.last();
        if (!mainCatalogs) return;

        const { listData } = action.payload;

        if (listData) {
          mainCatalogs.catalogs[listData.list_id] = listData;
        }

        mainCatalogs.didLoad = true;
        mainCatalogs.update();

        break;
      }
      case 'EDIT_CATALOG': {
        const mainCatalogs = Catalogs.last();
        if (!mainCatalogs) return;

        const { listData, listId } = action.payload;

        if (listData && listId && mainCatalogs.catalogs[listId]) {
          mainCatalogs.catalogs[listId] = { ...listData };
          mainCatalogs.update();

          Catalogs.withId(mainCatalogs.id).update({
            catalogs: mainCatalogs.catalogs,
          });
        }

        break;
      }
      case 'DELETE_CATALOG': {
        const mainCatalogs = Catalogs.last();
        if (!mainCatalogs) return;

        const { listId } = action.payload;

        if (listId && mainCatalogs.catalogs[listId]) {
          delete mainCatalogs.catalogs[listId];
          mainCatalogs.update();
        }

        break;
      }
      case 'REMOVE_CATALOGS_DB':
        try {
          Catalogs.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export default Catalogs;
export { Catalogs };
