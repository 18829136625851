import React, { useState, useContext, useEffect } from 'react';
import KleverApi from '~lib/api/api';

export const NetworkAdapterContext = React.createContext([
  {
    API: KleverApi
  },
  () => {}
]);

const NetworkAdapter = ({ children, user }) => {
  const [state, setState] = useState({
    API: KleverApi,
    token: user ? user.token : null
  });

  useEffect(() => {
    if (user) {
      setState({
        ...state,
        token: user.token
      });
    }
  }, []);

  return (
    <NetworkAdapterContext.Provider value={[state, setState]}>
      {children}
    </NetworkAdapterContext.Provider>
  );
};

export default NetworkAdapter;
export { KleverApi };
