import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiLists {
  static async getUserLists(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/get?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getUserLists').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getUserLists');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getFavForLists(communityId, listId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/get_favs?communityId=${communityId}&listId=${listId}`,
        {
          signal: KLAbortController.genController('getFavForLists').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getFavForLists');

      KleverApi.handleErrorCodes(json.code);
      if (!json.lists) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async createNewList({
    communityId,
    sourceListId,
    listTitle,
    listDesc,
    listImg,
    listItems,
    listPrivacyType,
    listReasons,
    tags,
    sharedWith,
    isPublic,
    isAutomated,
    maxDataPoint,
    token,
    recommendations,
  }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/lists/create`, {
        signal: KLAbortController.genController('createNewList').signal,
        method: 'POST',
        body: JSON.stringify({
          communityId,
          sourceListId,
          listTitle,
          listDesc,
          listImg,
          listItems,
          sharedWith,
          tags,
          isAutomated,
          isPublic,
          listPrivacyType,
          listReasons,
          maxDataPoint,
          recommendations,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('createNewList');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async createNewListCategory({ communityId, listId, category, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/category/create`,
        {
          signal: KLAbortController.genController('createNewListCategory')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            listId,
            communityId,
            categoryData: category,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('createNewListCategory');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async editList({ communityId, listId, delta, token }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/lists/edit`, {
        signal: KLAbortController.genController('editList').signal,
        method: 'POST',
        body: JSON.stringify({ communityId, listId, delta }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('editList');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async editListCategory({
    communityId,
    listId,
    categoryId,
    delta,
    token,
  }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/category/edit`,
        {
          signal: KLAbortController.genController('editListCategory').signal,
          method: 'POST',
          body: JSON.stringify({
            listId,
            communityId,
            categoryId,
            categoryData: delta,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('editListCategory');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async updateListCategoryPosition({
    communityId,
    listId,
    positions,
    token,
  }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/category/category_position`,
        {
          signal: KLAbortController.genController('updateListCategoryPosition')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            listId,
            communityId,
            positions,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );

      KLAbortController.clearController('updateListCategoryPosition');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async setDidGenerateList({ listId, communityId, isGenerated, token }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/lists/add_company`, {
        signal: KLAbortController.genController('setDidGenerateList').signal,
        method: 'POST',
        body: JSON.stringify({ listId, communityId, isGenerated }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('setDidGenerateList');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async addListCompany({ listId, companyId, communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/lists/add_company`, {
        signal: KLAbortController.genController('addListCompany').signal,
        method: 'POST',
        body: JSON.stringify({ listId, companyId, communityId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('addListCompany');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async removeListCompanies({ listId, companyIds, communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/remove_company`,
        {
          signal: KLAbortController.genController('removeListCompanies').signal,
          method: 'POST',
          body: JSON.stringify({ listId, companyIds, communityId }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('removeListCompanies');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async archiveListCompany({ listId, companyId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/remove_company`,
        {
          signal: KLAbortController.genController('archiveListCompany').signal,
          method: 'POST',
          body: JSON.stringify({ listId, companyId }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('archiveListCompany');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async updateListPosition({ listsArr, token }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/lists/update_pos`, {
        signal: KLAbortController.genController('updateListPosition').signal,
        method: 'POST',
        body: JSON.stringify({ listsArr }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('updateListPosition');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async getSortedListBatch({ communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/get_sorted?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getSortedListBatch').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getSortedListBatch');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async deleteList({ communityId, listId, token }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/lists/delete`, {
        signal: KLAbortController.genController('deleteList').signal,
        method: 'POST',
        body: JSON.stringify({ communityId, listId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('deleteList');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async deleteCategory({ communityId, listId, categoryId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/category/delete`,
        {
          signal: KLAbortController.genController('deleteCategory').signal,
          method: 'POST',
          body: JSON.stringify({ communityId, listId, categoryId }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('deleteCategory');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getRoomActivities({ listId, communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/get_new_events?listId=${listId}&communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getRoomActivities').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('getRoomActivities');

      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
  static async readRoomActivities({ listId, communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/read_new_events?communityId=${communityId}&listId=${listId}`,
        {
          signal: KLAbortController.genController('readRoomActivities').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('readRoomActivities');

      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
  static async getDataPointsForList({ listId, communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/datapoints/get_for_list?communityId=${communityId}&listId=${listId}`,
        {
          signal: KLAbortController.genController('getDataPointsForList')
            .signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('getDataPointsForList');

      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
  static async getRecommendsForListCreate({ challenge, communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/get_recommends`,
        {
          signal: KLAbortController.genController('getRecommendsForListCreate')
            .signal,
          method: 'POST',
          body: JSON.stringify({ challenge, communityId }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('getRecommendsForListCreate');

      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
  static async getRecommendsForList({ listId, communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/get_recommends`,
        {
          signal: KLAbortController.genController('getRecommendsForList')
            .signal,
          method: 'POST',
          body: JSON.stringify({ listId, communityId }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('getRecommendsForList');

      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
  static async updateRecommendsForList({
    communityId,
    companyId,
    listId,
    status,
    token,
  }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/upd_recommends`,
        {
          signal: KLAbortController.genController('updateRecommendsForList')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            companyId,
            listId,
            status,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('updateRecommendsForList');

      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async getListCompanies({ listId, communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/lists/poppulate?communityId=${communityId}&listId=${listId}`,
        {
          signal: KLAbortController.genController('getListCompanies').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('getListCompanies');

      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
}

export default ApiLists;
