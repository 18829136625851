import { attr, Model } from 'redux-orm';

/**
 * Startup model
 * @author Villi K.
 */
class Startup extends Model {
  static get modelName() {
    return 'Startup';
  }

  static get fields() {
    return {
      startupProfile: attr(),
      stats: attr(),
      didLoad: attr(),
    };
  }

  static reducer(action, Startup, session) {
    if (!Startup) return;
    switch (action.type) {
      case 'CREATE_STARTUP_DB': {
        Startup.delete();

        Startup.create({
          startupProfile: null,
          didLoad: false,
        });
        break;
      }
      case 'SET_STARTUP': {
        const startupDB = Startup.last();
        if (!startupDB) return;
        const { startupData } = action.payload;

        if (!startupData) return;

        startupDB.didLoad = true;
        startupDB.startupProfile = startupData;

        startupDB.update();
        break;
      }
      case 'UPDATE_STARTUP': {
        const startupDB = Startup.last();
        if (!startupDB) return;

        const { startupData } = action.payload;
        startupDB.startupProfile = startupData;

        Startup.update({});
        break;
      }
      case 'SET_STARTUP_STATS': {
        const startupDB = Startup.last();
        if (!startupDB) return;
        const { stats } = action.payload;

        if (!stats) return;

        startupDB.didLoad = true;
        startupDB.stats = stats;

        startupDB.update();
        break;
      }
      case 'UPDATE_STARTUP_LIKES': {
        const startupDB = Startup.last();
        if (!startupDB) return;
        const { likesCount } = action.payload;

        if (!Number.isNumber(likesCount) || !startupDB.stats) return;

        startupDB.stats = { ...startupDB.stats, favourite_count: likesCount };

        startupDB.update();
        break;
      }
      case 'UPDATE_STARTUP_LIKES': {
        const startupDB = Startup.last();
        if (!startupDB) return;
        const { likesCount } = action.payload;

        if (!Number.isNumber(likesCount) || !startupDB.stats) return;

        startupDB.stats = { ...startupDB.stats, favourite_count: likesCount };

        startupDB.update();
        break;
      }
      case 'UPDATE_STARTUP_VIEWS': {
        const startupDB = Startup.last();
        if (!startupDB) return;
        const { viewsCount } = action.payload;

        if (!Number.isNumber(viewsCount) || !startupDB.stats) return;

        startupDB.stats = { ...startupDB.stats, view_count: viewsCount };

        startupDB.update();
        break;
      }
      case 'DELETE_STARTUP_DB':
        try {
          Startup.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Startup };
export default Startup;
