import { mean, deviation } from 'd3-array';

const zscore = (inputs, accessor) => {
  const m = mean(inputs, accessor);
  // here the n-1 : http://duramecho.com/Misc/WhyMinusOneInSd.html
  let d = deviation(inputs, accessor);
  const getInput = accessor || ((v) => v);
  return inputs.map((i) => (getInput(i) - m) / d);
};

const GetZPercent = (z) => {
  // z == number of standard deviations from the mean

  // if z is greater than 6.5 standard deviations from the mean the
  // number of significant digits will be outside of a reasonable range

  if (z < -6.5) {
    return 0.0;
  }

  if (z > 6.5) {
    return 1.0;
  }

  let factK = 1;
  let sum = 0;
  let term = 1;
  let k = 0;
  const loopStop = Math.exp(-23);

  while (Math.abs(term) > loopStop) {
    term =
      (((0.3989422804 * Math.pow(-1, k) * Math.pow(z, k)) /
        (2 * k + 1) /
        Math.pow(2, k)) *
        Math.pow(z, k + 1)) /
      factK;
    sum += term;
    k++;
    factK *= k;
  }

  sum += 0.5;

  return sum;
};

const arrMath = {
  max: function (array) {
    return Math.max.apply(null, array);
  },

  min: function (array) {
    return Math.min.apply(null, array);
  },

  range: function (array) {
    return arrMath.max(array) - arrMath.min(array);
  },

  midrange: function (array) {
    return arrMath.range(array) / 2;
  },

  sum: function (array) {
    const num = 0;
    for (var i = 0, l = array.length; i < l; i++) num += array[i];
    return num;
  },

  mean: function (array) {
    return arrMath.sum(array) / array.length;
  },

  median: function (array) {
    array.sort(function (a, b) {
      return a - b;
    });
    const mid = array.length / 2;
    return mid % 1 ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
  },

  modes: function (array) {
    if (!array.length) return [];
    let modeMap = {},
      maxCount = 0,
      modes = [];

    array.forEach(function (val) {
      if (!modeMap[val]) modeMap[val] = 1;
      else modeMap[val]++;

      if (modeMap[val] > maxCount) {
        modes = [val];
        maxCount = modeMap[val];
      } else if (modeMap[val] === maxCount) {
        modes.push(val);
        maxCount = modeMap[val];
      }
    });
    return modes;
  },

  variance: function (array) {
    const mean = arrMath.mean(array);
    return arrMath.mean(
      array.map(function (num) {
        return Math.pow(num - mean, 2);
      })
    );
  },

  standardDeviation: function (array) {
    return Math.sqrt(arrMath.variance(array));
  },

  meanAbsoluteDeviation: function (array) {
    const mean = arrMath.mean(array);
    return arrMath.mean(
      array.map(function (num) {
        return Math.abs(num - mean);
      })
    );
  },

  zScores: function (array) {
    const mean = arrMath.mean(array);
    const standardDeviation = arrMath.standardDeviation(array);
    return array.map(function (num) {
      return (num - mean) / standardDeviation;
    });
  },
};

// Arithmetic mean
const getMean = (data) => {
  return (
    data.reduce((a, b) => {
      return Number(a) + Number(b);
    }) / data.length
  );
};

const getPercentageChange = (oldNumber, newNumber) => {
  if (!oldNumber) return -newNumber;

  const decreaseValue = oldNumber - newNumber;

  return (decreaseValue / oldNumber) * 100;
};
// Function aliases:
arrMath.average = arrMath.mean;

const standardDeviation = (arr, usePopulation = false) => {
  const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
  return Math.sqrt(
    arr
      .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
      .reduce((acc, val) => acc + val, 0) /
      (arr.length - (usePopulation ? 0 : 1))
  );
};

const calcStdDev = (arr, accessor) => {
  const I = arr.reduce(
    function (I, x, k) {
      if (accessor) {
        if (typeof accessor === 'function') {
          x = accessor(x);
        } else if (typeof accessor === 'string') {
          x = x[accessor];
        }
      }
      k = k + 1;
      return {
        Sg: I.Sg + x,
        Mk: k === 1 ? x : I.Mk + (x - I.Mk) / k,
        Qk: k === 1 ? 0 : I.Qk + ((k - 1) * Math.pow(x - I.Mk, 2)) / k,
      };
    },
    { Sg: 0, Mk: 0, Qk: 0 }
  );

  const t = I.Sg;
  const n = arr.length;
  const m = t / n;
  const s2 = I.Qk / (n - 1);
  const s = Math.sqrt(s2);
  const o2 = I.Qk / n;
  const o = Math.sqrt(o2);

  // console.log('variance', arr, s2);

  return {
    sum: Number(isNaN(t) ? 0 : t),
    mean: Number(isNaN(m) ? 0 : m),
    popStdDev: Number(isNaN(o) ? 0 : o),
    popVariance: Number(isNaN(o2) ? 0 : o2),
    sampleStdDev: Number(isNaN(s) ? 0 : s),
    sampleVariance: Number(isNaN(s2) ? 0 : s2),
  };
};

const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export {
  GetZPercent,
  arrMath,
  zscore,
  getPercentageChange,
  standardDeviation,
  calcStdDev,
  randomIntFromInterval,
};
