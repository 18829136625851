import { attr, Model } from 'redux-orm';

import { genUniqueId } from '~lib/utils/strings';

/**
 * Projects model
 * @author Villi K.
 */
class Projects extends Model {
  static get modelName() {
    return 'Projects';
  }

  static get fields() {
    return {
      projects: attr(),
      didLoad: attr(),
    };
  }

  static reducer(action, Projects, session) {
    if (!Projects) return;
    switch (action.type) {
      case 'CREATE_PROJECTS_DB': {
        // Projects.delete();

        Projects.create({
          projects: [],
          didLoad: false,
        });
        break;
      }
      case 'SET_PROJECTS': {
        const projectsDB = Projects.last();
        if (!projectsDB) return;

        const { projects = [] } = action.payload;

        projectsDB.didLoad = true;
        projectsDB.projects = projects || [];

        projectsDB.update();
        projectsDB.update({
          didLoad: true,
          projects: [...projectsDB.projects],
        });
        break;
      }
      case 'UPDATE_PROJECT': {
        const projectsDB = Projects.last();
        if (!projectsDB) return;

        const { projectId, data } = action.payload;

        projectsDB.didLoad = true;
        projectsDB.projects.forEach((i, idx) => {
          if (projectsDB.projects[idx].project_id === projectId) {
            projectsDB.projects[idx] = data;
          }
        });

        projectsDB.update();
        projectsDB.update({
          projects: [...projectsDB.projects],
        });
        break;
      }
      case 'REMOVE_PROJECT_ITEM': {
        const projectsDB = Projects.last();
        if (!projectsDB) return;

        const { projectId, companyId } = action.payload;

        projectsDB.didLoad = true;

        projectsDB.projects.forEach((i, idx) => {
          if (projectsDB.projects[idx].project_id === projectId) {
            projectsDB.projects[idx].companies = projectsDB.projects[
              idx
            ].companies.filter((id) => id !== companyId);
          }
        });

        projectsDB.update();
        projectsDB.update({
          projects: [...projectsDB.projects],
        });
        break;
      }
      case 'INSERT_PROJECT': {
        const projectsDB = Projects.last();
        const { projectData } = action.payload;
        if (!projectsDB) return;

        if (!projectsDB.projects) projectsDB.projects = [];
        projectsDB.projects.push(projectData);

        projectsDB.update({
          projects: [...projectsDB.projects],
        });
        break;
      }
      case 'DELETE_PROJECTS_DB':
        try {
          Projects.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Projects };
export default Projects;
