import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';
import CatalogsHelpers from '~app/helpers/CatalogsHelpers';

import KleverApi from './api';

class ApiForms {
  static async getForms(communityId, token) {
    try {
      const communityPath = communityId ? `?community_id=${communityId}` : '';

      const json = await KleverApi.workerFetch(
        `${API_URL}/forms${communityPath}`,
        {
          signal: KLAbortController.genController('getForms').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getForms');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async getCategories(communityId, token) {
    try {
      const communityPath = communityId ? `?community_id=${communityId}` : '';

      const json = await KleverApi.workerFetch(
        `${API_URL}/categories/all${communityPath}`,
        {
          signal: KLAbortController.genController('getCategories').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getCategories');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async getQuestions(communityId, token) {
    try {
      const communityPath = communityId ? `?community_id=${communityId}` : '';

      const json = await KleverApi.workerFetch(
        `${API_URL}/questions/all${communityPath}`,
        {
          signal: KLAbortController.genController('getQuestions').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getQuestions');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async createForm({ form, communityId, token }) {
    try {
      const categories = Object.values(form.categories);

      const json = await KleverApi.workerFetch(`${API_URL}/forms/create`, {
        signal: KLAbortController.genController('getForms').signal,
        method: 'PUT',
        body: JSON.stringify({
          community_id: communityId,
          form_name: form.form_name,
          owner_id: form.owner_id,
          is_temporary: form.is_temporary,
          categories,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('getForms');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async updateForm({ formId, formDelta, communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/forms/update`, {
        signal: KLAbortController.genController('updateForm').signal,
        method: 'POST',
        body: JSON.stringify({
          formId,
          formDelta,
          communityId,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });

      KLAbortController.clearController('updateForm');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async createQuestion({ communityId, question, token }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/questions`, {
        signal: KLAbortController.genController('createQuestion').signal,
        method: 'PUT',
        body: JSON.stringify({
          community_id: communityId,
          ...question,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });

      KLAbortController.clearController('createQuestion');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async updateQuestion({ communityId, questionDelta, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/questions/${questionDelta.question_id}`,
        {
          signal: KLAbortController.genController('updateQuestion').signal,
          method: 'POST',
          body: JSON.stringify({
            community_id: communityId,
            question_description: questionDelta.question_description,
            question_title: questionDelta.question_title,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );

      KLAbortController.clearController('updateQuestion');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async getFormById(formId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/forms/get?form_id=${formId}`,
        {
          signal: KLAbortController.genController('getFormById').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );

      KLAbortController.clearController('getFormById');

      KleverApi.handleErrorCodes(json.code);

      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getQuestionsByFormId(formId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/questions/by-form-id?formID=${formId}`,
        {
          signal: KLAbortController.genController('getQuestionsByFormId')
            .signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );

      KLAbortController.clearController('getQuestionsByFormId');

      KleverApi.handleErrorCodes(json.code);

      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async deleteQuestion({ qId, token }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/questions/${qId}`, {
        signal: KLAbortController.genController('deleteQuestion').signal,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });

      KLAbortController.clearController('deleteQuestion');

      KleverApi.handleErrorCodes(json.code);

      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async deleteForm({ formId, token }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/forms/${formId}`, {
        signal: KLAbortController.genController('deleteForm').signal,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });

      KLAbortController.clearController('deleteForm');

      KleverApi.handleErrorCodes(json.code);

      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiForms;
