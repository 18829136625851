/**
 * User model selectors
 * @author Villi K.
 */
import { createSelector } from 'redux-orm';
import orm from '../store/orm';

const dbStateSelector = (state) => state.db;

const getUser = createSelector(orm, dbStateSelector, (session) => {
  try {
    return session.User.last();
  } catch (err) {
    return session.User.last();
  }
});

const getUserSettings = createSelector(orm, dbStateSelector, (session) => {
  try {
    return session.User.last()?.profile?.user_settings || {};
  } catch (err) {
    return {};
  }
});

const getUserHideTo = createSelector(orm, dbStateSelector, (session) => {
  try {
    return session.User.last()?.hideCompaniesPromoTo || [];
  } catch (err) {
    return {};
  }
});

export { getUser, getUserSettings, getUserHideTo };
