import { attr, Model } from 'redux-orm';

/**
 * Klever app state model
 * @author Villi K.
 */
class AppState extends Model {
  static get modelName() {
    return 'AppState';
  }

  static get fields() {
    return {
      isOnline: attr(),
      isSocketConnected: attr(),
      isAppUpgradeDialogShown: attr(),
      lastSyncedAt: attr(),
      pages: attr(),
      titleBar: attr(),
      showGettingStarted: attr(),
    };
  }

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param User
   * @param session
   * @returns {undefined}
   */
  static reducer(action, AppState, session) {
    if (!AppState) return;
    switch (action.type) {
      case 'CREATE_APP_STATE_DB': {
        // AppState.delete();

        AppState.create({
          isOnline: true,
          isSocketConnected: false,
          isAppUpgradeDialogShown: false,
          lastSyncedAt: null,
          showGettingStarted: false,
          pages: {},
          titleBar: null,
        });
        break;
      }
      case 'SET_LAST_SYNC': {
        const appState = AppState.last();
        if (!appState) return;

        const { lastSync } = action.payload;

        appState.lastSyncedAt = lastSync || Date.now();
        appState.update();
        break;
      }
      case 'SET_PAGE_STATE': {
        const appState = AppState.last();
        if (!appState) return;

        const { pageName, pageState } = action.payload;

        if (!appState.pages) {
          appState.pages = {};
        }

        appState.pages[pageName] = {
          ...(appState.pages[pageName] || {}),
          ...pageState,
        };
        appState.update();
        break;
      }
      case 'SET_TITLE_BAR_STATE': {
        const appState = AppState.last();
        if (!appState) return;

        const { tabData } = action.payload;

        if (!appState.titleBar) {
          appState.titleBar = {};
        }

        appState.update({
          titleBar: {
            ...(tabData || {}),
          },
        });
        break;
      }
      case 'TOGGLE_GETTING_STARTED': {
        try {
          const appState = AppState.last();
          if (!appState) return;

          const { isVisible } = action.payload;

          appState.update({
            showGettingStarted: isVisible,
          });
        } catch (err) {
          console.log(err);
        }
        break;
      }
      case 'DELETE_APP_STATE_DB': {
        try {
          AppState.delete();
        } catch (err) {
          console.log(err);
        }
        break;
      }
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `AppState model`;
  }
}

export default AppState;
export { AppState };
