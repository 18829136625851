import { isNil } from '~lib/utils/lodash';
import { attr, Model } from 'redux-orm';
import ChatsHelpers from '~app/helpers/ChatsHelpers';
import { mapEvaluationMembers2 } from '~lib/klever/evaluationHelpers';
import { USER_TYPES } from '~lib/constants';

class Chats extends Model {
  static get modelName() {
    return 'Chats';
  }

  static get fields() {
    return {
      chats_list: attr(),
      didLoad: attr(),
    };
  }

  static setEvaluationMessages = (chatsMap, memberships) => {
    Object.keys(chatsMap).forEach((i) => {
      if (chatsMap[i] && chatsMap[i].messages) {
        chatsMap[i].messages.forEach((m, idx) => {
          chatsMap[i].messages[idx] = {
            ...m,
            title: `${memberships?.[m.user_id]?.first_name || 'Member'} ${
              memberships?.[m.user_id]?.last_name || ''
            }`,
            message_data: {
              ...m.message_data,
              data: {
                ...(m.message_data?.data || {}),
              },
            },
          };
        });
      }
    });
  };

  static refreshCommunityChats({
    chats = {},
    evaluations = {},
    community = {},
    user = {},
    onboardings = {},
    startups = [],
  }) {
    const membersEvalMap = mapEvaluationMembers2(evaluations);

    const tempEvalChatsMap = ChatsHelpers.createEvalTemporaryChatsMap({
      evaluations,
      startups,
      membersEvalMap,
      communityId: community?.community_id,
      userId: user.user_id,
    });

    const tempVendorsChatsMap = ChatsHelpers.createReqInfoTempChats({
      onboardings,
      startups,
      communityId: community?.community_id,
      userId: user.user_id,
    });

    Chats.setEvaluationMessages(tempEvalChatsMap, community.memberships);

    const chatsWithEvals = ChatsHelpers.mergeEvalTempChats(
      tempEvalChatsMap,
      chats,
      user
    );

    const allChats = ChatsHelpers.mergeReqInfoTempChats(
      tempVendorsChatsMap,
      chatsWithEvals,
      user
    );

    return allChats;
  }

  static refreshVendorChats = ({ chats, userId, contactRequests, leads }) => {
    const contactRequestMessages = ChatsHelpers.getContactRequestMessages({
      contactRequests,
      leads,
    });

    const kleverChat = {
      ...ChatsHelpers.defaultChatRoom,
      chat_id: ChatsHelpers.defaultKLEVERChatId,
      chatTitle: 'Information Requests',
      chatImg: '/imgs/klever/logo_black.jpg',
      user_id: userId,
      messages: contactRequestMessages,
      isApproved: true,
      participants: [],
    };

    return { [userId]: kleverChat, ...chats };
  };

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param Chats
   * @returns {undefined}
   */

  static reducer(action, Chats, session) {
    if (!Chats) return;
    switch (action.type) {
      case 'CREATE_CHATS_DB':
        // Chats.delete();

        Chats.create({
          chats_list: {},
          didLoad: false,
        });
        break;
      case 'SET_CHATS': {
        try {
          const chatsDB = Chats.last();
          if (!chatsDB) return;

          const {
            chats = {},
            user = {},
            community = {},
            evaluations = {},
            onboardings = {},
            startups = [],
            leads = {},
            contactRequests = [],
          } = action.payload;

          if (chats) {
            const allChats = {
              ...(chatsDB.chats_list || {}),
              ...chats,
            };

            const chatsList =
              user.user_type === USER_TYPES.STARTUP
                ? Chats.refreshVendorChats({
                    chats: allChats,
                    userId: user.user_id,
                    leads,
                    contactRequests,
                  })
                : Chats.refreshCommunityChats({
                    chats: allChats,
                    evaluations,
                    memberships: community.members,
                    community,
                    user,
                    onboardings,
                    startups,
                  });

            Object.keys(chatsList || {}).forEach((id) => {
              const modelChat = chatsDB.chats_list?.[id] || {};

              const messagesMap = [
                ...(modelChat.messages || []),
                ...(chatsList[id]?.messages || []),
              ].reduce((acc, val) => {
                if (val.message_id) {
                  acc[val.message_id] = val;
                }
                return acc;
              }, {});

              const messages = Object.values(messagesMap || {});

              messages.sort(
                (a, b) => new Date(a.created_at) - new Date(b.created_at)
              );

              if (modelChat) {
                chatsList[id] = {
                  ...modelChat,
                  ...chatsList[id],
                  isMessagesEmpty: isNil(modelChat.isMessagesEmpty)
                    ? chatsList[id].isMessagesEmpty
                    : modelChat.isMessagesEmpty,
                  messages,
                  isMinimized: isNil(modelChat.isMinimized)
                    ? chatsList[id].isMinimized
                    : modelChat.isMinimized,
                  isVisible: isNil(modelChat.isVisible)
                    ? chatsList[id].isVisible
                    : modelChat.isVisible,
                };
              }
            });

            chatsDB.didLoad = true;

            chatsDB.update({
              chats_list: {
                ...chatsList,
              },
            });
          }

          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'ADD_CHAT': {
        try {
          const chatsDB = Chats.last();

          const { chatId, data } = action.payload;

          if (!chatId || !data || !chatsDB || !chatsDB.chats_list) return;

          const chatsListCpy = { ...chatsDB.chats_list };

          const existingChat = ChatsHelpers.getCurChat(
            chatsListCpy,
            data.participants,
            data.user_id
          );

          Object.keys(chatsListCpy).forEach((id) => {
            if (chatsListCpy[id]) {
              chatsListCpy[id] = { ...chatsListCpy[id], isMinimized: true };
            }
          });

          if (existingChat?.temp_chat_id) {
            delete chatsListCpy[existingChat.temp_chat_id];
          }

          chatsDB.update({
            chats_list: {
              ...chatsListCpy,
              [chatId]: { ...(existingChat || {}), ...data },
            },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'APPROVE_CHAT': {
        try {
          const chatsDB = Chats.last();
          if (!chatsDB) return;

          const { chatId, room } = action.payload;
          if (!chatId || !chatsDB.chats_list || !room) return;

          const existingChat = chatsDB.chats_list[chatId];

          if (!existingChat) return;

          chatsDB.update({
            chats_list: {
              ...chatsDB.chats_list,
              [chatId]: { ...existingChat, ...room },
            },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'SWAP_TEMP_CHAT': {
        try {
          const chatsDB = Chats.last();
          if (!chatsDB) return;

          const { tempChatId, chatId, room } = action.payload;
          if (!tempChatId || !chatId || !chatsDB.chats_list || !room) return;

          const existingChat = { ...chatsDB.chats_list[tempChatId] };

          if (!existingChat) return;

          delete chatsDB.chats_list[tempChatId];

          Object.keys(chatsDB.chats_list).forEach((key) => {
            chatsDB.chats_list[key].isMinimized = true;
          });

          existingChat.isMinimized = false;
          existingChat.isVisible = true;

          chatsDB.update({
            chats_list: {
              ...chatsDB.chats_list,
              [chatId]: { ...existingChat, ...room },
            },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'TOGGLE_CHAT_VISIBLE': {
        try {
          const chatsDB = Chats.last();
          if (!chatsDB) return;

          const { chatId, isVisible, isMinimized } = action.payload;
          if (!chatId || !chatsDB.chats_list) return;

          const existingChat = chatsDB.chats_list[chatId];

          if (!existingChat) return;

          if (!isNil(isVisible)) {
            existingChat.isVisible = isVisible;
          }

          if (!isNil(isMinimized)) {
            Object.keys(chatsDB.chats_list).forEach((key) => {
              chatsDB.chats_list[key].isMinimized = true;
            });
            existingChat.isMinimized = isMinimized;
          }

          if (!isNil(existingChat.isSystemSide)) {
            delete existingChat.isSystemSide;
          }

          chatsDB.update({
            chats_list: {
              ...chatsDB.chats_list,
              [chatId]: {
                ...existingChat,
              },
            },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'SET_CHAT': {
        try {
          const chatsDB = Chats.last();
          if (!chatsDB) return;

          const { chatId, chatData } = action.payload;
          if (!chatId || !chatData || !chatsDB.chats_list) return;

          const existingChat = chatsDB.chats_list[chatId];

          if (!existingChat) return;

          if (!(chatData.isMinimized ?? true)) {
            Object.keys(chatsDB.chats_list).forEach((key) => {
              chatsDB.chats_list[key].isMinimized = true;
            });
          }

          if (
            !isNil(chatData.isSystemSide) &&
            !chatData.isSystemSide &&
            existingChat.isSystemSide
          ) {
            delete existingChat.isSystemSide;
          }

          const existingMessages = (existingChat.messages || []).filter(
            (m) =>
              !chatData.messages?.some(
                (mData) => mData.message_id === m.message_id
              )
          );

          const allMessages = [
            ...existingMessages,
            ...(chatData.messages || []),
          ];

          allMessages.sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
          );

          chatsDB.update({
            chats_list: {
              ...chatsDB.chats_list,
              [chatId]: {
                ...existingChat,
                ...chatData,
                messages: allMessages,
              },
            },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'SET_CHAT_MESSAGES': {
        try {
          const chatsDB = Chats.last();
          if (!chatsDB) return;

          const { chatId, messages } = action.payload;
          if (!chatId || !messages || !chatsDB.chats_list) return;

          const existingChat = chatsDB.chats_list[chatId];

          if (!existingChat) return;

          if (existingChat.isMessagesEmpty) {
            existingChat.isMessagesEmpty = false;
          }

          chatsDB.update({
            chats_list: {
              ...chatsDB.chats_list,
              [chatId]: {
                ...existingChat,
                didMessagesLoaded: true,
                messages: messages,
                updated_at: new Date(),
              },
            },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'ADD_MESSAGE': {
        try {
          const chatsDB = Chats.last();
          if (!chatsDB) return;

          const { chatId, clientId, message } = action.payload;
          if (!chatId || !message || !chatsDB.chats_list) return;

          const chat = chatsDB.chats_list[chatId];

          if (!chat) return;

          // chat.messages.push(message);

          const chatMessages = (chat.messages || []).filter(
            (m) =>
              m.client_id !== clientId && message.message_id !== m.message_id
          );

          chatsDB.update({
            chats_list: {
              ...chatsDB.chats_list,
              [chatId]: {
                ...chat,
                messages: [
                  ...chatMessages,
                  { ...message, client_id: clientId },
                ],
              },
            },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'SET_MESSAGE_DATA': {
        try {
          const chatsDB = Chats.last();
          if (!chatsDB) return;

          const { chatId, clientId, message } = action.payload;
          if (!chatId || !message || !chatsDB.chats_list) return;

          const chat = chatsDB.chats_list[chatId];

          if (!chat) return;

          const mIdx = (chat.messages || []).findIndex(
            (m) => m.client_id === clientId
          );

          if (mIdx !== -1) {
            const chatMessages = [...(chat.messages || [])];

            chatMessages[mIdx] = message;

            chatsDB.update({
              chats_list: {
                ...chatsDB.chats_list,
                [chatId]: {
                  ...chat,
                  messages: chatMessages,
                },
              },
            });
          }
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'SET_REJECTED_MESSAGE': {
        try {
          const chatsDB = Chats.last();
          if (!chatsDB) return;

          const { chatId, clientId } = action.payload;
          if (!chatId || !clientId || !chatsDB.chats_list) return;

          const chat = chatsDB.chats_list[chatId];

          if (!chat) return;

          // chat.messages.push(message);

          const mIdx = (chat.messages || []).findIndex(
            (m) => m.client_id === clientId
          );

          const pendingMessage = chat.messages?.[mIdx];

          if (pendingMessage) {
            const newMessagesList = [...(chat.messages || [])];

            newMessagesList[mIdx] = {
              ...pendingMessage,
              messageStatus: 'failed',
            };

            chatsDB.update({
              chats_list: {
                ...chatsDB.chats_list,
                [chatId]: {
                  ...chat,
                  messages: newMessagesList,
                },
              },
            });
          }

          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'REPLACE_REJECTED_MESSAGE': {
        try {
          const chatsDB = Chats.last();
          if (!chatsDB) return;

          const { chatId, clientId, message } = action.payload;
          if (!chatId || !clientId || !message || !chatsDB.chats_list) return;

          const chat = chatsDB.chats_list[chatId];

          if (!chat) return;

          // chat.messages.push(message);

          const mIdx = (chat.messages || []).findIndex(
            (m) => m.client_id === clientId
          );

          chat.messages?.splice(mIdx, 1);

          const newMessagesList = [...(chat.messages || []), message];

          chatsDB.update({
            chats_list: {
              ...chatsDB.chats_list,
              [chatId]: {
                ...chat,
                messages: newMessagesList,
              },
            },
          });

          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'DELETE_CHAT': {
        try {
          const chatsDB = Chats.last();
          if (!chatsDB) return;

          const { chatId } = action.payload;
          if (!chatId || !chatsDB.chats_list) return;

          const chatsCopy = { ...chatsDB.chats_list };

          delete chatsCopy[chatId];

          chatsDB.update({
            chats_list: chatsCopy,
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'DELETE_CHATS_DB':
        try {
          Chats.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Chats };
export default Chats;
