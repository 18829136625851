import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';
import CatalogsHelpers from '~app/helpers/CatalogsHelpers';

import KleverApi from './api';

class ApiLists {
  static async getUserCatalogs({
    communityId,
    token,
    tags,
    vendors,
    onFetchCompaniesById = () => {},
  }) {
    try {
      return {
        success: true,
        catalogs: await CatalogsHelpers.buildCatalogs({
          tags,
          vendors,
          onFetchCompaniesById,
        }),
      };

      const json = await KleverApi.workerFetch(
        `${API_URL}/catalogs/get?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getUserCatalogs').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getUserCatalogs');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiLists;
