import moment from 'moment';

const datesGroupByComponent = (dates, token, data = {}) => {
  return dates.reduce(function (val, obj) {
    let comp = moment(obj['date'], 'MM/DD/YYYY').format(token);
    (val[comp] = val[comp] || []).push(obj);

    return val;
  }, {});
};

const isInTheLast24Hours = (date) => {
  const date1 = new Date(date);
  const timeStamp = Math.round(new Date().getTime() / 1000);
  const timeStampYesterday = timeStamp - 24 * 3600;
  const is24 = date1 >= new Date(timeStampYesterday * 1000).getTime();

  return is24;
};

const getDate = (date) => {
  const isnum = /^\d+$/.test(date);
  const time = isnum ? +date : date;

  return new Date(time);
};

export { datesGroupByComponent, isInTheLast24Hours, getDate };
