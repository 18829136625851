import { isNil } from '~lib/utils/lodash';
import { EVALUATION_STATUS } from '~lib/constants';
import { didUserCompleteEvaluation } from '~lib/klever/evaluationHelpers';
import { isGuid, isValidEmail } from '~lib/utils/strings';
import { PLATFORM_URL } from '~lib/constants';

class ChatsHelpers {
  static messageType = {
    DATE: 'date',
    SYSTEM: 'system',
    INTRO: 'intro',
    PHOTO: 'photo',
    TEXT: 'text',
    FILE: 'file',
    VIDEO: 'video',
    AUDIO: 'audio',
    CUSTOM: 'custom',
    EVALUATION: 'evaluation',
    CONTACT: 'contact',
    DECISION: 'decision',
    REQUEST_INFO: 'request_info',
    INVITE: 'invite',
    AUTO_RESPOND: 'auto_respond',
  };

  static messageStatus = {
    WAITING: 'waiting',
    SENT: 'sent',
    RECEIVED: 'received',
    READ: 'read',
    FAILED: 'failed',
  };

  static defaultKLEVERChatId = 'klever-chat';

  static defaultChatParticipant = {
    community_id: null,
    user_id: null,
    is_muted: false,
    is_vendor: false,
    did_approve: false,
    approved_at: new Date(),
    updated_at: new Date(),
    joined_at: new Date(),
  };

  static defaultMessageData = {
    type: null,
    title: null,
    text: null,
    data: { file_path: null, action: null },
    updated_at: new Date(),
    created_at: new Date(),
  };

  static defaultChatMessage = {
    chat_id: null,
    client_id: null,
    community_id: null,
    created_at: new Date(),
    message_data: null,
    message_id: null,
    updated_at: new Date(),
    user_id: null,
    viewed_by: [{ user_id: null, viewed_at: new Date() }],
  };

  static randomChatId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  static defaultChatRoom = {
    chat_id: null,
    community_id: null,
    user_id: null,
    room_secret: null,
    participants: [],
    isVisible: false,
    isMinimized: false,
    messages: [],
    created_at: Date.now(),
    updated_at: Date.now(),
    model_version: null,
  };

  static createTemporaryChat({
    communityId,
    user_id,
    temp_chat_id,
    participants,
    isVendorChat,
  }) {
    try {
      const isExternalChat = !isGuid(temp_chat_id) && !isVendorChat;

      const tempChat = {
        ...ChatsHelpers.defaultChatRoom,
        community_id: communityId,
        user_id,
        participants,
        isVisible: true,
        isMinimized: false,
        isApproved: true,
        isChatLoading: true,
        isExternalChat,
        isTempChat: true,
        temp_chat_id,
        isVendorChat: isVendorChat,
      };

      delete tempChat.messages;

      if (isExternalChat) {
        tempChat.chatTitle = temp_chat_id;
        tempChat.isVisible = false;
        tempChat.isMinimized = true;
      }

      return tempChat;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static getDefaultMessage({
    chatId,
    clientId,
    communityId,
    userId,
    messageData,
    date,
  }) {
    try {
      return {
        ...ChatsHelpers.defaultChatMessage,
        chat_id: chatId,
        client_id: clientId,
        message_id: clientId,
        community_id: communityId,
        user_id: userId,
        updated_at: date,
        created_at: date,
        message_data: {
          ...ChatsHelpers.defaultMessageData,
          ...(messageData || {}),
          updated_at: date,
          created_at: date,
        },
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  }

  static getDateMessage(date, text) {
    try {
      return {
        ...ChatsHelpers.defaultMessageData,
        created_at: date,
        text: text,
        type: ChatsHelpers.messageType.DATE,
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  }

  static getChats(communityId, userId) {
    try {
      const chatId = ChatsHelpers.randomChatId();
      const staticChats = {
        [chatId]: {
          ...ChatsHelpers.defaultChatRoom,
          chat_id: chatId,
          participants: [
            {
              ...ChatsHelpers.defaultChatParticipant,
              user_id: 'be499820-229f-11ea-9242-5b85b0500e65',
            },
            {
              ...ChatsHelpers.defaultChatParticipant,
              user_id: 'afeea653-5898-4005-b500-9f2e770dba71',
            },
          ],
          messages: [
            {
              ...ChatsHelpers.defaultChatMessage,
              message_id: ChatsHelpers.randomChatId(),
              message_data: {
                ...ChatsHelpers.defaultMessageData,
                text: 'Started new conversation',
                type: ChatsHelpers.messageType.SYSTEM,
              },
            },
            {
              ...ChatsHelpers.defaultChatMessage,
              message_id: ChatsHelpers.randomChatId(),
              message_data: {
                ...ChatsHelpers.defaultMessageData,
                text: '',
                title: 'Yasmin Shmuel',
                type: ChatsHelpers.messageType.TEXT,
              },
              user_id: '1ef064ef-594d-437a-a518-054ebba0ff51',
            },
          ],
          user_id: '1ef064ef-594d-437a-a518-054ebba0ff51',
          community_id: communityId,
        },
      };

      return staticChats;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  static fillDecisionMessages({
    evaluations,
    userEvaluations,
    userDecisions,
    ownerId,
  }) {
    userEvaluations.forEach((evId) => {
      const ev = evaluations[evId] || {};
      ev.evaluations?.forEach((dec) => {
        if (dec.user_id === ownerId || dec.email === ownerId) {
          const companiesDecision = dec.decisions;
          Object.keys(companiesDecision).forEach((key) => {
            const decData = companiesDecision[key];

            const decisionDate = new Date(
              dec.date || decData.date || ev.created_at
            );

            decisionDate.setTime(decisionDate.getTime() + 60000);

            const userDecision = {
              created_at: decisionDate,
              company_id: key,
              user_id: ownerId,
              ev_type: ev.evaluation_form_type,
              evaluation_id: evId,
              // value: dec.values || dec.isAdded ? 5 : 0,
              // reason: dec.reason?.name,
              // form_data: Object.keys(dec.form_data || {}).reduce((a, v) => {
              //   if (dec.form_data?.[v]) {
              //     a[v] = dec.form_data?.[v].items[0].value;
              //   }
              //   return a;
              // }, {}),
            };
            userDecisions.push(userDecision);
          });
        }
      });
    });
  }

  static createEvalMessage = (val, userId, participant_id) => {
    return {
      ...ChatsHelpers.defaultChatMessage,
      message_id: val.evaluation_id,
      updated_at: val.created_at,
      created_at: val.created_at,
      messageStatus:
        val.status === 'completed'
          ? ChatsHelpers.messageStatus.READ
          : ChatsHelpers.messageStatus.SENT,
      viewed_by: [
        { user_id: userId, viewed_at: val.created_at },
        { user_id: participant_id, viewed_at: val.created_at },
      ],
      user_id: val.issuer_id,
      message_data: {
        ...ChatsHelpers.defaultMessageData,
        updated_at: val.created_at,
        created_at: val.created_at,
        date: new Date(val.created_at),
        text: 'New evaluation',
        data: {
          file_path: `/workspace/evaluation/request/${val.evaluation_id}`,
          action: null,
          evaluation_form_type: val.evaluation_form_type,
          status: val.status,
          company_id_list: val.company_id_list,
          // company_logo: vendor.company_logo?.thumb,
        },
        type: ChatsHelpers.messageType.EVALUATION,
      },
    };
  };

  static createEvalTemporaryChatsMap({
    membersEvalMap = {},
    evaluations = {},
    startups,
    communityId,
    userId,
  }) {
    try {
      const memberEvals = membersEvalMap[userId] || {};

      const tempChats = Object.keys(membersEvalMap || {}).reduce(
        (a, participantId) => {
          const isExternalChat = !isGuid(participantId);

          if (
            participantId === userId ||
            !participantId ||
            participantId === 'klever_share'
          )
            return a;

          const memberEvalsDecisions = [];
          const ownerEvalsDecisions = [];

          const ownerEvals = membersEvalMap[participantId] || {};

          const filteredOwnerSended = (ownerEvals.sent || []).filter((evId) =>
            evaluations[evId]?.participants?.some((p) => p.user_id === userId)
          );

          const filteredOwnerIncoming = (ownerEvals.received || []).filter(
            (evId) => evaluations[evId]?.issuer_id === userId
          );

          const filteredMemberSended = (memberEvals.sent || []).filter(
            (evId) =>
              evaluations[evId]?.participants?.some(
                (p) => (p.user_id || p.email) === participantId
              ) ||
              evaluations[evId]?.external_participants?.some(
                (p) => (p.email || p.user_id) === participantId
              )
          );

          const filteredMemberIncoming = (memberEvals.received || []).filter(
            (evId) => evaluations[evId]?.issuer_id === participantId
          );

          const allOwnerEvals = [
            ...filteredOwnerIncoming,
            ...filteredOwnerSended,
          ];

          const allMemberEvals = [
            ...filteredMemberSended,
            ...filteredMemberIncoming,
          ];

          ChatsHelpers.fillDecisionMessages({
            evaluations,
            userEvaluations: allOwnerEvals,
            userDecisions: memberEvalsDecisions,
            ownerId: participantId,
          });

          ChatsHelpers.fillDecisionMessages({
            evaluations,
            userEvaluations: allMemberEvals,
            userDecisions: ownerEvalsDecisions,
            ownerId: userId,
          });

          if (!allMemberEvals.length && !allOwnerEvals.length) return a;

          const commonEvals = [...allMemberEvals, ...allOwnerEvals];

          const commonDecisions = [
            ...memberEvalsDecisions,
            ...ownerEvalsDecisions,
          ];

          const evalMessages = commonEvals.map((evId) =>
            ChatsHelpers.getEvalMessage(
              evaluations[evId],
              userId,
              participantId
            )
          );

          const decisionMessages = commonDecisions.map((val) =>
            ChatsHelpers.getEvalDecisions({
              curDec: val,
              compName: startups?.[val.company_id]?.company_name,
              userId,
              participantId,
            })
          );

          a[participantId] = {
            ...ChatsHelpers.defaultChatRoom,
            chat_id: null,
            temp_chat_id: participantId,
            user_id: participantId,
            community_id: communityId,
            messages: [...evalMessages, ...decisionMessages],
            isApproved: true,
            isExternalChat,
            chatTitle: isExternalChat ? participantId : null,
            participants: [
              {
                ...ChatsHelpers.defaultChatParticipant,
                user_id: userId,
                did_approve: true,
              },
            ],
          };

          return a;
        },
        {}
      );

      const allKleverShares = (
        membersEvalMap.klever_share?.sent || []
      ).filter((evId) =>
        evaluations[evId].participants?.find((i) => i.user_id === userId)
      );

      const allKSDecisionMessages = [];

      ChatsHelpers.fillDecisionMessages({
        evaluations,
        userEvaluations: allKleverShares,
        userDecisions: allKSDecisionMessages,
        ownerId: userId,
      });

      const allKleverSharesMessages = allKleverShares.map((evId) =>
        ChatsHelpers.getEvalMessage(evaluations[evId], userId)
      );

      const allMessages = [
        ...(allKleverSharesMessages || []),
        ...(allKSDecisionMessages || []),
      ];

      allMessages.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );

      const kleverChat = {
        ...ChatsHelpers.defaultChatRoom,
        chat_id: ChatsHelpers.defaultKLEVERChatId,
        // user_id: null,
        // community_id: null,
        chatTitle: 'KLEVER Discovery',
        chatImg: '/imgs/klever/logo_black.jpg',
        messages: allMessages?.length ? allMessages : null,
        isApproved: true,
        didMessagesLoaded: allMessages?.length ? true : false,
        participants: [
          {
            ...ChatsHelpers.defaultChatParticipant,
            user_id: userId,
            did_approve: true,
          },
        ],
      };

      return kleverChat.messages
        ? { ...tempChats, [kleverChat.chat_id]: kleverChat }
        : tempChats;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  static mergeEvalTempChats(tempMap, chats, user) {
    try {
      Object.values(chats).forEach((c) => {
        const chatKey = c.chat_id || c.temp_chat_id;

        if (c.user_id === user.user_id) {
          c.participants?.forEach((p) => {
            if (p && tempMap?.[p.user_id]) {
              const participantMessages = [
                ...(chats[chatKey]?.messages || []),
                ...(tempMap[p.user_id]?.messages || []),
              ];
              participantMessages.sort(
                (a, b) => new Date(a.created_at) - new Date(b.created_at)
              );
              chats[chatKey].messages = participantMessages;
            }
          });
        } else {
          if (tempMap[c.user_id]) {
            const ownerMessages = [
              ...(chats[chatKey]?.messages || []),
              ...(tempMap[c.user_id]?.messages || []),
            ];
            ownerMessages.sort(
              (a, b) => new Date(a.created_at) - new Date(b.created_at)
            );
            chats[chatKey].messages = ownerMessages;
          }
        }

        chats[chatKey].didMessagesLoaded = false;
        chats[chatKey].isMessagesEmpty = true;
      });

      Object.values(chats || {}).forEach((c) => {
        delete tempMap[c.user_id];
        c.participants.forEach((p) => {
          delete tempMap[p.user_id];
        });
      });

      const evalChats = Object.values(tempMap || {}).reduce((a, v) => {
        v.messages?.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        a[v.user_id || v.chat_id] = v;
        return a;
      }, {});

      return { ...chats, ...evalChats };
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  static createReqInfoTempChats({
    onboardings = {},
    startups = {},
    communityId = null,
    userId = null,
  }) {
    try {
      const tempChats = (onboardings?.list || []).reduce((a, v) => {
        const vendor =
          startups?.[v.company_id || v.post_onboarding_company_id] || {};

        if (v.issuer_id !== userId) return a;

        const getRequestType = () => {
          switch (v.onboarding_reason) {
            case ChatsHelpers.messageType.INVITE:
              return ChatsHelpers.messageType.INVITE;
            case ChatsHelpers.messageType.REQUEST_INFO:
              return ChatsHelpers.messageType.REQUEST_INFO;
            default:
              return ChatsHelpers.messageType.REQUEST_INFO;
          }
        };

        const recipientName =
          vendor.company_name || isValidEmail(v.recipient_id)
            ? v.recipient_id
            : null;

        const custom_message =
          v.onboarding_reason === ChatsHelpers.messageType.INVITE &&
          v.onboarding_type === 'link'
            ? `${PLATFORM_URL}/static/onboarding?request_id=${v.request_id}&recipient=${v.recipient_id}`
            : v.custom_message || '';

        const text =
          v.onboarding_reason === ChatsHelpers.messageType.INVITE
            ? 'New invite'
            : 'New request information';

        // if (
        //   v.onboarding_reason !== ChatsHelpers.messageType.REQUEST_INFO ||
        //   !v.company_id ||
        //   !vendor
        // )
        //   return a;

        const requestMessage = {
          ...ChatsHelpers.defaultChatMessage,
          message_id: v.request_id,
          updated_at: v.created_at,
          created_at: v.created_at,
          messageStatus:
            v.status === 'completed'
              ? ChatsHelpers.messageStatus.READ
              : ChatsHelpers.messageStatus.SENT,
          viewed_by: [
            { user_id: userId, viewed_at: v.created_at },
            { user_id: v.issuer_id, viewed_at: v.created_at },
          ],
          user_id: v.issuer_id,
          message_data: {
            ...ChatsHelpers.defaultMessageData,
            updated_at: v.created_at,
            created_at: v.created_at,
            date: new Date(v.created_at),
            text,
            data: {
              file_path: vendor.company_id
                ? `/workspace/company/${vendor.company_id}`
                : null,
              action: null,
              request_type: v.onboarding_type,
              requested_fields:
                (v.requested_fields &&
                  v.requested_fields.map((i) => i.replace(/_/g, ' '))) ||
                [],
              recipientName,
              custom_message,
              company_logo: vendor.company_logo?.thumb,
              status: v.status,
            },
            type: getRequestType(),
          },
        };

        const reqInfoMessages = [
          ...(a[vendor.company_id]?.messages || []),
          requestMessage,
        ];

        const chatId =
          vendor.company_id ||
          v.company_id ||
          v.post_onboarding_company_id ||
          v.recipient_id;

        a[chatId] = {
          ...ChatsHelpers.defaultChatRoom,
          chat_id: null,
          temp_chat_id: chatId,
          user_id: userId,
          community_id: communityId,
          chatTitle: vendor.company_name || v.recipient_id,
          isVendorChat: true,
          messages: reqInfoMessages,
          isApproved: true,
          isTempChat: !vendor.company_id,
          isExternalChat: !vendor.company_id,
          participants: [
            {
              ...ChatsHelpers.defaultChatParticipant,
              ...(vendor || {}),
              community_id: communityId,
              company_id: vendor.company_id,
              is_vendor: true,
              did_approve: true,
            },
          ],
        };

        return a;
      }, {});

      return tempChats;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  static mergeReqInfoTempChats(tempMap, chats, user) {
    try {
      Object.values(chats).forEach((c) => {
        const chatKey = c.chat_id || c.temp_chat_id;

        if (c.user_id === user.user_id && chatKey) {
          c.participants?.forEach((p) => {
            const participantChat = tempMap[p.company_id];

            if (participantChat) {
              const participantMessages = [
                ...(chats[chatKey]?.messages || []),
                ...(participantChat?.messages || []),
              ];
              participantMessages.sort(
                (a, b) => new Date(a.created_at) - new Date(b.created_at)
              );
              chats[chatKey].messages = participantMessages;
              chats[chatKey].didMessagesLoaded = false;
              chats[chatKey].isMessagesEmpty = true;
            }
          });
        }
      });

      Object.values(chats || {}).forEach((c) => {
        c.participants.forEach((p) => {
          if (p) {
            delete tempMap[p.company_id];

            if (c.chat_id && chats[p.company_id]) {
              const tempChat = { ...(chats[p.company_id] || {}) };

              chats[c.chat_id].isSystemSide = tempChat.isSystemSide;
              chats[c.chat_id].isVendorChat = tempChat.isVendorChat;
              chats[c.chat_id].isVisible = tempChat.isVisible;
              chats[c.chat_id].isMinimized = tempChat.isMinimized;
              chats[c.chat_id].isApproved = tempChat.isApproved;

              delete chats[p.company_id];
            }
          }
        });
      });

      const reqInfoChats = Object.keys(tempMap || {}).reduce((a, key) => {
        tempMap[key].messages?.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        a[key] = tempMap[key];
        return a;
      }, {});

      return { ...chats, ...reqInfoChats };
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  static getContactRequestMessages({ contactRequests, leads }) {
    try {
      const tempMessages = contactRequests.map((i) => {
        const community =
          leads.find((lead) => lead.community_id === i.community_id) || {};

        return {
          ...ChatsHelpers.defaultChatMessage,
          updated_at: i.created_at,
          created_at: i.created_at,
          viewed_by: [
            // { user_id: userId, viewed_at: i.created_at },
            // { user_id: v.user_id, viewed_at: i.created_at },
          ],
          user_id: i.community_id,
          message_data: {
            ...ChatsHelpers.defaultMessageData,
            updated_at: i.created_at,
            created_at: i.created_at,
            date: new Date(i.created_at),
            data: {},
            contact: {
              title: i.subject,
              titleColor: '#434d7e',
              message: i.custom_message,
            },
            title: community.community_name,
            text: '',
            type: ChatsHelpers.messageType.CONTACT,
          },
        };
      });

      return tempMessages;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  static syncVendorChatMessages({
    chat = {},
    onboardings = {},
    vendor = {},
    userId = null,
  }) {
    try {
      const reqInfoMessages = (onboardings?.list || []).reduce((a, v) => {
        if (
          v.onboarding_reason !== ChatsHelpers.messageType.REQUEST_INFO ||
          !v.company_id ||
          v.company_id !== vendor.company_id
        )
          return a;

        const requestMessage = {
          ...ChatsHelpers.defaultChatMessage,
          message_id: v.request_id,
          updated_at: v.created_at,
          created_at: v.created_at,
          viewed_by: [
            { user_id: userId, viewed_at: v.created_at },
            { user_id: v.issuer_id, viewed_at: v.created_at },
          ],
          user_id: v.issuer_id,
          message_data: {
            ...ChatsHelpers.defaultMessageData,
            updated_at: v.created_at,
            created_at: v.created_at,
            date: new Date(v.created_at),
            text: 'New request information',
            data: {
              file_path: `/workspace/company/${v.company_id}`,
              action: null,
              request_type: v.onboarding_type,
              requested_fields:
                (v.requested_fields &&
                  v.requested_fields.map((i) => i.replace(/_/g, ' '))) ||
                [],
              custom_message: v.custom_message || '',
              company_logo: vendor.company_logo?.thumb,
              status: v.status,
            },
            type: ChatsHelpers.messageType.REQUEST_INFO,
          },
        };

        if (
          !chat.messages?.find(
            (m) => m.message_id === requestMessage.message_id
          )
        ) {
          a.push(requestMessage);
        }

        return a;
      }, []);

      const allMessages = [...(chat.messages || []), ...reqInfoMessages].filter(
        (i) => i.message_id
      );

      allMessages.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );

      chat.messages = allMessages;
    } catch (err) {
      console.log(err);
    }
  }

  static createInviteVendorTempChats({
    chatId = null,
    requestId = null,
    communityId = null,
    onboardingType = null,
    userId = null,
    customMessage = '',
    isVisible = true,
  }) {
    try {
      const messageLink =
        onboardingType === 'link'
          ? `${PLATFORM_URL}/static/onboarding?request_id=${requestId}&recipient=${chatId}`
          : null;

      const inviteMessage = {
        ...ChatsHelpers.defaultChatMessage,
        message_id: requestId,
        updated_at: new Date(),
        created_at: new Date(),
        viewed_by: [{ user_id: userId, viewed_at: new Date() }],
        user_id: userId,
        messageStatus: ChatsHelpers.messageStatus.SENT,
        message_data: {
          ...ChatsHelpers.defaultMessageData,
          updated_at: new Date(),
          created_at: new Date(),
          date: new Date(),
          text: 'New invite',
          data: {
            file_path: null,
            action: null,
            request_type: onboardingType,
            recipientName: chatId,
            custom_message: messageLink || customMessage,
            status: 'pending',
          },
          custom_message: messageLink || customMessage,
          type: ChatsHelpers.messageType.INVITE,
        },
      };

      const tempChat = {
        ...ChatsHelpers.defaultChatRoom,
        chat_id: null,
        community_id: communityId,
        user_id: userId,
        temp_chat_id: chatId,
        messages: [inviteMessage],
        participants: [
          {
            ...ChatsHelpers.defaultChatParticipant,
            name: chatId,
            company_name: chatId,
            community_id: communityId,
            company_id: chatId,
            is_vendor: true,
            did_approve: true,
          },
        ],
        isVisible,
        isMinimized: false,
        chatTitle: chatId,
        isVendorChat: true,
        isApproved: true,
        isTempChat: true,
        isExternalChat: true,
        didMessagesLoaded: false,
        isMessagesEmpty: true,
      };

      return tempChat;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  static onCompleteEvalMessage = ({
    curChat,
    evalId,
    evalData,
    userId,
    evaluations,
    decisionUserId,
  }) => {
    try {
      if (curChat && evalId && evalData) {
        const mIdx = curChat.messages?.findIndex(
          (m) => m.message_id === evalId
        );

        if (!isNil(mIdx) && mIdx !== -1) {
          const mData = curChat.messages[mIdx];

          curChat.messages[mIdx] = {
            ...(mData || {}),
            messageStatus: ChatsHelpers.messageStatus.READ,
            message_data: {
              ...(mData?.message_data || {}),
              data: {
                ...(mData?.message_data?.data || {}),
                status: 'completed',
              },
            },
          };
        }

        const ownerEvalsDecisions = [];

        const userEvaluations = evalData.evaluations?.reduce((acc, ev) => {
          const evUserKey = ev.user_id || ev.email;
          if (evUserKey === decisionUserId) {
            // acc.push({
            //   ...ev,
            //   evaluation_form_type: evalData.evaluation_form_type,
            //   evaluation_id: evalData.evaluation_id,
            // });
            acc.push(evalData.evaluation_id);
          }
          return acc;
        }, []);

        ChatsHelpers.fillDecisionMessages({
          evaluations: { [evalId]: evalData },
          userEvaluations: userEvaluations,
          userDecisions: ownerEvalsDecisions,
          ownerId: decisionUserId,
        });

        const decisionMessages = ownerEvalsDecisions.map((val) => {
          return {
            ...ChatsHelpers.defaultChatMessage,
            message_id: `${val.evaluation_id}_${val.company_id}`,
            updated_at: val.created_at,
            created_at: val.created_at,
            messageStatus: ChatsHelpers.messageStatus.READ,
            viewed_by: [
              { user_id: userId, viewed_at: val.created_at },
              { user_id: val.user_id, viewed_at: val.created_at },
            ],
            user_id: val.user_id,
            message_data: {
              ...ChatsHelpers.defaultMessageData,
              updated_at: val.created_at,
              created_at: val.created_at,
              date: new Date(val.created_at),
              text: `${
                decisionUserId === userId ? 'You' : 'Your peer'
              } completed a consult`,
              data: {
                file_path: `/workspace/company/${val.company_id}?showStats=true`,
                action: null,
                evaluation_form_type: val.ev_type,
              },
              type: ChatsHelpers.messageType.DECISION,
            },
          };
        });

        curChat.messages.push(...decisionMessages);

        return curChat;
      } else {
        return curChat;
      }
    } catch (err) {
      console.error(err);
      return curChat;
    }
  };

  static getEvalMessage(curEval, userId, participantId) {
    try {
      const viewedBy = [];

      if (userId) {
        viewedBy.push({ user_id: userId, viewed_at: curEval.created_at });
      }

      if (participantId) {
        viewedBy.push({
          user_id: participantId,
          viewed_at: curEval.created_at,
        });
      }

      const isKLEVERShare = curEval.evaluation_type === 'klever_share';

      const compIdList = isKLEVERShare
        ? curEval.company_meta_data?.map((i) => i.company_id) ||
          curEval.company_id_list
        : curEval.company_id_list;

      const evalStatus = isKLEVERShare
        ? didUserCompleteEvaluation(userId, curEval)
          ? 'completed'
          : curEval.status
        : curEval.status;

      const messageStatus = isKLEVERShare
        ? ChatsHelpers.messageStatus.READ
        : curEval.status === 'completed'
        ? ChatsHelpers.messageStatus.READ
        : ChatsHelpers.messageStatus.SENT;

      const evalPath = isKLEVERShare
        ? didUserCompleteEvaluation(userId, curEval)
          ? ''
          : evalStatus !== EVALUATION_STATUS.EXPIRED &&
            `/workspace/evaluation/request/${curEval.evaluation_id}`
        : curEval.issuer_id !== userId
        ? `/workspace/evaluation/request/${curEval.evaluation_id}`
        : curEval.company_id_list?.[0]
        ? `/workspace/network/company/${curEval.company_id_list?.[0]}`
        : '';

      const { link_data } = curEval;

      const curLinkData = (link_data || []).find(
        (i) => i.user_id === participantId || i.recipient === participantId
      );

      const messageLink = curLinkData
        ? `${PLATFORM_URL}/static/evaluation?id=${curLinkData.request_id}&key=${curLinkData.secret}`
        : '';

      return {
        ...ChatsHelpers.defaultChatMessage,
        message_id: curEval.evaluation_id,
        updated_at: curEval.created_at,
        created_at: curEval.created_at,
        viewed_by: viewedBy,
        user_id: curEval.issuer_id,
        messageStatus,
        message_data: {
          ...ChatsHelpers.defaultMessageData,
          updated_at: curEval.created_at,
          created_at: curEval.created_at,
          date: new Date(curEval.created_at),
          text: isKLEVERShare ? 'Weekly solutions' : 'New evaluation',
          title: isKLEVERShare && 'KLEVER Discovery',
          data: {
            file_path: evalPath,
            action: null,
            evaluation_form_type: curEval.evaluation_form_type,
            evaluation_type: curEval.evaluation_type,
            status: evalStatus,
            company_id_list: compIdList,
            custom_message: messageLink || '',
          },
          type: ChatsHelpers.messageType.EVALUATION,
        },
      };
    } catch (err) {
      console.error(err);
      return {};
    }
  }

  static getEvalDecisions({ curDec, compName, userId, participantId }) {
    try {
      return {
        ...ChatsHelpers.defaultChatMessage,
        message_id: `${curDec.evaluation_id}_${curDec.company_id}`,
        updated_at: curDec.created_at,
        created_at: curDec.created_at,
        messageStatus:
          curDec.status === 'completed'
            ? ChatsHelpers.messageStatus.READ
            : ChatsHelpers.messageStatus.SENT,
        viewed_by: [
          { user_id: userId, viewed_at: curDec.created_at },
          { user_id: participantId, viewed_at: curDec.created_at },
        ],
        user_id: curDec.user_id,
        message_data: {
          ...ChatsHelpers.defaultMessageData,
          updated_at: curDec.created_at,
          created_at: curDec.created_at,
          date: new Date(curDec.created_at),
          text: `${
            curDec.user_id === userId ? 'You' : 'Your peer'
          } completed a consult
          ${compName ? 'on ' + compName : ''}`,
          data: {
            file_path: `/workspace/company/${curDec.company_id}?showStats=true`,
            action: null,
            evaluation_form_type: curDec.ev_type,
            // value: curDec.value,
            // reason: curDec.reason,
            // form_data: curDec.form_data,
          },
          // title: `User completed evaluation`,
          type: ChatsHelpers.messageType.DECISION,
        },
      };
    } catch (err) {
      console.error(err);
      return {};
    }
  }

  static getIntroMessage(chat) {
    try {
      return {
        ...ChatsHelpers.defaultChatMessage,
        message_id: chat.temp_chat_id || chat.chat_id,
        updated_at: chat.created_at,
        created_at: chat.created_at,
        user_id: chat.user_id,
        type: ChatsHelpers.messageType.INTRO,
        text: `Chat directly with ${chat.chatTitle}`,
        date: new Date(chat.created_at),
        message_data: {
          ...ChatsHelpers.defaultMessageData,
          updated_at: chat.created_at,
          created_at: chat.created_at,
          date: new Date(chat.created_at),
          text: `Chat directly with ${chat.chatTitle}`,
        },
      };
    } catch (err) {
      console.error(err);
      return {};
    }
  }

  static getAutoRespondMessage = (chat, userId) => {
    try {
      const isRespondMessageExist = chat.messages?.find(
        (i) => i.type === ChatsHelpers.messageType.AUTO_RESPOND
      );

      if (isRespondMessageExist) return null;

      const lastMessage = chat.last_message || chat.messages?.[0];

      const messageDate = new Date(
        lastMessage?.created_at || chat.created_at || Date.now()
      );

      return {
        ...ChatsHelpers.defaultChatMessage,
        message_id: chat.temp_chat_id || chat.chat_id,
        updated_at: messageDate,
        created_at: messageDate,
        user_id: chat.user_id,
        type: ChatsHelpers.messageType.AUTO_RESPOND,
        text: `This Tech Solution has yet to claim its KLEVER account. We will contact it for you shortly.`,
        date: messageDate,
        // || new Date(chat.created_at),
        message_data: {
          ...ChatsHelpers.defaultMessageData,
          type: ChatsHelpers.messageType.AUTO_RESPOND,
          updated_at: messageDate,
          created_at: messageDate,
          date: messageDate,
          // || new Date(chat.created_at),
          text: `This Tech Solution has yet to claim its KLEVER account. We will contact it for you shortly.`,
        },
      };
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  static setViewedChatMessages = (chat, userId) => {
    try {
      const chatMessages = chat?.messages || [];
      chatMessages.some((originalMessage, idx) => {
        chatMessages[idx].viewed_by = [
          ...(chatMessages[idx]?.viewed_by || []),
          { user_id: userId, viewed_at: new Date() },
        ];

        return false;
      });

      return chatMessages;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  static getCurChat(chatsMap, participantsList, ownerId) {
    try {
      const curChat =
        chatsMap[ownerId] ||
        Object.values(chatsMap || {}).find(
          (i) =>
            (i.participants || []).every((p) =>
              (participantsList || []).find(
                (e) =>
                  (e.vendor_id || e.company_id || e.user_id) ===
                    (p.company_id || p.user_id) ||
                  (e.vendor_id || e.company_id || e.user_id) ===
                    (i.company_id || i.user_id)
              )
            ) &&
            (!ownerId || i.user_id === ownerId)
        );

      return curChat;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  static getChatByMessageId(chatsMap, mId) {
    try {
      if (!chatsMap || !mId) return null;

      const curChat = Object.values(chatsMap || {}).find((i) =>
        (i.messages || []).some((m) => m.message_id === mId)
      );
      return curChat;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  static getCurOpenChat(chatsMap) {
    try {
      const curChat = Object.values(chatsMap || {}).find(
        (i) => i.isVisible && !i.isMinimized
      );
      return curChat;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
}

export default ChatsHelpers;
