import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiTags {
  static async getTags({ communityId, skip = 0, limit = 5, token }) {
    try {
      const communityPath = communityId ? `communityId=${communityId}&` : '';

      const json = await KleverApi.workerFetch(
        `${API_URL}/custom_tags/get_all?${communityPath}skip=${skip}&limit=${limit}`,
        {
          signal: KLAbortController.genController('getTags').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('getTags');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async updateCompanyTags({ communityId, companyId, tagsIds, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/custom_tags/add_to_company`,
        {
          signal: KLAbortController.genController('updateCompanyTags').signal,
          method: 'POST',
          body: JSON.stringify({
            tagData: {
              tags: tagsIds,
              communityId,
              companyId,
            },
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('updateCompanyTags');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async tagsAutocomplete({ communityId, query, token }) {
    try {
      const communityPath = communityId ? `communityId=${communityId}&` : '';

      const json = await KleverApi.workerFetch(
        `${API_URL}/custom_tags/autocomplete?${communityPath}s=${query}`,
        {
          signal: KLAbortController.genController('tagsAutocomplete').signal,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('tagsAutocomplete');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async createTag({ tagText, communityId, token }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/custom_tags/add`, {
        signal: KLAbortController.genController('createTag').signal,
        method: 'POST',
        body: JSON.stringify({
          tagData: { tagText, communityId: communityId },
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('createTag');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async updateTag({ tagId, tagText, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/custom_tags/edit/${tagId}`,
        {
          signal: KLAbortController.genController('updateTag').signal,
          method: 'PUT',
          body: JSON.stringify({
            tagData: { tagText },
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('updateTag');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async deleteTag({ tagId, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/custom_tags/delete/${tagId}`,
        {
          signal: KLAbortController.genController('deleteTag').signal,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('deleteTag');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiTags;
