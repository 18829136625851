import { attr, Model } from 'redux-orm';

import { unescapeString } from '~lib/utils/strings';

import { GenerateRandomAvatar } from '../lib/constants';

/**
 * EntityPool model
 * @author Villi K.
 */
class EntityPool extends Model {
  static get modelName() {
    return 'EntityPool';
  }

  static get fields() {
    return {
      startups: attr(),
      partners: attr(),
      advisors: attr(),
      followUpList: attr(),
      rejectedList: attr(),
      recommendations: attr(),
      didLoad: attr(),
      lastSyncTime: attr(),
    };
  }

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param User
   * @param session
   * @returns {undefined}
   */
  static reducer(action, EntityPool, session) {
    if (!EntityPool) return;
    switch (action.type) {
      case 'CREATE_ENTITY_POOL':
        EntityPool.create({
          startups: {},
          partners: {},
          advisors: {},
          followUpList: {},
          rejectedList: {},
          recommendations: {},
          ...action.payload,
        });
        break;
      case 'INSERT_ENTITY_POOL': {
        let lastEntity = EntityPool.last();
        if (!lastEntity) {
          lastEntity = EntityPool.create({
            startups: {},
            partners: {},
            advisors: {},
            followUpList: {},
            rejectedList: {},
            recommendations: {},
            ...action.payload,
          });
        }
        const { startups, partners, advisors } = action.payload;

        const mainEntity = {
          startups: {},
          partners: {},
          advisors: {},
          followUpList: {},
          rejectedList: {},
          recommendations: {},
          ...action.payload,
        };

        if (startups) {
          if (!mainEntity.startups) mainEntity.startups = {};
          Object.keys(startups)
            .filter(
              (i) => startups?.[i].company_name && startups?.[i].company_id
            )
            .forEach((e) => {
              try {
                startups[e].description = unescapeString(
                  startups?.[e].description
                );

                startups[e].short_description = unescapeString(
                  startups?.[e].short_description
                );

                startups[e].finance = {
                  ...(mainEntity.startups?.[e]?.finance || {}),
                  ...(startups?.[e].finance || {}),
                  investor_list:
                    startups?.[e].finance?.investor_list?.filter(
                      (i) => i.fullname
                    ) || [],
                };

                if (startups?.[e].founders && startups?.[e].founders.length) {
                  startups?.[e].founders.forEach((i, idx) => {
                    startups[e].founders[idx] = {
                      ...i,
                      img_path: i.img_path || GenerateRandomAvatar(),
                    };
                  });
                }
              } catch (err) {
                console.log(err);
              }

              if (Array.isArray(startups?.[e].company_id)) {
                startups[e].company_id = startups?.[e].company_id[0];
              }

              if (
                mainEntity.startups?.[e]?.didLoad &&
                startups?.[e]?.isMetaData
              ) {
                delete startups?.[e].didLoad;
                delete startups?.[e].finance;
              }

              mainEntity.startups[e] = {
                ...(mainEntity.startups?.[e] || {}),
                ...(startups?.[e] || {}),
              };
            });
        }
        if (partners) {
          try {
            e.description = unescapeString(e.description);
            e.short_description = unescapeString(e.short_description);
          } catch (err) {}

          Object.values(partners).forEach((e) => {
            mainEntity.partners[e.company_id || e.id] = e;
          });
        }
        if (advisors) {
          Object.values(advisors).forEach((e) => {
            mainEntity.advisors[e.advisor_id] = e;
          });
        }

        const startupsDeepClone = JSON.parse(
          JSON.stringify(mainEntity.startups)
        );

        lastEntity.update({
          didLoad: true,
          lastSyncTime: Date.now(),
          startups: startupsDeepClone,
          partners: { ...mainEntity.partners },
          advisors: { ...mainEntity.advisors },
        });

        break;
      }
      case 'ADD_STARTUP': {
        const mainEntity = EntityPool.last();
        if (!mainEntity) return;

        const { data } = action.payload;
        const { startups } = mainEntity;

        if (!data) return;

        mainEntity.update({
          startups: {
            ...(startups || {}),
            [data.company_id]: data,
          },
        });

        break;
      }
      case 'BATCH_ADD_TO_STARTUPS': {
        const mainEntity = EntityPool.last();

        if (!mainEntity) return;

        const { newStartups } = action.payload;

        const { startups } = mainEntity;

        if (!newStartups) return;

        mainEntity.update({
          startups: {
            ...(startups || {}),
            ...newStartups,
          },
        });
        break;
      }
      case 'SET_VENDOR_DATA': {
        const mainEntity = EntityPool.last();

        if (!mainEntity) return;

        const { vendorId, data } = action.payload;

        const { startups } = mainEntity;

        if (!data || !vendorId) return;

        mainEntity.update({
          startups: {
            ...(startups || {}),
            [vendorId]: { ...data },
          },
        });

        break;
      }
      case 'SET_RECOMMENDATIONS_DATA': {
        const mainEntity = EntityPool.last();
        if (!mainEntity) return;

        const { key, data } = action.payload;

        if (mainEntity.recommendations) {
          mainEntity.update({
            recommendations: {
              ...mainEntity.recommendations,
              [key]: data,
            },
          });
        }

        break;
      }
      case 'SET_RECOMMENDATION_COMPANY_DATA': {
        const mainEntity = EntityPool.last();
        if (!mainEntity) return;

        const { key, tagId, compId, data } = action.payload;

        const vendors = mainEntity.recommendations?.[key]?.[tagId];
        const compIdx = (vendors || []).findIndex(
          (comp) => comp.company_id === compId
        );

        if (vendors && compIdx !== -1) {
          mainEntity.recommendations[key][tagId][compIdx] = data;

          mainEntity.update();
        }

        break;
      }
      case 'ADD_ORGANIZATIONS': {
        const mainEntity = EntityPool.last();
        if (!mainEntity) return;

        const data = action.payload.organizations;

        Object.keys(data).forEach((k) => {
          if (
            mainEntity.organizations[k] === null ||
            mainEntity.organizations[k] === undefined
          ) {
            mainEntity.organizations[k] = data[k];
          }
        });

        mainEntity.update();
        break;
      }
      case 'ADD_ENTITY_FOLLOW_UP': {
        const mainEntity = EntityPool.last();
        if (!mainEntity) return;

        const { companyId } = action.payload;
        if (companyId) {
          mainEntity.followUpList[companyId] = { following: true };
          mainEntity.update();
        }
        break;
      }
      case 'REMOVE_ENTITY_FOLLOW_UP': {
        const mainEntity = EntityPool.last();
        if (!mainEntity) return;

        const { companyId } = action.payload;
        if (companyId) {
          delete mainEntity.followUpList[companyId];
          mainEntity.update();
        }
        break;
      }
      case 'ADD_REJECTED_ENTITY': {
        const mainEntity = EntityPool.last();
        if (!mainEntity) return;

        const { companyId } = action.payload;
        if (companyId) {
          mainEntity.rejectedList[companyId] = { rejected: true };
          mainEntity.update();
        }
        break;
      }
      case 'REMOVE_REJECTED_ENTITY': {
        const mainEntity = EntityPool.last();
        if (!mainEntity) return;

        const { companyId } = action.payload;
        if (companyId) {
          delete mainEntity.rejectedList[companyId];
          mainEntity.update();
        }
        break;
      }
      case 'ADD_COMPANY': {
        const mainEntity = EntityPool.last();
        if (!mainEntity) return;

        const { companyId, data } = action.payload;

        if (data.description)
          data.description = unescapeString(data.description);

        if (data.short_description)
          data.short_description = unescapeString(data.short_description);

        const { startups } = mainEntity;

        // startups?.[companyId] = {
        //   ...(startups?.[companyId] || {}),
        //   ...data,
        // };

        // mainEntity.startups = startups;

        mainEntity.update({
          startups: {
            ...startups,
            [companyId]: {
              ...(startups?.[companyId] || {}),
              ...data,
            },
          },
        });
        // EntityPool.withId(mainEntity.id).update({
        //   startups: mainEntity.startups,
        // });
        break;
      }
      case 'UPDATE_COMPANY': {
        const mainEntity = EntityPool.last();
        if (!mainEntity) return;

        const { companyId, data } = action.payload;

        if (data.description)
          data.description = unescapeString(data.description);

        if (data.short_description)
          data.short_description = unescapeString(data.short_description);

        if (!mainEntity.startups?.[companyId]) return;

        const { startups } = mainEntity;

        mainEntity.update({
          startups: {
            ...startups,
            [companyId]: {
              ...(startups?.[companyId] || {}),
              ...data,
            },
          },
        });
        break;
      }
      case 'TOGGLE_REQUEST_INFO_STATE': {
        try {
          const mainEntity = EntityPool.last();
          if (!mainEntity) return;

          const { companyId, isRequesting = false } = action.payload;

          const { startups } = mainEntity;

          if (!startups || !startups?.[companyId]) return;

          mainEntity.update({
            startups: {
              ...startups,
              [companyId]: {
                ...startups?.[companyId],
                is_requesting_info: isRequesting,
              },
            },
          });

          break;
        } catch (err) {
          console.error(err);
          return;
        }
      }
      case 'TOGGLE_MULTI_REQUEST_INFO_STATE': {
        const mainEntity = EntityPool.last();
        if (!mainEntity) return;

        const { companyIdList, isRequesting = false } = action.payload;

        const startups = { ...mainEntity.startups };

        companyIdList.forEach((companyId) => {
          if (!startups?.[companyId]) return;
          startups[companyId].is_requesting_info = isRequesting;
        });

        mainEntity.startups = startups;

        mainEntity.update();
        EntityPool.withId(mainEntity.id).update({
          startups: mainEntity.startups,
        });

        break;
      }
      case 'UPDATE_ENTITY_POOL':
        // EntityPool.withId(action.payload.id).update(action.payload);
        break;
      case 'REMOVE_ENTITY':
        try {
          EntityPool.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export default EntityPool;
export { EntityPool };
