import { createSliceWithThunks } from '../createSlice';
import KleverApi from '~lib/api/api';

const initialState = {
  curTabIdx: null,
  lists: [],
  totalLists: 0,
  listsLoading: false,
  notifications: [],
};

export const sidebarSlice = createSliceWithThunks({
  name: 'sidebar',
  initialState,
  reducers: (create) => ({
    fetchLists: create.asyncThunk(
      async ({ communityId, token }) => {
        const res = await KleverApi.Lists.getUserLists(communityId, token);

        if (res.success) {
          const notifications = await KleverApi.Lists.getSortedListBatch({
            communityId,
            token,
          });

          res.lists.forEach((list) => {
            list.notifications_counter =
              notifications.lists.find((el) => el._id === list.list_id)
                ?.notifications_counter || 0;
          });

          res.all_count = notifications.all_count;
        }

        return await res;
      },
      {
        pending: (state) => {
          state.listsLoading = true;
        },
        rejected: (state) => {
          state.listsLoading = false;
        },
        fulfilled: (state, { payload: { lists, all_count } }) => {
          state.totalLists = all_count;
          state.lists = [
            ...lists
              .sort((a, b) => b.notifications_counter - a.notifications_counter)
              .sort((a, b) =>
                a.list_type === 'AI_decision_room'
                  ? -1
                  : b.list_type === 'AI_decision_room'
                  ? 1
                  : 0
              ),
          ];
          state.listsLoading = false;
        },
      }
    ),
    updateLists: create.asyncThunk(
      async ({ communityId, token }) => {
        const res = await KleverApi.Lists.getUserLists(communityId, token);

        if (res.success) {
          const notifications = await KleverApi.Lists.getSortedListBatch({
            communityId,
            token,
          });

          res.lists.forEach((list) => {
            list.notifications_counter =
              notifications.lists.find((el) => el._id === list.list_id)
                ?.notifications_counter || 0;
          });

          res.all_count = notifications.all_count;
        }

        return await res;
      },
      {
        fulfilled: (state, { payload: { lists, all_count } }) => {
          state.totalLists = all_count;
          state.lists = [
            ...lists
              .sort((a, b) => b.notifications_counter - a.notifications_counter)
              .sort((a, b) =>
                a.list_type === 'AI_decision_room'
                  ? -1
                  : b.list_type === 'AI_decision_room'
                  ? 1
                  : 0
              ),
          ];
        },
      }
    ),
    addList: create.reducer((state, { payload }) => {
      state.lists = [payload, ...state.lists];
    }),
    addCompanyToList: create.reducer(
      (state, { payload: { companyId, listId } }) => {
        state.lists = [
          ...state.lists?.map((list) => {
            if (list?.list_id === listId)
              return {
                ...list,
                list_items: [...list?.list_items, { company_id: companyId }],
              };
            return list;
          }),
        ];
      }
    ),
    setCurTabIdx: create.reducer((state, { payload }) => {
      state.curTabIdx = payload;
    }),
  }),
  selectors: {
    selectCurTabIdx: (sliceState) => sliceState.curTabIdx,
    selectLists: (sliceState) => sliceState.lists,
    selectTotalLists: (sliceState) => sliceState.totalLists,
    selectListsLoading: (sliceState) => sliceState.listsLoading,
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurTabIdx,
  fetchLists,
  updateLists,
  addList,
  addCompanyToList,
} = sidebarSlice.actions;

// Selector creators for each uniqe value inside state
export const {
  selectCurTabIdx,
  selectLists,
  selectTotalLists,
  selectListsLoading,
} = sidebarSlice.selectors;

export default sidebarSlice.reducer;
