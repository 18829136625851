import React from 'react';
import SVGLoader from '~imgs-new/loaders/loader.svg';

const DefaultLoader = ({ height = null, width = null }) => {
  return (
    <div className={'loader default margin-center'}>
      <SVGLoader height={height || 36} width={width || 36} />
    </div>
  );
};

export default DefaultLoader;
