import { createSliceWithThunks } from '../createSlice';
import KleverApi from '~lib/api/api';

const initialState = {
  list: {},
  listItems: [],
  selectedIds: [],
  listItemsLoading: false,
  decisionMakers: {},
  curSearchTxt: '',
  curSort: null,
  // isHeaderHidden: false,
  // listViewType: null,
  isMultiSelectionMode: false,
  isDropdownOpen: false,
  roomTabIdx: 0,
  listItemsLoaded: false,
  solutionAges: [],
  solutionSizes: [],
  myImpacts: [],
};

export const listViewSlice = createSliceWithThunks({
  name: 'listView',
  initialState,
  reducers: (create) => ({
    fetchListItems: create.asyncThunk(
      async ({ listId, communityId, token }) => {
        const res = await KleverApi.Lists.getListCompanies({
          listId,
          communityId,
          token,
        });

        return await res;
      },
      {
        pending: (state) => {
          state.listItemsLoading = true;
        },
        rejected: (state) => {
          state.listItemsLoading = false;
        },
        fulfilled: (
          state,
          {
            payload: {
              companyList = [],
              decision_makers = [],
              solution_sizes = [],
              solution_ages = [],
              my_impacts = [],
            },
          }
        ) => {
          state.listItemsLoading = false;
          state.decisionMakers = { ...decision_makers };
          state.listItems = [...companyList];
          state.solutionAges = [...solution_ages];
          state.solutionSizes = [...solution_sizes];
          state.myImpacts = [...my_impacts];
          state.listItemsLoaded = true;
        },
      }
    ),
    updateListItems: create.asyncThunk(
      async ({ communityId, listId, token }) => {
        const res = KleverApi.Lists.getListCompanies({
          communityId,
          listId,
          token,
        });
        return await res;
      },
      {
        fulfilled: (
          state,
          {
            payload: {
              companyList = [],
              decision_makers = [],
              solution_sizes = [],
              solution_ages = [],
              my_impacts = [],
            },
          }
        ) => {
          state.decisionMakers = { ...decision_makers };
          state.listItems = [...companyList];
          state.solutionAges = [...solution_ages];
          state.solutionSizes = [...solution_sizes];
          state.myImpacts = [...my_impacts];
        },
      }
    ),
    addListItem: create.reducer((state, { payload }) => {
      state.listItems = [...state.listItems, payload];
    }),
    removeListItems: create.reducer((state, { payload }) => {
      state.listItems = [
        ...state.listItems.filter((i) => !payload.includes(i.company_id)),
      ];
    }),
    setIsFavItem: create.reducer(
      (state, { payload: { companyIds, value } }) => {
        state.listItems = [
          ...state.listItems.map((i) =>
            companyIds.includes(i.company_id) ? { ...i, isFav: value } : i
          ),
        ];
      }
    ),
    clearListItems: create.reducer((state) => {
      state.listItems = [];
    }),
    updateListItemsWithKanabn: create.reducer((state, { payload }) => {
      state.listItems = [
        ...state.listItems.map((item) => ({
          ...item,
          column_name:
            payload.find((comp) => comp.company_id === item.company_id)
              ?.column_name || '',
        })),
      ];
    }),
    setCurSearchTxt: create.reducer((state, { payload }) => {
      state.curSearchTxt = payload;
    }),
    setCurSort: create.reducer((state, { payload }) => {
      state.curSort = { ...payload };
    }),
    // setIsHeaderHidden: create.reducer((state, { payload }) => {
    //   state.isHeaderHidden = payload;
    // }),
    // setListViewType: create.reducer((state, { payload }) => {
    //   state.listViewType = payload;
    // }),
    setIsMultiSelectionMode: create.reducer((state, { payload }) => {
      state.isMultiSelectionMode = payload;
    }),
    setSelectedIds: create.reducer((state, { payload }) => {
      state.selectedIds = payload;
    }),
    setList: create.reducer((state, { payload }) => {
      state.list = { ...payload };
    }),
    clearList: create.reducer((state) => {
      state.list = {};
    }),
    clearListItemsLoaded: create.reducer((state) => {
      state.listItemsLoaded = false;
    }),
    setIsDropdownOpen: create.reducer((state, { payload }) => {
      state.isDropdownOpen = payload;
    }),
    setRoomTabIdx: create.reducer((state, { payload }) => {
      state.roomTabIdx = payload;
    }),
  }),
  selectors: {
    selectListItemsLoading: (sliceState) => sliceState.listItemsLoading,
    selectListItems: (sliceState) => sliceState.listItems,
    selectDecisionMakers: (sliceState) => sliceState.decisionMakers,
    selectCurSearchTxt: (sliceState) => sliceState.curSearchTxt,
    selectCurSort: (sliceState) => sliceState.curSort,
    // selectIsHeaderHidden: (sliceState) => sliceState.isHeaderHidden,
    // selectListViewType: (sliceState) => sliceState.listViewType,
    selectIsMultiSelectionMode: (sliceState) => sliceState.isMultiSelectionMode,
    selectSelectedIds: (sliceState) => sliceState.selectedIds,
    selectList: (sliceState) => sliceState.list,
    selectIsDropdownOpen: (sliceState) => sliceState.isDropdownOpen,
    selectRoomTabIdx: (sliceState) => sliceState.roomTabIdx,
    selectListItemsLoaded: (sliceState) => sliceState.listItemsLoaded,
    selectMyImpacts: (sliceState) => sliceState.myImpacts,
    selectSolutionAges: (sliceState) => sliceState.solutionAges,
    selectSolutionSizes: (sliceState) => sliceState.solutionSizes,
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchListItems,
  updateListItems,
  updateListItemsWithKanabn,
  clearListItems,
  addListItem,
  setIsFavItem,
  removeListItems,
  setCurSearchTxt,
  setCurSort,
  // setIsHeaderHidden,
  // setListViewType,
  setIsMultiSelectionMode,
  setSelectedIds,
  setList,
  clearList,
  clearListItemsLoaded,
  setIsDropdownOpen,
  setRoomTabIdx,
} = listViewSlice.actions;

// Selector creators for each uniqe value inside state
export const {
  selectListItemsLoading,
  selectListItems,
  selectDecisionMakers,
  selectCurSearchTxt,
  selectCurSort,
  // selectIsHeaderHidden,
  // selectListViewType,
  selectIsMultiSelectionMode,
  selectSelectedIds,
  selectList,
  selectIsDropdownOpen,
  selectRoomTabIdx,
  selectListItemsLoaded,
  selectMyImpacts,
  selectSolutionAges,
  selectSolutionSizes,
} = listViewSlice.selectors;

export default listViewSlice.reducer;
