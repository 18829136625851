import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: '',
  isAfterEvaluation: false,
  antiBiasEnabled: true,
};

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setIsAfterEvaluation: (state, action) => {
      state.isAfterEvaluation = action.payload;
    },
    setAntiBiasEnabled: (state, { payload }) => {
      state.antiBiasEnabled = payload;
    },
  },
  selectors: {
    selectCurrentPage: (sliceState) => sliceState.currentPage,
    selectIsAfterEvaluation: (sliceState) => sliceState.isAfterEvaluation,
    selectAntiBiasEnabled: (sliceState) => sliceState.antiBiasEnabled,
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentPage,
  setIsAfterEvaluation,
  setAntiBiasEnabled,
} = appStateSlice.actions;

// Selector creators for each uniqe value inside state
export const {
  selectCurrentPage,
  selectIsAfterEvaluation,
  selectAntiBiasEnabled,
} = appStateSlice.selectors;

export default appStateSlice.reducer;
