import { attr, Model } from 'redux-orm';

import { genUniqueId } from '~lib/utils/strings';

/**
 * Communities model
 * @author Villi K.
 */
class Onboardings extends Model {
  static get modelName() {
    return 'Onboardings';
  }

  static get fields() {
    return {
      list: attr(),
      didLoad: attr(),
    };
  }

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param User
   * @returns {undefined}
   */
  static reducer(action, Onboardings, session) {
    if (!Onboardings) return;
    switch (action.type) {
      case 'CREATE_ONBOARDINGS_DB':
        // Onboardings.delete();

        Onboardings.create({
          list: [],
          didLoad: false,
        });
        break;
      case 'SET_ONBOARDINGS': {
        const onboardingDB = Onboardings.last();

        const { userOnboardings = {} } = action.payload;

        if (!onboardingDB) {
          Onboardings.create({
            list: userOnboardings,
            didLoad: true,
          });
          return;
        } else {
          onboardingDB.update({
            didLoad: true,
            list: userOnboardings,
          });
          // onboardingDB.didLoad = true;
          // onboardingDB.list = userOnboardings;
        }

        break;
      }
      case 'INSERT_ONBOARDING': {
        const onboardingDB = Onboardings.last();
        if (!onboardingDB) return;

        const { onboardingData } = action.payload;
        if (!onboardingData || !onboardingDB) return;

        onboardingDB.didLoad = true;

        onboardingDB.update({
          list: [...(onboardingDB.list || []), onboardingData],
        });
        break;
      }
      case 'UPDATE_ONBOARDING': {
        const onboardingDB = Onboardings.last();
        if (!onboardingDB) return;

        const { requestId, delta } = action.payload;

        if (!requestId || !onboardingDB || !onboardingDB.list || !delta) return;

        const curOnboarding = onboardingDB.list.findIndex(
          (i) => i.request_id === requestId
        );

        if (curOnboarding === -1) return;

        onboardingDB.list[curOnboarding] = {
          ...onboardingDB.list[curOnboarding],
          ...delta,
        };

        onboardingDB.update({
          list: [...onboardingDB.list],
        });

        break;
      }
      case 'REMOVE_ONBOARDINGS': {
        break;
      }
      case 'DELETE_ONBOARDINGS_DB':
        try {
          Onboardings.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Onboardings };
export default Onboardings;
