import { attr, Model } from 'redux-orm';

/**
 * Forms model
 * @author Bohdan L.
 */
class Forms extends Model {
  static get modelName() {
    return 'Forms';
  }

  static get fields() {
    return {
      forms_list: attr(),
      categories: attr(),
      questions: attr(),
      didLoad: attr(),
    };
  }

  static reducer(action, Forms, session) {
    if (!Forms) return;
    switch (action.type) {
      case 'CREATE_FORMS_DB':
        Forms.create({
          forms_list: {},
          categories: {},
          questions: [],
          didLoad: false,
        });
        break;
      case 'SET_FORMS': {
        let formsDB = Forms.last();

        if (!formsDB) {
          Forms.create({
            forms_list: {},
            categories: {},
            questions: [],
            didLoad: false,
          });

          formsDB = Forms.last();
        }

        const { forms = {}, categories = {}, questions = [] } = action.payload;

        formsDB.update({
          forms_list: forms,
          categories,
          questions,
          didLoad: true,
        });
        break;
      }
      case 'INSERT_FORMS': {
        const formsDB = Forms.last();
        const { forms } = action.payload;
        if (!forms) return;

        if (!formsDB.forms_list) return;

        formsDB.update({
          forms_list: { ...forms, ...formsDB.forms_list },
        });
        break;
      }
      case 'ADD_FORM': {
        const formsDB = Forms.last();
        const { formId, formData } = action.payload;
        if (!formId || !formData || !formsDB.forms_list) return;

        formsDB.update({
          forms_list: { ...formsDB.forms_list, [formId]: formData },
        });
        break;
      }
      case 'REMOVE_FORM': {
        const formsDB = Forms.last();
        const { formId } = action.payload;
        if (!formId || !formsDB.forms_list) return;

        const newFormsDB = { ...formsDB.forms_list };

        delete newFormsDB[formId];

        formsDB.update({
          forms_list: { ...newFormsDB },
        });
        break;
      }
      case 'UPDATE_FORM': {
        const formsDB = Forms.last();
        const { formId, delta } = action.payload;
        if (!formId || !delta || !formsDB.forms_list) return;

        const form = formsDB.forms_list[formId];
        const newForm = { ...form, ...delta };

        formsDB.forms_list[formId] = newForm;

        formsDB.update();
        break;
      }
      case 'ADD_QUESTION': {
        const formsDB = Forms.last();
        const { question } = action.payload;

        if (!question || !formsDB) return;

        formsDB.update({
          questions: {
            ...(formsDB.questions || {}),
            [question.question_id]: question,
          },
        });
        break;
      }
      case 'UPDATE_QUESTION': {
        const formsDB = Forms.last();
        const { qId, delta } = action.payload;

        if (!qId || !delta || !formsDB.questions) return;

        const question = formsDB.questions[qId];
        const newQuestion = { ...question, ...delta };

        formsDB.update({
          questions: {
            ...(formsDB.questions || {}),
            [qId]: newQuestion,
          },
        });
        break;
      }
      case 'DELETE_QUESTION': {
        const formsDB = Forms.last();

        const { qId } = action.payload;
        if (!qId || !formsDB.questions) return;

        const newQuestionsDB = { ...formsDB.questions };

        delete newQuestionsDB[qId];

        formsDB.update({
          questions: newQuestionsDB,
        });
        break;
      }
      case 'DELETE_FORMS_DB':
        try {
          Forms.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Forms };
export default Forms;
