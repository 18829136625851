import { attr, Model } from 'redux-orm';
import { isNil } from 'lodash';

import ListsHelpers from '~app/helpers/ListsHelpers';

/**
 * Lists user model
 * @author Bohdan L.
 */
class Lists extends Model {
  static get modelName() {
    return 'Lists';
  }

  static get fields() {
    return {
      lists: attr(),
      active_board_id: attr(),
      didLoad: attr(),
      needUpdateListDataPoints: attr(),
      communityListCount: attr(),
    };
  }

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param User
   * @param session
   * @returns {undefined}
   */

  static reducer(action, Lists, session) {
    if (!Lists) return;
    switch (action.type) {
      case 'CREATE_LISTS_DB':
        Lists.create({
          lists: {},
          didLoad: false,
          needUpdateListDataPoints: false,
          communityListCount: 0,
        });
        break;
      case 'SET_COMMUNITY_LIST_COUNT': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { communityListCount } = action.payload;

        mainLists.communityListCount = communityListCount;
        mainLists.update();

        break;
      }
      case 'UPDATE_LISTS_DATA_POINTS': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { needUpdate } = action.payload;

        mainLists.needUpdateListDataPoints = needUpdate;
        mainLists.update();

        break;
      }
      case 'SET_LISTS_DATA': {
        let mainLists = Lists.last();

        if (!mainLists) {
          Lists.create({
            lists: {},
            didLoad: false,
          });
          mainLists = Lists.last();
        }

        const { listsData } = action.payload;

        if (listsData && Array.isArray(listsData)) {
          mainLists.lists = listsData.reduce((acc, val) => {
            acc[val.list_id] = val;
            return acc;
          }, {});
        }

        mainLists.didLoad = true;
        mainLists.update();

        break;
      }
      case 'ADD_LIST': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { listData } = action.payload;

        if (listData) {
          mainLists.lists[listData.list_id] = listData;
        }

        mainLists.didLoad = true;
        mainLists.update();

        break;
      }
      case 'ADD_ITEM': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { item, listId } = action.payload;

        if (item && listId && mainLists.lists[listId]) {
          mainLists.lists[listId].list_items = [
            ...mainLists.lists[listId].list_items,
            item,
          ];
          mainLists.update();
        }

        break;
      }
      case 'ADD_LIST_ITEMS': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { items, listId } = action.payload;

        if (!mainLists.lists[listId]) return;

        if (Array.isArray(items)) {
          items.forEach((i) => {
            if (
              !mainLists.lists[listId].list_items.find(
                (a) => a.company_id === i.company_id
              )
            )
              mainLists.lists[listId].list_items.push(i);
          });
        }

        mainLists.update();

        break;
      }
      case 'EDIT_LIST': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { listData, listId } = action.payload;

        if (listData && listId && mainLists.lists[listId]) {
          const newListData = {
            ...(mainLists?.lists?.[listId] || {}),
            ...listData,
          };

          mainLists.update({
            lists: { ...mainLists.lists, [listId]: newListData },
          });
        }

        break;
      }
      case 'EDIT_LIST_CATEGORY': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { listId, categoryId, category } = action.payload;

        if (listId && categoryId && category && mainLists.lists[listId]) {
          const newListData = {
            ...(mainLists?.lists?.[listId] || {}),
          };

          const catIdx = newListData?.list_categories?.findIndex(
            (i) => i.category_id === categoryId
          );

          if (!isNil(catIdx) && catIdx !== -1) {
            newListData.list_categories[catIdx] = category;
          }

          mainLists.update({
            lists: { ...mainLists.lists, [listId]: newListData },
          });
        }

        break;
      }
      case 'UPDATE_LIST_CATEGORIES': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { listId, categories } = action.payload;

        if (listId && categories) {
          const newListData = {
            ...(mainLists?.lists?.[listId] || {}),
            list_categories: categories,
          };

          mainLists.update({
            lists: { ...mainLists.lists, [listId]: newListData },
          });
        }

        break;
      }
      case 'DELETE_LIST_CATEGORY': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { listId, categoryId } = action.payload;

        if (listId && categoryId && mainLists.lists?.[listId]) {
          const newListData = {
            ...(mainLists?.lists?.[listId] || {}),
          };

          const curListCategories = newListData.list_categories || [];

          const curCategory = curListCategories.find(
            (i) => i.category_id === categoryId
          );

          const newListCategories = curListCategories.filter(
            (i) => i.category_id !== categoryId
          );

          const newListItems = [
            ...(newListData.list_items || []),
            ...(curCategory.category_items || []),
          ];

          mainLists.update({
            lists: {
              ...mainLists.lists,
              [listId]: {
                ...newListData,
                list_items: newListItems,
                list_categories: newListCategories,
              },
            },
          });
        }

        break;
      }
      case 'SET_LIST_GENERATED': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { listId, isGenerated } = action.payload;
        if (!listId) return;

        if (!mainLists.lists[listId]) return;

        mainLists.lists[listId].is_list_generated = isGenerated;

        mainLists.update();

        break;
      }
      case 'ADD_LIST_ITEM': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { listId, itemData } = action.payload;
        if (!listId || !itemData || !itemData.company_id) return;

        const curList = mainLists.lists?.[listId];

        if (curList) {
          const newListItems = [...(curList.list_items || []), itemData];

          mainLists.update({
            lists: {
              ...mainLists.lists,
              [listId]: { ...curList, list_items: newListItems },
            },
          });
        }

        break;
      }
      case 'ADD_MULTI_SHARED_WITH': {
        const mainLists = Lists.last();

        const { lists, member } = action.payload;
        if (!lists || !member || !mainLists) return;

        lists.forEach((id) => {
          if (
            !mainLists.lists[id] ||
            !mainLists.lists[id].shared_with ||
            mainLists.lists[id].shared_with.find(
              (i) => i.user_id === member.user_id
            )
          )
            return;

          mainLists.lists[id].shared_with.push(member);
        });

        mainLists.update({
          lists: { ...mainLists.lists },
        });

        break;
      }
      case 'REMOVE_LIST_ITEM': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { listId, companyId } = action.payload;
        if (!listId || !companyId) return;

        if (!mainLists.lists[listId]) return;

        const curListCopy = { ...mainLists.lists[listId] };

        curListCopy.list_items = curListCopy.list_items.filter(
          (i) => i.company_id !== companyId
        );

        mainLists.update({
          lists: { ...mainLists.lists, [listId]: curListCopy },
        });

        break;
      }
      case 'UPDATE_LISTS_POSITIONS': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { posArr } = action.payload;

        const listsCpy = { ...(mainLists.lists || {}) };

        (posArr || []).some((i) => {
          const curList = listsCpy[i.list_id];
          if (i.list_id && curList) {
            curList.list_position = i.list_position;
          }

          return false;
        });

        mainLists.update({
          lists: listsCpy,
        });

        break;
      }

      case 'DELETE_LIST': {
        const mainLists = Lists.last();
        if (!mainLists) return;

        const { listId } = action.payload;

        if (listId && mainLists.lists[listId]) {
          delete mainLists.lists[listId];
          mainLists.update();
        }

        break;
      }
      case 'REMOVE_LISTS_DB':
        try {
          Lists.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export default Lists;
export { Lists };
