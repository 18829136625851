import { attr, Model } from 'redux-orm';

/**
 * Klever app model
 * @author Villi K.
 */
class App extends Model {
  static get modelName() {
    return 'App';
  }

  static get fields() {
    return {
      cdn: attr(),
      inboundEmail: attr(),
      platformVersion: attr(),
      apiVersion: attr(),
      appVersionsData: attr(),
    };
  }

  static reducer(action, App, session) {
    if (!App) return;
    switch (action.type) {
      case 'CREATE_APP_DB': {
        // App.delete();

        App.create({
          cdn: '',
          inboundEmail: '',
          platformVersion: '',
          apiVersion: '',
          appVersionsData: '',
        });
        break;
      }
      case 'SET_APP_SETTINGS': {
        const app = App.last();
        if (!app) return;

        const { lastSync } = action.payload;

        app.lastSyncedAt = lastSync || Date.now();
        app.update();
        break;
      }
      case 'DELETE_APP_DB': {
        try {
          App.delete();
        } catch (err) {
          console.log(err);
        }
        break;
      }
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.appVersion}`;
  }
}

export default App;
export { App };
