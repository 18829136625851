import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';
class ApiStartup {
  static async getStartupData(token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/startup/get`, {
        signal: KLAbortController.genController('getStartupData').signal,
        method: 'GET',
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('getStartupData');

      window.last_request_time = Date.now();

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async getStartupStats(token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/startup/stats`, {
        signal: KLAbortController.genController('getStartupStats').signal,
        method: 'GET',
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('getStartupStats');

      window.last_request_time = Date.now();

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async getStartupNotifications(token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/notifications/startup/get`,
        {
          signal: KLAbortController.genController('getStartupNotifications')
            .signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getStartupNotifications');

      window.last_request_time = Date.now();

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async updateStartupData(companyId, companyData, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/startup/edit`, {
        signal: KLAbortController.genController('updateStartupData').signal,
        method: 'POST',
        body: JSON.stringify({ companyId, companyData }),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('updateStartupData');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async activeStartupAccount(companyId, companyData, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/startup/activate`, {
        signal: KLAbortController.genController('activeStartupAccount').signal,
        method: 'POST',
        body: JSON.stringify({ companyId, companyData }),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('activeStartupAccount');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async getStartupFeedbacks(token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/startup/feedbacks`, {
        signal: KLAbortController.genController('getStartupFeedbacks').signal,
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('getStartupFeedbacks');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async getStartupContactRequests(token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/startup/contact_requests`,
        {
          signal: KLAbortController.genController('getStartupContactRequests')
            .signal,
          method: 'POST',
          body: JSON.stringify({}),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getStartupContactRequests');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async getStartupLeads(token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/startup/leads`, {
        signal: KLAbortController.genController('getStartupLeads').signal,
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('getStartupLeads');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }
}

export default ApiStartup;
