// @flow
// $FlowFixMe
import * as Sentry from '@sentry/react';
// $FlowFixMe
import { Integrations } from '@sentry/tracing';
// $FlowFixMe
import 'rc-slider/assets/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
// $FlowFixMe
import { Provider } from 'react-redux';
// $FlowFixMe
import { BrowserRouter } from 'react-router-dom';
// $FlowFixMe
// $FlowFixMe
import KleverClient from './app/index.jsx';
import Store from './store/store';
// $FlowFixMe
import WebFont from 'webfontloader';

import * as serviceWorker from './lib/service-worker/serviceWorker';

// $FlowFixMe
import FullStory from 'react-fullstory';

// $FlowFixMe
require('../css/style.scss');
// const history = createBrowserHistory();

// $FlowFixMe
if (!__DEVELOPMENT__) {
  // $FlowFixMe
  const sentryConfig = {
    // $FlowFixMe
    dsn: __SENTRY__,
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // $FlowFixMe
    release: __VERSION__,
    // $FlowFixMe
    environment: __DEVELOPMENT__ ? 'dev' : 'prod',
  };

  // if (__DEVELOPMENT__) {
  //   // $FlowFixMe
  //   sentryConfig.defaultIntegrations = false;
  // }

  Sentry.init(sentryConfig);
}

const appStore = Store();
const ORG_ID =
  'MTY5RFdFL3ZpbGxpQGtsZXZlcmNvbXBhbnkuY29tOkfqDWdkLloT7nKHVUSXoPi4wO7jpXTWWjKh/0WQ/x2r/MFW28TP';

try {
  const app = () => {
    ReactDOM.render(
      <Provider store={appStore.store}>
        {/* <PersistGate
          loading={<div>Loading KLEVER Platform</div>}
          persistor={appStore.persistor}
        > */}
        <BrowserRouter basename='/'>
          <FullStory org={ORG_ID} />
          <KleverClient />
        </BrowserRouter>
        {/* </PersistGate> */}
      </Provider>,
      document.getElementById('body')
    );
  };

  const webFontConfig = {
    google: {
      families: ['Roboto', 'Lato', 'Montserrat'],
    },
    custom: {
      families: ['Material Icons'],
      urls: [
        'https://fonts.googleapis.com/icon?family=Material+Icons',
        '/fonts/URW - URW DIN.ttf',
        '/fonts/URW - URW DIN Demi.ttf',
      ],
    },
    classes: false,
    timeout: 1000,
    active: app,
  };

  WebFont.load(webFontConfig);
  serviceWorker.register();
} catch (err) {
  console.log(err);
}
