import { attr, Model } from 'redux-orm';
import { unescapeString } from '~lib/utils/strings';

/**
 * CompanyUpdates model
 * @author Villi K.
 */
class CompanyUpdates extends Model {
  static get modelName() {
    return 'CompanyUpdates';
  }

  static get fields() {
    return {
      company_updates: attr(),
      didLoad: attr(),
    };
  }

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param User
   * @param session
   * @returns {undefined}
   */
  static reducer(action, CompanyUpdates, session) {
    if (!CompanyUpdates) return;
    switch (action.type) {
      case 'CREATE_COMPANY_UPDATES':
        CompanyUpdates.create({
          company_updates: [],
          didLoad: true,
        });
        break;

      case 'SYNC_COMPANY_UPDATES': {
        const mainCompanyUpdates = CompanyUpdates.last();
        if (!mainCompanyUpdates) return;

        const { updates } = action.payload;

        if (!updates) return;

        mainCompanyUpdates.update({
          company_updates: updates,
        });

        break;
      }

      case 'DELETE_COMPANY_UPDATES': {
        const mainCompanyUpdates = CompanyUpdates.last();

        const { companyId, listId } = action.payload;

        if (
          !companyId ||
          !mainCompanyUpdates ||
          !mainCompanyUpdates.company_updates
        )
          return;

        if (
          Array.isArray(mainCompanyUpdates.company_updates) &&
          mainCompanyUpdates.company_updates.some(
            (i) => i.company_id === companyId
          )
        ) {
          const updates = mainCompanyUpdates.company_updates.filter((i) =>
            i.company_id === companyId && i.list_id === listId ? false : true
          );

          mainCompanyUpdates.update({
            company_updates: updates,
          });
        }

        break;
      }
      case 'ADD_COMPANY_UPDATE': {
        const mainCompanyUpdates = CompanyUpdates.last();
        if (!mainCompanyUpdates) return;

        const { companyId, listId, data } = action.payload;

        const newUpdate =
          listId === 'network'
            ? {
                ...data,
                company_id: companyId,
              }
            : {
                ...data,
                list_id: listId,
                company_id: companyId,
              };

        const updates = [
          ...(mainCompanyUpdates.company_updates || []),
          newUpdate,
        ];

        mainCompanyUpdates.update({
          company_updates: updates,
        });

        break;
      }
      case 'MODIFY_COMPANY_UPDATE': {
        const mainCompanyUpdates = CompanyUpdates.last();

        if (!mainCompanyUpdates) return;

        const { companyId, listId, newData } = action.payload;

        const currentCompany = mainCompanyUpdates.company_updates.find(
          (i) => i.company_id === companyId && i.list_id === listId
        );

        if (!currentCompany) return;

        const updates = [
          ...(mainCompanyUpdates.company_updates || []),
          {
            ...currentCompany,
            ...newData,
          },
        ];

        mainCompanyUpdates.update({
          company_updates: updates,
        });

        break;
      }

      case 'REMOVE_COMPANY_UPDATES':
        try {
          CompanyUpdates.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export default CompanyUpdates;
export { CompanyUpdates };
