import React from 'react';
// import AnalyticsHelpers from '../helpers/AnalyticsHelpers';

const connectAnalytics = (key, userId) => {
  // const _analyticsHelpers = new AnalyticsHelpers(key, userId);

  return (Component) => {
    if (!Component.bind) return Component;
    const TComponent = Component;
    TComponent.defaultProps = {
      ...TComponent.props,
      analytics: {
        sendEvent: () => {},
        trackPage: () => {},
      },
    };
    return TComponent;
    // return Component.bind(null, { analytics: _analyticsHelpers });
  };
};

const loadAnalytics = (key, userId) => {
  // const _analyticsHelpers = new AnalyticsHelpers(key, userId);
  // return _analyticsHelpers;
};

export default connectAnalytics;
export { loadAnalytics };
