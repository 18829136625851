import { isNil } from '~lib/utils/lodash';

class KanbanHelpers {
  static defaultBoardName = 'favourites';

  static KanbanColumnType = {
    KLEVER_COLUMN: 'KLEVER_COLUMN',
    CUSTOM_COLUMN: 'CUSTOM_COLUMN',
  };

  static defaultBoard = {
    board_id: null,
    community_id: null,
    user_id: null,
    project_id: null,
    list_id: null,
    board_name: null,
    columns: [
      'Added',
      'Evaluated',
      // 'Shared',
      'Request Info',
      // 'Contacted',
      // 'Decision',
      'No Fit',
      'Selected',
    ].map((i, idx) => {
      return {
        column_id: idx.toString(),
        column_type: KanbanHelpers.KanbanColumnType.KLEVER_COLUMN,
        column_name: i.toLowerCase().replace(' ', '_'),
        column_title: i,
        companies: [],
        icon: idx,
        is_klever_column: true,
        is_editable: false,
        is_main_column: false,
        column_pos: idx,
        column_config: { main_column_id: '0' },
        column_priority: idx,
      };
    }),
    tags: [],
    is_public: null,
    shared_with: [],
    updated_at: Date.now(),
    created_at: Date.now(),
  };

  static boardHasItems(board, items) {
    const result = items.filter(
      (id) =>
        board &&
        !board.some((col) =>
          col.companies.map((i) => i.company_id).includes(id)
        )
    );
    return result;
  }

  static getKanbanData(data) {
    try {
    } catch (err) {
      console.log(err);
      return 0;
    }
  }

  static kanbanHasChange(original, updated) {
    const originalMap = original.reduce((acc, val) => {
      acc[val.column_id] = val;
      return acc;
    }, {});
    const updatedMap = updated.reduce((acc, val) => {
      acc[val.column_id] = val;
      return acc;
    }, {});
    return Object.values(originalMap).every((val) =>
      Object.keys(val).every((i) => {
        const type = typeof i;
        let isSame = true;
        switch (type) {
          default:
          case 'number':
          case 'string': {
            isSame = updatedMap[i] === val[i];
            break;
          }
          case 'object': {
            const isArray = Array.isArray(val[i]);
            if (isArray) isSame = updatedMap[i].length === val[i].length;
            break;
          }
        }
        return isSame;
      })
    );
  }

  static COLUMN_TYPE = {
    0: {
      companies: [],
      icon: { thumb: '/imgs_new/menu_icons/portfolio.svg' },
    },
    1: {
      companies: [],
      icon: { thumb: '/imgs_new/actions/evaluate.svg' },
    },
    2: {
      companies: [],
      icon: { thumb: '/imgs_new/actions/import-board-column.svg' },
    },
    3: {
      companies: [],
      icon: { thumb: '/imgs_new/menu_icons/bolt.svg' },
      is_fixed: true,
      decision_type: 'bad',
    },
    4: {
      companies: [],
      icon: { thumb: '/imgs_new/menu_icons/bolt.svg' },
      is_fixed: true,
      decision_type: 'good',
    },
  };

  static createCompanies = (category) => {
    if (!category) return [];

    const comps = Array.isArray(category)
      ? category
      : KanbanHelpers.sortItemsByDate(category);

    return comps.map((comp, idx) => {
      return {
        company_id: comp.id,
        item_pos: idx,
        item_moved: false,
        is_automated: true,
        is_new: comp.is_new,
        date: comp.date,
      };
    });
  };

  static sanitizePortfolioObj = (obj) => {
    return Object.keys(obj).reduce((acc, val) => {
      acc[val] = {
        ...obj[val],
        id: val,
      };
      return acc;
    }, {});
  };

  static sortCategoryCompanies = ({
    col,
    key,
    entityPool,
    evaluations,
    portfolios,
    filterIds,
    listId,
    projectId,
  }) => {
    const movedCompanies = col.companies.filter(
      (i) => i.item_moved && filterIds.includes(i.company_id)
    );
    col.companies = col.companies.filter(
      (i) => !i.item_moved && filterIds.includes(i.company_id)
    );

    const staticCompanies = movedCompanies.concat(col.companies);

    staticCompanies.sort((a, b) => a.item_pos - b.item_pos);

    switch (key) {
      case 'stashed':
      case 'added': {
        col.companies = [
          ...staticCompanies,
          ...KanbanHelpers.createCompanies(
            KanbanHelpers.sanitizePortfolioObj(
              filterIds.reduce((acc, val) => {
                acc[val] = {};
                return acc;
              }, {})
            )
          ).filter(
            (i) =>
              !col.companies.find((item) => item.company_id === i.company_id) &&
              !movedCompanies.find(
                (item) => item.company_id === i.company_id
              ) &&
              filterIds.includes(i.company_id)
          ),
        ];
        break;
      }
      case 'evaluated': {
        col.companies = [
          ...staticCompanies,
          ...(evaluations
            ? Object.values(evaluations || {})
                .filter((i) => {
                  if (projectId) {
                    return (
                      i &&
                      i.status === 'completed' &&
                      i.project_id === projectId
                    );
                  } else if (listId) {
                    return (
                      i && i.status === 'completed' && i.list_id === listId
                    );
                  }
                  return i && i.status === 'completed';
                })
                .reduce((acc, val) => {
                  val.company_id_list.forEach((i) => {
                    if (!acc.includes(i)) {
                      acc.push(i);
                    }
                  });
                  return acc;
                }, [])
                .map((i) => entityPool.startups?.[i])
                .filter((i) => i)
                .map((i, idx) => {
                  return {
                    company_id: i.company_id,
                    item_pos: idx,
                    item_moved: false,
                    is_automated: true,
                    is_new: isNil(i.is_new) ? true : i.is_new,
                    date: i.date || Date.now(),
                  };
                })
            : []
          ).filter(
            (i) =>
              !col.companies.find((item) => item.company_id === i.company_id) &&
              !movedCompanies.find(
                (item) => item.company_id === i.company_id
              ) &&
              filterIds.includes(i.company_id)
          ),
        ];
        break;
      }
      // case 'shared': {
      //   col.companies = [
      //     ...staticCompanies,
      //     ...(evaluations
      //       ? Object.values(evaluations || {})
      //           .filter((i) => {
      //             if (projectId) {
      //               return (
      //                 i &&
      //                 i.status === 'in-progress' &&
      //                 i.evaluation_type !== 'klever_share' &&
      //                 i.evaluation_type !== 'co_evaluation' &&
      //                 i.project_id === projectId
      //               );
      //             } else if (listId) {
      //               return (
      //                 i &&
      //                 i.status === 'in-progress' &&
      //                 i.evaluation_type !== 'klever_share' &&
      //                 i.evaluation_type !== 'co_evaluation' &&
      //                 i.list_id === listId
      //               );
      //             }
      //             return (
      //               i &&
      //               i.status === 'in-progress' &&
      //               i.evaluation_type !== 'klever_share' &&
      //               i.evaluation_type !== 'co_evaluation'
      //             );
      //           })
      //           .reduce((acc, val) => {
      //             val.company_id_list.forEach((i) => {
      //               if (!acc.includes(i)) {
      //                 acc.push(i);
      //               }
      //             });
      //             return acc;
      //           }, [])
      //           .map((i) => entityPool.startups?.[i])
      //           .filter((i) => i)
      //           .map((i, idx) => {
      //             return {
      //               company_id: i.company_id,
      //               item_pos: idx,
      //               item_moved: false,
      //               is_automated: true,
      //               is_new: isNil(i.is_new) ? true : i.is_new,
      //               date: i.date || Date.now(),
      //             };
      //           })
      //       : []
      //     ).filter(
      //       (i) =>
      //         !col.companies.find((item) => item.company_id === i.company_id) &&
      //         !movedCompanies.find(
      //           (item) => item.company_id === i.company_id
      //         ) &&
      //         filterIds.includes(i.company_id)
      //     ),
      //   ];
      //   break;
      // }
      // case 'contacted': {
      //   col.companies = [
      //     ...staticCompanies,
      //     ...KanbanHelpers.createCompanies(
      //       Object.keys(
      //         KanbanHelpers.sanitizePortfolioObj(portfolios.contacted)
      //       ).map((key) => {
      //         return { id: key };
      //       })
      //     ).filter(
      //       (i) =>
      //         !col.companies.find((item) => item.company_id === i.company_id) &&
      //         !movedCompanies.find(
      //           (item) => item.company_id === i.company_id
      //         ) &&
      //         filterIds.includes(i.company_id)
      //     ),
      //   ];
      //   break;
      // }
      case 'request_info': {
        col.companies = [
          ...staticCompanies,
          ...KanbanHelpers.createCompanies(
            Object.values(entityPool.startups || {})
              .filter((i) => i.is_requesting_info)
              .map((item) => {
                return { id: item.company_id };
              })
          ).filter(
            (i) =>
              !col.companies.find((item) => item.company_id === i.company_id) &&
              !movedCompanies.find(
                (item) => item.company_id === i.company_id
              ) &&
              filterIds.includes(i.company_id)
          ),
        ];
        break;
      }
      default: {
        col.companies = [...movedCompanies, ...col.companies];
        break;
      }
    }
  };

  static getCategoryCompanies = ({
    entityPool,
    evaluations,
    portfolios,
    columns,
    filterIds,
    listId,
    projectId,
    boardId,
  }) => {
    try {
      const portfolioKeys = [
        'added',
        'evaluated',
        'shared',
        'contacted',
        'request_info',
        'decision',
        'no_fit',
        'selected',
      ];

      if (!portfolios) return [];
      portfolioKeys.forEach((name, id) => {
        const col = columns.find(
          (i) =>
            i.column_name === name ||
            i.column_title.toLowerCase().replace(' ', '_') === name
        );
        if (col) {
          if (projectId) col.is_editable = true;

          KanbanHelpers.sortCategoryCompanies({
            col,
            key: name,
            entityPool,
            evaluations,
            portfolios,
            filterIds,
            listId,
            projectId,
          });
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  static sortItemsByDate = (itemsObj) => {
    if (!itemsObj) return [];

    const sortedPortfolio = Object.keys(itemsObj).filter(
      (i) => itemsObj[i].date
    );

    const noDateItems = Object.keys(itemsObj).filter((i) => !itemsObj[i].date);

    sortedPortfolio.sort((a, b) => itemsObj[b].date - itemsObj[a].date);

    const pool = [...sortedPortfolio, ...noDateItems].map((i) => {
      return {
        ...itemsObj[i],
        date: itemsObj[i].date || 0,
      };
    });

    pool.sort((a, b) => new Date(b.date) - new Date(a.date));

    return pool.filter((i) => i.id);
  };

  static priorityCompaniesSort = (
    boardCols,
    unchangeableItems = [],
    filterIds = null
  ) => {
    const colsCopy = [...boardCols].sort(
      (a, b) => a.column_priority - b.column_priority
    );

    const customCols = boardCols.filter((i) => !i.is_klever_column);

    const excludeCompanies = [
      ...Object.values(
        customCols.reduce((acc, val) => {
          val.companies.forEach((i) => {
            acc[i.company_id] = i;
          });
          return acc;
        }, {})
      ),
      ...unchangeableItems,
    ];

    boardCols.forEach((boardCol) =>
      boardCol.companies.forEach((i) =>
        i.item_moved ? excludeCompanies.push(i) : null
      )
    );

    colsCopy.forEach((copy, idx) => {
      if (!copy.is_klever_column) {
        boardCols[idx].companies = boardCols[idx].companies.filter(
          (customItem) => {
            if (filterIds) return filterIds.includes(customItem.company_id);
            return false;
          }
        );
        return;
      }

      const filteredCompanies = copy.companies.filter((comp) => {
        if (comp.item_moved) return true;

        for (let a = copy.column_priority + 1; a < colsCopy.length; a++) {
          const nextCol = colsCopy.find(
            (colum) => colum.column_priority && colum.column_priority === a
          );

          if (!nextCol) continue;

          const findDup = nextCol.companies.find((company) => {
            return company.company_id === comp.company_id;
          });

          const findExclude = excludeCompanies.find((company) => {
            return company.company_id === comp.company_id;
          });

          if (findDup || findExclude) return false;
        }

        return true;
      });

      const originalIdx = boardCols.findIndex(
        (colum) => colum.column_id === copy.column_id
      );

      if (originalIdx === -1) return;

      boardCols[originalIdx].companies = filteredCompanies;

      if (KanbanHelpers.isMainColumn(copy))
        boardCols[originalIdx].is_main_column = true;
    });
  };

  static companyExist = (columns, compId) => {
    return columns.find(
      (comp) => comp.companies && comp.companies.includes(compId)
    );
  };

  static refreshBoardData = ({
    portfolios,
    entityPool,
    evaluations,
    newBoard,
    filterIds,
    listId,
    projectId,
  }) => {
    try {
      if (!newBoard) return;

      const clearDupeCols = {};

      newBoard.columns.forEach((_, i) => {
        if (!newBoard.columns[i].companies) newBoard.columns[i].companies = [];
        clearDupeCols[newBoard.columns[i].column_id] = newBoard.columns[i];
      });

      newBoard.columns = Object.values(clearDupeCols);

      const unchangeableItems = newBoard.columns.reduce((acc, cur) => {
        acc = acc.concat(cur.companies.filter((i) => i.item_moved));
        return acc;
      }, []);

      KanbanHelpers.getCategoryCompanies({
        entityPool,
        evaluations,
        portfolios,
        columns: newBoard.columns,
        filterIds,
        listId,
        projectId,
        boardId: newBoard.board_id,
      });

      KanbanHelpers.priorityCompaniesSort(
        newBoard.columns || [],
        unchangeableItems,
        filterIds
      );

      // newBoard.columns.sort((a, b) => a.column_pos - b.column_pos);
    } catch (err) {
      console.error(err);
    }
  };

  static updateBoardColumns = (board) => {
    try {
      board = {
        ...board,
        columns: KanbanHelpers.setBoardColumns(board.columns),
      };
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  static setBoardColumns = (arr) => {
    if (!arr) return;
    return arr.map((i, indx) => {
      const kanbanSettings = KanbanHelpers.COLUMN_TYPE[i.column_id] || {
        companies: [],
        icon: { thumb: '/imgs_new/menu_icons/portfolio.svg' },
      };

      const companies = i.companies
        ? i.companies
        : kanbanSettings.companies || [];

      return {
        ...i,
        companies: companies,
        icon: kanbanSettings.icon || {
          thumb: '/imgs_new/icons/ai.png',
        },
        ...(kanbanSettings?.is_fixed && { is_fixed: kanbanSettings.is_fixed }),
        ...(kanbanSettings?.decision_type && {
          decision_type: kanbanSettings.decision_type,
        }),
      };
    });
  };

  static createNewBoard = ({
    boardId = null,
    listId = null,
    projectId = null,
    portfolios = {},
    entityPool = {},
    evaluations = {},
    list = {},
    boardCompanies = [],
  }) => {
    const newBoard = KanbanHelpers.buildKanbanData({
      boardList: {
        [KanbanHelpers.defaultBoardName]: KanbanHelpers.defaultBoard,
      },
    });

    const curBoard =
      newBoard[boardId] || newBoard[KanbanHelpers.defaultBoardName];

    if (curBoard) {
      KanbanHelpers.refreshBoardData({
        portfolios,
        entityPool,
        evaluations,
        newBoard: curBoard,
        filterIds: boardCompanies || [],
        listId,
        projectId,
      });

      curBoard.list_id = listId;
      curBoard.tags = list.tags;
      curBoard.board_name = list.list_title;

      return curBoard;
    }
  };

  static isMainColumn = (column) => {
    if (!column.column_config || !column.column_id) return false;
    return column.column_config.main_column_id === column.column_id;
  };

  static getMembersImageSetData = ({
    company = null,
    evaluators = [],
    community = {},
  }) => {
    try {
      const { memberships } = community;

      if (evaluators && company) {
        const list = Object.keys(evaluators || {})
          .map((i) => {
            if (!evaluators[i][company.company_id]) return null;

            return (
              memberships[i] || {
                email: i,
                is_external: true,
                img: GenerateRandomAvatar(1),
              }
            );
          })
          .filter((i) => i !== null && i !== undefined);

        return list;
      }

      return [];
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  static getBoardEvaluators = ({
    allCompanies = [],
    evaluations = {},
    listId = null,
    projectId = null,
  }) => {
    try {
      return Object.values(evaluations || {})
        .filter((i) => {
          if (projectId) {
            return i.project_id === projectId;
          } else if (listId) {
            return i.list_id === listId;
          }
          return true;
        })
        .reduce((acc, val) => {
          if (val.evaluations) {
            val.evaluations.forEach((i) => {
              Object.keys(i.decisions).forEach((a) => {
                if (allCompanies.indexOf(a) !== -1) {
                  acc[i.user_id || i.email] = {
                    ...(acc[i.user_id || i.email] || {}),
                    [a]: true,
                  };
                } else {
                  acc[i.user_id || i.email] = {
                    ...(acc[i.user_id || i.email] || {}),
                    [a]: true,
                  };
                }
              });
            });
          }
          return acc;
        }, {});
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  static getMatchesImageSet = ({
    listId = null,
    companyId = null,
    portfolios = {},
    community = {},
    list = {},
    user = {},
  }) => {
    if (!portfolios?.matches || !portfolios?.matches?.[companyId]) return [];

    const matches = listId
      ? portfolios?.matches?.[companyId]?.matches?.filter(
          (i) =>
            list.shared_with?.find((user) => user?.user_id === i.user_id) ||
            list.user_id === i.user_id
        ) || []
      : portfolios.matches?.[companyId]?.matches || [];

    const results = matches.reduce((acc, item) => {
      const member = community?.memberships?.[item.user_id];

      if (user) {
        acc.push({
          title: member?.first_name || 'Not found',
          img: member?.avatar.thumb || '',
          entityIdsMap: { userId: member?.user_id },
          type: 'member',
        });
      }

      return acc;
    }, []);

    return results;
  };

  static getColumnVendors = ({
    colId = '',
    listId = null,
    portfolios = {},
    list = {},
    user = {},
    companies = [],
    sorts = {},
    boardSorts = {},
    filters = {},
    entityPool = {},
    community = {},
    filterStartups = () => {},
    getSortedList = () => {},
  }) => {
    const sortFunc = sorts?.action || boardSorts?.action || null;

    const compsWithMatches = companies?.reduce((acc, val) => {
      const originalComp = entityPool.startups?.[val.company_id];

      if (originalComp) {
        acc.push({
          ...val,
          matches: KanbanHelpers.getMatchesImageSet({
            companyId: val.company_id,
            listId,
            portfolios,
            community,
            list,
            user,
          }),
        });
      }

      return acc;
    }, []);

    if (!filters && !sorts) return compsWithMatches;

    const communityVendors = compsWithMatches.map((i) => ({
      ...i,
      ...(entityPool.startups?.[i.company_id] || {}),
    }));

    const filteredComps = filterStartups(
      entityPool || {},
      filters || {},
      communityVendors
    ).filter((i) => i);

    const _customSort = sortFunc ? (arr) => sortFunc(arr, colId) : null;

    const result =
      getSortedList(entityPool || {}, filteredComps, sorts, _customSort) ||
      filteredComps ||
      [];

    return result.map((i, idx) => ({
      company_id: i.company_id,
      item_pos: idx,
      matches: i.matches,
    }));
  };

  static reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((i, idx) => ({ ...i, item_pos: idx }));
  };

  static move = (
    source,
    destination,
    droppableSource,
    droppableDestination,
    draggableId
  ) => {
    try {
      const sourceClone = Array.from(source);
      const destClone = Array.from(destination);
      const sourceItemId = Object.values(source).findIndex(
        (i) => i.company_id === draggableId
      );

      const [removed] = sourceClone.splice(sourceItemId, 1);

      destClone.splice(droppableDestination.index, 0, {
        ...removed,
        item_moved: true,
        is_automated: false,
      });

      const result = {};

      result[droppableSource.droppableId] = sourceClone.map((i, idx) => ({
        ...i,
        item_pos: idx,
      }));
      result[droppableDestination.droppableId] = destClone.map((i, idx) => ({
        ...i,
        item_pos: idx,
      }));

      return result;
    } catch (err) {
      console.error('Kanban move, error moving.');
      return {};
    }
  };

  static buildKanbanData = ({
    boardList = {},
    allStartups = {},
    portfolio_data = {},
    evaluations,
  }) => {
    try {
      const boardKeys = Object.keys(boardList);

      // const allIds = Object.keys(allStartups || {});

      const generatedBoardsColumns = boardKeys.reduce((acc, key, curIdx) => {
        return {
          ...acc,
          [boardKeys]: {
            ...boardList[key],
            board_name: key,
            columns: KanbanHelpers.setBoardColumns(boardList[key].columns),
          },
        };
      }, {});

      Object.keys(generatedBoardsColumns).forEach((boardKey) => {
        KanbanHelpers.priorityCompaniesSort(
          generatedBoardsColumns[boardKey].columns
        );
      });

      return generatedBoardsColumns;
    } catch (err) {
      console.error(err);
      return {};
    }
  };
}

export default KanbanHelpers;
