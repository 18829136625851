import { isNil, mean } from '~lib/utils/lodash';
import FormsHelper from '~app/helpers/FormsHelper';
import { zscore, GetZPercent, calcStdDev } from '~lib/utils/math';
import { capitalize } from '~lib/utils/strings';
import { EVALUATION_FORM_TYPES } from '~lib/constants';

const FACTORS_MAP = {
  ...FormsHelper.CategoryType,
  YES_NO: 'yes_no',
};

const FACTOR_TYPE = {
  MARKET: 2,
  SCALABILITY: 5,
  VISION: 3,
  TEAM: 4,
  PRODUCT: 1,
};

const DECISION_FACTOR_TYPE = {
  MARKET: 2,
  SCALABILITY: 5,
  MISSION: 3,
  VISION: 3,
  TEAM: 4,
  PRODUCT: 1,
};

const parseNum = (num, digits) => {
  return parseFloat((num || 0).toFixed(digits || 2));
};

const setEvaluationParticipantsDecisions = ({
  participants = [],
  ev = {},
  participantsWithDecisionsMap = {},
  memberships = null,
}) => {
  participants.forEach((p) => {
    const pId = (p.user_id || p.email).toLowerCase();

    const decisions = ev.evaluations.reduce((acc, i) => {
      if (i.user_id === pId || i.email === pId) {
        acc.push({
          ...i,
          issuer_id: ev.issuer_id,
          participants: ev.participants,
          external_participants: ev.external_participants,
          company_id_list: ev.company_id_list,
          evaluation_id: ev.evaluation_id,
          evaluation_form_type: ev.evaluation_form_type,
          status: ev.status,
          created_at: ev.created_at,
        });
      }

      return acc;
    }, []);

    if (!decisions.length) return true;

    const profile = (memberships && memberships[pId]) || null;

    participantsWithDecisionsMap[pId] = {
      is_external: isNil(p.user_id),
      email: p.email,
      user_id: p.user_id,
      profile,
      evaluation_id: ev.evaluation_id,
      evaluations: [
        ...(participantsWithDecisionsMap[pId]?.evaluations || []),
        ...decisions,
      ],
    };
  });
};

const mapEvaluationMembers = (companyId, evaluations, memberships) => {
  const stat_memberships = {};
  const external_stat_memberships = {};

  const evs = Array.isArray(evaluations)
    ? evaluations
    : Object.values(evaluations);

  const filteredEvs = companyId
    ? evs.filter(
        (item) =>
          item.company_id_list.includes(companyId) &&
          item.evaluations.find((c) => c.decisions[companyId] !== null)
      )
    : evs;

  filteredEvs.forEach((ev) => {
    setEvaluationParticipantsDecisions({
      participants: ev.participants,
      ev,
      participantsWithDecisionsMap: stat_memberships,
      memberships,
    });

    setEvaluationParticipantsDecisions({
      participants: ev.external_participants,
      ev,
      participantsWithDecisionsMap: external_stat_memberships,
    });
  });

  Object.keys(stat_memberships).forEach((i) => {
    stat_memberships[i].evaluations.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  });
  Object.keys(external_stat_memberships).forEach((i) => {
    external_stat_memberships[i].evaluations.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  });

  return {
    members: stat_memberships,
    external_members: external_stat_memberships,
  };
};

const getLastEvaluation = (companyId, evaluations) => {
  const list = evaluations.filter((item) =>
    item.company_id_list.includes(companyId)
  );
  const allEvaluations = list.reduce((val, next) => {
    return next.evaluations.reduce((v, n) => {
      if (n.decisions[companyId]) {
        v.push(n);
      }
      return v;
    }, []);
    // if(!val[next.])
  }, []);
  allEvaluations.sort((a, b) => b.created_at - a.created_at);
  return allEvaluations[0] || null;
};

const sortEvaluationsByTime = (companyId, evaluations) => {
  const list = evaluations.filter((item) =>
    item.company_id_list.includes(companyId)
  );
  const allEvaluations = list.reduce((val, next) => {
    return next.evaluations.reduce((v, n) => {
      if (n.decisions[companyId]) {
        v.push(n);
      }
      return v;
    }, []);
  }, []);
  allEvaluations.sort((a, b) => b.created_at - a.created_at);

  allEvaluations.forEach((i) => {});

  return allEvaluations[0] || null;
};

const getCommunityDataPoints = (
  evaluations,
  filterUser,
  companiesList,
  projectId,
  listId
) => {
  const members = {};
  if (!Object.values(evaluations || {}).length) return 0;

  Object.values(evaluations)
    .filter((i) =>
      projectId
        ? i.project_id === projectId
        : listId
        ? i.list_id === listId
        : true
    )

    .forEach((item) => {
      item.evaluations.forEach((e) => {
        const id = e.user_id || e.email;
        if (!members[id]) {
          if (filterUser) {
            if (id === filterUser) {
              if (companiesList && companiesList.length) {
                members[id] = {
                  count: Object.keys(e.decisions).filter((i) =>
                    companiesList.includes(i)
                  ).length,
                };
              } else {
                members[id] = {
                  count: Object.keys(e.decisions).length,
                };
              }
            }
          } else {
            if (companiesList && companiesList.length) {
              members[id] = {
                count: Object.keys(e.decisions).filter((i) =>
                  companiesList.includes(i)
                ).length,
              };
            } else {
              members[id] = {
                count: Object.keys(e.decisions).length,
              };
            }
          }
        } else {
          if (companiesList && companiesList.length) {
            members[id].count += Object.keys(e.decisions).filter((i) =>
              companiesList.includes(i)
            ).length;
          } else {
            members[id].count += Object.keys(e.decisions).length;
          }
        }
      });
    });
  return Object.values(members).reduce((val, cur) => {
    val += cur.count;
    return val;
  }, 0);
};

const getMemberEvaluationsMap = (userId, evaluations) => {
  const companies = {};
  const companiesEvs = {};

  const factors = [
    'market',
    'product',
    'scalability',
    'team',
    'vision',
    'yes_no',
  ];

  evaluations = Array.isArray(evaluations)
    ? evaluations.reduce((a, c) => {
        a[c.evaluation_id] = c;
        return a;
      }, {})
    : evaluations;

  if (!Object.values(evaluations || {}).length) return {};

  Object.values(evaluations).forEach((item) => {
    const userEvaluations = item.evaluations.find(
      (i) => i.user_id === userId || i.email === userId
    );
    if (userEvaluations) {
      Object.keys(userEvaluations.decisions).forEach((u) => {
        if (!companies[u]) {
          companies[u] = [
            {
              ...(getMemberTotalFormScores({
                companyId: u,
                ev: [userEvaluations],
              }) || {}),
              evaluation_id: item.evaluation_id,
              evaluation_form_type: item.evaluation_form_type,
            },
          ];
          companiesEvs[u] = [
            {
              ...userEvaluations,
              evaluation_id: item.evaluation_id,
              evaluation_form_type: item.evaluation_form_type,
            },
          ];
        } else {
          companies[u] = [
            ...companies[u],
            {
              ...(getMemberTotalFormScores({
                companyId: u,
                ev: [userEvaluations],
              }) || {}),
              evaluation_id: item.evaluation_id,
              evaluation_form_type: item.evaluation_form_type,
            },
          ];
          companiesEvs[u] = [
            ...companiesEvs[u],
            {
              ...userEvaluations,
              evaluation_id: item.evaluation_id,
              evaluation_form_type: item.evaluation_form_type,
            },
          ];
        }
      });
    }
  });

  const timeMap = [];

  const allValues = Object.values(companies).reduce((acc, val) => {
    acc = acc.concat(
      val.reduce((a, v) => {
        if (!v.total) {
          let allFactors = [];
          factors.forEach((i) => {
            if (v[i]) {
              allFactors = allFactors.concat(v[i].total.values);
            }
          });

          v.total = {
            total: {
              values: [
                allFactors.reduce((num, cur) => {
                  num += cur;
                  return num;
                }, 0) / allFactors.length,
              ],
            },
          };
        }

        a = a.concat(v.total.total?.values || []);
        return a;
      }, [])
    );
    return acc;
  }, []);

  const avg =
    allValues.reduce((a, v) => {
      a += v;
      return a;
    }, 0) / allValues.length;

  const std = calcStdDev(allValues).popStdDev;

  const newMap = Object.keys(companies).reduce((a, v) => {
    const values = companies[v][0];
    const userStd =
      values.total.total.values.length > 1
        ? calcStdDev(values.total.total.values).sampleVariation
        : 0;
    const zScore = (values.total.total.values[0] - avg) / std;

    companiesEvs[v].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );

    a[v] = {
      score: parseFloat(GetZPercent(zScore) * 100),
      std: userStd,
      factors: values,
      allEvaluations: companiesEvs[v],
    };
    return a;
  }, {});

  return newMap;
};

const getMemberEvaluations = (evaluations, userId) => {
  const members = {};
  if (!Object.values(evaluations || {}).length) return 0;

  Object.values(evaluations).forEach((item) => {
    item.evaluations.forEach((e) => {
      const id = e.user_id || e.email;
      if (id === userId) {
        if (!members[id]) {
          members[id] = {
            count: 1,
          };
        } else {
          members[id].count += 1;
        }
      }
    });
  });
  return Object.values(members).reduce((val, cur) => {
    val += cur.count;
    return val;
  }, 0);
};

const getMemberDataPoints = (evaluations, userId, companyId) => {
  const members = {};
  if (!Object.values(evaluations || {}).length) return 0;

  Object.values(evaluations)
    .filter((i) => i.evaluation_type !== 'klever_share')
    .forEach((item) => {
      item.evaluations.forEach((e) => {
        const id = e.user_id || e.email;
        if (id === userId) {
          if (!members[id]) {
            members[id] = {
              count: Object.keys(e.decisions).filter((x) => {
                if (companyId) {
                  return companyId === x;
                } else {
                  return true;
                }
              }).length,
            };
          } else {
            members[id].count += Object.keys(e.decisions).filter((x) => {
              if (companyId) {
                return companyId === x;
              } else {
                return true;
              }
            }).length;
          }
        }
      });
    });
  return Object.values(members).reduce((val, cur) => {
    val += cur.count;
    return val;
  }, 0);
};

const getMemberVsEcosystemEvaluationRatio = (evaluations, userId) => {
  const members = {};
  let total = 0;
  if (!Object.values(evaluations || {}).length) return 0;

  Object.values(evaluations).forEach((item) => {
    item.evaluations.forEach((e) => {
      const id = e.user_id || e.email;
      total += Object.keys(e.decisions).length;
      if (id === userId) {
        if (!members['member']) {
          members['member'] = {
            count: Object.keys(e.decisions).length,
          };
        } else {
          members['member'].count += Object.keys(e.decisions).length;
        }
      } else {
        if (!members['ecosystem']) {
          members['ecosystem'] = {
            count: Object.keys(e.decisions).length,
          };
        } else {
          members['ecosystem'].count += Object.keys(e.decisions).length;
        }
      }
    });
  });
  return {
    total,
    ...members,
  };
};
const createZsObj = (arr) => {
  if (!arr || !arr.length) {
    return {
      average: 0,
      std: 0,
      scores: arr,
      zscoreArr: [],
    };
  }
  const average =
    arr.reduce((v, n) => {
      v += n;
      return v;
    }, 0) / arr.length;

  if (arr.length === 1) {
    return {
      average: arr[0],
      std: 0,
      zscoreArr: [],
      scores: arr,
    };
  }
  const sd = calcStdDev(arr).sampleVariation;
  const scores = arr.length > 5 ? zscore(arr) : arr;
  return {
    average,
    std: sd,
    scores: arr,
    zscoreArr: scores.map((i) => {
      if (i === Infinity || isNaN(i)) {
        return 1;
      }

      return i;
    }),
  };
};

const getZScoredPercentage = (
  companyId,
  evaluations,
  filterUsers = null,
  filterDate = null,
  excludeUsers = [],
  projectId,
  listId
) => {
  let evs = Array.isArray(evaluations)
    ? evaluations.filter((i) => (projectId ? i.project_id === projectId : true))
    : Object.values(evaluations).filter((i) =>
        projectId ? i.project_id === projectId : true
      );

  evs = evs.filter((i) => (listId ? i.list_id === listId : true));

  const members = {};

  const membersMap = mapEvaluationMembers(companyId, evs);
  const usersMap = Object.values(membersMap).reduce((acc, val) => {
    Object.keys(val).forEach((i) => {
      if (filterUsers && filterUsers !== (val[i].user_id || val[i].email))
        return true;

      acc[i] = val[i];
    });
    return acc;
  }, {});

  const allEvaluations = Object.values(usersMap).reduce((acc, val) => {
    acc = acc.concat(val.evaluations);
    return acc;
  }, []);

  const statScores = allEvaluations.reduce((acc, val) => {
    if (val.decisions[companyId]) {
      const formScoreList = getMemberTotalFormScores({
        companyId,
        ev: [val],
      });

      const finScore = Object.values(formScoreList)
        .reduce((n, v) => {
          n.push(v.zscores.zscorePercentile || (v.total.value / 5) * 100);
          return n;
        }, [])
        .filter((i) => i && !isNaN(i));

      if (finScore.length) {
        acc.push(
          finScore.reduce((acc, val) => {
            acc += val;
            return acc;
          }, 0) / finScore.length
        );
      }
    }
    return acc;
  }, []);

  const formScoreList = getMemberTotalFormScores({
    companyId,
    ev: allEvaluations,
  });
  const total = formScoreList.total;
  delete formScoreList.total;

  const scores = Object.values(formScoreList || {}).reduce((n, v) => {
    n.push(v.zscores.zscorePercentile || (v.total.value / 5) * 100);
    return n;
  }, []);
  const totalScores = total
    ? [total].reduce((n, v) => {
        n.push(v.zscores.zscorePercentile || (v.total.value / 5) * 100);
        return n;
      }, [])
    : [];

  const allScores = [...scores, ...totalScores];

  const sc =
    allScores.reduce((acc, val) => {
      acc += val;
      return acc;
    }, 0) / allScores.length;
  const result = {
    value: isNaN(sc) ? 0 : parseFloat(sc),
    variation: allScores.length > 1 ? calcStdDev(allScores).sampleVariation : 0,
    stats: statScores,
  };

  return result;

  // revise bottom code

  Object.values(usersMap).forEach((e) => {
    const id = e.user_id || e.email;
    if (excludeUsers.includes(id) || (filterUsers && filterUsers !== id))
      return true;

    const userEvs = filterDate
      ? e.evaluations.filter(
          (i) => new Date(i.created_at) <= new Date(filterDate)
        )
      : e.evaluations;

    const formScores = getMemberTotalFormScores(companyId, userEvs);
    const formData = Object.values(formScores || {}).reduce((n, v) => {
      n.push(v.total.value);
      return n;
    }, []);
  });

  if (!evs.length)
    return {
      zScoreValue: 0,
      variation: 0,
    };

  evs.sort((a, b) => new Date(b.updated_at) - new Date(a.updated));

  evs.forEach((item) => {
    item.evaluations
      .filter((i) => {
        if (filterDate)
          return (
            new Date(i.created_at) <= new Date(filterDate) &&
            item.evaluation_form_type !== 'yes_no'
          );
        return item.evaluation_form_type !== 'yes_no';
      })
      .forEach((e) => {
        const id = e.user_id || e.email;
        if (excludeUsers.includes(id) || (filterUsers && filterUsers !== id))
          return true;

        if (e.decisions[companyId]) {
          if (members[id] && e.decisions !== undefined) {
            Object.keys(e.decisions || {}).forEach((d) => {
              if (e.user_id !== id) return true;

              if (!members[id].companies[d]) {
                members[id].companies[d] = {
                  values: [],
                  type: null,
                  original_values: [],
                };
              }
              members[id].companies[d].type = item.evaluation_form_type;

              if (
                item.evaluation_form_type === 'form' ||
                item.evaluation_form_type === 'digits'
              ) {
                const formData = Object.values(
                  getMemberTotalFormScores(companyId, [e]) || {}
                ).reduce((n, v) => {
                  n.push(v.total.value);
                  return n;
                }, []);

                if (!members[id].original_values) {
                  members[id].original_values = [];
                }
                members[id].original_values = members[
                  id
                ].original_values.concat(formData);

                members[id].companies[d].values = formData.length
                  ? [
                      ...members[id].companies[d].values,
                      formData.reduce((acc, val) => {
                        acc += val;
                        return acc;
                      }, 0) / formData.length,
                    ]
                  : [];
              }
            });
          } else {
            members[id] = {
              user_id: id,
              companies: {},
            };
            Object.keys(e.decisions || {}).forEach((d) => {
              if (d !== companyId) return true;
              if (!members[id].companies[d]) {
                members[id].companies[d] = {
                  values: [],
                  type: null,
                  original_values: [],
                };
              }
              members[id].companies[d].type = item.evaluation_form_type;

              if (
                item.evaluation_form_type === 'form' ||
                item.evaluation_form_type === 'digits'
              ) {
                const formData = Object.values(
                  getMemberTotalFormScores(companyId, [e]) || {}
                )
                  .reduce((n, v) => {
                    n.push(v.total.value);
                    return n;
                  }, [])
                  .filter((i) => i);

                if (!members[id].original_values) {
                  members[id].original_values = [];
                  members[id].companies[d].original_values = [];
                }
                members[id].original_values = members[
                  id
                ].original_values.concat(formData);

                members[id].companies[d].original_values = members[
                  id
                ].companies[d].original_values.concat(formData);

                members[id].companies[d].values = formData.length
                  ? [
                      ...members[id].companies[d].values,
                      formData.reduce((acc, val) => {
                        acc += val;
                        return acc;
                      }, 0) / formData.length,
                    ]
                  : [];
              }
            });
          }
        }
      });
  });

  Object.keys(members).forEach((m) => {
    Object.keys(members[m].companies || {}).forEach((c) => {
      if (!members[m].allRatingCombined2) {
        members[m].allRatingCombined2 = {};
      }
      if (!members[m].allRatingCombined2[c]) {
        members[m].allRatingCombined2[c] = { values: [], companyId: c };
      }

      members[m].allRatingCombined2[c] = {
        ...members[m].allRatingCombined2[c],
        values: [
          ...members[m].allRatingCombined2[c].values,
          ...members[m].companies[c].values,
        ],
      };
    });

    const stats = createZsObj(
      Object.values(members[m].allRatingCombined2 || {}).reduce((v, n) => {
        v = v.concat(n.values);
        return v;
      }, [])
    );

    if (!stats.scores.length) {
      delete members[m];
    } else {
      members[m] = {
        ...members[m],
        ...stats,
      };
    }
  });

  const compId = companyId;
  let curCompMembers = Object.values(members).filter(
    (item) => item.companies[compId]
  );

  if (filterUsers) {
    curCompMembers = curCompMembers.filter(
      (item) => item.user_id === filterUsers
    );
  }

  const curCompStats = curCompMembers.map((item) => {
    const org = Object.values(item.allRatingCombined2).find(
      (i) => i.companyId === companyId
    );
    const index = Object.values(item.allRatingCombined2).reduce(
      (val, next, i, arr) => {
        if (next.companyId === companyId) {
          arr.splice(1);
        }
        val += next.values.length;
        return val;
      },
      -1
    );

    if (item.scores.length > 1) {
      const t = [...item.scores].splice(index, index + org.values.length);
      const zArr = [...item.zscoreArr].splice(index, index + org.values.length);

      return {
        values: t,
        zValues: zArr,
      };
    }

    return {
      values: item.scores,
      zValues: item.zscoreArr,
    };
  });

  let stdList = [];
  curCompMembers.map((item) => {
    const score = item.original_values;
    if (score !== null && score !== undefined) {
      stdList = stdList.concat(score);
    }
  });

  const stats = curCompMembers.reduce((acc, val) => {
    const { values } = val.companies[companyId];
    if (values && values.length && values[values.length - 1]) {
      acc.push(values[values.length - 1]);
    }
    return acc;
  }, []);

  const zscorePercents =
    curCompStats.reduce((v, n) => {
      if (n.zValues.length && n.zValues.length > 1) {
        v += GetZPercent(n.zValues[0]) * 100;
      } else {
        if (n.values.length > 1) {
          v += (100 * n.values[0]) / 5;
        } else {
          v += (100 * n.values[0]) / 5;
        }
      }
      return v;
    }, 0) /
    curCompStats.reduce((v, n) => {
      v += 1;
      return v;
    }, 0);

  return {
    value: isNaN(zscorePercents) ? 0 : parseFloat(zscorePercents),
    variation:
      stdList.length > 1
        ? calcStdDev(stdList.map((i) => (i / 5) * 100)).sampleVariation
        : 0,
    stats,
  };
};

const setFormTotalScore = ({ isMap, factors, totalsMap, decision, evId }) => {
  factors.forEach((factor) => {
    if (!decision[factor]) return;

    const total = totalsMap.total?.total || {};

    decision[factor].items?.forEach((el) => {
      // if (!isMap) el.id = 'total';
      if (el.isSkipped) return true;

      const factorTotal = totalsMap[factor]?.total || {};

      const fQuestion = {
        ...el,
        question_title: el.question_title || `Overall ${capitalize(factor)}`,
        category_type: factor,
      };

      totalsMap[factor] = {
        total: {
          question: el.question || el,
          value: (factorTotal.value || 0) + (el.value || 0),
          values: [...(factorTotal.values || []), el.value || 0],
          evIds: [...(factorTotal.evIds || []), evId],
        },
        questions: [...(totalsMap[factor]?.questions || []), fQuestion],
      };

      totalsMap.total = {
        total: {
          question: el.question || el.question_title,
          value: (total?.value || 0) + (el.value || 0),
          values: [...(total?.values || []), el.value || 0],
          evIds: [...(total.evIds || []), evId],
        },
        questions: [...(totalsMap.total?.questions || []), fQuestion],
      };
    });
  });
};

const setFactorTotalScore = ({
  factors,
  curFactor,
  totalsMap = {},
  decision,
  evId,
}) => {
  const value = parseInt(decision.value);
  const total = totalsMap.total?.total || {};

  if (!value) return;

  totalsMap.total = {
    total: {
      value: (total?.value || 0) + (value || 0),
      values: [...(total.values || []), value],
      question: curFactor,
      evIds: [...(total.evIds || []), evId],
    },
  };

  // TODO: Fix one factor total scoring

  const decFactor =
    factors.find((f) => f === curFactor.name.toLowerCase()) ||
    factors[curFactor.value];

  if (decFactor) {
    Object.values(FormsHelper.CategoryType).forEach((factor) => {
      const factorQuestion = {
        question_title: `Overall ${capitalize(factor)}`,
        category_type: factor,
        value,
      };

      totalsMap[factor] = {
        total: {
          value: (totalsMap[factor]?.total?.value || 0) + value,
          values: [...(totalsMap[factor]?.total?.values || []), value],
          question: factor,
          evIds: [...(totalsMap[factor]?.total?.evIds || []), evId],
        },
        questions: [...(totalsMap[factor]?.questions || []), factorQuestion],
      };
    });
  }
};

const setYesNoTotalScore = ({ totalsMap, isAdded, evId }) => {
  const value = (isAdded && 5) || 0;
  const total = totalsMap.total?.total || {};

  totalsMap.total = {
    total: {
      value: (total?.value || 0) + (value || 0),
      values: [...(total.values || []), value],
      evIds: [...(total.evIds || []), evId],
      // question: curFactor,
    },
  };

  totalsMap.yes_no = {
    total: {
      values: [...(totalsMap.yes_no?.total?.values || []), value],
      value: (totalsMap.yes_no?.total?.value || 0) + value,
      evIds: [...(totalsMap.yes_no?.total?.evIds || []), evId],
    },
    questions: [
      ...(totalsMap.yes_no?.questions || []),
      {
        question_title: `Overall Company`,
        value,
      },
    ],
  };
};

const getMemberTotalFormScores = ({
  companyId,
  ev = [],
  isMap = false,
  withZScore = false,
  customFactors = null,
  evaluationId = null,
  userId,
}) => {
  const params = customFactors || Object.values(FACTORS_MAP);

  if (!Array.isArray(ev)) {
    console.warn('evaluations as not an array', ev);
    return {};
  }

  const factorsTotalsMap = ev.reduce((acc, val) => {
    if (
      !val ||
      !val.decisions ||
      (userId && userId !== val.user_id && userId !== val.email)
    )
      return acc;

    const { decisions } = val;

    if (decisions[companyId]) {
      const { form_data, reason, isAdded } = decisions[companyId];

      switch (true) {
        case !isNil(form_data):
          setFormTotalScore({
            isMap,
            factors: params,
            totalsMap: acc,
            decision: form_data,
            evId: val.evaluation_id,
          });
          break;

        case !isNil(reason) && !isNil(decisions[companyId]):
          setFactorTotalScore({
            factors: params,
            curFactor: reason,
            totalsMap: acc,
            decision: decisions[companyId],
            evId: val.evaluation_id,
          });
          break;
        case !isNil(isAdded):
          setYesNoTotalScore({
            totalsMap: acc,
            isAdded,
            evId: val.evaluation_id,
          });
          break;
      }
    }

    return acc;
  }, {});

  const result = Object.keys(factorsTotalsMap || {}).reduce(
    (acc, factorKey) => {
      const curTotal = factorsTotalsMap[factorKey];

      acc[factorKey] = {
        ...curTotal,
      };

      if (curTotal.total?.values) {
        const allValues = curTotal.total.values.filter(
          (i) => !isNaN(parseInt(i))
        );

        const curValIdx = evaluationId
          ? curTotal.total.evIds?.findIndex((i) => i === evaluationId)
          : null;

        const evIdx = curValIdx === -1 ? null : curValIdx;

        acc[factorKey].zscores = createZsObj(allValues);

        const zscoreArr = acc[factorKey].zscores.zscoreArr;

        if (zscoreArr && zscoreArr.length) {
          const allPercentiles =
            zscoreArr.length > 5
              ? zscoreArr.map((i) =>
                  parseFloat((GetZPercent(i) * 100).toFixed(2))
                )
              : zscoreArr.map((i) => parseNum((i * 100) / 5));

          acc[factorKey].zscores.zscorePercentileArr = allPercentiles;

          acc[factorKey].zscores.zscoreVal = allPercentiles[evIdx] || 0;

          acc[factorKey].zscores.zscorePercentile = parseFloat(
            allPercentiles.reduce((a, c) => {
              a += parseFloat(c || 0);
              return a;
            }, 0) / allPercentiles.length
          );

          acc[factorKey].zscores.originalVal = allValues[evIdx] || 0;
          acc[factorKey].zscores.factorZScore =
            acc[factorKey].zscores.zscoreArr[evIdx] || 0;
        } else {
          acc[factorKey].zscores.zscorePercentile =
            (acc[factorKey].zscores.average / 5) * 100;

          acc[factorKey].zscores.originalVal = allValues[evIdx] || 0;
          acc[factorKey].zscores.factorZScore =
            acc[factorKey].zscores.average / 5;
        }

        acc[factorKey].total.value =
          allValues.reduce((a, c) => {
            a += c || 0;
            return a;
          }, 0) / allValues.length;

        if (isNaN(parseInt(acc[factorKey].total.value))) {
          acc[factorKey].total.value = 0;
        }
      }

      return acc;
    },
    {}
  );

  return result;
};

const getMemberFormScores = (
  companyId,
  companyEvs = [],
  evaluationId = null,
  isMap = false,
  withZScore = false,
  userId
) => {
  const params = customFactors || Object.values(FACTORS_MAP);

  if (!Array.isArray(companyEvs)) {
    console.warn('evaluations as not an array', companyEvs);
    return {};
  }

  const map = companyEvs.reduce((acc, val, idx) => {
    if (!val) return acc;

    const { decisions } = val;

    if (
      !decisions ||
      (userId && userId !== val.user_id && userId !== val.email)
    )
      return acc;

    if (decisions[companyId]) {
      const { form_data, reason, isAdded, date } = decisions[companyId];
      if (form_data) {
        params.forEach((i) => {
          if (!acc[i]) {
            if (form_data[i]) {
              acc[i] = form_data[i].items.reduce((a, v) => {
                if (!isMap) {
                  v.id = 'total';
                }
                if (!a[v.id]) {
                  a[v.id] = {
                    question: v.question,
                    value: v.value || 0,
                    values: [v.value || 0],
                    evIds: [val.evaluation_id],
                  };
                } else {
                  a[v.id].value += v.value || 0;
                  a[v.id].values.push(a.value || 0);
                  a[v.id].evIds.push(val.evaluation_id || 0);
                }
                return a;
              }, {});
            } else {
              if (i !== 'yes_no')
                acc[i] = {
                  total: {
                    value: 0,
                    question: '',
                    values: [0],
                    evIds: [val.evaluation_id],
                  },
                };
            }
          } else {
            if (form_data[i]) {
              form_data[i].items.forEach((a) => {
                acc[i].total.value += a.value || 0;
                acc[i].total.values.push(a.value || 0);

                if (val) {
                  const curEvId = val.evaluation_id || 0;
                  if (acc[i].total.evIds) {
                    acc[i].total.evIds.push(curEvId);
                  } else {
                    acc[i].total.evIds = [curEvId];
                  }
                }
              });
            }
          }
        });
      } else if (reason && decisions[companyId]) {
        if (reason.name && reason.name === 'Mission') {
          reason.name = 'Vision';
        }
        const { value } = decisions[companyId];
        const parsedValue = !isNaN(parseInt(value)) ? parseInt(value) : 0;
        if (!acc['total']) {
          acc['total'] = {
            total: {
              values: [parsedValue],
              question: reason,
              evIds: [val.evaluation_id],
            },
          };
        } else {
          acc['total'] = {
            total: {
              values: [...acc['total'].total.values, parsedValue],
              question: reason,
              evIds: [...acc['total'].total.evIds, val.evaluation_id],
            },
          };
        }

        params.forEach((i) => {
          const key = i.toLowerCase();
          if (!acc[i]) {
            if (
              reason.name &&
              typeof reason.name === 'string' &&
              reason.name.toLowerCase() === key
            ) {
              acc[i.toLowerCase()] = {
                total: {
                  value: parsedValue || 0,
                  evIds: [val.evaluation_id],
                  values: [parsedValue || 0],
                  question: i,
                },
              };
            } else {
              acc[key] = {
                total: {
                  value: 0,
                  evIds: [],
                  values: [],
                  question: i,
                },
              };
            }
          } else {
            if (
              reason.name &&
              typeof reason.name === 'string' &&
              reason.name.toLowerCase() === key
            ) {
              acc[i.toLowerCase()].total.value += parsedValue || 0;
              if (parsedValue) {
                acc[i.toLowerCase()].total.values.push(parsedValue);
                acc[i.toLowerCase()].total.evIds.push(val.evaluation_id);
              }
            }
          }
        });
      } else if (isAdded !== undefined && isAdded !== null) {
        if (!acc.yes_no) {
          acc.yes_no = {
            total: {
              values: [isAdded ? 5 : 0],
              evIds: [val.evaluation_id],
              value: isAdded ? 5 : 0,
            },
          };
        } else {
          acc.yes_no.total.values.push(isAdded ? 5 : 0);
          acc.yes_no.total.evIds.push(val.evaluation_id);
        }
      }
    }
    return acc;
  }, {});

  const result = [...Object.keys(map || {}), 'total'].reduce((acc, val) => {
    const curTotal = factorsTotalsMap[factorKey];

    acc[factorKey] = {
      ...curTotal,
    };
    if (!map[val]) {
      return acc;
    }
    acc[val] = {
      ...map[val],
    };
    if (map[val]?.total?.values) {
      const allValues = map[val].total.values.filter(
        (i) => !isNaN(parseInt(i))
      );

      const curValIdx = map[val].total.evIds.findIndex(
        (i) => i === evaluationId
      );

      acc[val].zscores = createZsObj(allValues);
      const zscoreArr = acc[val].zscores.zscoreArr;
      if (zscoreArr && zscoreArr.length) {
        const allPercentiles =
          zscoreArr.length > 5
            ? acc[val].zscores.zscoreArr.map((i) =>
                parseFloat((GetZPercent(i) * 100).toFixed(2))
              )
            : zscoreArr.map((i) => parseNum((i * 100) / 5));

        acc[val].zscores.zscorePercentileArr = allPercentiles;

        acc[val].zscores.zscoreVal =
          allPercentiles[curValIdx === -1 ? null : curValIdx] || 0;

        acc[val].zscores.zscorePercentile = parseFloat(
          allPercentiles.reduce((a, c) => {
            a += parseFloat(c || 0);
            return a;
          }, 0) / allPercentiles.length
        );
        acc[val].zscores.originalVal =
          allValues[curValIdx === -1 ? null : curValIdx] || 0;
        acc[val].zscores.factorZScore =
          acc[val].zscores.zscoreArr[curValIdx === -1 ? null : curValIdx] || 0;
      } else {
        acc[val].zscores.zscorePercentile =
          (acc[val].zscores.average / 5) * 100;
        acc[val].zscores.originalVal =
          allValues[curValIdx === -1 ? null : curValIdx] || 0;
        acc[val].zscores.factorZScore = acc[val].zscores.average / 5;
      }

      acc[val].total.value =
        allValues.reduce((a, c) => {
          a += c || 0;
          return a;
        }, 0) / allValues.length;

      if (isNaN(parseInt(acc[val].total.value))) {
        acc[val].total.value = 0;
      }
    }

    return acc;
  }, {});

  return result;
};

const getEvalTotalZScore = (compFactors, evId) => {
  const allZScores = [];
  const allAvgs = [];
  const evalZScores = Object.keys(compFactors).reduce((a, v) => {
    const timeIdx = compFactors[v].items.findIndex(
      (i) => i.evaluation_id === evId
    );
    // if (timeIdx !== -1) {
    //   const factorZScore = compFactors[v]?.timeMap?.[timeIdx]?.score || 0;
    //   a[v] = factorZScore;
    // }
    if (timeIdx !== -1) {
      a[v] = compFactors[v].zScoresPercentilesAvg || 0;
      allZScores.push(a[v]);
      allAvgs.push(compFactors[v].avg);
    }
    return a;
  }, {});

  const total = parseNum(
    allZScores.reduce((a, v) => (a += v), 0) / allZScores.length
  );

  const avg = parseNum(allAvgs.reduce((a, v) => (a += v), 0) / allAvgs.length);

  const std = parseNum(calcStdDev(allZScores).popStdDev);

  return { total: total || 0, std, avg, factorZScoreMap: evalZScores };
};

const getCompanyTotalDataPoints = (
  companyId,
  evaluations,
  userId = null,
  projectId,
  listId
) => {
  const evs = Object.values(evaluations)
    .filter(
      (i) =>
        i.company_id_list?.includes(companyId) &&
        i.evaluation_type !== 'klever_share'
    )
    .filter((i) => (projectId ? i.project_id === projectId : true))
    .filter((i) =>
      listId && listId !== 'watchlist' ? i.list_id === listId : true
    );

  return evs.reduce((acc, val) => {
    val.evaluations.forEach((e) => {
      if (!userId && e.decisions[companyId]) {
        acc++;
      } else if (userId && e.user_id === userId && e.decisions[companyId]) {
        acc++;
      }
    });
    return acc;
  }, 0);
};

const getTotalCommunityScore = (
  companyId,
  evaluations,
  filterUsers = null,
  filterDate = null,
  excludeUsers = [],
  projectId,
  listId
) => {
  return getZScoredPercentage(
    companyId,
    evaluations,
    filterUsers,
    filterDate,
    excludeUsers,
    projectId,
    listId
  );
  const members = {};
  const evs = Object.values(evaluations).filter((i) =>
    projectId ? i.project_id === projectId : true
  );
  if (!evs.length) {
    return Number(0);
  }

  evs.forEach((item) => {
    item.evaluations
      .filter((i) => {
        if (filterDate) return i.created_at <= filterDate;
        return true;
      })
      .forEach((e) => {
        const id = e.user_id || e.email;
        if (excludeUsers.includes(id)) return true;

        if (e.decisions[companyId]) {
          if (members[id] && e.decisions !== undefined) {
            Object.keys(e.decisions || {}).forEach((d) => {
              if (!members[id].companies[d]) {
                members[id].companies[d] = { values: [] };
              }
              if (item.evaluation_form_type === 'digits') {
                members[id].companies[d].values = [
                  ...members[id].companies[d].values,
                  e.decisions[d].value,
                ];
              } else if (item.evaluation_form_type === 'form') {
                const formData = Object.values(
                  getMemberTotalFormScores(companyId, [e]) || {}
                ).reduce((n, v) => {
                  n.push(v.total.value);
                  return n;
                }, []);
                members[id].companies[d].values = [
                  ...members[id].companies[d].values,
                  formData.reduce((acc, val) => {
                    acc += val;
                    return acc;
                  }, 0) / formData.length,
                ];
              }
            });
          } else {
            members[id] = {
              user_id: id,
              companies: {},
            };
            Object.keys(e.decisions || {}).forEach((d) => {
              if (!members[id].companies[d]) {
                members[id].companies[d] = { values: [] };
              }

              if (item.evaluation_form_type === 'digits') {
                members[id].companies[d].values = [
                  ...members[id].companies[d].values,
                  e.decisions[d].value,
                ];
              } else if (item.evaluation_form_type === 'form') {
                const formData = Object.values(
                  getMemberTotalFormScores(companyId, [e]) || {}
                ).reduce((n, v) => {
                  n.push(v.total.value);
                  return n;
                }, []);

                members[id].companies[d].values = [
                  ...members[id].companies[d].values,
                  formData.reduce((acc, val) => {
                    acc += val;
                    return acc;
                  }, 0) / formData.length,
                ];
              }
            });
          }
        }
      });
  });

  const compId = companyId;
  let curCompMembers = Object.values(members).filter(
    (item) => item.companies[compId]
  );
  if (filterUsers) {
    curCompMembers = curCompMembers.filter(
      (item) => item.user_id === filterUsers
    );
  }

  const stats = curCompMembers.reduce((acc, val) => {
    const { values } = val.companies[companyId];
    if (values && values.length && values[values.length - 1]) {
      acc.push(values[values.length - 1]);
    }
    return acc;
  }, []);

  const variation = stats.length ? calcStdDev(stats).sampleVariation : null;

  const finalVal =
    (stats.reduce((n, v) => {
      n += v;
      return n;
    }, 0) /
      stats.length /
      5) *
    100;

  return {
    value: isNaN(finalVal) ? Number(0) : finalVal,
    variation: !isNaN(variation) ? (variation * 100).toFixed(2) : 'None',
    stats,
  };
};

const getTotalEvaluatedCompanies = (evaluations, companiesList) => {
  const evs = Object.values(evaluations);

  const companiesMap = evs.reduce((acc, val) => {
    val.evaluations.forEach((i) => {
      Object.keys(i.decisions || {}).forEach((j) => {
        if (
          companiesList &&
          companiesList.length &&
          companiesList.includes(j)
        ) {
          acc[j] = true;
        } else if (!companiesList) {
          acc[j] = true;
        }
      });
    });
    return acc;
  }, {});

  return Object.keys(companiesMap || {}).length;
};

const getTotalCommunityDataPoints = (
  evaluations,
  filterUsers = null,
  filterDate = null,
  excludeUsers = []
) => {
  const members = {};
  const evs = Object.values(evaluations);

  if (!evs.length) {
    return Number(0);
  }

  evs.forEach((item) => {
    item.evaluations
      .filter((i) => {
        if (filterDate) return i.created_at <= filterDate;

        return true;
      })
      .forEach((e) => {
        const id = e.user_id || e.email;
        if (excludeUsers.includes(id)) return true;

        if (members[id] && e.decisions !== undefined) {
          Object.keys(e.decisions).forEach((d) => {
            members[id].companies[d] = {
              value: members[id].companies[d]
                ? members[id].companies[d].value + 1
                : 1,
            };
          });
        } else {
          members[id] = { companies: {}, user_id: id };
          Object.keys(e.decisions).forEach((d) => {
            members[id].companies[d] = {
              value: 1,
            };
          });
        }
      });
  });

  let curCompMembers = Object.values(members);
  if (filterUsers) {
    curCompMembers = curCompMembers.filter(
      (item) => item.user_id === filterUsers
    );
  }

  const stats = curCompMembers.reduce((acc, val) => {
    Object.values(val.companies).forEach((i) => {
      const { value } = i;
      if (value) {
        acc.push(value);
      }
    });
    return acc;
  }, []);

  const finalVal = stats.reduce((n, v) => {
    n += v;
    return n;
  }, 0);

  return isNaN(finalVal) ? Number(0) : finalVal;
};

const getAllEvaluatedCompanies = (evaluations) => {
  return evaluations.reduce((a, c) => {
    c.evaluations.forEach((i) => {
      Object.keys(i.decisions).forEach((k) => {
        a[k] = true;
      });
    });
    return a;
  }, {});
};

const getMemberFactorMap = (userId, evaluations) => {
  const companies = {};
  const companiesEvs = {};

  evaluations = Array.isArray(evaluations)
    ? evaluations.reduce((a, c) => ({ ...a, [c.evaluation_id]: c }), {})
    : evaluations;

  if (!Object.values(evaluations || {}).length) return {};

  Object.values(evaluations).forEach((item) => {
    const userEvaluations = item.evaluations.find(
      (i) => i.user_id === userId || i.email === userId
    );

    if (userEvaluations) {
      Object.keys(userEvaluations.decisions).forEach((u) => {
        const factorData = getMemberTotalFormScores({
          companyId: u,
          ev: [userEvaluations],
        });

        const generalInfo = {
          owner_id: userId,
          evaluation_id: item.evaluation_id,
          created_at: userEvaluations.created_at,
          decision: userEvaluations.decisions[u],
        };

        const evalDetails = {
          company_evaluations: [
            {
              ...generalInfo,
              factors: factorData,
            },
          ],
          evaluation_info: {
            ...generalInfo,
            evaluation_form_type: item.evaluation_form_type,
          },
        };

        companies[u] = [
          ...(companies[u] || []),
          {
            ...(factorData || {}),
            ...evalDetails,
          },
        ];
        companiesEvs[u] = [
          ...(companiesEvs[u] || []),
          {
            ...userEvaluations,
            ...evalDetails,
            evaluation_id: item.evaluation_id,
            evaluation_form_type: item.evaluation_form_type,
            decision: userEvaluations.decisions[u],
          },
        ];
      });
    }
  });

  // sort inner evaluations by date
  Object.keys(companies).forEach((i) => {
    companies[i].sort(
      (a, b) =>
        new Date(b.evaluation_info.created_at) -
        new Date(a.evaluation_info.created_at)
    );
  });

  return companies;
};

const getStatItem = ({ company_id, show_z_score, values = [], compEval }) => {
  const value =
    parseNum(values.reduce((a, v) => (a += v), 0) / values.length) || 0;
  const score = parseNum((value / 5) * 100 || 0);
  const filteredValues = values.filter((v) => !isNil(v) && !isNaN(parseInt(v)));
  return {
    company_id,
    show_z_score,
    value,
    score,
    values: filteredValues,
    ...compEval.evaluation_info,
    created_at: new Date(compEval.evaluation_info.created_at).getTime(),
  };
};

const setFactorStatData = ({
  stats,
  evaluationStats,
  statsCompId,
  companyId,
  factor,
  fCount,
}) => {
  if (companyId) {
    evaluationStats[statsCompId]?.forEach((compEval) =>
      !isNil(compEval[factor]?.total?.values?.[0])
        ? stats[factor].items.push(
            getStatItem({
              company_id: statsCompId,
              show_z_score: evaluationStats[statsCompId].length > 3,
              values: compEval[factor].total.values,
              compEval,
            })
          )
        : null
    );
  }

  if (
    !isNil(evaluationStats[statsCompId]?.[0]?.[factor]?.total?.values?.[0]) &&
    !companyId
  ) {
    stats[factor].items.push(
      getStatItem({
        company_id: statsCompId,
        show_z_score: evaluationStats[statsCompId].length > 3,
        values: evaluationStats[statsCompId][0][factor].total.values,
        compEval: evaluationStats[statsCompId][0],
      })
    );
  }
};

const calculateUserFactorStats = ({
  customFactors,
  evaluationStats,
  userId,
  companyId,
}) => {
  const factors = {};

  const factorsNames = customFactors || Object.values(FACTORS_MAP);
  const factorsCount = customFactors
    ? factorsNames.length
    : factorsNames.length - 1;

  Object.keys(evaluationStats).forEach((key) => {
    if (companyId && key !== companyId) return true;

    factorsNames.forEach((f) => {
      if (!factors[f]) {
        factors[f] = {
          items: [],
        };
      }

      setFactorStatData({
        stats: factors,
        companyId,
        evaluationStats,
        statsCompId: key,
        factor: f,
        fCount: factorsCount,
      });
    });
  });

  factorsNames.forEach((f) => {
    if (factors[f]) {
      const allFactorScores = [...(factors[f].items || [])] || [
        ...(factors[f].items || []),
        ...(factors.yes_no?.items || []),
      ];

      factors[f].items.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      allFactorScores.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      const scoreTimeMap = [];

      const allValues = allFactorScores.reduce((a, v) => {
        scoreTimeMap.push(v.created_at);
        return [...a, ...(v.values || [])];
      }, []);

      factors[f].avg = parseNum(
        allValues.reduce((a, v) => (a += v), 0) / allValues.length
      );

      factors[f].std =
        allValues.length > 1 ? parseNum(calcStdDev(allValues).popStdDev) : 0;

      const areAllValSame = allValues.every((val) => val === allValues[0]);

      if (allValues.length > 1 && !areAllValSame) {
        const showOriginal = false && allValues.length > 5;
        factors[f].zScores = showOriginal ? zscore(allValues) : allValues;

        factors[f].zScoresPercentiles = showOriginal
          ? factors[f].zScores.reduce((a, v) => {
              if (!isNil(v) && !isNaN(v))
                a.push(parseFloat(GetZPercent(v) * 100));
              return a;
            }, [])
          : factors[f].zScores.map((v) => parseNum((v / 5) * 100));

        factors[f].timeMap = scoreTimeMap.map((i, idx) => ({
          score: factors[f].zScoresPercentiles[idx],
          timestamp: i,
        }));

        factors[f].zScoresPercentilesAvg = parseNum(
          factors[f].zScoresPercentiles.reduce((a, v) => (a += v), 0) /
            factors[f].zScoresPercentiles.length
        );
      } else {
        // reset 0
        factors[f].zScores = allValues;
        factors[f].zScoresPercentiles = allValues.map((v) =>
          parseNum((v / 5) * 100)
        );
        factors[f].zScoresPercentilesAvg =
          factors[f].zScoresPercentiles[0] || 0;
        factors[f].timeMap = scoreTimeMap.map((i, idx) => ({
          score: factors[f].zScoresPercentiles[idx],
          timestamp: i,
        }));
      }
    }
  });

  return factors;
};
const getFactorItems = (fKey, ev, prevFactorItemsMap) => {
  const { form_data, reason, value, date, isAdded } = ev.decision;

  let items = [];

  switch (true) {
    case !isNil(form_data):
      items =
        fKey === 'total'
          ? Object.values(form_data || {}).reduce(
              (a, f) => [...a, ...(f.items || [])],
              []
            )
          : (form_data[fKey]?.items || []).map((q) =>
              q.question_id
                ? q
                : {
                    created_at: date || ev.created_at,
                    updated_at: date || ev.created_at,
                    owner_id: ev.owner_id,
                    question_title: `Overall ${capitalize(fKey)}`,
                    category_type: fKey,
                    value: q.value,
                  }
            );
      break;

    case !isNil(reason):
      items = [
        {
          created_at: date || ev.created_at,
          updated_at: date || ev.created_at,
          owner_id: ev.owner_id,
          question_title: `Overall ${capitalize(fKey)}`,
          category_type: reason.name,
          value,
        },
      ];
      break;
    case !isNil(isAdded):
      items = [
        {
          created_at: date || ev.created_at,
          updated_at: date || ev.created_at,
          owner_id: ev.owner_id,
          question_title: `Overall Company`,
          category_type: fKey,
          value: isAdded ? 5 : 0,
        },
      ];
      break;
  }

  const decisions = items.reduce((acc, q) => {
    const decision = {
      skipped: q.isSkipped,
      created_at: parseInt(q.created_at),
      evaluation_id: ev.evaluation_id,
      owner_id: ev.owner_id,
      value: q.value,
      score: parseNum(((q.value || 0) / 5) * 100),
    };

    const qKey = (q.question_title || q.question)
      ?.toLowerCase()
      ?.replace(/ /g, '_');

    const prevItem = prevFactorItemsMap?.[qKey] || {};

    const reviewsTimeline = [
      ...(prevItem.reviewsTimeline || []),
      ...(acc[qKey]?.reviewsTimeline || []),
      decision,
    ];

    acc[qKey] = {
      ...q,
      // values: reviewsValues,`
      // score: qScore,
      reviewsTimeline,
    };

    return acc;
  }, {});

  return decisions;
};

const calculateCompanyFactors = (factorResult, companyId, memId) => {
  const factors = {};
  const factorsNames = Object.values(FACTORS_MAP);
  const companyScoreTimeMap = [];
  const factorsQuestionsMap = {};

  factorsNames.forEach((f) => {
    if (factorResult?.[f]?.items?.length) {
      // <-- For user STD (variation) -->
      factorResult[f].items.forEach((item) => {
        const questionsMap = getFactorItems(
          f,
          item,
          factorsQuestionsMap[f] || {}
        );
        factorsQuestionsMap[f] = {
          ...(factorsQuestionsMap[f] || {}),
          ...questionsMap,
        };
      });

      const values = Object.values(factorsQuestionsMap[f] || {}).reduce(
        (a, v) => {
          const questionsValues = v.reviewsTimeline?.map((el) => el.value);
          const allValues = [...a, ...questionsValues].filter((i) => i);
          return allValues;
        },
        []
      );

      // <-- For user STD (variation) -->

      const idx = factorResult[f].items.findIndex(
        (i) => i.company_id === companyId
      );

      const raws = factorResult[f].items.filter(
        (i) => i.company_id === companyId
      );

      const std =
        values.length > 1 ? parseNum(calcStdDev(values).popStdDev) : 0;

      const companyData = {
        std,
        raw: factorResult[f].items[idx],
        raws,
        values,
        score:
          (values.reduce((a, v) => (a += v), 0) / values.length / 5) * 100 || 0,
      };

      companyScoreTimeMap.push({
        companyData,
        timeMap: factorResult[f],
      });

      factors[f] = companyData;
    }
  });

  return factors;
};

const getCompanyScoreFromStats = (
  evaluationStats,
  companyId,
  memberId,
  ignoredMemberIds = []
) => {
  try {
    if (!evaluationStats) return null;

    const factorsNames = Object.values(FACTORS_MAP);

    if (memberId) {
      const filtered = {};
      filtered.memberId = evaluationStats[memberId];
      evaluationStats = filtered;
    }

    if (ignoredMemberIds && ignoredMemberIds.length) {
      evaluationStats = Object.keys(evaluationStats).reduce((acc, val) => {
        if (!ignoredMemberIds.includes(val) && val !== 'klever_share') {
          acc[val] = evaluationStats[val];
        }
        return acc;
      }, {});
    }

    let timeline = [];

    const factorsMap = {};

    const allOriginalValues = [];

    const allCompanyEvaluations = Object.values(evaluationStats || {}).reduce(
      (a, c) => {
        if (c && c.evaluations) {
          const evList = c.evaluations.filter((i) => i.decisions[companyId]);
          a = a.concat(evList);
        }
        return a;
      },
      []
    );

    const scores = Object.values(evaluationStats || {}).reduce((a, v) => {
      if (v && v.evaluations && v.companies[companyId]) {
        const companyFactors = v.companies[companyId].factors;

        timeline = timeline.concat(
          v.companies[companyId].company_evaluation_timeline
        );

        const sc = Object.keys(companyFactors).reduce((acc, c) => {
          const curFactorScore = companyFactors[c]?.score;

          if (companyFactors[c] && !isNaN(Number.parseInt(curFactorScore))) {
            if (
              // c === 'yes_no' &&
              (!isNil(curFactorScore) || curFactorScore !== 0) &&
              companyFactors[c].raw
            ) {
              acc.push(curFactorScore);
            }
            // else if (
            //   c !== 'yes_no' &&
            //   curFactorScore !== 0 &&
            //   companyFactors[c].raw
            // ) {
            //   acc.push(curFactorScore);
            // }
          }
          return acc;
        }, []);

        if (sc.length) {
          a.push(
            sc.reduce((e, val) => {
              e += val;
              return e;
            }, 0) / sc.length
          );
        }
      }
      return a;
    }, []);

    timeline.some((el) => {
      Object.keys(el.factors || {}).some((fKey) => {
        const factor = el.factors?.[fKey];

        if (factor) {
          const curFactorScore = factor.zscores?.zscorePercentile || 0;
          const factorValues = factor.total?.values || [];
          const questionsMap = getFactorItems(
            fKey,
            el,
            factorsMap[fKey]?.questionsMap
          );

          factorsMap[fKey] = {
            values: [...(factorsMap[fKey]?.values || []), curFactorScore],
            originalValues: [
              ...(factorsMap[fKey]?.originalValues || []),
              ...factorValues,
            ],
            questionsMap: {
              ...(factorsMap[fKey]?.questionsMap || {}),
              ...questionsMap,
            },
            scoreTimeline: [
              ...(factorsMap[fKey]?.scoreTimeline || []),
              {
                created_at: el.created_at,
                evaluation_id: el.evaluation_id,
                owner_id: el.owner_id,
                score: curFactorScore,
              },
            ],
          };
        }

        return false;
      });

      return false;
    });

    factorsNames.some((fKey) => {
      if (!factorsMap[fKey]) {
        factorsMap[fKey] = {};
      }

      const factor = factorsMap[fKey];
      const yesNoFactor = fKey === 'yes_no' ? null : factorsMap['yes_no'];

      if (yesNoFactor) {
        const yesNoReviews =
          yesNoFactor.questionsMap.overall_company?.reviewsTimeline;

        const curFactorOverallQ =
          factor.questionsMap?.[`overall_${fKey}`] || {};

        const factorOverallReviews = [
          ...(curFactorOverallQ.reviewsTimeline || []),
          ...yesNoReviews,
        ];

        let reviewsCount = 0;

        const reviewsTotal = factorOverallReviews.reduce((a, i) => {
          if (!i.isSkipped && !i.skipped) {
            reviewsCount += 1;
            return (a += i.score);
          }
          return a;
        }, 0);

        const qScore = parseNum(reviewsTotal / reviewsCount);

        factor.questionsMap = {
          ...(factor.questionsMap || {}),
          [`overall_${fKey}`]: {
            ...yesNoFactor.questionsMap.overall_company,
            ...curFactorOverallQ,
            question_title: `Overall ${capitalize(fKey)}`,
            category_type: fKey,
            score: qScore,
            reviewsTimeline: factorOverallReviews,
          },
        };
      }

      const allValues = [
        ...(factor?.values || []),
        ...(yesNoFactor?.values || []),
      ];

      const factorOriginalValues = [
        ...(factor?.originalValues || []),
        ...(yesNoFactor?.originalValues || []),
      ];

      const evalValues = Object.values(factor.questionsMap || {})
        .map((el) => el.value)
        .filter((i) => !isNil(i));

      allOriginalValues.push(...evalValues);

      factorsMap[fKey].scoreTimeline = [
        ...(factor?.scoreTimeline || []),
        ...(yesNoFactor?.scoreTimeline || []),
      ];

      // factorsMap[fKey].values = allValues;

      factorsMap[fKey].title = fKey;

      const showOriginal = false && allValues?.length > 5;

      const zScores = zscore(allValues);

      factorsMap[fKey].values = showOriginal
        ? zScores.reduce((a, v) => {
            if (v !== undefined && v !== null && !isNaN(v))
              a.push(parseFloat(GetZPercent(v) * 100));
            return a;
          }, [])
        : allValues;

      factorsMap[fKey].score = (
        (factorOriginalValues.reduce((e, val) => {
          e += val;
          return e;
        }, 0) /
          factorOriginalValues.length /
          5) *
        100
      ).toFixed(2);

      return false;
    });

    const usersTotalMap = timeline.reduce((acc, val) => {
      if (val.owner_id && !acc[val.owner_id]) {
        acc[val.owner_id] = val.total;
      }
      return acc;
    }, {});

    const formFactors = Object.values(factorsMap).filter(
      (f) =>
        parseInt(f.score) &&
        factorsNames.includes(f.title) &&
        f.title !== 'yes_no'
    );

    const scoreFactors = formFactors?.length
      ? formFactors
      : Object.values(factorsMap);

    const companyScore =
      scoreFactors.reduce((a, v) => (a += +v.score), 0) / scoreFactors.length;

    // Object.values(usersTotalMap || {}).reduce((a, v) => (a += v), 0) /
    // Object.values(usersTotalMap || {}).length;

    let stdDev = null;

    if (timeline.length > 1) {
      stdDev = calcStdDev(allOriginalValues);
    }

    return {
      timeline,
      allCompanyEvaluations,
      scoreList: scores,
      companyScore: companyScore || 0,
      std: parseNum(stdDev?.popStdDev),
      factorsMap,
    };
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getCompanyScoreFromFactor = (timelineFactorsList) => {
  const scores = timelineFactorsList.reduce((a, v) => {
    Object.values(v).forEach((i) => {
      if (i.score && !isNaN(i.score)) a.push(i.score);
    });
    return a;
  }, []);

  const allValues = timelineFactorsList.reduce((a, v) => {
    const allFactorsValues = Object.values(v).reduce(
      (values, f) => [...values, ...(f.values || [])],
      []
    );
    return [...a, ...allFactorsValues];
  }, []);

  const isStdVisible = timelineFactorsList.some((f) =>
    Object.values(f).some((el) => el.raws?.length > 1)
  );

  return {
    companyScore:
      (allValues.reduce((a, v) => (a += v), 0) / allValues.length / 5) * 100 ||
      0,
    std: isStdVisible
      ? parseNum(calcStdDev(allValues).popStdDev) || null
      : null,
  };
};

const getCompanyTotalScore = (evaluationStats, usersIds, companyId) => {
  return (
    usersIds.reduce((a, id) => {
      const curEval = evaluationStats[id]?.companies?.[companyId];
      if (
        curEval.company_evaluation_timeline &&
        curEval.company_evaluation_timeline[0]
      ) {
        a += curEval.company_evaluation_timeline[0].total || 0;
      }
      return a;
    }, 0) / usersIds.length
  );
};

const isEvaluationCompleteByMe = (evaluation, userId) => {};

const didUserCompleteEvaluation = (userId, evaluation = {}) => {
  const requireDecisions =
    evaluation.company_meta_data || evaluation.company_id_list || [];
  const userDecisions =
    evaluation.evaluations?.reduce((a, v) => {
      if (v.user_id === userId) {
        a = { ...a, ...(v.decisions || {}) };
      }
      return a;
    }, {}) || {};

  return requireDecisions.length === Object.keys(userDecisions || {})?.length;
};

// const evaluationComments = () => {

//   return {};
// }

const fillParticipantEvaluations = (curEval, entryParticipantsMap = {}) => {
  try {
    const participantsMap = entryParticipantsMap;
    curEval.participants.forEach((p) => {
      if (curEval.issuer_id === p.user_id) return true;

      participantsMap[p.user_id] = [
        ...(participantsMap[p.user_id] || []),
        curEval,
      ];
    });

    curEval.external_participants.forEach((exP) => {
      participantsMap[exP.email] = [
        ...(participantsMap[exP.email] || []),
        curEval,
      ];
    });

    participantsMap[curEval.issuer_id] = [
      ...(participantsMap[curEval.issuer_id] || []),
      curEval,
    ];

    return participantsMap;
  } catch (err) {
    console.error(err);
    return {};
  }
};

// --------------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------------
// -------------------------------      NEW CODE       -----------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------------

const DEFAULT_COMPANY_STATS_FACTOR = {
  title: null,
  values: [],
  total: 0,
};

const DEFAULT_MEMBER_DECISION_STATS = {
  values: [],
  variation: null,
  factorsMap: {},
  timeline: [],
  totalScore: 0,
  dataPoints: 0,
  value: 0,
  avg: 0,
};

const mapEvaluationMembers2 = (evaluations) => {
  const membersMap = {};

  const evs = Array.isArray(evaluations)
    ? evaluations
    : Object.values(evaluations);

  evs.forEach((ev) => {
    ev.participants?.forEach((p) => {
      const pId = p.user_id || p.email;
      membersMap[pId] = {
        ...(membersMap[pId] || {}),
        received: [...(membersMap[pId]?.received || []), ev.evaluation_id],
      };
    });

    ev.external_participants?.forEach((p) => {
      const pId = p.email || p.user_id;
      membersMap[pId] = {
        ...(membersMap[pId] || {}),
        received: [...(membersMap[pId]?.received || []), ev.evaluation_id],
      };
    });

    if (ev.evaluation_type !== 'klever_share') {
      membersMap[ev.issuer_id] = {
        ...(membersMap[ev.issuer_id] || {}),
        sent: [...(membersMap[ev.issuer_id]?.sent || []), ev.evaluation_id],
      };
    } else {
      membersMap.klever_share = {
        ...(membersMap.klever_share || {}),
        sent: [...(membersMap.klever_share?.sent || []), ev.evaluation_id],
      };
    }
  });

  return membersMap;
};

const setCompanyFactorsStats = ({ compStats, fKey, value, isSkipped }) => {
  const compFactorValues = compStats.factorsMap?.[fKey]?.values || [];
  const compFactorTotal = compStats.factorsMap?.[fKey]?.total || 0;

  const compFactor = { ...DEFAULT_COMPANY_STATS_FACTOR };

  compFactor.key = fKey;
  compFactor.title = fKey;

  compFactor.values = isSkipped
    ? compFactorValues
    : [...compFactorValues, value];
  compFactor.total = isSkipped ? compFactorTotal : compFactorTotal + value;

  compStats.factorsMap = {
    ...(compStats.factorsMap || {}),
    [fKey]: compFactor,
  };
};

const setUserFactorStats = ({
  factorsMap,
  fKey,
  type,
  value,
  question = {},
  evId,
  ownerId,
  createdAt,
}) => {
  const fQuestion = {
    ...question,
    type,
    value,
    score: parseNum((value / 5) * 100),
    created_at: createdAt,
    evaluation_id: evId,
    owner_id: ownerId,
    category_type: fKey,
    question_title: question.question_title || `Overall ${capitalize(fKey)}`,
  };

  factorsMap[fKey] = {
    ...(factorsMap[fKey] || {}),
    questions: [...(factorsMap[fKey]?.questions || []), fQuestion],
  };

  if (question.isSkipped) return;

  const factorTotal = (factorsMap[fKey]?.total || 0) + value;
  const factorValues = [...(factorsMap[fKey]?.values || []), value];
  // const factorScores = factorValues.map((v) => parseNum(v * 20));
  const avg = mean(factorValues);

  factorsMap[fKey].total = factorTotal;
  factorsMap[fKey].values = factorValues;
  factorsMap[fKey].avg = parseNum(avg);
  factorsMap[fKey].score = parseNum(avg * 20);
  // factorsMap[fKey].score = parseNum(mean(factorScores));
  factorsMap[fKey].dataPoints = (factorsMap[fKey]?.dataPoints || 0) + 1;
};

const getTimelineDecision = ({ type, ev, decision, factors, pId }) => {
  const { form_data, reason, value, isAdded, date } = decision;

  const fQuestion = {
    ...decision,
    type,
    value: null,
    score: null,
    category_type: null,
    question_title: null,
    created_at: date || ev.created_at,
    evaluation_id: ev.evaluation_id,
    owner_id: pId,
  };

  const tDecision = {
    decision,
    avg: null,
    score: 0,
    factors: {},
    owner_id: pId,
    type: ev.evaluation_form_type,
    evaluation_id: ev.evaluation_id,
    created_at: date || ev.created_at,
    updated_at: date || ev.created_at,
  };

  let total = null;
  const values = [];

  switch (true) {
    case !isNil(form_data):
      factors.forEach((fKey) => {
        const factor = form_data[fKey];
        if (!factor) return;
        // let factorVal = 0;
        const factorValues = [];
        const factorQuestions = [];
        const factorScores = [];
        const skippedArray = [];

        factor.items.forEach((item) => {
          if (!item.isSkipped) {
            total += item.value;
            values.push(item.value);
            // factorVal += item.value;
            factorValues.push(item.value);
            factorScores.push((item.value / 5) * 100);
          }

          skippedArray.push(item.isSkipped);

          factorQuestions.push({
            ...fQuestion,
            question_id: item.question_id,
            question_title:
              item.question_title || `Overall ${capitalize(fKey)}`,
            category_type: fKey,
            value: item.isSkipped ? null : item.value,
            score: item.isSkipped ? null : (item.value / 5) * 100,
            isSkipped: item.isSkipped,
          });
        });

        const factorAvg =
          factorValues.length > 0 ? parseNum(mean(factorValues)) : null;

        tDecision.factors[fKey] = {
          value: factorAvg,
          avg: factorAvg,
          comment: factor.comment,
          isSkipped: skippedArray.every((i) => i),
          score: factorScores.length > 0 ? parseNum(mean(factorScores)) : null,
          questions: [
            ...(tDecision.factors[fKey]?.questions || []),
            ...factorQuestions,
          ],
        };
      });
      break;

    case !isNil(reason):
      factors.forEach((fKey) => {
        total += value;
        values.push(value);
        tDecision.factors[fKey] = {
          // isMainReason: reason.value === FACTOR_TYPE[fKey.toUpperCase()],
          isMainReason:
            DECISION_FACTOR_TYPE[reason.name?.toUpperCase()] ===
            FACTOR_TYPE[fKey.toUpperCase()],
          avg: value,
          value,
          score: parseNum(value * 20),
          questions: [
            ...(tDecision.factors[fKey]?.questions || []),
            {
              ...fQuestion,
              score: parseNum(value * 20),
              category_type: fKey,
              question_title: `Overall ${capitalize(fKey)}`,
              value,
            },
          ],
        };
      });
      break;
    case !isNil(isAdded):
      factors.forEach((fKey) => {
        const yesNoVal = (isAdded && 5) || 0;
        total += yesNoVal;
        values.push(yesNoVal);

        tDecision.factors[fKey] = {
          avg: yesNoVal,
          value: yesNoVal,
          score: parseNum(yesNoVal * 20),
          questions: [
            ...(tDecision.factors[fKey]?.questions || []),
            {
              ...fQuestion,
              score: parseNum(yesNoVal * 20),
              category_type: fKey,
              question_title: `Overall ${capitalize(fKey)}`,
              value: yesNoVal,
            },
          ],
        };
      });
      break;
  }

  const dScores = Object.values(tDecision.factors || {}).reduce(
    (a, v) => (!isNil(v.score) ? [...a, v.score] : a),
    []
  );
  tDecision.avg = parseNum(total / values.length);
  tDecision.score = parseNum(mean(dScores));

  return tDecision;
};

const setCompanyEvaluatorStats = ({
  type,
  evaluation,
  decision,
  decStats,
  factorsMap,
  totalValue,
  values,
  pId,
}) => {
  decStats.avg = parseNum(totalValue / values.length);
  decStats.value = totalValue / values.length;
  decStats.dataPoints = (decStats.dataPoints || 0) + 1;
  decStats.factorsMap = factorsMap;

  const factorsList = Object.values(factorsMap).map((el) => el.score);

  decStats.totalScore = mean(factorsList);

  const timelineDecision = getTimelineDecision({
    type,
    decision,
    ev: evaluation,
    factors: Object.keys(factorsMap),
    pId,
  });

  decStats.timeline = [...(decStats.timeline || []), timelineDecision];
};

const setFormStats = ({
  decStats = {},
  compStats = {},
  factors,
  decision,
  evaluation,
  pId,
}) => {
  let totalValue = decStats.totalValue || 0;
  const values = decStats.values || [];
  const factorsMap = decStats.factorsMap || {};

  const { date, form_data } = decision;

  factors.forEach((fKey) => {
    const factor = form_data[fKey];
    if (!factor) return;
    factor.items.forEach((item) => {
      setUserFactorStats({
        factorsMap,
        fKey,
        type: EVALUATION_FORM_TYPES.FORM,
        value: item.value,
        question: item,
        evId: evaluation.evaluation_id,
        ownerId: pId,
        createdAt: date || evaluation.created_at,
      });

      if (item.isSkipped) return;

      totalValue += item.value;
      values.push(item.value);

      // setCompanyFactorsStats({ compStats, fKey, value: item.value });
    });
  });

  // SETUP COMPANY EVALUATOR STATISTIC
  setCompanyEvaluatorStats({
    type: EVALUATION_FORM_TYPES.FORM,
    evaluation,
    decision,
    decStats,
    factorsMap,
    totalValue,
    values,
    pId,
  });
};

const setFactorStats = ({
  decStats = {},
  compStats = {},
  factors,
  evaluation,
  decision,
  pId,
}) => {
  const { reason, date, value } = decision;

  let totalValue = decStats.totalValue || 0;
  const decValues = decStats.values || [];
  const factorsMap = decStats.factorsMap || {};

  factors.forEach((fKey) => {
    if (fKey === 'yes_no') return;

    totalValue += value;
    decValues.push(value);

    setUserFactorStats({
      factorsMap,
      fKey,
      type: EVALUATION_FORM_TYPES.DIGITS,
      value,
      question: decision,
      evId: evaluation.evaluation_id,
      ownerId: pId,
      createdAt: date || evaluation.created_at,
    });

    // setCompanyFactorsStats({ compStats, fKey, value });
  });

  // SETUP COMPANY EVALUATOR STATISTIC
  setCompanyEvaluatorStats({
    type: EVALUATION_FORM_TYPES.DIGITS,
    evaluation,
    decision,
    decStats,
    factorsMap,
    totalValue,
    values: decValues,
    pId,
  });
};

const setYesNoStats = ({
  decStats = {},
  compStats = {},
  factors,
  evaluation,
  decision,
  isAdded,
  pId,
}) => {
  const value = (isAdded && 5) || 0;

  let totalValue = decStats.totalValue || 0;
  const decValues = decStats.values || [];
  const factorsMap = decStats.factorsMap || {};

  factors.forEach((fKey) => {
    totalValue += value;
    decValues.push(value);

    setUserFactorStats({
      factorsMap,
      fKey,
      type: EVALUATION_FORM_TYPES.YES_NO,
      value,
      question: decision,
      evId: evaluation.evaluation_id,
      ownerId: pId,
      createdAt: decision.date || evaluation.created_at,
    });

    // setCompanyFactorsStats({ compStats, fKey, value });
  });

  // SETUP COMPANY EVALUATOR STATISTIC
  setCompanyEvaluatorStats({
    type: EVALUATION_FORM_TYPES.YES_NO,
    evaluation,
    decision,
    decStats,
    factorsMap,
    totalValue,
    values: decValues,
    pId,
  });
};

const getDecisionStats = ({
  evaluation,
  decision,
  compStats,
  pId,
  customFactors,
}) => {
  const params = customFactors || Object.values(FACTORS_MAP);

  const decStats = {
    values: [],
    variation: null,
    factorsMap: {},
    timeline: [],
    totalScore: 0,
    dataPoints: 0,
    value: 0,
    avg: 0,
    list_id: evaluation.list_id,
    ...(compStats.decisions?.[pId] || {}),
  };

  const { form_data, reason, isAdded } = decision;

  switch (true) {
    case !isNil(form_data):
      setFormStats({
        decStats,
        compStats,
        factors: params,
        decision,
        evaluation,
        pId,
      });
      break;

    case !isNil(reason):
      setFactorStats({
        decStats,
        compStats,
        factors: params,
        decision,
        evaluation,
        pId,
      });
      break;
    case !isNil(isAdded):
      setYesNoStats({
        decStats,
        compStats,
        factors: params,
        isAdded,
        decision,
        evaluation,
        pId,
      });
      break;
  }

  return decStats;
};

const setCompanyDecisions = ({ compStats, evaluation, companyId }) => {
  evaluation.evaluations?.forEach((ev) => {
    const dec = ev.decisions[companyId];
    // Check if current decision of this evaluation match the company id
    if (dec) {
      const pId = ev.user_id || ev.email;

      const curMemberDecisionStats = getDecisionStats({
        evaluation,
        decision: dec,
        compStats,
        pId,
      });

      // Sort current user decisions for this company
      curMemberDecisionStats.timeline.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      // DEFAULT_MEMBER_DECISION_STATS => Example of curMemberDecisionStats
      compStats.decisions = {
        ...(compStats.decisions || {}),
        [pId]: curMemberDecisionStats,
      };

      // Adding data points on each evaluation
      compStats.dataPoints = (compStats.dataPoints || 0) + 1;
    }
  });
};

const getFilteredEvaluations = ({ evs, userId, listId, formId }) => {
  let filteredEvaluations = evs;

  if (userId)
    filteredEvaluations = filteredEvaluations.filter((ev) =>
      ev.participants.some((p) => (p.user_id || p.email) === userId)
    );

  if (listId)
    filteredEvaluations = filteredEvaluations.filter(
      (ev) => ev.list_id === listId
    );

  if (formId)
    filteredEvaluations = filteredEvaluations.filter(
      (ev) => ev.form_id === formId
    );

  return filteredEvaluations;
};

const getCompanyStatsFromEval = ({
  evaluations,
  companyId,
  customFactors,
  userId,
  listId,
  formId,
}) => {
  let evs = Array.isArray(evaluations)
    ? evaluations
    : Object.values(evaluations);

  const factorsNames = customFactors || Object.values(FACTORS_MAP);

  evs = getFilteredEvaluations({ evs, userId, listId, formId });

  const result = evs.reduce((compStats, curEval) => {
    // 1) Check if current evaluation contain company and decision
    if (
      curEval.company_id_list.includes(companyId) &&
      curEval.evaluations.some((c) => !isNil(c.decisions[companyId]))
    ) {
      compStats.allCompanyEvaluations = [
        ...(compStats.allCompanyEvaluations || []),
        curEval.evaluation_id,
      ];

      // 2) Setup company statistic + decision makers based on decision of current evaluation
      setCompanyDecisions({
        compStats,
        evaluation: curEval,
        companyId,
      });
    }

    // 3) Return overall statistic for company
    return compStats;
  }, {});

  Object.keys(result.decisions || {}).forEach((decKey) => {
    const dec = result.decisions[decKey];
    const lastEval = dec.timeline?.[0] || {};
    const lastEvalFactors = lastEval.factors || {};

    if (!isNil(lastEval.score)) {
      result.decisions[decKey].lastEvalScore = lastEval.score;
    }

    Object.values(FormsHelper.CategoryType).forEach((fKey) => {
      if (lastEvalFactors[fKey]) {
        setCompanyFactorsStats({
          compStats: result,
          fKey,
          value: lastEvalFactors[fKey].value,
          isSkipped: lastEvalFactors[fKey].isSkipped,
        });
      }
    });
  });

  const formFactors = Object.values(result.factorsMap || {}).reduce(
    (fMap, f) => {
      // DEFAULT_COMPANY_STATS_FACTOR => Example of {f} data

      const isValidFactor =
        factorsNames.includes(f.title) && f.title !== 'yes_no';

      if (isValidFactor) {
        const avg = f.total / f.values.length;
        const factorValue = parseNum(avg / 5);
        const factorScore = avg * 20;

        fMap[f.title] = {
          ...f,
          avg,
          score: factorScore,
          value: factorValue,
        };
      }

      return fMap;
    },
    {}
  );

  const scoreUsers = Object.values(result.decisions || {}).reduce(
    (a, v) => (isNil(v.lastEvalScore) ? a : [...a, v.lastEvalScore]),
    []
  );

  const companyScore = parseNum(mean(scoreUsers));

  let stdDev = null;

  if (result.dataPoints > 1) {
    // DEFAULT_MEMBER_DECISION_STATS => Example of result.decisions object
    const decisionMakersDataList = Object.values(result.decisions || {});
    const allScores = decisionMakersDataList.map(
      (val) => (val.timeline?.[0]?.score || 0) / 100
    );
    stdDev = calcStdDev(allScores);
  }

  return {
    scoreList: [],
    dataPoints: result.dataPoints,
    companyScore,
    std: parseNum(stdDev?.sampleVariance, 3),
    decisions: result.decisions || {},
    allCompanyEvaluations: result.allCompanyEvaluations,
    stats: formFactors,
  };
};

const isCompanyHasEvaluations = (evaluationStats, compId) => {
  const compEvals = Object.values(evaluationStats || {}).reduce((acc, ev) => {
    if (ev?.companies?.[compId]) {
      acc.push(ev?.evaluation_id);
    }
    return acc;
  }, []);

  return compEvals.length > 0;
};

export {
  FACTORS_MAP,
  parseNum,
  getCompanyStatsFromEval,
  getCompanyTotalScore,
  getEvalTotalZScore,
  mapEvaluationMembers,
  mapEvaluationMembers2,
  getLastEvaluation,
  getZScoredPercentage,
  getCommunityDataPoints,
  getMemberEvaluations,
  getMemberVsEcosystemEvaluationRatio,
  getMemberDataPoints,
  getMemberTotalFormScores,
  getTotalCommunityScore,
  getTotalCommunityDataPoints,
  getTotalEvaluatedCompanies,
  getCompanyTotalDataPoints,
  sortEvaluationsByTime,
  getMemberEvaluationsMap,
  getAllEvaluatedCompanies,
  getCompanyScoreFromStats,
  getMemberFactorMap,
  calculateUserFactorStats,
  calculateCompanyFactors,
  getCompanyScoreFromFactor,
  isEvaluationCompleteByMe,
  getMemberFormScores,
  didUserCompleteEvaluation,
  fillParticipantEvaluations,
  isCompanyHasEvaluations,
};
