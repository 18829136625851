import localForage from 'localforage';
import { attr, Model } from 'redux-orm';
import { GenerateRandomAvatar } from '../lib/constants';
import merge from 'deepmerge';

/**
 * Active user model
 * @author Villi K.
 */
class User extends Model {
  static get modelName() {
    return 'User';
  }

  static get fields() {
    return {
      user_id: attr(),
      email: attr(),
      roles: attr(),
      token: attr(),
      device: attr(),
      profile: attr(),
      isLoggedIn: attr(),
      is_first_login: attr(),
      is_welcome_visible: attr(),
      last_action_time: attr(),
      hideCompaniesPromoTo: attr(),
    };
  }

  /**
   * Declare static reducer for redux hook
   * @param action
   * @param User
   * @param session
   * @returns {undefined}
   */
  static reducer(action, User, session) {
    if (!User) return;
    switch (action.type) {
      case 'CREATE_USER': {
        if (User) {
          User?.delete();
        }

        const userData = action.payload || {};
        if (!userData.profile.avatar || !userData.profile.avatar.thumb) {
          const randImg = GenerateRandomAvatar();
          userData.profile.avatar = {
            original: randImg,
            thumb: randImg,
          };
        }

        const new_user = {
          ...userData,
          is_welcome_visible: false,
          isLoggedIn: true,
        };

        try {
          window.localStorage.setItem('user_data', JSON.stringify(new_user));
        } catch (error) {
          console.log(error);
          console.error('Session will expired: ' + error.message);
        }

        User.create(new_user);
        break;
      }
      case 'UPDATE_USER':
        User.withId(action.payload.id).update(action.payload);
        break;
      case 'SET_USER_MFA': {
        const profileData = User.last();
        const { isMfaEnabled } = action.payload;

        try {
          window.localStorage.setItem(
            'user_data',
            JSON.stringify({
              ...profileData._fields,
              mfa_enabled: isMfaEnabled,
            })
          );
        } catch (error) {
          console.log(error);
          console.error('Session will expired: ' + error.message);
        }

        profileData.update({
          mfa_enabled: isMfaEnabled,
        });
        break;
      }
      case 'SET_USER_FIRST_LOGIN': {
        const profileData = User.last();
        const { isFirstTime } = action.payload;

        try {
          window.localStorage.setItem(
            'user_data',
            JSON.stringify({
              ...profileData._fields,
              is_first_login: isFirstTime,
            })
          );
        } catch (error) {
          console.log(error);
          console.error('Session will expired: ' + error.message);
        }

        profileData.update({
          is_first_login: isFirstTime,
        });
        break;
      }
      case 'SET_USER_WELCOME_VISIBLE': {
        const profileData = User.last();
        const { isWelcomeVisible } = action.payload;

        try {
          window.localStorage.setItem(
            'user_data',
            JSON.stringify({
              ...profileData._fields,
              is_welcome_visible: isWelcomeVisible,
            })
          );
        } catch (error) {
          console.log(error);
          console.error('Session will expired: ' + error.message);
        }
        profileData.update({
          is_welcome_visible: isWelcomeVisible,
        });
        break;
      }
      case 'UPDATE_USER_PROFILE':
        const profileData = User.last();
        const { profile } = action.payload;

        const newProfileData = merge(profileData.profile, profile);

        try {
          window.localStorage.setItem(
            'user_data',
            JSON.stringify({
              ...profileData._fields,
              profile: {
                ...(profileData.profile || {}),
                ...(newProfileData || {}),
              },
            })
          );
        } catch (error) {
          console.log(error);
          console.error('Session will expired: ' + error.message);
        }

        profileData.update({
          profile: {
            ...(profileData.profile || {}),
            ...(newProfileData || {}),
          },
        });
        break;
      case 'UPDATE_USER_PREFERENCES': {
        const profileData = User.last();
        const { preferences } = action.payload;
        if (!profileData || !preferences) return;

        try {
          window.localStorage.setItem(
            'user_data',
            JSON.stringify({
              ...profileData._fields,
              profile: {
                ...(profileData.profile || {}),
                user_preferences: preferences,
              },
            })
          );
        } catch (error) {
          console.log(error);
          console.error('Session will expired: ' + error.message);
        }
        profileData.update({
          profile: {
            ...(profileData.profile || {}),
            user_preferences: preferences,
          },
        });
        break;
      }
      case 'UPDATE_USER_SETTINGS': {
        const profileData = User.last();
        const { settings } = action.payload;
        if (!profileData || !settings) return;

        const newSettings = {
          ...(profileData.profile?.user_settings || {}),
          ...settings,
        };

        try {
          window.localStorage.setItem(
            'user_data',
            JSON.stringify({
              ...profileData._fields,
              profile: {
                ...(profileData.profile || {}),
                user_settings: newSettings,
              },
            })
          );
        } catch (error) {
          console.log(error);
          console.error('Session will expired: ' + error.message);
        }

        profileData.update({
          profile: {
            ...(profileData.profile || {}),
            user_settings: newSettings,
          },
        });
        break;
      }
      case 'UPDATE_USER_LAST_ACTION':
        const userData = User.last();
        // userData.last_action_time = Date.now();
        // User.withId(profileData.id).update(userData);
        break;
      case 'UPDATE_PROMO_HIDE_COMPANIES':
        {
          const profileData = User.last();
          const { hideTo } = action.payload;
          const newList = [
            ...(profileData?.hideCompaniesPromoTo || []),
            ...hideTo,
          ];
          const filteredList = newList.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.companyId === value.companyId && t.hideTo === value.hideTo
              )
          );
          try {
            window.localStorage.setItem(
              'hideCompaniesPromoTo',
              JSON.stringify(filteredList)
            );
          } catch (err) {
            console.log(err);
          }
          profileData.update({
            hideCompaniesPromoTo: filteredList,
          });
        }
        break;
      case 'REMOVE_USER':
        try {
          window.localStorage.removeItem('user_data');
          window.localStorage.removeItem('communities_data');
          User?.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export default User;
export { User };
