import { genUniqueId } from '~lib/utils/strings';

class CatalogsHelpers {
  static CATALOGS_VIEW_TYPES = {
    INVESTORS: 'investors',
    HUBS: 'hubs',
    RECOMMENDED: 'recommended',
  };

  static defaultCatalogName = 'default';

  static defaultCatalog = {
    catalog_id: `${Date.now()}_${Math.floor(Math.random() * 1000 + 1)}`,
    user_id: null,
    community_id: null,
    catalog_title: '',
    catalog_img: {
      thumb: '/imgs_new/vertical_imgs/blockchain.jpg',
      origin: '',
    },
    created_by: 'KLEVER Team',
    catalog_description:
      'Exploring the emerging robotics startups that are advancing the robotics industry',
    catalog_items: [],
    is_public: null,
    tags: [],
    updated_at: Date.now(),
    created_at: Date.now(),
  };

  static catalogDescriptions = {
    'healthtech_&_biotech':
      'Disocver best-fit  HealthTech solution in a $10 trillion market- from medical devices to e-health applications.',
    energy:
      'Energy Transition spend hit $500 billion in 2020. Discover best-fit energy solutions to accelerate your own transition',
    sustainability:
      'Sustainability and Green Tech will hit $74 billion by 2030. Discover solutions good for the world and good for your bottom-lines.',
    mobility:
      'Mobility market expected to hit $777 billion by 2030. Discover the new tech solutions in drones, electrical cars and smart cities.',
    'agritech_&_foodtech':
      'Food industry generated $8.2 trillion in 2021, AgriTech generated $41 billion. Discover best-fit soltuions from cultured meat and agtech robotics.',
    edtech:
      'EdTech will reach $2.3 trillion by 2028 in US alone. Discover solutions that educate our society in the new hybrid era.',
    'fintech_&_insurtech':
      'Fintech is a 305 billion market by 2025, InsurTech is growing rapidly to $159B market by 2021. Discover solutions from KYC to pet insurance.',
    'government_&_military':
      'GovTech market will extremely grow from $400 billion to $1 trillion market by 2025. Discover solutions to make our government smarer and efficient.',
    'logistics_&_manufacturing':
      'Lositics market generated $8.6 trillion so far, manufacturing will reach $117 billion by 2025. Discover best-fit solutions to digitalize supply chain and smartly track your assets.',
    maritime:
      'Marine vessel market will value $188.5 billion by 2028. Discover best-fit solutions from smart port to low-carbon.',
    'privacy_&_security':
      'Cyber Security market is projected to grow to $376 billion by 2029. Discover best-fit solutions to keep your data and systems safe.',
    'construction_&_real_estate':
      'The global construction market is expected to grow to $15.2 trillion by 2030. Real Estate is already a $10.5 trillion market. Discover solutions from smart buildings, materials and blockchain that change our homes.',
    'retail_&_consumer_goods':
      'In 2021, retail e-commerce sales amounted to $4.9 trillion worldwide and will reach $7.4 trillion by 2025. Discover solutions that will get you there and change the way we consume.',
    traveltech:
      'The travel market size was valued at $695.9 billion in 2020, and is expected to reach $2 trillion by 2028. Discover tech solutions that change the way we travel in a new era- from ticketing to space travel.',
    telecommunications:
      'The global telecom market size was estimated at $1.6 trillion in 2020 and is expected to reach $1.7 trillion in 2021. Discover tech solutions that change the way we communicate.',
  };

  static defaultCatalogsBank = [
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.HUBS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/nlc.png',
      },
      catalog_title: 'NLC - European Healthtech Venture Builder',
      catalog_description: `NLC's mission is to advance health by making impactful medical innovations accessible for everyone.`,
      tags: [
        'Accelerator',
        'Biopharma',
        'Health Care',
        'HealthTech & BioTech',
        'Health Diagnostics',
        'Innovation Management',
        'Medical Device',
      ],
      catalog_items: [
        'bab90fdb-cbf9-4405-b526-1c61e621167a',
        'b9b20338-7e9e-4868-8f19-2cba9ea12d80',
        '961746b6-5a0a-4ed8-9294-1848be2a1fc9',
        'ef0cd965-bb66-4ed5-8248-82f4a0959495',
        '4ab89081-02fc-4c27-a78d-840dc68b87b5',
        '7aa26ff0-80cb-46de-8537-04495c3043f3',
        '3a850597-74c2-4475-8230-20eeb9ccf6ef',
        '74a46b13-9a4b-491c-8542-2469ff4e09e6',
        '4b8ab14c-0528-48b2-851b-abee45745873',
        'a64859f7-d3b2-47bd-9ef4-76115a2c42e0',
      ],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.HUBS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/hvn.jpeg',
      },
      catalog_title: 'Health Valley Netherlands',
      catalog_description:
        'We identify issues in healthcare, bring public and private parties together and accelerate innovation through the use of technology.',
      tags: [
        'Accelerator',
        'Biotechnology',
        'Health Care',
        'HealthTech & BioTech',
        'Health Diagnostics',
        'Pharmaceutical',
        'Medical Device',
        'Robotics',
      ],
      catalog_items: [
        '3c55e05d-50da-4d5a-80e6-d740106523d9',
        'df7cb2f4-67d4-4c46-846a-d6b6f76ba86f',
        '7213af45-263d-49fc-ad71-5fd413e2a33d',
        '9c6a90de-764e-42d1-9ef4-ee2609dd452d',
        '2626d554-2096-4337-885a-16f2a23156db',
        '8beb7975-42fa-4923-8f68-ed7d3291381c',
        '6595d4ce-ca74-40c4-b6ab-4bb51977d38e',
        '65bb68a7-0edb-4db0-967c-ec804fda9ac8',
        'c5b6b20b-0112-4be1-bb93-f4d0eb2ef9a7',
        'a5d88a00-9d8f-4a5c-83d6-09656dd3cbbb',
      ],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.HUBS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/smile.png',
      },
      catalog_title: 'SmiLe Incubator',
      catalog_description: `SmiLe is a business incubator specialized in life science. Passionate, driven innovators advance the future of health and their businesses here.`,
      tags: [
        'Incubator',
        'Health tech & Biotech',
        'Health Care',
        'HealthTech & BioTech',
        'Health Diagnostics',
        'Medical Device',
        'Robotics',
        'Pharmaceutical',
      ],
      catalog_items: [
        '821153d9-1495-43d1-a1d5-3c18bcdb281a',
        'a040f11e-023e-485d-b68f-ca87ca2a824d',
        '608e76e8-f2c6-496d-a9d0-15953465cec1',
        '1611bbec-80ac-4b87-bff2-9f83f683bbcd',
        '76536e9d-1da9-414c-9c9f-d9831e17ddad',
        '06a1b464-a483-48ef-8ca7-af5544ba7096',
        '8d38192c-b3fa-4392-9b3a-7546a894ffaa',
        '52974a91-aead-4627-a729-f041eeb87a22',
        '0746c84d-c7e0-465f-ac55-d4f355892739',
        '52974a91-aead-4627-a729-f041eeb87a22',
      ],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.HUBS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/base_launch.jpeg',
      },
      catalog_title: 'BaseLaunch',
      catalog_description: `We partner with scientists and entrepreneurs to launch and build exceptional biotech companies.`,
      tags: [
        'Incubator',
        'Health tech & Biotech',
        'Health Care',
        'HealthTech & BioTech',
        'Health Diagnostics',
        'Pharmaceutical',
        'Medical Device',
        'Robotics',
      ],
      catalog_items: [
        'c7d29cfc-04a7-48a8-b107-cf11fc52e2fa',
        '9ba7a23a-0d7d-4b6b-a259-49df5415e2cd',
        '36edae04-0911-4cae-bd1e-a100865b5fae',
        '8d07f9df-3434-43f6-a188-ad113d9feafa',
        'e81c90cc-0a40-42f5-864e-1162eac597ed',
        '31a46ca3-01b0-4215-8773-9dddcfd2d0f5',
        '85d562cb-b138-482d-abe5-ab25489276fe',
        'a890614c-b828-49ff-ac8d-21fe967ae6df',
        '95a06394-df86-4f0f-94a8-4aa60acf10f5',
        'f621f5e2-02a9-4def-8506-76807ef17361',
      ],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.HUBS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/open.png',
      },
      catalog_title: 'Open Accelerator',
      catalog_description: `At Open Accelerator (OA) we are looking for the world’s leading innovators whose digital solutions are shaping the future of health, thanks to the adoption of cutting-edge technologies.`,
      tags: [
        'Accelerator',
        'Wearables',
        'Biomarkers',
        'Biopharma',
        'Biotechnology',
      ],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.HUBS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/health.png',
      },
      catalog_title: 'Health Venture Lab',
      catalog_description: `A global network helping teams disrupt healthcare.`,
      tags: [
        'Artificial Intelligence',
        'Accelerator',
        'HealthTech & BioTech',
        'Health tech & Biotech',
        'Medtech',
      ],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.HUBS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/medtech.png',
      },
      catalog_title: 'Medtech Accelerator',
      catalog_description: `We provide funding for the early stage development of innovative medical devices, diagnostics and digital health solutions that meet unmet clinical needs within the NHS and have the potential to grow into disruptive healthtech companies.`,
      tags: [
        'Accelerator',
        'HealthTech & BioTech',
        'Health tech & Biotech',
        'Artificial Intelligence',
        'Medtech',
      ],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.HUBS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/incubator.jpeg',
      },
      catalog_title: 'Health Incubator Helsinki',
      catalog_description: `Health Incubator Helsinki is a unique three-year incubator program for research-based health sector teams and startups.`,
      tags: [
        'Accelerator',
        'HealthTech & BioTech',
        'Health tech & Biotech',
        'Artificial Intelligence',
        'Medtech',
      ],
      catalog_items: [],
    },
    // <-- RECOMMENDED -->
    // {
    //   view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
    //   catalog_img: {
    //     thumb: '/imgs_new/catalogs/healthcare/surgery.jpg',
    //   },
    //   catalog_title: 'Surgery',
    //   catalog_description: `Startups taking a variety of approaches to innovating inside of the Neuroscience industry and around the world.`,
    //   tags: ['Surgery', 'Health tech & Biotech'],
    //   catalog_items: [
    //     '6233ff05-0ad0-4984-ad4a-b539fdf250fe',
    //     '6a2efcb5-b6ea-4cfd-845d-9bacc91300a7',
    //     '08698257-1732-474d-8128-5047e4096543',
    //     'd57069f5-6b29-4a64-ac5c-7ca809a57f25',
    //     '4830104d-7138-479c-8413-8fc4c7ddd259',
    //     '9ec65b85-8ecb-4ae7-a0ac-edc742410334',
    //     '5607fa1c-fd76-407e-a777-14acba632ed9',
    //     '6bf6d03a-c806-4aac-99a6-d69e8803f5f7',
    //     'aab2f108-5f71-41b3-804f-308f268169d4',
    //     '48fd7804-6967-4218-8f4c-1754ca72f0ab',
    //   ],
    // },
    // {
    //   view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
    //   catalog_img: {
    //     thumb: '/imgs_new/catalogs/healthcare/neuroscience.jpg',
    //   },
    //   catalog_title: 'Neuroscience',
    //   catalog_description: `Startups taking a variety of approaches to innovating inside of the Neuroscience industry and around the world.`,
    //   tags: ['Neuroscience', 'Health tech & Biotech'],
    //   catalog_items: [
    //     '6233ff05-0ad0-4984-ad4a-b539fdf250fe',
    //     '6a2efcb5-b6ea-4cfd-845d-9bacc91300a7',
    //     '08698257-1732-474d-8128-5047e4096543',
    //     'd57069f5-6b29-4a64-ac5c-7ca809a57f25',
    //     '4830104d-7138-479c-8413-8fc4c7ddd259',
    //     '9ec65b85-8ecb-4ae7-a0ac-edc742410334',
    //     '5607fa1c-fd76-407e-a777-14acba632ed9',
    //     '6bf6d03a-c806-4aac-99a6-d69e8803f5f7',
    //     'aab2f108-5f71-41b3-804f-308f268169d4',
    //     '48fd7804-6967-4218-8f4c-1754ca72f0ab',
    //   ],
    // },
    // {
    //   view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
    //   catalog_img: {
    //     thumb: '/imgs_new/catalogs/healthcare/cardiovascular.jpg',
    //   },
    //   catalog_title: 'Cardiovascular',
    //   catalog_description: `Companies engaged in R&D for developing medical devices, diagnostics tests, and pharmaceuticals for the diagnosis and treatment of cardiac and vascular disorders.`,
    //   tags: ['Cardiovascular', 'Health tech & Biotech'],
    //   catalog_items: [
    //     'f9ccf97d-cedd-464e-a00f-d8c2d6d89dbc',
    //     'f6a0f682-2208-4d20-9fd2-fa8be2a4d028',
    //     '57b30ae1-4ecf-4db6-87d6-f644b4fae8fa',
    //     'f5b39991-bb6b-4ae9-af8f-b02e73142560',
    //     '4de933fb-18da-4df5-b436-265dcefb670a',
    //     'f1c54ecd-bb91-404f-9427-688902ccaa8a',
    //     'cd0dff20-35a2-444b-a082-0268c959559f',
    //     '0e78c397-2b9c-4c03-9c3d-8ff17908eb52',
    //     '43c3f52d-0b3c-4862-888a-4f6420152dfd',
    //     '3180ebe3-1e8b-4389-a49f-7c9a658d401b',
    //   ],
    // },
    // {
    //   view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
    //   catalog_img: {
    //     thumb: '/imgs_new/catalogs/healthcare/diabetes.jpg',
    //   },
    //   catalog_title: 'Diabetes',
    //   catalog_description: `Digital Therapeutic intervention driven by software programs to prevent, manage, or treat Diabetes.`,
    //   tags: ['Diabetes', 'Health tech & Biotech', 'Medical Devices'],
    //   catalog_items: [
    //     '3fee527b-6857-49b3-8ebc-c6c55bf175f5',
    //     '08435562-e6ab-4d9e-91fb-b2ea5260055c',
    //     '18bb2ab1-137d-46c0-a03a-0c40aaf703fb',
    //     'cadbb292-d8f7-4cb8-b52d-dce1aaec488c',
    //     'eae69ca1-82de-4b02-abca-3519e12b4644',
    //     '82aac67f-9989-4284-a5b8-0bd193b3057f',
    //     '3cf902d8-285d-4d2f-ae25-3f36710a6aec',
    //     'd1fb02d5-e9e1-4935-a273-3d46f0140f8a',
    //     'f98430cd-a9a3-4d4f-961f-dd731fca9dc3',
    //     '8163fd5f-314a-48b8-9ccd-8b86a247791e',
    //   ],
    // },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/agetech.jpg',
      },
      catalog_title: 'Agetech',
      query: 'elderly and elderly care and elder',
      catalog_description: `Digital tech that’s built around the needs and wants of older adults.`,
      tags: [
        'Elder Care',
        'Wearablesa',
        'Medical Devices',
        'Health Care',
        'HealthTech & BioTech',
      ],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/mental_health.jpg',
      },
      catalog_title: 'Mental Health',
      query: 'Mental Health and Wellness',
      catalog_description: `Mental health solutions injecting technology to improve sleep and stress to avoid burnout.`,
      tags: [
        'Sleep',
        'Stress',
        'Corporate Wellness',
        'Mental Health',
        'Health Care',
        'HealthTech & BioTech',
      ],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/medical_imaging.jpg',
      },
      catalog_title: 'Medical Imaging',
      query: 'Medical Imaging',
      catalog_description: `Technologies that are used to view the human body in order to diagnose, monitor, or treat medical conditions.`,
      tags: ['Medtech', 'Imaging', 'Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/epilepsy.jpeg',
      },
      catalog_title: 'Epilepsy',
      query: 'Epilepsy',
      catalog_description: `Noninvasive technology to help epilepsy patients.`,
      tags: [
        'Medtech',
        'Epilepsy',
        'Medical Devices',
        'Health Care',
        'HealthTech & BioTech',
      ],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/drug_discovery.jpg',
      },
      catalog_title: 'Drug Discovery',
      query: 'Drug Discovery',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/wearables.jpg',
      },
      catalog_title: 'Wearables',
      query: 'health wearables',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/monitoring.jpg',
      },
      catalog_title: 'Monitoring',
      query: 'medical monitoring and remote monitoring',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/biopharma.jpg',
      },
      catalog_title: 'BioPharma',
      query: 'pharmaceutical',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/telehealth.jpg',
      },
      catalog_title: 'Telehealth',
      query: 'Telehealth and Telemedicine',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/screening_diagnostics.jpg',
      },
      catalog_title: 'Screening & Diagnostics',
      query: 'Health Diagnostics',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/female_health.jpg',
      },
      catalog_title: 'FemTech',
      query: 'Femalehealth',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/immunology.jpg',
      },
      catalog_title: 'Immunology',
      query: 'autoimmune disease',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/oncology.jpg',
      },
      catalog_title: 'Oncology',
      query: 'oncology and cancer',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/fitness_nutrition.jpg',
      },
      catalog_title: 'Fitness & Nutrition',
      query: 'Fitness & Nutrition',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/omics.jpg',
      },
      catalog_title: 'Omics',
      query: 'Omics',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    // {
    //   view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
    //   catalog_img: {
    //     thumb:
    //       '/imgs_new/catalogs/healthcare/workflow_digitalisation_automation.jpg',
    //   },
    //   catalog_title: 'Workflow Digitalisation & Automation',
    //   query: 'clinical workflow and process automation',
    //   tags: ['Health Care', 'HealthTech & BioTech'],
    //   catalog_items: [],
    // },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/clinical_trials.jpg',
      },
      catalog_title: 'Clinical Trials',
      query: 'Clinical Trials',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/patient_data_analysis.jpg',
      },
      catalog_title: 'Patient data analysis',
      query: 'Electronic Health Record EHR',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/health_plans.jpg',
      },
      catalog_title: 'Health Plans & Benefit Management',
      query: 'Health Insurance',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/life_science.jpg',
      },
      catalog_title: 'Life Science',
      query: 'life science',
      tags: ['Health Care', 'HealthTech & BioTech'],
      catalog_items: [],
    },

    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.INVESTORS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/gilde.jpeg',
      },
      catalog_title: 'Gilde Healthcare',
      catalog_description: `Gilde Healthcare is a specialized healthcare
      investor managing with two fund strategies:
      Venture&Growth and Private Equity.`,
      tags: [
        'Gilde Healthcare',
        'Venture Capital',
        'HealthTech & BioTech',
        'Biotechnology',
        'Health Care',
        'HealthTech & BioTech',
        'Medical Device',
        'Biopharma',
      ],
      catalog_items: [
        'dc83e6fa-ebcd-438f-8186-6966b250bff1',
        '72718bf8-99db-41c8-a512-810fb65fea42',
        'a5a8c540-348c-4a0f-861d-ad0539683f9c',
        '68022bde-3e2f-496e-a370-0ea53c4842e5',
        '391e1bd0-5551-4508-9015-8fc3a474d6cd',
        '001b5856-59bd-4af0-bd5f-a0f5a971db30',
        '3180ebe3-1e8b-4389-a49f-7c9a658d401b',
        '040d673d-e9d7-4159-a8aa-39b19b5a987e',
        '656ebe41-682a-4724-bc0f-b519aafad1a0',
        '7e740923-ff8c-4ef0-bd72-0b32ec1dae4d',
      ],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.INVESTORS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/creathor.jpeg',
      },
      catalog_title: 'Creathor Ventures',
      catalog_description: `Creathor Ventures invests in technology-oriented companies that drive the automation of industry and business, and the personalization and digitalization of healthcare.`,
      tags: [
        'Creathor Ventures',
        'Venture Capital',
        'Artificial Intelligence',
        'HealthTech & BioTech',
        'Therapeutics',
        'Genetics',
        'Biopharma',
      ],
      catalog_items: [
        '41234f47-c8bd-414b-a002-095235b5b18b',
        '572831e3-d610-43d7-9c16-b35b07846871',
        '302a3ce9-fa7d-43b0-9ae0-ecd6e8078a7b',
        '1c202d8d-2ad6-4c45-99c1-7fe5397088d5',
        '0199f1a8-c3fa-498e-b563-b9f517cfee2f',
        '72f9e827-2757-48a1-9a74-3dd0aef3d068',
        '89f8e26e-f481-4964-bf83-d69706e3e7f4',
        '35999def-33b1-43d4-a345-b3ac1d0e6cbd',
        '7949ba1b-091c-4a86-ac40-c78b8c0c2709',
        'b12c9232-e848-4eb2-8712-f38a097b1995',
      ],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.INVESTORS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cipio.png',
      },
      catalog_title: 'Cipio Partners',
      catalog_description: `European Growth Capital and Minority Buyouts for Technology Companies.`,
      tags: [
        'Cipio Partners',
        'Venture Capital',
        'Artificial Intelligence',
        'Machine Learning',
        'Predictive Analytics',
        'Medical Device',
      ],
      catalog_items: [
        '61ed5a17-611e-480c-a267-f4ddf4438e57',
        '326d84ec-b4c1-4eb7-ae64-6cf490dcfd70',
        '3cfdc7cc-369e-4613-9ce5-9cbf61961e42',
        'b675c4fd-7302-42b9-b3f6-037fc7716a91',
        'cd57230b-ba0d-4b26-b84b-60d88bdabce6',
        '44e2daa3-c8b8-447b-bc29-90b2ec055130',
        '6ee658e8-0763-4e77-b78d-4db384a59d18',
        'f3715d50-075d-4fab-a7e1-67966062b8f1',
        '8d6197be-bc2b-4018-b299-157b92b40123',
        '556d7708-a508-4857-ab92-fbccafa77ef4',
      ],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.INVESTORS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cottonwood.png',
      },
      catalog_title: 'Cottonwood Technology Fund',
      catalog_description: `Cottonwood’s investment approach and focus is on hard science, pre-seed / seed and early-stage funding in IP-driven companies which provide Key Enabling Technologies.`,
      tags: [
        'Cottonwood Technology Fund',
        'HealthTech & BioTech',
        'Energy',
        'Cleantech',
        'Sustainability',
        'Biotechnology',
        'Manufacturing',
        'Venture Capital',
      ],
      catalog_items: [
        'a69c3211-e16b-4519-8304-3f9b2913eaa3',
        '23ad7ed7-1a86-4967-84e5-bbaec126478d',
        'e8d82995-1a98-4bc9-ae1b-f7de2ba070af',
        'b7336961-eb79-484f-a170-263f9d17023c',
        '3e84b9dd-2788-4969-ad72-03ea6ff45ebd',
        '4b386a3c-29ff-485b-aa55-0792facc1b09',
        '60f37f6d-81ad-4fe4-b299-d8af01705360',
        '97b16905-bf5e-4b94-819c-1347181a1900',
        '7bc0d780-46f5-47b2-a29e-6b31dee42e69',
        'f8bb5a10-fc53-4401-8e6c-1873bef4e2cd',
      ],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.INVESTORS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/inventages.png',
      },
      catalog_title: 'Inventages',
      catalog_description: `Formed with the support of Nestlé, one of the world’s first strategic life-sciences, nutrition and wellness focused venture capital fund managers. We add value by supporting our portfolio companies with their strategy development, assisting in major financial transactions, recruiting key executives and having access to valuable exit opportunities.`,
      tags: [
        'Inventages',
        'HealthTech & BioTech',
        'Energy',
        'Biotechnology',
        'Biopharma',
        'Fitness & Nutrition',
        'Venture Capital',
      ],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.INVESTORS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/noaber.jpeg',
      },
      catalog_title: 'Noaber Ventures',
      catalog_description: `Driving change, improving health - That is our mission. We want to improve health and encourage change. The latter preferably within systems.`,
      tags: [
        'Noaber',
        'HealthTech & BioTech',
        'Lifestyle Medicine',
        'Population health',
        'Wellness',
        'Fitness & Nutrition',
        'Venture Capital',
      ],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.INVESTORS,
      catalog_img: {
        thumb: '/imgs_new/catalogs/peppermint.png',
      },
      catalog_title: 'Peppermint Venture Partners (PVP)',
      catalog_description: `Peppermint VenturePartners (PVP), a private Venture Capital firm based in Berlin, Germanyis dedicated to supporting outstanding entrepreneurs and innovative scientists in transforming healthcare.`,
      tags: [
        'Peppermint Venture Partners',
        'Medtech',
        'Health tech & Biotech',
        'Digital Health',
        'Medical Innovation',
        'Venture Capital',
      ],
      catalog_items: [],
    },
    // {
    //   view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
    //   catalog_img: {
    //     thumb: '/imgs_new/catalogs/seed_capital.png',
    //   },
    //   catalog_title: 'Seed Capital',
    //   catalog_description: `Seed Capital has established itself as the most successful seed stage investor in Denmark. We look for ambitious tech companies born in Denmark.`,
    //   tags: ['Seed capital', 'Early stage ', 'Technologies', 'Venture Capital'],
    //   catalog_items: [],
    // },

    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/energy_generation.jpg',
      },
      catalog_title: 'Energy Generation',
      query: 'Energy Generation and renewables',
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/energy_storage.jpg',
      },
      catalog_title: 'Energy Storage',
      query: `Energy storage`,
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/energy_distribution.jpg',
      },
      catalog_title: 'Energy Distribution',
      query: `Energy distribution and smart grid`,
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/energy_efficiency.jpg',
      },
      catalog_title: 'Energy Efficiency',
      query: `energy efficiency applications`,
      tags: ['Energy'],
      catalog_items: [],
    },
    // {
    //   view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
    //   catalog_img: {
    //     thumb: '/imgs_new/catalogs/energy/energy_savings.jpg',
    //   },
    //   catalog_title: 'Energy Savings',
    //   query: 'energy conservation',
    //   tags: ['Energy'],
    //   catalog_items: [],
    // },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/wind.jpg',
      },
      catalog_title: 'Wind',
      query: 'Wind energy',
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/solar.jpg',
      },
      catalog_title: 'Solar',
      query: 'Solar Energy and renewable',
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/biomass.jpg',
      },
      catalog_title: 'Biomass',
      query: 'Biomass Energy',
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/biofuels.jpg',
      },
      catalog_title: 'BioFuels',
      query: 'Biofuel',
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/geothermal_energy.jpg',
      },
      catalog_title: 'Geothermal Energy',
      query: 'Geothermal Energy',
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/hydrogen_generation.jpg',
      },
      catalog_title: 'Hydrogen Generation & Storage',
      query: 'Hydrogen Generation and Hydrogen storage',
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/batteries.jpg',
      },
      catalog_title: 'Batteries',
      query: 'battery and electrochemical batteries',
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/smart_grids.jpg',
      },
      catalog_title: 'Smart Grids',
      query: 'smart grid',
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/oil_and_gas.jpg',
      },
      catalog_title: 'Oil & Gas',
      query: 'oil and gas',
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/cleantech.jpg',
      },
      catalog_title: 'Cleantech',
      query: 'cleantech',
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/low_carbon.jpg',
      },
      catalog_title: 'Low Carbon',
      query: 'Carbon and cleantech',
      tags: ['Energy'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/materials.jpg',
      },
      catalog_title: 'Materials',
      query: 'materials',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/waste_management.jpg',
      },
      catalog_title: 'Waste Management',
      query: 'waste management and recycling',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/biochemical_material.jpg',
      },
      catalog_title: 'BioChemical Material',
      query: 'biochemical',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/bio_based_materials.jpg',
      },
      catalog_title: 'Biobased Material',
      query: 'biobased material',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/3d_printing_material.jpg',
      },
      catalog_title: '3D Printing Material',
      query: '3d printing and material',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/low_carbon.jpg',
      },
      catalog_title: 'Low Carbon',
      query: 'Carbon and cleantech',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/sharing_economy.jpg',
      },
      catalog_title: 'Circular Economy',
      query: 'sharing economy and circular economy',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/electrification.jpg',
      },
      catalog_title: 'Electrification',
      query: 'electric vehicles and EV',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/industry_automation.jpg',
      },
      catalog_title: 'Industry 4.0',
      query: 'industry 4.0 and industrial',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/foodtech.jpg',
      },
      catalog_title: 'FoodTech',
      query: 'Food tech and foodtech',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/agritech.jpg',
      },
      catalog_title: 'AgriTech',
      query: 'AgriTech and agtech',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/sustainable_proteins.jpg',
      },
      catalog_title: 'Sustainable proteins',
      query: 'protein and alternative ingredients',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/water_tech.jpg',
      },
      catalog_title: 'Water Tech',
      query: 'water',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/renewable_energy.jpg',
      },
      catalog_title: 'Renewable Energy',
      query: 'renewable energy',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/cleantech.jpg',
      },
      catalog_title: 'CleanTech',
      query: 'cleantech',
      tags: ['Sustainability'],
      catalog_items: [],
    },
    // <-- MOBILITY -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/autonomous_driving.jpg',
      },
      catalog_title: 'Autonomous Driving',
      query: 'autonomous driving, autonomous vehicles and lidar',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/drones.jpg',
      },
      catalog_title: 'Drones',
      query: 'drones',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/electrification.jpg',
      },
      catalog_title: 'Electrification',
      query: 'electric vehicles and EV',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/mobility_as_a_service.jpg',
      },
      catalog_title: 'Mobility-as-a-Service',
      query: 'mobility as a service',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/smart_infrastructure.jpg',
      },
      catalog_title: 'Smart Infrastructure',
      query: 'infrastructure and transportation',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/smart_cities.jpg',
      },
      catalog_title: 'Smart Cities',
      query: 'smart cities',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/3d_tech.jpg',
      },
      catalog_title: '3D Tech',
      query: 'automotive 3d',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/space_tech.jpg',
      },
      catalog_title: 'SpaceTech',
      query: 'space and aerospace technology',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/connected_cars.jpg',
      },
      catalog_title: 'Connected Cars',
      query: 'automotive internet of things and automotive sensors',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/last_mile.jpg',
      },
      catalog_title: 'Last Mile',
      query: 'last mile transportation',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/location_based_services.jpg',
      },
      catalog_title: 'Location based services',
      query: 'location based services and gps',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/energy/batteries.jpg',
      },
      catalog_title: 'Batteries',
      query: 'battery and electrochemical batteries',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/low_carbon.jpg',
      },
      catalog_title: 'Low Carbon',
      query: 'Carbon and cleantech',
      tags: ['Mobility'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/logistics_manufacturing/semiconductor.jpg',
      },
      catalog_title: 'Semiconductor',
      query: 'semiconductor',
      tags: ['Mobility'],
      catalog_items: [],
    },
    // <-- AgriTech -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/agritech_foodtech/farming_tech.jpg',
      },
      catalog_title: 'Farming Tech',
      query: 'farming',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/agritech_foodtech/agri_biotech.jpg',
      },
      catalog_title: 'Precision Agriculture',
      query: 'precision agriculture',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/agritech_foodtech/robotics.jpg',
      },
      catalog_title: 'Robotics',
      query: 'agtech robotics',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/agritech_foodtech/regenerative_agriculture.jpg',
      },
      catalog_title: 'Farm Management Software',
      query: 'Farm Management',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/water_tech.jpg',
      },
      catalog_title: 'Water Tech',
      query: 'water',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/sustainable_proteins.jpg',
      },
      catalog_title: 'Sustainable proteins',
      query: 'protein and alternative ingredients',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/agritech_foodtech/personalised_food.jpg',
      },
      catalog_title: 'Personal Nutrition',
      query: 'Personal Nutrition',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/fitness_nutrition.jpg',
      },
      catalog_title: 'Fitness & Nutrition',
      query: 'Fitness & Nutrition',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/agritech_foodtech/food_delivery.jpg',
      },
      catalog_title: 'Food Delivery',
      query: 'food delivery',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/agritech_foodtech/animal_feed.jpg',
      },
      catalog_title: 'Animal Feed',
      query: 'animal feed',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/agritech_foodtech/dairy_alternatives.jpg',
      },
      catalog_title: 'Dairy alternatives',
      query: 'Dairy alternative',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/agritech_foodtech/beverages.jpg',
      },
      catalog_title: 'Beverages',
      query: 'Beer and Wine and coffee and tea',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/agritech_foodtech/livestock_sea.jpg',
      },
      catalog_title: 'Livestock & Sea',
      query: 'meat, fish, livestock, cultured meat and alternative protein',
      tags: ['AgriTech & FoodTech'],
      catalog_items: [],
    },
    // <-- EdTech -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/edtech/language_learning.jpg',
      },
      catalog_title: 'Language Learning',
      query: 'Language Learning',
      tags: ['EdTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/edtech/learning_management_systems.jpg',
      },
      catalog_title: 'Learning Management Systems',
      query: 'e-learning',
      tags: ['EdTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/edtech/immersive_learning.jpg',
      },
      catalog_title: 'Immersive Learning',
      query: 'virtual edtech',
      tags: ['EdTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/edtech/upskilling.jpg',
      },
      catalog_title: 'Upskilling',
      query: 'skill learning',
      tags: ['EdTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/fitness_nutrition.jpg',
      },
      catalog_title: 'Fitness & Nutrition',
      query: 'Fitness & Nutrition',
      tags: ['EdTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/edtech/enterprise_learning.jpg',
      },
      catalog_title: 'Enterprise Learning',
      query: 'enterprise learning and employee training',
      tags: ['EdTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/edtech/tutoring_testing.jpg',
      },
      catalog_title: 'Tutoring & Testing',
      query: 'testing and tutoring',
      tags: ['EdTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/health_plans.jpg',
      },
      catalog_title: 'Health plans & Benefit Mangement',
      query: 'Health Insurance',
      tags: ['EdTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/edtech/stem_education.jpg',
      },
      catalog_title: 'STEM Education',
      query: 'STEM education',
      tags: ['EdTech'],
      catalog_items: [],
    },
    // <-- Fintech -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/blockchain.jpg',
      },
      catalog_title: 'Blockchain',
      query: 'blockchain in finance',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/sensors.jpg',
      },
      catalog_title: 'Sensors',
      query: 'sensors in finance',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/digital_banking.jpg',
      },
      catalog_title: 'Digital Banking',
      query: 'digital banking',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/fintech_insurtech/virtual_and_augmented_reality.jpg',
      },
      catalog_title: 'Virtual and Augmented Reality',
      query: 'virtual reality in finance',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/smart_contracts.jpg',
      },
      catalog_title: 'Smart Contracts',
      query: 'smart contracts in finance',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/fintech_insurtech/robotics_process_automation.jpg',
      },
      catalog_title: 'Robotics Process Automation (RPA)',
      query: 'Robotics Process Automation (RPA)',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/payments.jpg',
      },
      catalog_title: 'Payments',
      query: 'payments',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/fintech_insurtech/voice_enabled_payments.jpg',
      },
      catalog_title: 'Voice-Enabled Payments',
      query: 'Voice Payments and speech recognition',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/autonomous_finance.jpg',
      },
      catalog_title: 'Autonomous Finance',
      query: 'personal finance',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/biometric_security.jpg',
      },
      catalog_title: 'Biometric Security',
      query: 'biometrics and KYC',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/open_banking.jpg',
      },
      catalog_title: 'Open Banking',
      query: 'open banking',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/regtech.jpg',
      },
      catalog_title: 'RegTech',
      query: 'regtech and regulation',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/quantum_computing.jpg',
      },
      catalog_title: 'Quantum Computing',
      query: 'Quantum Computing',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/healthcare/health_plans.jpg',
      },
      catalog_title: 'Health plans & Benefit Mangement',
      query: 'Health Insurance',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/crypto.jpg',
      },
      catalog_title: 'Crypto',
      query: 'crypto and cryptocurrency',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/lending.jpg',
      },
      catalog_title: 'Lending',
      query: 'lending',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/investment_management.jpg',
      },
      catalog_title: 'Investment Management',
      query: 'investment management platforms',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/home_insurance.jpg',
      },
      catalog_title: 'Home Insurance',
      query: 'Home insurance and property insurance',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/car_insurance.jpg',
      },
      catalog_title: 'Car Insurance',
      query: 'Auto insurance',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/travel_insurance.jpg',
      },
      catalog_title: 'Travel Insurance',
      query: 'travel insurance applications',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/pet_insurance.jpg',
      },
      catalog_title: 'Pet Insurance',
      query: 'pet insurance',
      tags: ['Fintech & InsurTech'],
      catalog_items: [],
    },
    // <-- Goverment -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/government_military/smart_cities.jpg',
      },
      catalog_title: 'Smart Cities',
      query: 'smart cities',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/government_military/spacetech.jpg',
      },
      catalog_title: 'SpaceTech',
      query: 'space and aerospace technology',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/government_military/cyber_homeland_security.jpg',
      },
      catalog_title: 'Cyber & Homeland Security (HLS)',
      query: 'homeland security and cyber',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/government_military/robotics.jpg',
      },
      catalog_title: 'Robotics',
      query: 'military robotics',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/government_military/digital_identity_ecosystems.jpg',
      },
      catalog_title: 'Biometric Security',
      query: 'biometrics and KYC',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/government_military/advanced_defense_equipment.jpg',
      },
      catalog_title: 'Advanced Defense Equipment',
      query: 'defense hardware',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/government_military/internet_of_military_things.jpg',
      },
      catalog_title: 'Internet of Military Things',
      query: 'military iot',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/government_military/blockchain_government.jpg',
      },
      catalog_title: 'Blockchain',
      query: 'blockchain government',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/government_military/immersive_tech.jpg',
      },
      catalog_title: 'Immersive Tech',
      query: 'virtual reality, augmented reality and mixed reality',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/government_military/5G.jpg',
      },
      catalog_title: '5G',
      query: '5G',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/government_military/cloud_migration.jpg',
      },
      catalog_title: 'Cloud Migration',
      query: 'cloud migration and data migration',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/government_military/smart_government.jpg',
      },
      catalog_title: 'Smart Government',
      query: 'govtech',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/drones.jpg',
      },
      catalog_title: 'Drones',
      query: 'drones',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/mobility_as_a_service.jpg',
      },
      catalog_title: 'Mobility as a Service',
      query: 'mobility as a service',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/location_based_services.jpg',
      },
      catalog_title: 'Location based services',
      query: 'location based services and gps',
      tags: ['Government & Military'],
      catalog_items: [],
    },
    // <-- Logistics -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/logistics_manufacturing/logistics_supply_chain.jpg',
      },
      catalog_title: 'Logistics & Supply Chain',
      query: 'logistics and supply chain',
      tags: ['Logistics & Manufacturing'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/logistics_manufacturing/digital_freight_shipping.jpg',
      },
      catalog_title: 'Digital Freight Shipping',
      query: 'Freight Shipping',
      tags: ['Logistics & Manufacturing'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/logistics_manufacturing/ecommerce_logistics.jpg',
      },
      catalog_title: 'E-Commerce Logistics',
      query: 'E Commerce Logistics',
      tags: ['Logistics & Manufacturing'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/logistics_manufacturing/fleet_management.jpg',
      },
      catalog_title: 'Fleet Management',
      query: 'Trucking Marketplace and Fleet Management',
      tags: ['Logistics & Manufacturing'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/logistics_manufacturing/warehousing.jpg',
      },
      catalog_title: 'Warehousing',
      query: 'Warehousing and logistics',
      tags: ['Logistics & Manufacturing'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/logistics_manufacturing/sensors_asset_tagging.jpg',
      },
      catalog_title: 'Sensors & Asset Tagging',
      query: 'sensors and logistics and iot logistics',
      tags: ['Logistics & Manufacturing'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/logistics_manufacturing/inventory_management.jpg',
      },
      catalog_title: 'Inventory Management',
      query: 'Inventory Management',
      tags: ['Logistics & Manufacturing'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/last_mile.jpg',
      },
      catalog_title: 'Last Mile',
      query: 'last mile transportation',
      tags: ['Logistics & Manufacturing'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/logistics_manufacturing/semiconductor.jpg',
      },
      catalog_title: 'Semiconductor',
      query: 'semiconductor',
      tags: ['Logistics & Manufacturing'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/industry_automation.jpg',
      },
      catalog_title: 'Industry 4.0',
      query: 'industry 4.0 and industrial',
      tags: ['Logistics & Manufacturing'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/logistics_manufacturing/logistics_supply_chain.jpg',
      },
      catalog_title: 'Manufacturing Tech',
      query: 'Manufacturing and industrial robotics',
      tags: ['Logistics & Manufacturing'],
      catalog_items: [],
    },
    // <-- Maritime -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/logistics_manufacturing/logistics_supply_chain.jpg',
      },
      catalog_title: 'Logistics & Supply Chain',
      query: 'smart cities',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/maritime/marine_technology.jpg',
      },
      catalog_title: 'Marine Technology',
      query: 'marine technology',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/government_military/cyber_homeland_security.jpg',
      },
      catalog_title: 'Cyber & HLS',
      query: 'homeland security and cyber',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/maritime/shipping.jpg',
      },
      catalog_title: 'Shipping',
      query: 'shipping',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/logistics_manufacturing/sensors_asset_tagging.jpg',
      },
      catalog_title: 'Sensors & Asset Tagging',
      query: 'sensors and logistics and iot logistics',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/maritime/autonomous_vessel.jpg',
      },
      catalog_title: 'Autonomous Vessel',
      query: 'smart vessel and autonomous transportation',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/maritime/environment_safety.jpg',
      },
      catalog_title: 'Environment & Safety',
      query: 'safety and industry',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/maritime/smart_port.jpg',
      },
      catalog_title: 'Smart Port',
      query: 'port and marine technology',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/logistics_manufacturing/warehousing.jpg',
      },
      catalog_title: 'Warehousing',
      query: 'biometrics and KYC',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/maritime/maintenance_operations.jpg',
      },
      catalog_title: 'Maintenance & Operations',
      query: 'vessel maintenance and port operations',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/maritime/fuel_performance_management.jpg',
      },
      catalog_title: 'Fuel & Performance Management',
      query: 'Fuel & Performance Management',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/drones.jpg',
      },
      catalog_title: 'Drones',
      query: 'drones',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/3d_tech.jpg',
      },
      catalog_title: '3D Tech',
      query: 'automotive 3d',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/materials.jpg',
      },
      catalog_title: 'Materials',
      query: 'materials',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/maritime/5g.jpg',
      },
      catalog_title: '5G',
      query: '5G infrastructure',
      tags: ['Maritime'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/low_carbon.jpg',
      },
      catalog_title: 'Low Carbon',
      query: 'Carbon and cleantech',
      tags: ['Maritime'],
      catalog_items: [],
    },
    // <-- Privacy -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/privacy_security/data_security.jpg',
      },
      catalog_title: 'Data Security',
      query: 'Data security and protection',
      tags: ['Privacy & Security'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/government_military/cyber_homeland_security.jpg',
      },
      catalog_title: 'Cyber & HLS',
      query: 'homeland security and cyber',
      tags: ['Privacy & Security'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/privacy_security/ddos_attack_security.jpg',
      },
      catalog_title: 'DDoS Attack Security',
      query: 'DDos and Encryption',
      tags: ['Privacy & Security'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/privacy_security/blockchain.jpg',
      },
      catalog_title: 'Blockchain',
      query: 'blockchain security',
      tags: ['Privacy & Security'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/biometric_security.jpg',
      },
      catalog_title: 'Biometric Security',
      query: 'biometrics and KYC',
      tags: ['Privacy & Security'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/privacy_security/risk_compliance.jpg',
      },
      catalog_title: 'Risk management and compliance',
      query: 'risk management and compliance',
      tags: ['Privacy & Security'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/privacy_security/api_security.jpg',
      },
      catalog_title: 'API Security',
      query: 'API and app security',
      tags: ['Privacy & Security'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/privacy_security/threat_detection_analysis.jpg',
      },
      catalog_title: 'Threat Detection & Analysis',
      query: 'Threat Detection and threat intelligence',
      tags: ['Privacy & Security'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/privacy_security/identity_security.jpg',
      },
      catalog_title: 'Identity Security',
      query: 'Identity Security',
      tags: ['Privacy & Security'],
      catalog_items: [],
    },
    // {
    //   view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
    //   catalog_img: {
    //     thumb:
    //       '/imgs_new/catalogs/privacy_security/incident_management_response.jpg',
    //   },
    //   catalog_title: 'Incident Management & Response',
    //   query: 'cyber incident and response',
    //   tags: ['Privacy & Security'],
    //   catalog_items: [],
    // },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/privacy_security/endpoint_iot_security.jpg',
      },
      catalog_title: 'Endpoint & IoT Security',
      query: 'endpoint security',
      tags: ['Privacy & Security'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/privacy_security/cloud_security.jpg',
      },
      catalog_title: 'Cloud Security',
      query: 'cloud security',
      tags: ['Privacy & Security'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/privacy_security/devops.jpg',
      },
      catalog_title: 'DevOps',
      query: 'DevOps',
      tags: ['Privacy & Security'],
      catalog_items: [],
    },
    // <-- Construction -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/construction_real_estate/planning_design.jpg',
      },
      catalog_title: 'Planning & Design',
      query: 'building design and interior design',
      tags: ['Construction & Real Estate'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/construction_real_estate/smart_buildings.jpg',
      },
      catalog_title: 'Smart Buildings',
      query: 'smart building',
      tags: ['Construction & Real Estate'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/construction_real_estate/inspection__built_monitoring.jpg',
      },
      catalog_title: 'Construction Tech',
      query: 'construction and infrastructure technologies',
      tags: ['Construction & Real Estate'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/sustainability/materials.jpg',
      },
      catalog_title: 'Materials',
      query: 'materials and building methods',
      tags: ['Construction & Real Estate'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/construction_real_estate/proptech.jpg',
      },
      catalog_title: 'PropTech',
      query: 'real estate and proptech',
      tags: ['Construction & Real Estate'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/construction_real_estate/blockchain.jpg',
      },
      catalog_title: 'Blockchain',
      query: 'real estate blockchain',
      tags: ['Construction & Real Estate'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/construction_real_estate/marketplace.jpg',
      },
      catalog_title: 'Marketplaces',
      query: 'real estate marketplace',
      tags: ['Construction & Real Estate'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/logistics_manufacturing/warehousing.jpg',
      },
      catalog_title: 'Warehousing',
      query: 'Warehousing and logistics',
      tags: ['Construction & Real Estate'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/construction_real_estate/enhanced_onsite_execution.jpg',
      },
      catalog_title: 'Smart Cities',
      query: 'Smart Cities',
      tags: ['Construction & Real Estate'],
      catalog_items: [],
    },
    // <-- Retail -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/retail_consumer_goods/ecommerce_distribution.jpg',
      },
      catalog_title: 'Ecommerce Distribution',
      query: 'ecommerce and online shopping',
      tags: ['Retail & Consumer Goods'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/retail_consumer_goods/beauty_cosmetics.jpg',
      },
      catalog_title: 'Beauty & Cosmetics',
      query: 'beauty and cosmetics',
      tags: ['Retail & Consumer Goods'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/payments.jpg',
      },
      catalog_title: 'Payment Technologies ',
      query: 'payments',
      tags: ['Retail & Consumer Goods'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/retail_consumer_goods/instore_analytics_marketing.jpg',
      },
      catalog_title: 'In-Store Analytics & Marketing',
      query: 'shelf and store intelligence',
      tags: ['Retail & Consumer Goods'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/retail_consumer_goods/beacon_networks.jpg',
      },
      catalog_title: 'Beacon Networks',
      query: 'Beacon Networks',
      tags: ['Retail & Consumer Goods'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/retail_consumer_goods/apparel_clothing_technology.jpg',
      },
      catalog_title: 'Apparel',
      query: 'Apparel and clothing technology',
      tags: ['Retail & Consumer Goods'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/retail_consumer_goods/textile.jpg',
      },
      catalog_title: 'Textile',
      query: 'textile',
      tags: ['Retail & Consumer Goods'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/agritech_foodtech/beverages.jpg',
      },
      catalog_title: 'Beverages',
      query: 'Beer and Wine and coffee and tea',
      tags: ['Retail & Consumer Goods'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/retail_consumer_goods/immersive_shopping.jpg',
      },
      catalog_title: 'Immersive Shopping',
      query: '3d retail and virtual reality retail',
      tags: ['Retail & Consumer Goods'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/retail_consumer_goods/cashierless_shopping.jpg',
      },
      catalog_title: 'Cashierless shopping',
      query: 'no cash payment',
      tags: ['Retail & Consumer Goods'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/last_mile.jpg',
      },
      catalog_title: 'Last Mile',
      query: 'last mile transportation',
      tags: ['Retail & Consumer Goods'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/mobility/location_based_services.jpg',
      },
      catalog_title: 'Location based Services',
      query: 'location based services and gps',
      tags: ['Retail & Consumer Goods'],
      catalog_items: [],
    },
    // <-- TravelTech -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/traveltech/aviation.jpg',
      },
      catalog_title: 'Aviation',
      query: 'aviation and air transportation',
      tags: ['TravelTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/traveltech/space_travel.jpg',
      },
      catalog_title: 'Space Travel',
      query: 'Space Travel',
      tags: ['TravelTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/traveltech/ticketing.jpg',
      },
      catalog_title: 'Ticketing',
      query: 'travel and ticketing',
      tags: ['TravelTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/payments.jpg',
      },
      catalog_title: 'Payments',
      query: 'payments',
      tags: ['TravelTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/fintech_insurtech/voice_enabled_payments.jpg',
      },
      catalog_title: 'Voice-Enabled Payments',
      query: 'Voice Payments and speech recognition',
      tags: ['TravelTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/traveltech/contactless_travel.jpg',
      },
      catalog_title: 'Contactless Travel',
      query: 'contactless travel',
      tags: ['TravelTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/traveltech/airporttech.jpg',
      },
      catalog_title: 'Airport Tech',
      query: 'airport',
      tags: ['TravelTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/fintech_insurtech/travel_insurance.jpg',
      },
      catalog_title: 'Travel Insurance',
      query: 'travel insurance applications',
      tags: ['TravelTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/traveltech/immersive_experience.jpg',
      },
      catalog_title: 'Immersive Experience',
      query: 'virtual reality travel and augmented reality tourism',
      tags: ['TravelTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/traveltech/sustainabile_airport.jpg',
      },
      catalog_title: 'Sustainabile Airport',
      query: 'aviation sustainability and clean air',
      tags: ['TravelTech'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/traveltech/entertainment.jpg',
      },
      catalog_title: 'Entertainment',
      query: 'entertainment',
      tags: ['TravelTech'],
      catalog_items: [],
    },
    // <-- Telecommunications -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/telecommunications/5g.jpg',
      },
      catalog_title: '5G',
      query: '5G and telecommunication',
      tags: ['Telecommunications'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/telecommunications/infrastructure.jpg',
      },
      catalog_title: 'Communication Infrastructure',
      query: 'communication infrastructure',
      tags: ['Telecommunications'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/telecommunications/mobile_connectivity.jpg',
      },
      catalog_title: 'Mobile Connectivity',
      query: 'Mobile and cellular Connectivity',
      tags: ['Telecommunications'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/telecommunications/meterverse.jpg',
      },
      catalog_title: 'Metaverse',
      query: 'Metaverse',
      tags: ['Telecommunications'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/telecommunications/network_functions_virtualization.jpg',
      },
      catalog_title: 'Network Functions Virtualization',
      query: 'Network Functions Virtualization',
      tags: ['Telecommunications'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/telecommunications/satellite_communications.jpg',
      },
      catalog_title: 'Satellite Communications',
      query: 'satellite communications',
      tags: ['Telecommunications'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/telecommunications/cloud_computing.jpg',
      },
      catalog_title: 'Cloud\nComputing',
      query: 'Cloud Computing',
      filter_key: 'Cloud Computing',
      tags: ['Telecommunications'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/telecommunications/omnichannel_communication.jpg',
      },
      catalog_title: 'Omnichannel communication',
      query: 'communication and customer engagement',
      tags: ['Telecommunications'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/telecommunications/video_streaming.jpg',
      },
      catalog_title: 'Broadcasting & Video streaming',
      query: 'Broadcasting and Video streaming',
      tags: ['Telecommunications'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/telecommunications/quantum_computing.jpg',
      },
      catalog_title: 'Quantum Computing',
      query: 'Quantum Computing',
      tags: ['Telecommunications'],
      catalog_items: [],
    },

    // <-- CYBER -->
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/data_security.jpg',
      },
      catalog_title: 'Data Security',
      question: 'Data protection',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/ddos_attack_security.jpg',
      },
      catalog_title: 'DDoS Attack Security',
      question: 'DDos Attack and Encryption',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/blockchain_tokenization.jpg',
      },
      catalog_title: 'Blockchain & Tokenization',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/risk_compliance.jpg',
      },
      catalog_title: 'Risk & Compliance',
      question: 'Governance and risk',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/identity_access_management.jpg',
      },
      catalog_title: 'Identity & Access Management',
      question: 'Identity Management and Access Management',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/application_api_security.jpg',
      },
      catalog_title: 'Application & API Security',
      question: 'API and App Security',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/monitoring_operations.jpg',
      },
      catalog_title: 'Monitoring & Operations',
      question: 'Analytics and network performance',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb:
          '/imgs_new/catalogs/cyber/vulnerability_assessment_management.jpg',
      },
      catalog_title: 'Vulnerability Assessment & Management',
      question: 'Vulnerability assessment management and Cyber Exposure',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/change_management.jpg',
      },
      catalog_title: 'Change Management',
      question: 'Asset management and system management',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/threat_detection_analysis.jpg',
      },
      catalog_title: 'Threat Detection & Analysis',
      question: 'Threat Intelligence and malware detection',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/devops.jpg',
      },
      catalog_title: 'DevOps',
      question: 'DevSecOps and Testing and Optimization',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/incident_management_response.jpg',
      },
      catalog_title: 'Incident Management & Response',
      question: 'Incident Management Response and forensics ',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/security_operations.jpg',
      },
      catalog_title: 'Security Operations',
      question: 'Security Intelligence',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/network_security.jpg',
      },
      catalog_title: 'Network Security',
      question: 'Firewall and wireless',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/endpoint_iot_security.jpg',
      },
      catalog_title: 'Endpoint & IoT Security',
      question: 'IoT Security and Endpoint Security',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/data_center.jpg',
      },
      catalog_title: 'Data Center',
      question: 'DNS and email security ',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/cloud_security.jpg',
      },
      catalog_title: 'Cloud Security',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
    {
      view_type: CatalogsHelpers.CATALOGS_VIEW_TYPES.RECOMMENDED,
      catalog_img: {
        thumb: '/imgs_new/catalogs/cyber/cloud_application_security.jpg',
      },
      catalog_title: 'Cloud Application Security',
      question: 'Container security and compliance',
      tags: ['Cyber Security', 'Cyber'],
      catalog_items: [],
    },
  ];

  static fetchCatCompanyByName = async (onFetchCompaniesById, name) => {
    const response = await onFetchCompaniesById(name);

    if (response.success) {
      const company = response.results.find((i) => i.company_name === name);

      return company;
    }

    return null;
  };

  static getIndustriesWithPriority = (user, withDefault) => {
    const industriesPriority = {
      sustainability: 1,
      maritime: 2,
      'logistics_&_manufacturing': 3,
      'fintech_&_insurtech': 4,
      'agritech_&_foodtech': 5,
      mobility: 6,
      energy: 7,
      'healthtech_&_biotech': 8,
    };

    const userIndustries = Array.isArray(
      user?.profile?.user_preferences?.industries
    )
      ? user?.profile?.user_preferences?.industries
      : [];

    const res =
      userIndustries.map((tag) => ({
        priority:
          industriesPriority[tag.toLowerCase()?.replace(/ /g, '_')] || 0,
        tag,
      })) || [];

    return withDefault && !!res.lengh
      ? [
          {
            tag: 'HealthTech & BioTech',
            img_path: '/imgs_new/catalogs/healthcare/medical_imaging.jpg',
            priority: 1,
          },
          {
            tag: 'AgriTech & FoodTech',
            img_path: '/imgs_new/catalogs/agritech_foodtech/agri_biotech.jpg',

            priority: 2,
          },
          {
            tag: 'Mobility',
            img_path: '/imgs_new/catalogs/mobility/smart_cities.jpg',
            priority: 3,
          },
          {
            tag: 'Energy',
            img_path: '/imgs_new/catalogs/energy/energy_generation.jpg',
            priority: 4,
          },
        ]
      : res;
  };

  static buildCatalogs = async ({
    catalogs = {},
    tags = [],
    vendors = [],
    onFetchCompaniesById = () => {},
  }) => {
    try {
      const kleverCatalogs = CatalogsHelpers.defaultCatalogsBank.reduce(
        (acc, val) => {
          const catalogId = genUniqueId();

          acc[catalogId] = {
            ...CatalogsHelpers.defaultCatalog,
            ...val,
            catalog_id: catalogId,
          };

          return acc;
        },
        {}
      );

      // for (const key of Object.keys(kleverCatalogs)) {
      //   for (
      //     let idx = 0;
      //     idx < kleverCatalogs[key].catalog_items.length;
      //     idx++
      //   ) {
      //     const id = kleverCatalogs[key].catalog_items[idx];

      //     const response = await onFetchCompaniesById(id);

      //     if (response?.success) {
      //       kleverCatalogs[key].catalog_items[idx] = response.company;
      //     }
      //   }

      //   kleverCatalogs[key].catalog_items = kleverCatalogs[
      //     key
      //   ].catalog_items.filter((i) => i?.company_id);
      // }

      const createdCatalogs = {
        ...catalogs,
        ...kleverCatalogs,
        // [CatalogsHelpers.defaultCatalog.catalog_id]: {
        //   ...CatalogsHelpers.defaultCatalog,
        //   catalog_id: CatalogsHelpers.defaultCatalog.catalog_id,
        //   created_by: 'John Doe',
        //   tags: tags,
        //   catalog_title: 'Top 10 Vendors by LinkedIn',
        //   catalog_items: vendors.length ? vendors : [],
        // },
      };

      return createdCatalogs;
    } catch (err) {
      console.error(err);
      return {};
    }
  };
}

export default CatalogsHelpers;
