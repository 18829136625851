import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiChats {
  static async getChats(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/chat/get?communityId=${communityId || ''}`,
        {
          signal: KLAbortController.genController('getChats').signal,
          // mode: 'no-cors',
          // credentials: 'omit',
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getChats');

      // const parsedData = decompress(json);

      // KleverApi.handleErrorCodes(parsedData.code);
      KleverApi.handleErrorCodes(json.code);
      if (!json.success || !Array.isArray(json)) {
        KleverApi.handleErrorMessage(json);
      }
      // console.log(json);

      // const chats = json.rooms.reduce((a, r) => {
      //   a[r.chat_id] = r;
      //   return a;
      // }, {});

      // ChatsHelpers.getChats(communityId, userId);
      return json;
      return parsedData;
    } catch (err) {
      return null;
    }
  }

  static async getMessages(chatId, communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/chat/messages/get?communityId=${communityId || ''}&chatId=${
          chatId || ''
        }`,
        {
          signal: KLAbortController.genController('getMessages').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getMessages');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async createChat({ communityId, participants, token }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/chat/create`, {
        signal: KLAbortController.genController('createChat').signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
        body: JSON.stringify({
          communityId,
          participants,
        }),
      });

      KLAbortController.clearController('createChat');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }
  static async createVendorChat({ communityId, participants, token }) {
    try {
      const response = await fetch(`${API_URL}/chat/vendor`, {
        signal: KLAbortController.genController('createVendorChat').signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
        body: JSON.stringify({
          communityId,
          participants,
        }),
      });

      KLAbortController.clearController('createVendorChat');

      const json = await response.json();

      // KleverApi.handleErrorCodes(json.code);
      // if (!json.success) {
      //   KleverApi.handleErrorMessage(json);
      // }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async approveChat(communityId, chatId, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/chat/approve`, {
        signal: KLAbortController.genController('approveChat').signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
        body: JSON.stringify({
          communityId,
          chatId,
        }),
      });
      KLAbortController.clearController('approveChat');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async sendMessage({
    communityId,
    clientId,
    chatId,
    messageData,
    token,
  }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/chat/messages/create`,
        {
          signal: KLAbortController.genController('sendMessage').signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
          body: JSON.stringify({
            communityId,
            chatId,
            clientId,
            messageData,
          }),
        }
      );
      KLAbortController.clearController('sendMessage');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async sendMessagesBatch({ communityId, chatId, messages, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/chat/messages/create`,
        {
          signal: KLAbortController.genController('sendMessage').signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
          body: JSON.stringify({
            communityId,
            chatId,
            clientId,
            messageData,
          }),
        }
      );
      KLAbortController.clearController('sendMessage');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async viewMessages({ communityId, chatId, messagesArr, token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/chat/messages/set_viewed`,
        {
          signal: KLAbortController.genController('viewMessages').signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
          body: JSON.stringify({
            communityId,
            chatId,
            messagesArr,
          }),
        }
      );
      KLAbortController.clearController('viewMessages');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }
}

export default ApiChats;
