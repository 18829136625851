import { emitLocalEvent } from '~lib/utils/events';

import { worker } from 'webworker-util';
import originalFetch from 'isomorphic-fetch';
import fetchBuilder from 'fetch-retry';

import ApiAnalytics from './analytics';
import ApiApp from './app';
import ApiAuth from './auth';
import ApiBoards from './boards';
import ApiChats from './chats';
import ApiComments from './comments';
import ApiCommunity from './community';
import ApiCompanies from './companies';
import ApiEvaluations from './evaluations';
import ApiExplore from './explore';
import ApiFiles from './files';
import ApiHeartbeat from './heartbeat';
import ApiInvites from './invites';
import ApiCatalogs from './catalogs';
import ApiForms from './forms';
import ApiTags from './tags';
import ApiLists from './lists';
import ApiCategories from './categories';
import ApiML from './ml';
import ApiNotes from './notes';
import ApiNotifications from './notifications';
import ApiOnboarding from './onboarding';
import ApiPortfolio from './portfolios';
import ApiProfile from './profile';
import ApiProjects from './projects';
import ApiStartup from './startup';
import ApiCustomFields from './custom_fields';

class KleverApi {
  static Analytics = ApiAnalytics;
  static App = ApiApp;
  static Auth = ApiAuth;
  static Community = ApiCommunity;
  static Portfolio = ApiPortfolio;
  static Onboarding = ApiOnboarding;
  static Notifications = ApiNotifications;
  static Evaluations = ApiEvaluations;
  static Profile = ApiProfile;
  static Files = ApiFiles;
  static Chats = ApiChats;
  static Notes = ApiNotes;
  static CustomFields = ApiCustomFields;
  static Comments = ApiComments;
  static Invites = ApiInvites;
  static Explore = ApiExplore;
  static Projects = ApiProjects;
  static Startup = ApiStartup;
  static Companies = ApiCompanies;
  static Heartbeat = ApiHeartbeat;
  static Boards = ApiBoards;
  static Catalogs = ApiCatalogs;
  static Forms = ApiForms;
  static Tags = ApiTags;
  static Lists = ApiLists;
  static Categories = ApiCategories;
  static ML = ApiML;

  static handleErrorMessage(obj) {
    if (!obj.message && !obj.msg) return;
    if (obj.code === 4003) return;

    emitLocalEvent('notify.notification', {
      notification: {
        message: obj.msg || obj.message || '',
        type: obj.code || '10000',
      },
    });
  }

  static handleErrorCodes(code) {
    if (code === undefined || code === null) return;
    switch (code) {
      case 4003: {
        window.did_session_end = true;
        emitLocalEvent('session.logout');
        break;
      }
    }
  }

  static async workerFallbackFetch(path, props) {
    try {
      if (window.did_session_end) return null;

      const data = await fetch(path, props);
      const json = await data.json();
      return json;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  static async fetchRetry(path, props) {
    try {
      if (window.did_session_end) return null;

      const options = {
        retries: 3,
        retryDelay: 3000,
      };

      const fetch = fetchBuilder(originalFetch, options);
      const data = await fetch(path, props);
      const json = await data.json();
      return json;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  static async workerFetch(path, props = {}, useFallback) {
    try {
      const _data = await KleverApi.workerFallbackFetch(path, props);
      return _data;

      if (useFallback) {
        const data = await KleverApi.workerFallbackFetch(path, props);
        return data;
      }

      delete props.signal;

      if (props.body)
        props.body =
          typeof props.body !== 'string'
            ? JSON.stringify(props.body)
            : props.body;

      const str = JSON.stringify(props);

      const data = await worker`(async () => {
        try{
          const obj = JSON.parse(${str});
          const {headers, body, method} = obj;
          const data = await fetch(
            ${path},
            {
              headers,
              body: body || null,
              method
            }
          );
          const json = await data.json();
          return json;
        } catch(err) {console.log(err)}
      })();`;
      return data;
    } catch (err) {
      console.error(err, path, props);
      return null;
    }
  }
}

const KApi = (user) => {
  return {
    Auth: ApiAuth,
    Community: ApiCommunity,
    Portfolio: ApiPortfolio,
    Onboarding: ApiOnboarding,
    Notifications: ApiNotifications,
    Evaluations: ApiEvaluations,
    Profile: ApiProfile,
    Files: ApiFiles,
    handleErrorMessage: (obj) => {
      if (obj.message === undefined && obj.msg === null) return;
      emitLocalEvent('notify.notification', {
        notification: {
          message: obj.msg || obj.message,
          type: obj.code || '10000',
        },
      });
    },
    handleErrorCodes: (code) => {
      if (code === undefined || code === null) return;
      switch (code) {
        case 4003: {
          emitLocalEvent('session.logout');
          break;
        }
      }
    },
  };
};

export default KleverApi;
