import { attr, Model } from 'redux-orm';

import { genUniqueId } from '~lib/utils/strings';
import {
  mapEvaluationMembers,
  fillParticipantEvaluations,
  getMemberEvaluationsMap,
  getCompanyStatsFromEval,
  getEvalTotalZScore,
  getCompanyScoreFromStats,
  getMemberFactorMap,
  calculateUserFactorStats,
  calculateCompanyFactors,
} from '~lib/klever/evaluationHelpers';

/**
 * Communities model
 * @author Villi K.
 */
class Evaluations extends Model {
  static get modelName() {
    return 'Evaluations';
  }

  static get fields() {
    return {
      evaluationList: attr(),
      evaluationStats: attr(),
      vendorsStats: attr(),
      didLoad: attr(),
    };
  }

  static refreshEvaluationStats(evaluationList) {
    const vendorsStats = evaluationList.reduce((acc, val) => {
      try {
        val.company_id_list.forEach((companyId) => {
          if (!acc[companyId]) {
            const res = getCompanyStatsFromEval({
              evaluations: evaluationList,
              companyId,
            });

            acc[companyId] = res;
          }
        });

        return acc;
      } catch (e) {
        console.log(e);
        return acc;
      }
    }, {});

    return { vendorsStats };
  }

  static reducer(action, Evaluations, session) {
    if (!Evaluations) return;
    switch (action.type) {
      case 'CREATE_EVALUATIONS_DB':
        // Evaluations.delete();

        const { evaluationList = [] } = action.payload;
        const evaluationsDB = {
          evaluationList: {},
        };

        evaluationList.forEach((item) => {
          evaluationsDB.evaluationList[item.evaluation_id] = item;
        });

        const { vendorsStats } = Evaluations.refreshEvaluationStats(
          evaluationList.filter((i) => i.evaluation_type !== 'klever_share')
        );

        evaluationsDB.vendorsStats = vendorsStats;

        Evaluations.create(evaluationsDB);
        break;
      case 'INSERT_EVALUATIONS': {
        const evaluationsDB = Evaluations.last();
        if (!evaluationsDB) return;

        const { evaluationList = [] } = action.payload;

        // evaluationsDB.didLoad = true;
        const evaluations = evaluationList.reduce((acc, val) => {
          acc[val.evaluation_id] = val;
          return acc;
        }, {});

        const otherEvaluations = evaluationList.reduce((a, v) => {
          switch (v.status) {
            case 'in-progress': {
              a.pendingEvaluations = fillParticipantEvaluations(
                v,
                a.pendingEvaluations || {}
              );
              break;
            }
            case 'expired': {
              a.expiredEvaluations = fillParticipantEvaluations(
                v,
                a.expiredEvaluations || {}
              );
              break;
            }
            default:
              break;
          }

          return a;
        }, {});

        const { vendorsStats } = Evaluations.refreshEvaluationStats(
          evaluationList.filter((i) => i.evaluation_type !== 'klever_share')
        );

        evaluationsDB.update({
          didLoad: true,
          evaluationList: {
            ...(evaluationsDB.evaluationList || {}),
            ...evaluations,
          },
          pendingEvaluations: otherEvaluations.pendingEvaluations || {},
          expiredEvaluations: otherEvaluations.expiredEvaluations || {},
          vendorsStats: {
            ...(evaluationsDB.vendorsStats || {}),
            ...vendorsStats,
          },
        });
        break;
      }
      case 'INSERT_EVALUATION': {
        const evaluationsDB = Evaluations.last();
        if (!evaluationsDB) return;

        const { evaluationId, data } = action.payload;
        if (!evaluationId || !data) return;

        evaluationsDB.evaluationList[evaluationId] = data;

        const { vendorsStats } = Evaluations.refreshEvaluationStats(
          Object.values(evaluationsDB.evaluationList || {}).filter(
            (i) => i.evaluation_type !== 'klever_share'
          )
        );

        evaluationsDB.update({
          didLoad: true,
          evaluationList: { ...evaluationsDB.evaluationList },
          vendorsStats: {
            ...(evaluationsDB.vendorsStats || {}),
            ...vendorsStats,
          },
        });
        break;
      }
      case 'UPDATE_EVALUATION': {
        const evaluationsDB = Evaluations.last();
        if (!evaluationsDB) return;

        const { evaluationId, data } = action.payload;

        if (evaluationsDB.evaluationList[evaluationId]) {
          evaluationsDB.evaluationList[evaluationId] = {
            ...evaluationsDB.evaluationList[evaluationId],
            ...data,
          };

          const { vendorsStats } = Evaluations.refreshEvaluationStats(
            Object.values(evaluationsDB.evaluationList || {}).filter(
              (i) => i.evaluation_type !== 'klever_share'
            )
          );

          evaluationsDB.update({
            didLoad: true,
            evaluationList: evaluationsDB.evaluationList,
            vendorsStats: {
              ...(evaluationsDB.vendorsStats || {}),
              ...vendorsStats,
            },
          });
        }
        break;
      }
      case 'REMOVE_EVALUATION': {
        const evaluationsDB = Evaluations.last();
        if (!evaluationsDB) return;

        const { evaluationId } = action.payload;
        if (evaluationsDB.evaluationList[evaluationId]) {
          delete evaluationsDB.evaluationList[evaluationId];

          const { vendorsStats } = Evaluations.refreshEvaluationStats(
            Object.values(evaluationsDB.evaluationList || {}).filter(
              (i) => i.evaluation_type !== 'klever_share'
            )
          );

          evaluationsDB.vendorsStats = vendorsStats;

          evaluationsDB.update();
        }
        break;
      }
      case 'DELETE_EVALUATIONS_DB':
        try {
          Evaluations.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Evaluations };
export default Evaluations;
