import localForage from 'localforage';
import { attr, Model } from 'redux-orm';
import ImageUtils from '~lib/utils/images';

/**
 * Communities model
 * @author Villi K.
 */
class Communities extends Model {
  static get modelName() {
    return 'Communities';
  }

  static get fields() {
    return {
      communityList: attr(),
      didLoad: attr(),
      didMembershipsLoad: attr(),
      didActivityFeedLoad: attr(),
      didStatsLoad: attr(),
      expertsList: attr(),
      expertsIsLoading: attr(),
    };
  }

  static reducer(action, Communities, session) {
    if (!Communities) return;
    switch (action.type) {
      case 'CREATE_COMMUNITY_DB':
        const { communityList = [] } = action.payload;
        const communityDB = {};
        communityList.forEach((item) => {
          communityDB[item.community_id] = item;
        });
        Communities.create({
          communityList: communityDB,
          didLoad: true,
          expertsList: [],
          expertsIsLoading: false,
        });
        break;
      case 'INSERT_COMMUNITIES': {
        Communities.delete();

        let communityDB = Communities.last();
        if (!communityDB) {
          communityDB = Communities.create({
            communityList: {},
            didLoad: true,
            expertsList: [],
            expertsIsLoading: false,
          });
        }

        const newCommunityList = {};

        const { communityList = [] } = action.payload;
        communityList.forEach((item) => {
          const prev = newCommunityList[item.community_id] || {};
          item.members = Object.values(item.members || {}).reduce((acc, i) => {
            const role = i.roles || i.role;
            if (role && role.includes('organizer')) {
              i.role = ['organizer'];
            } else if (role && role.includes('team_member')) {
              i.role = ['team_member'];
            } else {
              i.role = role;
            }

            acc[i.user_id] = i;
            return acc;
          }, {});
          newCommunityList[item.community_id] = {
            ...prev,
            ...item,
            didLoad: true,
          };
        });

        try {
          window.localStorage.setItem(
            'communities_data',
            JSON.stringify(newCommunityList)
          );
        } catch (error) {
          console.log(error);
          console.error('Session will expired: ' + error.message);
        }

        communityDB.update({
          didLoad: true,
          communityList: newCommunityList,
        });
        break;
      }

      case 'SET_COMMUNITY_ID': {
        const communityDB = Communities.last();
        if (!communityDB) return;
        const community_id = action.payload;

        if (!community_id) return;

        const firstCom = Object.keys(communityDB.communityList || {})[0];

        if (!firstCom || !communityDB.communityList?.[firstCom]) return;

        communityDB.update({
          communityList: {
            ...(communityDB.communityList || {}),
            [firstCom]: {
              ...communityDB.communityList[firstCom],
              community_id,
            },
          },
        });
        break;
      }
      case 'SYNC_COMMUNITY_STATS': {
        const communityDB = Communities.last();
        if (!communityDB) return;
        const { community_id, stats } = action.payload;

        if (!communityDB.communityList[community_id]) return;
        communityDB.communityList[community_id].stats = stats;
        communityDB.update({
          communityList: { ...communityDB.communityList },
          didStatsLoad: true,
        });
        break;
      }
      case 'SET_ACTIVITY_FEED': {
        const communityDB = Communities.last();
        if (!communityDB) return;
        const { community_id, activities } = action.payload;

        if (!communityDB.communityList[community_id]) return;

        const curCommunityCopy = { ...communityDB.communityList[community_id] };

        curCommunityCopy.activity_feed = activities.reduce((acc, val) => {
          acc[val.item_id] = val;
          return acc;
        }, {});

        communityDB.update({
          communityList: {
            ...communityDB.communityList,
            [community_id]: curCommunityCopy,
          },
          didActivityFeedLoad: true,
        });
        break;
      }
      case 'SET_TIMELINES': {
        const communityDB = Communities.last();
        if (!communityDB) return;
        const { community_id, timelines } = action.payload;

        if (!communityDB.communityList[community_id] || !timelines) return;

        communityDB.communityList[
          community_id
        ].company_activity_timeline = timelines;

        communityDB.update({
          communityList: communityDB.communityList,
        });
        break;
      }
      case 'SET_MEMBERSHIPS': {
        const communityDB = Communities.last();
        if (!communityDB) return;
        const { community_id, memberships } = action.payload;

        if (!communityDB.communityList[community_id]) return;

        // communityDB.didMembershipsLoad = true;

        Object.keys(memberships).forEach((i) => {
          const randImg = ImageUtils.parseImagePath(
            memberships[i]?.avatar?.thumb
          );

          memberships[i].avatar = {
            original: randImg,
            thumb: randImg,
          };
        });

        const updatedCommunity = {
          ...communityDB.communityList[community_id],
          memberships: { ...memberships },
        };

        communityDB.update({
          communityList: {
            ...communityDB.communityList,
            [community_id]: updatedCommunity,
          },
          memberships: { ...memberships },
          didMembershipsLoad: true,
        });
        break;
      }
      case 'ADD_NEW_MEMBERSHIP': {
        const communityDB = Communities.last();
        if (!communityDB) return;
        const { communityId, userId, membership } = action.payload;

        const community = communityDB.communityList[communityId];

        community.memberships[userId] = membership;

        communityDB.update({
          communityList: communityDB.communityList,
          memberships: { ...community.memberships },
        });
        break;
      }
      case 'ADD_NEW_MEMBER': {
        try {
          const communityDB = Communities.last();
          if (!communityDB) return;
          const { communityId, userId, data } = action.payload;

          const community = communityDB.communityList[communityId];

          community.members[userId] = data;

          communityDB.update({
            communityList: communityDB.communityList,
            members: { ...community.members },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }
      case 'ADD_ACTIVITY_LOG_ITEM': {
        const communityDB = Communities.last();
        if (!communityDB) return;

        const { company_id, community_id, log_item } = action.payload;

        if (!communityDB.communityList[community_id]) return;
        if (!communityDB.communityList[community_id].interactions)
          communityDB.communityList[community_id].interactions = {};

        if (!communityDB.communityList[community_id].interactions[company_id])
          communityDB.communityList[community_id].interactions[company_id] = {};

        communityDB.communityList[community_id].interactions[
          company_id
        ] = log_item;

        communityDB.update();
        break;
      }
      case 'ADD_ACTIVITY_FEED_ITEM': {
        const communityDB = Communities.last();
        if (!communityDB) return;

        const { communityId, feedItem } = action.payload;

        if (!communityDB.communityList[communityId]) return;
        if (!communityDB.communityList[communityId].activity_feed)
          communityDB.communityList[communityId].activity_feed = {};

        communityDB.communityList[communityId].activity_feed[
          feedItem.item_id
        ] = feedItem;

        communityDB.update({
          communityList: communityDB.communityList,
        });

        // communityDB.update();
        break;
      }
      case 'ADD_ACTIVITY_TIMELINE_ITEM': {
        const communityDB = Communities.last();
        if (!communityDB) return;

        const { companyId, communityId, timelineItem } = action.payload;

        if (!communityDB.communityList[communityId]) return;
        if (!communityDB.communityList[communityId].company_activity_timeline)
          communityDB.communityList[communityId].company_activity_timeline = [];

        communityDB.communityList[communityId].company_activity_timeline.push(
          timelineItem
        );

        communityDB.update();
        break;
      }
      case 'SYNC_EXPERTS_LIST': {
        try {
          const communityDB = Communities.last();
          if (!communityDB) return;
          const { data } = action.payload;

          communityDB.update({
            expertsList: [...data],
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }
      case 'EXPERTS_LIST_IS_LOADING': {
        try {
          const communityDB = Communities.last();
          if (!communityDB) return;
          const { data } = action.payload;

          communityDB.update({
            expertsIsLoading: data,
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }
      case 'REMOVE_COMMUNITY': {
        const communityDB = Communities.last();
        if (!communityDB) return;

        const { communityId } = action.payload;
        if (communityDB.communityList[communityId]) {
          delete communityDB.communityList[communityId];
          communityDB.update();
        }
        break;
      }
      case 'DELETE_COMMUNITY_DB':
        try {
          Communities.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Communities };
export default Communities;
