import { attr, Model } from 'redux-orm';

/**
 * Files model
 * @author Bohdan L.
 */
class Files extends Model {
  static get modelName() {
    return 'Files';
  }

  static get fields() {
    return {
      files_list: attr(),
      didLoad: attr(),
    };
  }

  static reducer(action, Files, session) {
    if (!Files) return;
    switch (action.type) {
      case 'CREATE_FILES_DB':
        Files.create({
          files_list: {},
          didLoad: false,
        });
        break;
      case 'SET_FILES': {
        try {
          let FilesDB = Files.last();

          if (!FilesDB) {
            Files.create({
              files_list: {},
              didLoad: false,
            });

            FilesDB = Files.last();
          }

          let { filesData = {} } = action.payload;

          if (Array.isArray(filesData)) {
            filesData = filesData.reduce((acc, val) => {
              if (!acc[val.company_id]) {
                acc[val.company_id] = [val];
              } else {
                acc[val.company_id].push(val);
              }
              return acc;
            }, {});
          }

          FilesDB.didLoad = true;
          FilesDB.files_list = filesData || {};

          FilesDB.update();
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }
      case 'INSERT_FILES': {
        try {
          const FilesDB = Files.last();
          const { files } = action.payload;

          if (!files || !FilesDB.files_list) return;

          FilesDB.update({
            files_list: { ...files, ...FilesDB.files_list },
          });

          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }
      case 'ADD_COMPANY_FILES': {
        try {
          const FilesDB = Files.last();
          const { companyId, filesData } = action.payload;
          if (!companyId || !filesData) return;

          if (!FilesDB) {
            Files.create({
              files_list: {},
              didLoad: false,
            });

            FilesDB = Files.last();
          }

          FilesDB.update({
            files_list: { ...FilesDB.files_list, [companyId]: filesData },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'ADD_FILE': {
        try {
          const FilesDB = Files.last();
          const files = FilesDB.files_list;

          const { companyId, filesData } = action.payload;

          if (!companyId || !filesData || !files) return;

          const newCompanyFiles = [filesData, ...(files[companyId] || [])];

          FilesDB.update({
            files_list: { ...files, [companyId]: newCompanyFiles },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'REMOVE_FILE': {
        try {
          const FilesDB = Files.last();

          const { listId, companyId, fileId } = action.payload;

          if (!FilesDB.files_list || !fileId || !companyId) return;

          const newFilesDB = { ...FilesDB.files_list };

          const companyFiles = newFilesDB[companyId];

          newFilesDB[companyId] = companyFiles.filter(
            (f) => f.file_id !== fileId
          );

          FilesDB.update({
            files_list: { ...newFilesDB },
          });
          break;
        } catch (err) {
          console.error(err);
          break;
        }
      }

      case 'DELETE_FILES_DB':
        try {
          Files.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Files };
export default Files;
