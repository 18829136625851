import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiExplore {
  static async autocomplete(query, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/explore/autocomplete`,
        {
          signal: KLAbortController.genController('autocomplete').signal,
          method: 'POST',
          body: JSON.stringify({
            query,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('autocomplete');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async autocomplete2({
    query,
    communityId,
    forget,
    kleverOnly,
    limit = 10,
    custom_tags = [],
    company_size = [],
    industries = [],
    technologies = [],
    locations = [],
    funding = [],
    fromDR = false,
    withMultiplier = false,
    userTags,
    token,
  }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/explore/autocomplete2`,
        {
          signal: KLAbortController.genController('autocomplete2').signal,
          method: 'POST',
          body: JSON.stringify({
            query,
            custom_tags,
            company_size,
            industries,
            technologies,
            locations,
            funding,
            communityId,
            forget,
            limit,
            withMultiplier,
            fromDR,
            kleverOnly,
            userTags,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('autocomplete2');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async autocomplete3({ query = [], token }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/explore/autocomplete3?query=${query}`,
        {
          signal: KLAbortController.genController('autocomplete3').signal,
          method: 'GET',

          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('autocomplete3');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
  static async importCompaniesList(communityId, companyList, token) {
    try {
      const json = await KleverApi.fetchRetry(
        `${API_URL}/explore/import_multi`,
        {
          signal: KLAbortController.genController('importCompaniesList').signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            companyList,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('importCompaniesList');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async addQueryStatAction(queryId, communityId, queryAction, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/explore/add_query_action`,
        {
          signal: KLAbortController.genController('addQueryStatAction').signal,
          method: 'POST',
          body: JSON.stringify({
            queryId,
            communityId,
            queryAction,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('addQueryStatAction');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async autocompleteOrganization(query, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/explore/autocomplete_org`,
        {
          signal: KLAbortController.genController('autocompleteOrganization')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            query,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('autocompleteOrganization');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async importCompany(communityId, companyId, token) {
    try {
      const json = await KleverApi.fetchRetry(`${API_URL}/explore/import`, {
        signal: KLAbortController.genController('importCompany').signal,
        method: 'POST',
        body: JSON.stringify({
          communityId,
          companyId,
        }),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('importCompany');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async importKLEVERCompanies(companyList, communityId, token) {
    try {
      const json = await KleverApi.fetchRetry(
        `${API_URL}/explore/import_klever`,
        {
          signal: KLAbortController.genController('importKLEVERCompanies')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            companyList,
            communityId,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('importKLEVERCompanies');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async importDiscoverCompanies(communityId, discoverData, token) {
    try {
      const json = await KleverApi.fetchRetry(`${API_URL}/explore/import`, {
        signal: KLAbortController.genController('importDiscoverCompanies')
          .signal,
        method: 'POST',
        body: JSON.stringify({
          communityId,
          discoverData,
        }),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('importDiscoverCompanies');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async explore({
    query,
    communityId,
    limit = 10,
    custom_tags = [],
    company_size = [],
    industries = [],
    technologies = [],
    locations = [],
    funding = [],
    userTags = [],
    forget = false,
    fromDR = false,
    kleverOnly = false,
    withMultiplier = false,
    token,
    // pagination
    page = 1,
    total_counters = [],
    more_tags = [],
  }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/explore/search_with_pagination`,
        {
          signal: KLAbortController.genController('explore').signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            company_size,
            forget,
            fromDR,
            funding,
            industries,
            kleverOnly,
            withMultiplier,
            query,
            limit,
            custom_tags,
            technologies,
            locations,
            userTags,
            // pagination
            page,
            total_counters,
            more_tags,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('explore');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
  static async getLastAuto(communityId, search, token) {
    try {
      const json = await KleverApi.fetchRetry(
        `${API_URL}/search_queries/get_last_auto?communityId=${communityId}&search=${search}`,
        {
          signal: KLAbortController.genController('getLastAuto').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getLastAuto');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
}

export default ApiExplore;
