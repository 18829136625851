import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiApp {
  static async getAppSettings() {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/app/settings`, {
        signal: KLAbortController.genController('getAppSettings').signal,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // KLAbortController.clearController('getAppSettings');
      // KleverApi.handleErrorCodes(json.code);
      // if (!json.success) {
      //   KleverApi.handleErrorMessage(json);
      // }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async sendRequestVendor({
    company_name,
    website,
    linkedin_url,
    comment,
    token,
  }) {
    try {
      if (!company_name || !linkedin_url) return null;

      const json = await KleverApi.workerFetch(
        `${API_URL}/support/request_vendor`,
        {
          signal: KLAbortController.genController('sendRequestVendor').signal,
          method: 'POST',
          body: JSON.stringify({
            company_name,
            website,
            linkedin_url,
            comment,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('sendRequestVendor');
      // KleverApi.handleErrorCodes(json.code);
      // if (!json.success) {
      //   KleverApi.handleErrorMessage(json);
      // }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiApp;
