import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiML {
  static async getUserSuggestions(
    userId,
    communityId,
    limit = 10,
    allowPublic = false
  ) {
    try {
      const json = await KleverApi.workerFetch(
        `http://ec2-54-152-230-159.compute-1.amazonaws.com:8080/scoring/startups_list?user_id=${userId}&community_id=${communityId}&should_fetch_public=${allowPublic}&limit=${
          limit || 10
        }`,
        {
          signal: KLAbortController.genController('getUserSuggestions').signal,
          method: 'GET',
        }
      );
      KLAbortController.clearController('getUserSuggestions');
      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }
}

export default ApiML;
