import { createSliceWithThunks } from '../createSlice';
import KleverApi from '~lib/api/api';

const initialState = {
  trendingCompanies: [],
  updatedCompanies: [],
  news: [],
  companiesLoading: false,
};

export const dashboardCompaniesSlice = createSliceWithThunks({
  name: 'dashboardCompanies',
  initialState,
  reducers: (create) => ({
    fetchTrendingCompanies: create.asyncThunk(
      async ({ communityId, token }) => {
        const res = await KleverApi.Community.fetchNetworkTrending(
          communityId,
          'trending',
          token
        );

        return await res;
      },
      {
        pending: (state) => {
          state.companiesLoading = true;
        },
        rejected: (state) => {
          state.companiesLoading = false;
        },
        fulfilled: (state, { payload: { companyList } }) => {
          state.companiesLoading = false;
          state.trendingCompanies = [...companyList];
        },
      }
    ),
    fetchUpdatedCompanies: create.asyncThunk(
      async ({ communityId, token }) => {
        const res = await KleverApi.Community.fetchNetworkTrending(
          communityId,
          'uptated',
          token
        );

        return await res;
      },
      {
        pending: (state) => {
          state.companiesLoading = true;
        },
        rejected: (state) => {
          state.companiesLoading = false;
        },
        fulfilled: (state, { payload: { companyList } }) => {
          state.companiesLoading = false;
          state.updatedCompanies = [...companyList];
        },
      }
    ),
    fetchNews: create.asyncThunk(
      async ({ communityId, token }) => {
        const res = await KleverApi.Community.fetchNetworkTrending(
          communityId,
          'news',
          token
        );

        return await res;
      },
      {
        pending: (state) => {
          state.companiesLoading = true;
        },
        rejected: (state) => {
          state.companiesLoading = false;
        },
        fulfilled: (state, { payload: { companyList } }) => {
          state.companiesLoading = false;
          state.news = [...companyList];
        },
      }
    ),
    clearCompanies: create.reducer((state) => {
      state.trendingCompanies = [];
      state.updatedCompanies = [];
    }),
  }),
  selectors: {
    selectTrendingCompanies: (sliceState) => sliceState.trendingCompanies,
    selectUpdatedCompanies: (sliceState) => sliceState.updatedCompanies,
    selectNews: (sliceState) => sliceState.news,
    selectCompaniesLoading: (sliceState) => sliceState.companiesLoading,
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchTrendingCompanies,
  fetchUpdatedCompanies,
  fetchNews,
  clearCompanies,
} = dashboardCompaniesSlice.actions;

// Selector creators for each uniqe value inside state
export const {
  selectTrendingCompanies,
  selectUpdatedCompanies,
  selectCompaniesLoading,
  selectNews,
} = dashboardCompaniesSlice.selectors;

export default dashboardCompaniesSlice.reducer;
