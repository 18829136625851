import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiOnboarding {
  static async getUserOnboardings(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/onboarding/get?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getUserOnboardings').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getUserOnboardings');

      return json;
    } catch (err) {
      return null;
    }
  }
  static async getOnboardingRequest(requestId, recipient) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/onboarding/validate`,
        {
          signal: KLAbortController.genController('getOnboardingRequest')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            requestId,
            recipient,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getOnboardingRequest');

      return json;
    } catch (err) {
      return null;
    }
  }

  static async validateLandingPage(landingPageId) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/onboarding/validate/landing`,
        {
          signal: KLAbortController.genController('validateLandingPage').signal,
          method: 'POST',
          body: JSON.stringify({
            landingPageId,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('validateLandingPage');

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async requestOnboarding(data, token) {
    try {
      const {
        userId,
        communityId,
        organizationId,
        emailList,
        companyId,
        listId,
        requestedFields,
        customMessage,
        type = 'email',
      } = data;

      const json = await KleverApi.workerFetch(
        `${API_URL}/onboarding/request`,
        {
          signal: KLAbortController.genController('requestOnboarding').signal,
          method: 'POST',
          body: JSON.stringify({
            userId,
            communityId,
            organizationId,
            emailList,
            companyId,
            listId,
            requestedFields,
            customMessage,
            type,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('requestOnboarding');

      return json;
    } catch (err) {
      return null;
    }
  }

  static async requestMultipleOnboardings({
    companyIdList,
    communityId,
    listId,
    requestedFields,
    customMessage,
    token,
  }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/onboarding/request_multi`,
        {
          signal: KLAbortController.genController('requestMultipleOnboardings')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            companyIdList,
            communityId,
            listId,
            requestedFields,
            customMessage,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('requestMultipleOnboardings');

      return json;
    } catch (err) {
      return null;
    }
  }

  static async invite({
    userId,
    communityId,
    organizationId,
    listId,
    emailList,
    type = 'email',
    token,
  }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/onboarding/invite`, {
        signal: KLAbortController.genController('invite').signal,
        method: 'POST',
        body: JSON.stringify({
          userId,
          communityId,
          organizationId,
          listId,
          emailList,
          type,
        }),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('invite');

      return json;
    } catch (err) {
      return null;
    }
  }

  static async submitOnboardingForm(requestId, recipient, formData) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/onboarding/form`, {
        signal: KLAbortController.genController('submitOnboardingForm').signal,
        method: 'POST',
        body: JSON.stringify({
          requestId,
          recipient,
          formData,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('submitOnboardingForm');

      return json;
    } catch (err) {
      return null;
    }
  }

  static async submitLandingPageForm(landingPageId, formData) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/onboarding/landing/form`,
        {
          signal: KLAbortController.genController('submitLandingPageForm')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            landingPageId,
            formData,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('submitLandingPageForm');

      return json;
    } catch (err) {
      return null;
    }
  }
}

export default ApiOnboarding;
