import { attr, Model } from 'redux-orm';

import { genUniqueId } from '~lib/utils/strings';

/**
 * Active user model
 * @author Villi K.
 */
class Portfolios extends Model {
  static get modelName() {
    return 'Portfolios';
  }

  static get fields() {
    return {
      all: attr(),
      pending: attr(),
      evaluated: attr(),
      contacted: attr(),
      completed: attr(),
      rejected: attr(),
      following: attr(),
      viewed: attr(),
      imported: attr(),
      archived: attr(),
      matches: attr(),
      folders: attr(),
      share: attr(),
      didLoad: attr(),
    };
  }

  static reducer(action, Portfolios, session) {
    if (!Portfolios) return;
    switch (action.type) {
      case 'CREATE_MAIN_PORTFOLIO':
        Portfolios.create(action.payload);
        break;
      case 'SYNC_PORTFOLIO': {
        const lastPortfolio = Portfolios.last();
        if (!lastPortfolio) return null;

        const {
          all,
          folders,
          evaluated,
          contacted,
          completed,
          rejected,
          following,
          viewed,
          share,
          matches,
          imported,
          archived,
        } = action.payload;

        const portfolioAll = {
          ...(lastPortfolio.all || {}),
          ...(all || {}),
        };

        Object.keys(lastPortfolio.disliked || {}).forEach((id) => {
          delete portfolioAll[id];
        });

        lastPortfolio.update({
          all: portfolioAll,
          evaluated: {
            ...(lastPortfolio.evaluated || {}),
            ...(evaluated || {}),
          },
          contacted: {
            ...(lastPortfolio.contacted || {}),
            ...(contacted || {}),
          },
          completed: {
            ...(lastPortfolio.completed || {}),
            ...(completed || {}),
          },
          viewed: { ...(lastPortfolio.viewed || {}), ...(viewed || {}) },
          rejected: {
            ...(lastPortfolio.rejected || {}),
            ...(rejected || {}),
          },
          archived: {
            ...(lastPortfolio.archived || {}),
            ...(archived || {}),
          },
          imported: {
            ...(lastPortfolio.imported || {}),
            ...(imported || {}),
          },
          following: {
            ...(lastPortfolio.following || {}),
            ...(following || {}),
          },
          share: { ...(lastPortfolio.share || {}), ...(share || {}) },
          archived: {
            ...(lastPortfolio.archived || {}),
            ...(archived || {}),
          },
          folders: {
            ...(lastPortfolio.folders || {}),
            ...(folders || {}),
          },
          matches: { ...(lastPortfolio.matches || {}), ...(matches || {}) },
          didLoad: true,
        });
        break;
      }
      case 'CREATE_NEW_PORTFOLIO_FOLDER': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const newId = genUniqueId();
        const { folderName, companyList = [] } = action.payload;
        if (!folderName) return;
        if (mainPortfolio.folders[newId]) {
          return;
        }

        mainPortfolio.folders[newId] = {
          id: newId,
          name: folderName,
          companyRefList: [...companyList],
        };
        mainPortfolio.update();

        break;
      }
      case 'DELETE_PORTFOLIO_FOLDER': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { folderId } = action.payload;
        if (mainPortfolio.folders[folderId]) {
          delete mainPortfolio.folders[folderId];
          mainPortfolio.update();
        }
        break;
      }
      case 'ADD_IMPORTED_COMPANY': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { companyId } = action.payload;
        if (!companyId) return;
        if (!mainPortfolio.imported) {
          mainPortfolio.imported = {};
        }

        mainPortfolio.imported[companyId] = {
          created_at: Date.now(),
        };
        mainPortfolio.update();

        break;
      }
      case 'ADD_PORTFOLIO_ITEM': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { folderId, refId } = action.payload;
        if (
          mainPortfolio.folders[folderId] &&
          mainPortfolio.folders[folderId].companyRefList.indexOf(refId) === -1
        ) {
          mainPortfolio.folders[folderId].companyRefList.push(refId);
          mainPortfolio.update();
        }
        break;
      }
      case 'REMOVE_PORTFOLIO_ITEM': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { folderId, refId } = action.payload;
        if (mainPortfolio.folders[folderId]) {
          mainPortfolio.folders[
            folderId
          ].companyRefList = mainPortfolio.folders[
            folderId
          ].companyRefList.filter((item) => item !== refId);
          mainPortfolio.update();
        }
        break;
      }
      case 'ADD_VIEWED_ITEM': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const now = Date.now();

        const { companyId } = action.payload;

        mainPortfolio.update({
          ...mainPortfolio,
          viewed: {
            ...(mainPortfolio.viewed || {}),
            [companyId]: { viewed: true, date: now },
          },
        });

        break;
      }
      case 'ADD_CONTACTED_ITEM': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { companyId } = action.payload;
        if (!mainPortfolio.contacted[companyId]) {
          mainPortfolio.contacted[companyId] = { contacted: true };

          mainPortfolio.update();
          Portfolios.withId(mainPortfolio.id).update(mainPortfolio);
        }

        break;
      }
      case 'ADD_MATCHED_ITEM': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { companyId, data } = action.payload;
        if (Array.isArray(data) && mainPortfolio.matches) {
          if (!mainPortfolio.matches[companyId])
            mainPortfolio.matches[companyId] = {};

          mainPortfolio.matches[companyId].matches = Object.values(
            [
              ...(mainPortfolio.matches[companyId].matches || []),
              ...data,
            ].reduce((acc, val) => {
              acc[val.user_id] = val;
              return acc;
            }, {})
          );

          mainPortfolio.update();
          Portfolios.withId(mainPortfolio.id).update(mainPortfolio);
        }

        break;
      }
      case 'ADD_EVALUATED_ITEM_TO_PORTFOLIO': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { id } = action.payload;
        mainPortfolio.evaluated[id] = { evaluationData: {} };
        mainPortfolio.update();

        break;
      }
      case 'ADD_TO_PORTFOLIO': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { compList, portfolioId } = action.payload;
        if (mainPortfolio[portfolioId]) {
          if (mainPortfolio.rejected) {
            compList.forEach((i) => {
              delete mainPortfolio.rejected[i];
              delete mainPortfolio.disliked[i];
            });
          }
          compList.forEach((i) => {
            mainPortfolio[portfolioId][i] = {};
          });
        }

        mainPortfolio.update();

        break;
      }
      case 'BATCH_ADD_TO_PORTFOLIO': {
        const mainPortfolio = Portfolios.last();

        if (!mainPortfolio) return null;

        const { ids } = action.payload;

        const portfolioCpy = { ...(mainPortfolio.all || {}) };
        const dislikedCpy = { ...(mainPortfolio.disliked || {}) };
        const rejectedCpy = { ...(mainPortfolio.rejected || {}) };

        if (ids) {
          ids.forEach((id) => {
            portfolioCpy[id] = {
              id,
              date: Date.now(),
              isAdded: true,
            };
            delete dislikedCpy[id];
            delete rejectedCpy[id];
          });
        }

        mainPortfolio.update({
          ...mainPortfolio,
          all: portfolioCpy,
          rejected: rejectedCpy,
          disliked: dislikedCpy,
        });

        break;
      }
      case 'UPDATE_PORTFOLIO_ARCHIVED': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { archived } = action.payload;
        if (!archived) return;

        mainPortfolio.update({
          ...mainPortfolio,
          archived: archived || {},
        });

        break;
      }
      case 'ARCHIVE_PORTFOLIO_ITEM': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { companyId } = action.payload;
        if (!companyId) return;

        if (!mainPortfolio.archived) {
          mainPortfolio.archived = {};
        }

        mainPortfolio.archived[companyId] = {
          is_archived: true,
          time: Date.now(),
        };

        mainPortfolio.update({
          ...mainPortfolio,
        });

        break;
      }
      case 'UNARCHIVE_PORTFOLIO_ITEM': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { companyId } = action.payload;
        if (!companyId) return;

        if (!mainPortfolio.archived) {
          mainPortfolio.archived = {};
        }

        delete mainPortfolio.archived[companyId];

        mainPortfolio.update({
          ...mainPortfolio,
        });

        break;
      }
      case 'UPDATE_PORTFOLIO': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { data = {} } = action.payload;

        if (!data) return;

        mainPortfolio.update({
          ...mainPortfolio,
          ...data,
        });

        break;
      }

      case 'REMOVE_FROM_PORTFOLIO': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        if (mainPortfolio.rejected) {
          // mainPortfolio.rejected[action.payload.id] = {};
        }

        const { id } = action.payload;
        if (!id) return;

        const portfolioCpy = { ...(mainPortfolio.all || {}) };
        const evalCpy = { ...(mainPortfolio.evaluated || {}) };
        const disliked = { ...(mainPortfolio.disliked || {}), [id]: id };

        delete portfolioCpy[id];
        delete evalCpy[id];

        mainPortfolio.update({
          ...mainPortfolio,
          all: portfolioCpy,
          evaluated: evalCpy,
          disliked,
        });

        mainPortfolio.update();
        Portfolios.withId(mainPortfolio.id).update(mainPortfolio);

        break;
      }
      case 'REMOVE_MULTI_FROM_PORTFOLIO': {
        const mainPortfolio = Portfolios.last();
        if (!mainPortfolio) return null;

        const { companyIds } = action.payload;

        if (companyIds?.length) {
          const newPortfolioAll = { ...(mainPortfolio.all || {}) };

          companyIds.forEach((id) => delete newPortfolioAll[id]);

          mainPortfolio.update({
            all: { ...newPortfolioAll },
          });
        }

        break;
      }
      case 'REMOVE_PORTFOLIOS':
        try {
          Portfolios.delete();
          // const user = User.withId(action.payload);
          // user.delete();
        } catch (err) {
          console.log(err);
        }
        break;
    }
    // Return value is ignored.
    return session.state;
  }

  toString() {
    return `${this.name}`;
  }
}

export { Portfolios };
export default Portfolios;
