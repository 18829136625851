import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiInvites {
  static async getInvites(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/invites/get?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getInvites').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getInvites');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async getAccess(data) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/invites/get_access`,
        {
          signal: KLAbortController.genController('getAccess').signal,
          method: 'POST',
          body: JSON.stringify({ ...data }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getAccess');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async createNewInvite(communityId, inviteList, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/invites/new`, {
        signal: KLAbortController.genController('createNewInvite').signal,
        method: 'POST',
        body: JSON.stringify({ communityId, inviteList }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `kl=${token}`,
        },
      });
      KLAbortController.clearController('createNewInvite');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async generateInviteLink(
    communityId,
    { email, fullName, lastName, firstName, role, lists },
    token
  ) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/invites/generate_link`,
        {
          signal: KLAbortController.genController('generateInviteLink').signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            email,
            fullName,
            lastName,
            firstName,
            role,
            lists,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `kl=${token}`,
          },
        }
      );
      KLAbortController.clearController('generateInviteLink');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async validate(secret, email) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/invites/validate`, {
        signal: KLAbortController.genController('validate').signal,
        method: 'POST',
        body: JSON.stringify({ secret, email }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('validate');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async complete({ secret, email, newPass, languageData }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/invites/complete`, {
        signal: KLAbortController.genController('complete').signal,
        method: 'POST',
        body: JSON.stringify({ secret, email, newPass, languageData }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('complete');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiInvites;
