import { combineReducers } from 'redux';
import networkReducer from './slices/networkSlice';
import companyReducer from './slices/companySlice';
import recommendationCompanyReducer from './slices/recommendationCompanySlice';
import insightBarReducer from './slices/insightBarSlice';
import sidebarReducer from './slices/sidebarSlice';
import dashboardStatsReducer from './slices/dashboardStatsSlice';
import dashboardCompaniesReducer from './slices/dashboardCompaniesSlice';
import dashboardUserProfileReducer from './slices/dashboardUserProfileSlice';
import appStateReducer from './slices/appStateSlice';
import listViewReducer from './slices/listViewSlice';
import kanbanReducer from './slices/kanbanSlice';
import userReducer from './slices/userSlice';

export const rtkReducers = combineReducers({
  network: networkReducer,
  company: companyReducer,
  companyRecommendations: recommendationCompanyReducer,
  insightBar: insightBarReducer,
  sidebar: sidebarReducer,
  dashboardStats: dashboardStatsReducer,
  dashboardCompanies: dashboardCompaniesReducer,
  dashboardUserProfile: dashboardUserProfileReducer,
  appState: appStateReducer,
  listView: listViewReducer,
  kanban: kanbanReducer,
  user: userReducer,
});
