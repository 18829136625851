/**
 * Community model selectors
 * @author Villi K.
 */
import { createSelector } from 'redux-orm';

import orm from '../store/orm';

const dbStateSelector = (state) => state.db;

const getCommunity = createSelector(orm, dbStateSelector, (session) => {
  try {
    return Object.values(session.Communities.last().communityList)[0];
  } catch (err) {
    return {};
  }
});

const getCommunityMemberships = createSelector(
  orm,
  dbStateSelector,
  (session) => {
    try {
      return (
        Object.values(session.Communities.last().communityList)[0]
          .memberships || {}
      );
    } catch (err) {
      return {};
    }
  }
);

const getCommunityMembershipsByListId = (listId) =>
  createSelector(orm, dbStateSelector, (session) => {
    try {
      const listMemberships = {};
      const memberships =
        Object.values(session.Communities.last().communityList)[0]
          .memberships || {};

      const list = session.Lists.last()?.lists?.[listId];

      if (!list) {
        return {};
      }

      list.shared_with.forEach((m) => {
        if (memberships[m.user_id]) {
          listMemberships[m.user_id] = memberships[m.user_id];
        }
      });

      if (memberships[list.user_id]) {
        listMemberships[list.user_id] = memberships[list.user_id];
      }

      return listMemberships;
    } catch (err) {
      return {};
    }
  });

const getCommunityStats = createSelector(orm, dbStateSelector, (session) => {
  try {
    return Object.values(session.Communities.last().communityList)[0].stats;
  } catch (err) {
    return [];
  }
});

const getExpertsList = createSelector(orm, dbStateSelector, (session) => {
  try {
    return Object.values(session.Communities.last())[0].expertsList;
  } catch (err) {
    return [];
  }
});

const getExpertsListIsLoading = createSelector(
  orm,
  dbStateSelector,
  (session) => {
    try {
      return Object.values(session.Communities.last())[0].expertsIsLoading;
    } catch (err) {
      return [];
    }
  }
);

const getCommunityActivityFeed = createSelector(
  orm,
  dbStateSelector,
  (session) => {
    try {
      return (
        Object.values(session.Communities.last().communityList)[0]
          .activity_feed || {}
      );
    } catch (err) {
      return {};
    }
  }
);

const didCommunityLoad = createSelector(orm, dbStateSelector, (session) => {
  try {
    return session.Communities.last().didLoad;
  } catch (err) {
    return false;
  }
});

const didStatsLoad = createSelector(orm, dbStateSelector, (session) => {
  try {
    return session.Communities.last().didStatsLoad;
  } catch (err) {
    return false;
  }
});

const didMembershipsLoad = createSelector(orm, dbStateSelector, (session) => {
  try {
    return session.Communities.last().didMembershipsLoad;
  } catch (err) {
    return false;
  }
});

const didActivityFeedLoad = createSelector(orm, dbStateSelector, (session) => {
  try {
    return session.Communities.last().didActivityFeedLoad;
  } catch (err) {
    return false;
  }
});

const getCompanyActivityLog = (companyid) =>
  createSelector(orm, dbStateSelector, (session) => {
    try {
      const com = Object.values(session.Communities.last().communityList)[0];
      return com.company_activity_timeline.filter(
        (item) => item.company_id === companyid
      );
    } catch (err) {
      return [];
    }
  });

export {
  getCommunity,
  didCommunityLoad,
  didMembershipsLoad,
  didActivityFeedLoad,
  getCompanyActivityLog,
  getCommunityMemberships,
  getCommunityMembershipsByListId,
  getCommunityActivityFeed,
  getCommunityStats,
  didStatsLoad,
  getExpertsList,
  getExpertsListIsLoading,
};
