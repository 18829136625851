import { KleverApi } from '~shared/adapters/NetworkAdapter';
import { createSliceWithThunks } from '../createSlice';

const initialState = {
  loading: false,
  showHistory: false,
  fullComments: [],
  filteredComments: [],
  list: [],
  activityList: [],
  unitsList: [],
  usersList: [],
  challenge: {},
  filterBy: {
    key: '',
    active: false,
    values: [],
  },
  botOpen: false,
  botHint: '',
  botContext: '',
  botCompanyId: '',
  botCompanyName: '',
};

export const insightBarSlice = createSliceWithThunks({
  name: 'insightBar',
  initialState,
  reducers: (create) => ({
    fetchComments: create.asyncThunk(
      async ({ companyId, token }) => {
        const res = KleverApi.Companies.getCompany(companyId, true, token);
        return await res;
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, { payload: { company } }) => {
          state.loading = false;
          state.fullComments = { ...company };
        },
      }
    ),
    setBotOpen: create.reducer((state, { payload }) => {
      state.botOpen = payload;
    }),
    setBotHint: create.reducer((state, { payload }) => {
      state.botHint = payload;
    }),
    setBotContext: create.reducer((state, { payload }) => {
      state.botContext = payload;
    }),
    setBotCompanyId: create.reducer((state, { payload }) => {
      state.botCompanyId = payload;
    }),
    setBotCompanyName: create.reducer((state, { payload }) => {
      state.botCompanyName = payload;
    }),
    setUsers: create.reducer((state, { payload }) => {
      state.usersList = [...payload];
    }),
    setFullComments: create.reducer((state, { payload }) => {
      state.fullComments = [...payload];
    }),
    setList: create.reducer((state) => {
      state.list = [
        ...(state.filterBy?.active
          ? state.filteredComments
          : state.fullComments),
      ];
    }),
    setUsers: create.reducer((state, { payload }) => {
      state.usersList = [...payload];
    }),
    setUnits: create.reducer((state) => {
      state.unitsList = [
        ...state.usersList
          .map((user) => user?.unit_abbr?.[0])
          .filter((value, index, array) => array.indexOf(value) === index)
          .filter((unit) => unit?.length),
      ];
    }),
    // setUsers: create.reducer((state) => {
    //   const users = state.fullComments
    //     .map((comment) => ({
    //       ...(comment.type !== 'challenge' ? comment.user_obj : {}),
    //     }))
    //     .filter((user) => user.user_id);
    //   state.usersList = [
    //     ...new Set(users.map((user) => user.user_id)),
    //   ].map((user) => users.find((item) => item.user_id === user));
    // }),
    setShowHistory: create.reducer((state, { payload: { showHistory } }) => {
      state.showHistory = showHistory;
    }),
    setFilterBy: create.reducer((state, { payload: { key, values } }) => {
      state.filterBy = { key, values, active: !!values?.length };
    }),
    setFilteredComments: create.reducer((state, { payload: { comments } }) => {
      state.filteredComments = [...comments];
    }),
    setChallenge: create.reducer((state, { payload: { challenge } }) => {
      state.challenge = challenge;
    }),
    setActivityList: create.reducer((state, { payload }) => {
      state.activityList = payload;
    }),
    clearUnits: create.reducer((state) => {
      state.unitsList = [];
    }),
    clearUsers: create.reducer((state) => {
      state.usersList = [];
    }),
    clearFilter: create.reducer((state) => {
      state.filterBy = [
        {
          key: '',
          active: false,
          values: [],
        },
      ];
      state.list = [...state.fullComments];
    }),
    clearFullComments: create.reducer((state) => {
      state.fullComments = [];
      state.list = [];
    }),
    clearActivityList: create.reducer((state) => {
      state.activityList = [];
    }),
  }),
  selectors: {
    selectList: (sliceState) => sliceState.list,
    selectActivityList: (sliceState) => sliceState.activityList,
    selectFullList: (sliceState) => sliceState.fullComments,
    selectUsers: (sliceState) => sliceState.usersList,
    selectUnits: (sliceState) => sliceState.unitsList,
    selectFilterBy: (sliceState) => sliceState.filterBy,
    selectChallenge: (sliceState) => sliceState.challenge,
    selectBotHint: (sliceState) => sliceState.botHint,
    selectBotContext: (sliceState) => sliceState.botContext,
    selectBotOpen: (sliceState) => sliceState.botOpen,
    selectBotCompanyId: (sliceState) => sliceState.botCompanyId,
    selectBotCompanyName: (sliceState) => sliceState.botCompanyName,
  },
});

// Action creators are generated for each case reducer function
export const {
  setFullComments,
  setChallenge,
  setList,
  setUnits,
  setUsers,
  setShowHistory,
  setFilterBy,
  setFilteredComments,
  clearUnits,
  clearUsers,
  clearFilter,
  clearFullComments,
  clearActivityList,
  setBotOpen,
  setBotHint,
  setBotContext,
  setBotCompanyId,
  setBotCompanyName,
  setActivityList,
} = insightBarSlice.actions;

// Selector creators for each uniqe value inside state
export const {
  selectList,
  selectUsers,
  selectUnits,
  selectFullList,
  selectFilterBy,
  selectChallenge,
  selectBotHint,
  selectBotContext,
  selectBotOpen,
  selectBotCompanyId,
  selectBotCompanyName,
  selectActivityList,
} = insightBarSlice.selectors;

export default insightBarSlice.reducer;
