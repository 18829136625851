import localForage from 'localforage';
// default state configurator
const configurator = (orm) => {
  const initialState = orm.getEmptyState();
  const session = orm.session(initialState);
  const {
    User,
    App,
    AppState,
    KLRouter,
    Communities,
    Portfolios,
    KanbanBoards,
    Files,
    Chats,
    Lists,
    Catalogs,
    CompanyUpdates,
    EntityPool,
    Notifications,
    Evaluations,
    Notes,
    Comments,
    Forms,
    Onboardings,
    Invites,
    Projects,
    Startup,
    ContactRequests,
    Leads,
    KnowledgeCenter,
    Pins,
  } = session;

  AppState.create({ isInBackground: false });
  App.create({ appVersion: __VERSION__ });
  ContactRequests.create({ contact_request_list: [], didLoad: false });
  Leads.create({ leads: [], didLoad: false });
  KnowledgeCenter.create({ investmentData: {}, didLoad: false });
  KanbanBoards.create({ kanban_boards: {}, didLoad: false });
  Files.create({ files_list: {}, didLoad: false });
  Pins.create({ pins_list: {}, didLoad: false });
  Lists.create({ lists: {}, didLoad: false });
  Catalogs.create({ catalogs: {}, didLoad: false });
  CompanyUpdates.create({});
  Chats.create({
    chats_list: {},
    didLoad: false,
  });

  try {
    User.create({
      ...JSON.parse(window.localStorage.getItem('user_data') || '{}'),
    });
    Communities.create({
      communityList: {
        ...JSON.parse(window.localStorage.getItem('communities_data') || '{}'),
      },
    });
  } catch (e) {
    console.error('Failed to persist data' + e.message);
  }

  Notifications.create({
    notifications: [],
  });

  Projects.create({
    projects: [],
  });

  Notes.create({
    notes: [],
  });

  Comments.create({
    comments_list: [],
  });

  Forms.create({
    forms_list: {},
  });

  Invites.create({
    list: [],
  });

  Onboardings.create({
    list: [],
  });

  Evaluations.create({
    evaluationList: [],
  });

  Portfolios.create({
    all: {},
    folders: {},
    pending: {},
    evaluated: {},
    contacted: {},
    completed: {},
    rejected: {},
    following: {},
    share: {},
  });

  EntityPool.create({
    startups: {},
    partners: {},
    advisors: {},
    followUpList: {},
    rejectedList: {},
  });

  return session.state;
};

export default configurator;
