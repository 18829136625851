import { createSliceWithThunks } from '../createSlice';
import KleverApi from '~lib/api/api';

const initialState = {
  recommendedSolutions: [],
  searchResults: [],
  solutionsLoading: false,
  searchLoading: false,
  isMounted: [],
  solutionSearchQuery: '',
  showRecommended: true,
};

export const recommendationCompanySlice = createSliceWithThunks({
  name: 'companyRecommendations',
  initialState,
  reducers: (create) => ({
    fetchRecommendedSolutions: create.asyncThunk(
      async ({ listId, communityId, token }) => {
        const res = await KleverApi.Lists.getRecommendsForList({
          listId,
          communityId,
          token,
        });

        return await res;
      },
      {
        pending: (state) => {
          state.solutionsLoading = true;
        },
        rejected: (state) => {
          state.solutionsLoading = false;
        },
        fulfilled: (state, { payload: { solutions } }) => {
          state.solutionsLoading = false;
          state.recommendedSolutions = [
            ...solutions.filter((company) => company.status === 'new'),
          ];
          state.isMounted = state.recommendedSolutions.map((solution) => {
            return { company_id: solution.company_id, mount: true };
          });
        },
      }
    ),
    fetchSearchResults: create.asyncThunk(
      async ({ query, communityId, token }) => {
        const res = await KleverApi.Explore.autocomplete2({
          query,
          communityId,
          limit: 5,
          token,
          fromDR: true,
          forget: true,
          kleverOnly: false,
        });

        return await res;
      },
      {
        pending: (state) => {
          state.searchLoading = true;
        },
        rejected: (state) => {
          state.searchLoading = false;
        },
        fulfilled: (state, { payload: { results } }) => {
          state.searchLoading = false;
          state.searchResults = [...results.slice(0, 5)];
        },
      }
    ),
    updateSearchResults: create.reducer((state, { payload }) => {
      state.searchResults = [...payload];
    }),
    clearRecommendedSolutions: create.reducer((state) => {
      state.recommendedSolutions = [];
      state.isMounted = {};
    }),
    clearSearchResults: create.reducer((state) => {
      state.searchResults = [];
    }),
    setIsMounted: create.reducer((state, { payload }) => {
      state.isMounted.find(
        (mount) => mount.company_id === payload
      ).mount = false;
    }),
    setSolutionSearchQuery: create.reducer((state, { payload }) => {
      state.solutionSearchQuery = payload;
    }),
    setShowRecommended: create.reducer((state, { payload }) => {
      state.showRecommended = payload;
    }),
  }),
  selectors: {
    selectRecommendedSolutions: (sliceState) => sliceState.recommendedSolutions,
    selectSearchResults: (sliceState) => sliceState.searchResults,
    selectSearchLoading: (sliceState) => sliceState.searchLoading,
    selectIsMounted: (sliceState) => sliceState.isMounted,
    selectSolutionSearchQuery: (sliceState) => sliceState.solutionSearchQuery,
    selectShowRecommended: (sliceState) => sliceState.showRecommended,
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchRecommendedSolutions,
  fetchSearchResults,
  updateSearchResults,
  clearRecommendedSolutions,
  clearSearchResults,
  setIsMounted,
  setSolutionSearchQuery,
  setShowRecommended,
} = recommendationCompanySlice.actions;

// Selector creators for each uniqe value inside state
export const {
  selectRecommendedSolutions,
  selectSearchResults,
  selectSearchLoading,
  selectIsMounted,
  selectSolutionSearchQuery,
  selectShowRecommended,
} = recommendationCompanySlice.selectors;

export default recommendationCompanySlice.reducer;
