import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiProfile {
  static async updateProfile(profileData, token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/profile/update`, {
        signal: KLAbortController.genController('updateProfile').signal,
        method: 'POST',
        body: JSON.stringify({ profileData }),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('updateProfile');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
  static async setUserPreferences({
    job_roles,
    technologies,
    industries,
    purposes,
    userImg,
    userCircleImg,
    token,
  }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/user/set_preferences`,
        {
          signal: KLAbortController.genController('setUserPreferences').signal,
          method: 'POST',
          body: JSON.stringify({
            job_roles,
            technologies,
            purposes,
            industries,
            userImg,
            userCircleImg,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('setUserPreferences');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }
  static async setUserSettings({
    is_getting_started_visible = false,
    token = null,
  }) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/user/set_settings`, {
        signal: KLAbortController.genController('setUserPreferences').signal,
        method: 'POST',
        body: JSON.stringify({
          is_getting_started_visible,
        }),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });
      KLAbortController.clearController('setUserPreferences');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }
  static async getConsultsFeed({ communityId = null, token = null }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/profile/consults_feed?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getConsultsFeed').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getConsultsFeed');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }
  static async getDashboardRecommended({ token = null }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/profile/four_recommended_categories`,
        {
          signal: KLAbortController.genController('getDashboardRecommended')
            .signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getDashboardRecommended');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }
  static async getCategories({ token = null }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/profile/recommended_categories`,
        {
          signal: KLAbortController.genController('getCategories').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getCategories');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }
}

export default ApiProfile;
