import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiCommunity {
  static async getCommunities(communityId, token) {
    try {
      const path = `${API_URL}/community/get${
        communityId ? `?communityId=${communityId}` : ''
      }`;

      const json = await KleverApi.workerFetch(path, {
        method: 'GET',
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });

      KLAbortController.clearController('getCommunities');

      window.last_request_time = Date.now();
      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getVendors(communityId, token) {
    try {
      const response = await fetch(
        `${API_URL}/community/vendors/get${
          communityId ? `?communityId=${communityId}` : ''
        }`,
        {
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      window.last_request_time = Date.now();

      const json = await response.json();

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getAllCommunities(token) {
    try {
      const json = await KleverApi.workerFetch(`${API_URL}/community/all`, {
        signal: KLAbortController.genController('getAllCommunities').signal,
        method: 'GET',
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });

      KLAbortController.clearController('getAllCommunities');

      window.last_request_time = Date.now();

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getMemberships(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/memberships?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getMemberships').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('getMemberships');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async getActivities(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/activities?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getActivities').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('getActivities');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async getCommunityTimelines(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/timelines?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getCommunityTimelines')
            .signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('getCommunityTimelines');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async insertCommunityStartups(companyList, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/add_startups`,
        {
          signal: KLAbortController.genController('insertCommunityStartups')
            .signal,
          method: 'POST',
          body: JSON.stringify({ companyList }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('insertCommunityStartups');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async requestIntro(communityId, companyId, token) {
    try {
      return {};
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/request_intro`,
        {
          method: 'POST',
          body: JSON.stringify({ communityId, companyId }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async updateCompany(communityId, companyId, data, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/company/update`,
        {
          signal: KLAbortController.genController('updateCompany').signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            companyId,
            data: { ...data, updated_at: new Date() },
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('updateCompany');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async addTimelineItem(communityId, companyId, timelineItem, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/timeline/add`,
        {
          signal: KLAbortController.genController('addTimelineItem').signal,
          method: 'POST',
          body: JSON.stringify({ communityId, companyId, timelineItem }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('addTimelineItem');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async getCommunityMembersStats(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/member_stats?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getCommunityMembersStats')
            .signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('getCommunityMembersStats');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async getContactRequests(communityId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/contact_requests/get?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getContactRequests').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('getContactRequests');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async contactCompany(communityId, companyId, listId, formData, token) {
    try {
      const { contactSubject, contactMessage } = formData;

      const json = await KleverApi.workerFetch(
        `${API_URL}/community/company/contact`,
        {
          signal: KLAbortController.genController('contactCompany').signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            companyId,
            listId,
            contactSubject,
            contactMessage,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('contactCompany');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async publishCommunityStartup(communityId, companyId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/startup/publish`,
        {
          signal: KLAbortController.genController('publishCommunityStartup')
            .signal,
          method: 'POST',
          body: JSON.stringify({ communityId, companyId }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('publishCommunityStartup');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async unpublishCommunityStartup(communityId, companyId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/startup/unpublish`,
        {
          signal: KLAbortController.genController('unpublishCommunityStartup')
            .signal,
          method: 'POST',
          body: JSON.stringify({ communityId, companyId }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('unpublishCommunityStartup');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async getCommunityOrganizationData(orgId, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/organization/data?organization_id=${orgId}`,
        {
          signal: KLAbortController.genController(
            'getCommunityOrganizationData'
          ).signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('getCommunityOrganizationData');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      return null;
    }
  }

  static async requestUpgradeAccount(communityId, featureType, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/account/upgrade`,
        {
          signal: KLAbortController.genController('requestUpgradeAccount')
            .signal,
          method: 'POST',
          body: JSON.stringify({ communityId, featureType }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('requestUpgradeAccount');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      return null;
    }
  }

  static async addKLEVERCompanyToCommunity({
    communityId,
    evaluationId,
    companyId,
    isAdded,
    token,
  }) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/community/klever_share/add`,
        {
          signal: KLAbortController.genController('addKLEVERCompanyToCommunity')
            .signal,
          method: 'POST',
          body: JSON.stringify({
            communityId,
            evaluationId,
            companyId,
            isAdded,
          }),
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      KLAbortController.clearController('addKLEVERCompanyToCommunity');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getExperts({ communityId, companyIds }, token) {
    try {
      const response = await fetch(`${API_URL}/community/get_experts`, {
        method: 'POST',
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          communityId,
          companyIds,
        }),
      });

      window.last_request_time = Date.now();

      const json = await response.json();

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async fetchMembers(communityId, memberType, token) {
    try {
      const response = await fetch(
        `${API_URL}/community/network_stats_TM?communityId=${communityId}&member_type=${memberType}`,
        {
          signal: KLAbortController.genController('fetchMembers').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const json = await response.json();
      KLAbortController.clearController('fetchMembers');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getNetworkStats(communityId, token) {
    try {
      const response = await fetch(
        `${API_URL}/community/network_stats?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('getNetworkStats').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const json = await response.json();
      KLAbortController.clearController('getNetworkStats');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async fetchTechPartners({ main, additional = {} }) {
    const {
      communityId,
      token,
      sort: { sortKey = 'created_at', upToDown = +true },
      skip = 0,
      limit = 20,
      search,
    } = main;
    try {
      const response = await fetch(
        `${API_URL}/community/network_stats_TP?communityId=${communityId}&sortByField=${sortKey}&sortOrder=${upToDown}&skip=${skip}&limit=${limit}${
          additional?.filter ? '&filterByField=' + filter : ''
        }${search ? '&searchByField=' + search : ''}`,
        {
          signal: KLAbortController.genController('fetchTechPartners').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const json = await response.json();
      KLAbortController.clearController('fetchTechPartners');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async fetchInsgigtsStats(communityId, token) {
    try {
      const response = await fetch(
        `${API_URL}/community/network_stats_IN?communityId=${communityId}`,
        {
          signal: KLAbortController.genController('fetchInsgigtsStats').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const json = await response.json();
      KLAbortController.clearController('fetchInsgigtsStats');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async fetchNetworkTrending(communityId, tabName, token) {
    try {
      const response = await fetch(
        `${API_URL}/community/networkTrending?communityId=${communityId}&tabName=${tabName}`,
        {
          signal: KLAbortController.genController('fetchNetworkTrending')
            .signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const json = await response.json();
      KLAbortController.clearController('fetchNetworkTrending');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  static async changeStatus(data) {
    try {
      const { communityId, token, status, memberId } = data;
      const response = await fetch(`${API_URL}/community/change_status`, {
        signal: KLAbortController.genController('changeStatus').signal,
        method: 'POST',
        body: JSON.stringify({
          communityId,
          status,
          memberId,
        }),
        headers: {
          Authorization: `kl=${token}`,
          'Content-Type': 'application/json',
        },
      });

      const json = await response.json();
      KLAbortController.clearController('changeStatus');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }
      return json;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default ApiCommunity;
