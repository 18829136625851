import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';

const history = createBrowserHistory();

const SentryRoute = __DEVELOPMENT__ ? Route : Sentry.withSentryRouting(Route);

// $FlowFixMe
if (!__DEVELOPMENT__) {
  // $FlowFixMe
  const sentryConfig = {
    // $FlowFixMe
    dsn: __SENTRY__,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
    // $FlowFixMe
    release: __VERSION__,
    // $FlowFixMe
    environment: __DEVELOPMENT__ ? 'dev' : 'prod',
  };

  // if (__DEVELOPMENT__) {
  //   // $FlowFixMe
  //   sentryConfig.defaultIntegrations = false;
  // }

  Sentry.init(sentryConfig);
}

const AsyncRoute = ({ render, component: Component, fallback, ...rest }) => (
  <Suspense fallback={fallback}>
    <SentryRoute
      render={render ? render : (props) => <Component {...props} />}
      {...rest}
    />
  </Suspense>
);

AsyncRoute.defaultProps = {
  fallback: <div />,
};

export default AsyncRoute;
