import { API_URL } from '~lib/constants';
import { KLAbortController } from '~lib/utils/KLAbortFactory';

import KleverApi from './api';

class ApiCategories {
  static async getCategoriesByType(type, token) {
    try {
      const json = await KleverApi.workerFetch(
        `${API_URL}/preferences/${type || 'industry'}`,
        {
          signal: KLAbortController.genController('getCategoriesByType').signal,
          method: 'GET',
          headers: {
            Authorization: `kl=${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      KLAbortController.clearController('getCategoriesByType');

      KleverApi.handleErrorCodes(json.code);
      if (!json.success) {
        KleverApi.handleErrorMessage(json);
      }

      return json;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
}

export default ApiCategories;
